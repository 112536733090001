import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiCamera } from '@mdi/js';
import { IconButton, Menu, MenuItem } from '@mui/material';
import PopupTitle from '../common/popup_title';
import PromptDialog from './prompt_dialog';
import {
  beginGenerateRecipeImageAIOperation,
  checkGenerateRecipeImageAIOperation,
} from '../../operations/meal_operations';
import { isEmbeddedInSmorgCompanion } from '../../services/smorg_companion_auth';

const awaitableTimeout = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

const MAX_WAIT_MS = 180000;
const POLL_INTERVAL_MS = 2000;

const EditMealImagePopupMenu = ({
  style,
  defaultAIPrompt,
  onImageGeneratedAI,
  onImageSelectedForUpload,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [aiPrompt, setAiPrompt] = useState(defaultAIPrompt);
  const [isLoading, setIsLoading] = useState(false);
  const [aiPromptVisible, setAiPromptVisible] = useState(null);
  const fileRef = useRef();

  const handleGenerateAI = () => {
    setAiPromptVisible(true);
    handlePopupClose();
  };

  const handleUpload = () => {
    handlePopupClose();
    fileRef.current.click();
  };

  const handlePopupClose = () => {
    setAnchorEl(null);
  };

  const onGenerateImageAI = async () => {
    setIsLoading(true);
    try {
      const jobID = await beginGenerateRecipeImageAIOperation(
        aiPrompt,
        '512x512',
      );
      console.log({ jobID });
      if (!jobID) {
        throw new Error('No job ID returned');
      }
      let msWaitedSoFar = 0;
      while (msWaitedSoFar < MAX_WAIT_MS) {
        // eslint-disable-next-line no-await-in-loop
        await awaitableTimeout(POLL_INTERVAL_MS);
        msWaitedSoFar += POLL_INTERVAL_MS;
        // eslint-disable-next-line no-await-in-loop
        const jobResult = await checkGenerateRecipeImageAIOperation(jobID);
        console.log({ jobResult });
        if (jobResult?.jobStatus === 'COMPLETED') {
          onImageGeneratedAI(jobResult.imageUrl);
          break;
        }
        if (jobResult?.jobStatus === 'FAILED') {
          break;
        }
      }
    } finally {
      setAiPromptVisible(false);
      setIsLoading(false);
    }
  };

  /**
   * Hack to disable 'Take photo' as an input option
   * in the embedded app.
   * This would require the permission from the user
   * to access the camera.
   * Without the permission, the app crashes.
   */
  const imageInputAcceptMimeTypes = isEmbeddedInSmorgCompanion()
    ? 'application/octet-stream'
    : 'image/png, image/jpeg, image/webp';

  return (
    <>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handlePopupClose}>
        <PopupTitle
          titleText="Image actions"
          closeButtonEnabled
          onClickClose={handlePopupClose}
        />
        <MenuItem onClick={handleGenerateAI}>
          Generate a new image using AI
        </MenuItem>
        <MenuItem onClick={handleUpload}>Upload an image</MenuItem>
      </Menu>
      <div>
        <IconButton
          sx={{
            backgroundColor: 'rgba(225, 225, 225, 0.6)',
            '&:hover': { backgroundColor: 'rgba(225, 225, 225, 1)' },
          }}
          style={style}
          onClick={(ev) => setAnchorEl(ev.target)}
          title="Upload new image">
          <Icon path={mdiCamera} style={{ width: '24px', height: '24px' }} />
        </IconButton>
        <input
          ref={fileRef}
          onChange={() => {
            onImageSelectedForUpload(fileRef.current.files);
            fileRef.current.value = '';
          }}
          multiple={false}
          type="file"
          accept={imageInputAcceptMimeTypes}
          hidden
        />
      </div>
      {aiPromptVisible && (
        <PromptDialog
          label="Describe the image you want to create"
          title="Create an image using AI"
          prompt={aiPrompt}
          onChange={setAiPrompt}
          isLoading={isLoading}
          onCreate={onGenerateImageAI}
          onDismiss={() => setAiPromptVisible(false)}
        />
      )}
    </>
  );
};

EditMealImagePopupMenu.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  defaultAIPrompt: PropTypes.string,
  onImageGeneratedAI: PropTypes.func.isRequired,
  onImageSelectedForUpload: PropTypes.func.isRequired,
};

EditMealImagePopupMenu.defaultProps = {
  defaultAIPrompt: null,
};

export default EditMealImagePopupMenu;
