import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { recipeCollectionsCardDOMID } from '../../services/meals';
import EndUserContentEntryItem from './end_user_content_entry_item';

const EndUserContentBoardLaneView = () => {
  const navigate = useNavigate();

  const { boardID, laneID } = useParams();

  const sharedContentBoard = useSelector((state) =>
    (state.sharedContentBoards || []).find((b) => b.id === boardID),
  );

  if (!sharedContentBoard) {
    return null;
  }

  const theLane = sharedContentBoard.lanes.find((l) => l.id === laneID);

  if (!theLane) {
    return null;
  }

  return (
    <div className="collections-view">
      <div className="topNavBarMobile" style={{ flexDirection: 'column' }}>
        <Box style={{ borderBottom: '1px solid #e1e1e1' }}>
          <div className="popup-title-back-button">
            <IconButton aria-label="back" onClick={() => navigate(-1)}>
              <ArrowBackIosOutlinedIcon />
            </IconButton>
          </div>
          <div
            style={{
              padding: '8px',
              textAlign: 'center',
            }}>
            <Typography variant="popupTitle">{theLane.title}</Typography>
          </div>
        </Box>
      </div>
      <div
        style={{
          marginTop: '20px',
        }}>
        <h1 style={{ marginLeft: '23px' }}>{theLane.title}</h1>
        <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '15px' }}>
          {theLane.entries.map((entry) => (
            <div
              key={entry.id}
              id={recipeCollectionsCardDOMID(entry.id)}
              style={{ flexShrink: 0 }}>
              <EndUserContentEntryItem contentEntryID={entry.objectID} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EndUserContentBoardLaneView;
