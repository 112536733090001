import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select } from '@mui/material';
import { useSelector } from 'react-redux';

const AppLinkObjectIDPickerSharedContentBoards = ({
  objectID,
  onChangeObjectID,
}) => {
  const sharedContentBoards = useSelector(
    (state) => state.sharedContentBoards || [],
  );

  return (
    <Select
      variant="standard"
      style={{ margin: 'auto 0', maxWidth: '140px' }}
      value={objectID || ''}
      displayEmpty
      onChange={(ev) => {
        const newValue = ev.target.value;
        onChangeObjectID(newValue);
      }}>
      <MenuItem value="" disabled>
        <em>Select a board</em>
      </MenuItem>
      {sharedContentBoards.map((sharedContentBoard) => (
        <MenuItem key={sharedContentBoard.id} value={sharedContentBoard.id}>
          {sharedContentBoard.title}
        </MenuItem>
      ))}
    </Select>
  );
};

AppLinkObjectIDPickerSharedContentBoards.propTypes = {
  objectID: PropTypes.string,
  onChangeObjectID: PropTypes.func.isRequired,
};

AppLinkObjectIDPickerSharedContentBoards.defaultProps = {
  objectID: '',
};

export default AppLinkObjectIDPickerSharedContentBoards;
