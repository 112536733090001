import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import {
  EntryType,
  GRCRecipe,
  Meal,
  Nutrition,
  NutritionConstraints,
  Programme,
  RecommendedMenu,
} from '../../API';
import { deduplicate } from '../../services/arrays';
import {
  macroRatioFor,
  sumNutrition,
  targetDayNutritionFromMetricConstraints,
} from '../../services/nutrition';
import {
  DAY_TITLES,
  PROGRAMMES_DEFAULT_TARGET_CALORIES,
} from '../../services/programmes';
import Board from '../common/board';
import { useIsMobile } from '../common/layout_hooks';
import ProgrammePlanDayHeader from './programme_plan_day_header';
import smorg_loader from '../../assets/images/smorg_loader.gif';
import MealDetailModal from '../meal/meal_detail_modal';
import { mealWithScaledIngredients } from '../../services/meals';
import { cloneObject } from '../../operations/utils';
import ProgrammePreviewEntryCard from './programme_preview_entry_card';

interface RootState {
  programmes: Array<Programme>;
  meals: Record<string, Meal>;
  grcRecipes: Record<string, GRCRecipe>;
}

interface ProgrammeBoardProps {
  recommendedMenu: RecommendedMenu | null;
  recommenderErrorMessage: string | null;
  // calorieTarget: number;
  isLoading: boolean;
  nutritionConstraints: NutritionConstraints | null | undefined;
  showNutritionToUsers: boolean | null | undefined;
}

const components = {
  Card: ProgrammePreviewEntryCard,
  LaneHeader: ProgrammePlanDayHeader,
};

const labels: Record<string, unknown> = {
  'Add another lane': '+ Add another menu',
  'Delete lane': 'Delete menu',
  'Lane actions': 'Menu actions',
  button: {
    'Add lane': 'Add menu',
    'Add card': 'Add meal',
    Cancel: 'Cancel',
  },
  placeholder: {
    title: 'title',
    description: 'description',
    label: 'label',
  },
};

const customTranslation = (key: string) => labels[key];

const boardCss = { textAlign: 'left' };
const mobileHeightRule = { height: 'calc(var(--app-height) - 119px)' };
const desktopHeightRule = { height: 'calc(var(--app-height) - 100px)' };
const mobileBoardCss = { ...boardCss, ...mobileHeightRule };
const desktopBoardCss = { ...boardCss, ...desktopHeightRule };

const ProgrammePlanBoardPreview = ({
  recommendedMenu,
  recommenderErrorMessage,
  isLoading,
  nutritionConstraints,
  showNutritionToUsers,
}: ProgrammeBoardProps) => {
  const [showDetailForEntryID, setShowDetailForEntryID] = useState<
    string | null
  >(null);

  const getDetailVisibleFor = () => {
    if (!showDetailForEntryID || !recommendedMenu?.days) {
      return null;
    }
    const pair = recommendedMenu.days
      .flatMap((d) =>
        d.entries
          .filter((e) => e.entryType === 'meal')
          .flatMap((e) =>
            e.meals.map((m) => ({
              dayTitle: DAY_TITLES[d.dayIndex],
              entryType:
                m.mealSource === 'smorg'
                  ? EntryType.MEAL
                  : EntryType.GRC_RECIPE,
              meal: m,
            })),
          ),
      )
      .find(({ meal }) => meal.id === showDetailForEntryID);
    if (!pair) {
      return null;
    }
    return {
      entryID: showDetailForEntryID,
      entryType: pair.entryType,
      recommendedMeal: pair.meal,
      dayTitle: pair.dayTitle,
    };
  };

  const detailVisibleFor = getDetailVisibleFor();

  console.log({ detailVisibleFor });

  const meals = useSelector((state: RootState) => state.meals);

  const grcRecipes = useSelector((state: RootState) => state.grcRecipes);

  const preferredMetrics = deduplicate(
    (nutritionConstraints?.nutritionMetricConstraints || []).map(
      (c) => c.nutritionMetric,
    ),
  ).slice(0, 3);

  const hasNutritionHeader = preferredMetrics.length > 0;

  // console.log(
  //   `ProgrammePlanBoardPreview hasNutritionHeader = ${hasNutritionHeader}`,
  // );

  if (hasNutritionHeader && !preferredMetrics.includes('calories')) {
    preferredMetrics.unshift('calories');
  }

  const preferredMetricsWithRules = preferredMetrics.map((metricName) => ({
    metricName,
    rules: [],
  }));

  const reactTrelloData = {
    lanes: (recommendedMenu?.days || []).map((day, dayIndex) => {
      const allEntries = day.entries
        .filter((e) => e.entryType === 'meal')
        .flatMap((e) => e.meals);
      const actualTotalNutrition = allEntries.reduce(
        (acc, m) => sumNutrition(acc, m.scaledNutrition || {}) as Nutrition,
        {},
      );

      const targetNutrition = targetDayNutritionFromMetricConstraints(
        nutritionConstraints?.nutritionMetricConstraints || [],
        preferredMetrics,
        PROGRAMMES_DEFAULT_TARGET_CALORIES,
      );

      const cards = allEntries
        .map((m) => {
          if (m.mealSource === 'smorg') {
            const originalMeal = meals[m.id];
            if (!originalMeal) {
              return null;
            }
            // const recommendedProvidedScaledIngredients = m.servings && m.scaledIngredientsFullText && m.scaledNutrition &&
            const meal = /* originalMeal */ mealWithScaledIngredients(
              cloneObject(originalMeal),
              m.servings,
              m.scaledIngredientsFullText || [],
              m.scaledNutrition,
              m.scaledStructuredIngredients || [],
              m.scaledDerivedIngredientNutrition || [],
            );
            return {
              id: m.id,
              title: meal.recipes[0].title,
              description: meal.recipes[0].shortDescription || '',
              style: {
                _meal: meal,
                _preferredMetricsWithRules: preferredMetricsWithRules,
                _targetNutrition: targetNutrition,
                _targetCalories: PROGRAMMES_DEFAULT_TARGET_CALORIES,
              },
            };
          }

          if (m.mealSource === 'grc') {
            const grcRecipe = grcRecipes[m.id];
            if (!grcRecipe) {
              return null;
            }
            const meal =
              /* { recipes: [grcRecipe.recipe] } */ mealWithScaledIngredients(
                { recipes: [grcRecipe.recipe] },
                m.servings,
                m.scaledIngredientsFullText || [],
                m.scaledNutrition,
                m.scaledStructuredIngredients || [],
                m.scaledDerivedIngredientNutrition || [],
              );
            return {
              id: m.id,
              title: grcRecipe.recipe.title,
              description: grcRecipe.recipe.shortDescription || '',
              style: {
                _programmeEntryType: EntryType.GRC_RECIPE,
                _meal: meal,
                _preferredMetricsWithRules: preferredMetricsWithRules,
                _targetNutrition: targetNutrition,
                _targetCalories: PROGRAMMES_DEFAULT_TARGET_CALORIES,
              },
            };
          }

          return null;
        })
        .filter((cardData) => !!cardData);

      const actualMacroRatio = actualTotalNutrition
        ? macroRatioFor(actualTotalNutrition)
        : {};

      const titleStyle: Record<string, any> = { _isPreview: true };
      if (hasNutritionHeader) {
        titleStyle._nutritionHeader = {
          targetNutrition,
          actualTotalNutrition,
          preferredMetricsWithRules,
          targetCalories: PROGRAMMES_DEFAULT_TARGET_CALORIES,
          actualMacroRatio,
          showNutritionToUsers,
        };
      }

      return {
        id: uuidv4(),
        title: DAY_TITLES[dayIndex],
        titleStyle,
        cards,
      };
    }),
  };

  const onCardClick = useCallback(
    (cardId: string, _metadata: object, _laneId: string) => {
      setShowDetailForEntryID(cardId);
    },
    [],
  );

  const isMobile = useIsMobile();

  if (isLoading) {
    return <img src={smorg_loader} alt="Please wait..." />;
  }

  const onDismissDetail = () => {
    setShowDetailForEntryID(null);
  };

  const showNutritionMetrics = [
    'calories',
    'fat',
    'carbohydrate',
    'protein',
    'fibre',
  ];

  let visibleDetailMeal = null;
  if (detailVisibleFor?.entryType === EntryType.MEAL) {
    visibleDetailMeal = mealWithScaledIngredients(
      cloneObject(meals[detailVisibleFor.entryID]),
      detailVisibleFor.recommendedMeal.servings,
      detailVisibleFor.recommendedMeal.scaledIngredientsFullText || [],
      detailVisibleFor.recommendedMeal.scaledNutrition,
      detailVisibleFor.recommendedMeal.scaledStructuredIngredients || [],
      detailVisibleFor.recommendedMeal.scaledDerivedIngredientNutrition || [],
    );
  } else if (detailVisibleFor?.entryType === EntryType.GRC_RECIPE) {
    visibleDetailMeal = mealWithScaledIngredients(
      { recipes: [grcRecipes[detailVisibleFor.entryID].recipe] },
      detailVisibleFor.recommendedMeal.servings,
      detailVisibleFor.recommendedMeal.scaledIngredientsFullText || [],
      detailVisibleFor.recommendedMeal.scaledNutrition,
      detailVisibleFor.recommendedMeal.scaledStructuredIngredients || [],
      detailVisibleFor.recommendedMeal.scaledDerivedIngredientNutrition || [],
    );
  }

  return (
    <>
      <div
        className={classNames('smorg-board-container', {
          'with-fixed-header': hasNutritionHeader,
        })}
        id="programme-board">
        <Board
          laneDraggable={false}
          cardDraggable={false}
          data={reactTrelloData}
          components={components}
          onCardClick={onCardClick}
          style={isMobile ? mobileBoardCss : desktopBoardCss}
          backdropContent={recommenderErrorMessage}
          t={customTranslation}
        />
      </div>
      {detailVisibleFor?.entryType === EntryType.MEAL && (
        <MealDetailModal
          meal={visibleDetailMeal}
          menu={{ title: detailVisibleFor.dayTitle }}
          isReadOnly
          alwaysShowNutrition
          savingInProgress={false}
          showNutritionMetrics={showNutritionMetrics}
          derivedNutrition={visibleDetailMeal.derivedNutrition}
          canScaleIngredients={false}
          scaleToServings={1}
          currentlyEditingInline={{ section: '', itemId: null }}
          currentTextRef={{ current: null }}
          ingredientSuggestions={[]}
          ingredientSuggestionsNetworkState={{ loading: false }}
          onInlineEditFocus={() => {}}
          onInlineEditChange={() => {}}
          onInlineEditBlur={() => {}}
          onAddArraySectionItem={() => {}}
          onRemoveArraySectionItem={() => {}}
          onIngredientCheckChange={() => {}}
          onEditScaleToServings={() => {}}
          onChangeScaleToServings={() => {}}
          resetScaleToServings={() => {}}
          onNewImageChosen={() => {}}
          onRuleChanged={() => {}}
          onAddonChange={() => {}}
          onTagsChange={() => {}}
          onNewImageUrlSet={() => {}}
          onDialogClose={onDismissDetail}
        />
      )}
      {detailVisibleFor?.entryType === EntryType.GRC_RECIPE && (
        <MealDetailModal
          meal={visibleDetailMeal}
          menu={{ title: detailVisibleFor.dayTitle }}
          isReadOnly
          alwaysShowNutrition
          savingInProgress={false}
          showNutritionMetrics={showNutritionMetrics}
          derivedNutrition={visibleDetailMeal.derivedNutrition}
          canScaleIngredients={false}
          scaleToServings={1}
          currentlyEditingInline={{ section: '', itemId: null }}
          currentTextRef={{ current: null }}
          ingredientSuggestions={[]}
          ingredientSuggestionsNetworkState={{ loading: false }}
          onInlineEditFocus={() => {}}
          onInlineEditChange={() => {}}
          onInlineEditBlur={() => {}}
          onAddArraySectionItem={() => {}}
          onRemoveArraySectionItem={() => {}}
          onIngredientCheckChange={() => {}}
          onEditScaleToServings={() => {}}
          onChangeScaleToServings={() => {}}
          resetScaleToServings={() => {}}
          onNewImageChosen={() => {}}
          onRuleChanged={() => {}}
          onAddonChange={() => {}}
          onTagsChange={() => {}}
          onNewImageUrlSet={() => {}}
          onDialogClose={onDismissDetail}
        />
      )}
    </>
  );
};

export default ProgrammePlanBoardPreview;
