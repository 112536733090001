import React, { useRef } from 'react';
import Icon from '@mdi/react';
import { mdiCamera } from '@mdi/js';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

const ImageUploadIconButton = ({ onChange }) => {
  const fileRef = useRef();
  return (
    <div>
      <Button
        variant="outlined"
        startIcon={<Icon style={{ height: 18, width: 18 }} path={mdiCamera} />}
        onClick={() => fileRef.current.click()}
        title="Upload new image">
        Upload
      </Button>
      <input
        ref={fileRef}
        onChange={() => {
          onChange(fileRef.current.files);
          fileRef.current.value = '';
        }}
        multiple={false}
        type="file"
        accept="image/png, image/jpeg, image/webp"
        hidden
      />
    </div>
  );
};

ImageUploadIconButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  // style: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ImageUploadIconButton;
