import { matchPath } from 'react-router-dom';

export const LOGIN_ROUTE = '/login';
export const END_USER_SIGNUP_ROUTE = '/signup';
export const SMORG_COMPANION_START_ROUTE = '/smorgCompanionStart';
export const SIGNUP_SMORG_COMPANION_ROUTE = '/signupSmorgCompanion';
export const MY_DAY_ROUTE = '/my-day';
export const MY_DAY_TODAYS_MEALS_ROUTE = '/my-day/todays-meals';
export const MY_DAY_MEAL_ROUTE = '/my-day/meal/:parentID/:mealID';
export const MY_DAY_TODAYS_LESSONS_ROUTE = '/my-day/todays-lessons';
export const MY_DAY_CONTENT_ENTRY_ROUTE =
  '/my-day/lesson/:sharedContentEntryID';
export const MY_DAY_SHOPPING_LIST_ROUTE =
  '/my-day/shoppingList/:shoppingListID';
export const PUBLIC_SPACE_LANDING_PAGE_ROUTE = '/s/:spaceId';
export const PUBLIC_SPACE_LANDING_PAGE_ONBOARD_ROUTE = '/s/:spaceId/onboard';
export const PUBLIC_SPACE_LANDING_PAGE_ONBOARD_SCREEN_ROUTE =
  '/s/:spaceId/onboard/:screenName';
export const END_USER_RECIPES_ROUTE = '/recipes';
export const END_USER_RECIPES_BOARD_ROUTE = '/recipes/:recipesBoardId';
export const END_USER_RECIPES_MULTI_MENUS_ROUTE = '/recipesMultiMenus';
export const END_USER_RECIPES_BOARD_MEAL_ROUTE =
  '/recipes/:recipesBoardId/meal/:mealId';
export const END_USER_RECIPES_GRC_RECIPE_CATEGORY_ROUTE =
  '/recipes/grcRecipeCategory/:categoryID';
export const END_USER_RECIPES_GRC_RECIPE_ROUTE =
  '/recipes/grcRecipe/:grcRecipeId';
export const END_USER_RECIPES_ADVANCED_SEARCH_ROUTE = '/recipes/search';
export const END_USER_PLANNER_RECOMMENDER_ROUTE =
  '/planner/:startDate/recommend/:dayIndex';
export const END_USER_SHARED_PROGRAMMES_ROUTE = '/shared_programmes';
export const END_USER_SHARED_PROGRAMME_ROUTE =
  '/shared_programmes/:sharedProgrammeId';
export const END_USER_SHARED_PROGRAMME_ONBOARD_ROUTE =
  '/shared_programmes/:sharedProgrammeId/onboard';
export const END_USER_SHARED_PROGRAMME_PROGRESS_ROUTE =
  '/shared_programmes/:sharedProgrammeId/progress';
export const END_USER_RECIPE_COLLECTIONS_ROUTE = '/recipeCollections';
export const END_USER_RECIPE_SINGLE_COLLECTION_ROUTE =
  '/recipeCollections/board/:boardID';
export const END_USER_RECIPE_SINGLE_COLLECTION_MENU_ROUTE =
  '/recipeCollections/board/:boardID/menu/:menuID';
export const END_USER_RECIPE_COLLECTION_MEAL_DETAIL_ROUTE =
  '/recipeCollections/board/:boardID/meal/:mealID';
export const END_USER_RECIPE_COLLECTION_EDIT_STRUCTURE_ROUTE =
  '/recipeCollections/board/:boardID/editStructure';
export const END_USER_RECIPE_COLLECTION_EDIT_COVER_IMAGE_ROUTE =
  '/recipeCollections/board/:boardID/editCoverImage';
export const END_USER_RECIPE_COLLECTION_ADD_MEALS_ROUTE =
  '/recipeCollections/board/:boardID/menu/:menuID/addMeals';
export const END_USER_RECIPE_COLLECTION_SEARCH_MEALS_ROUTE =
  '/recipeCollections/board/:boardID/menu/:menuID/searchMeals';
export const END_USER_RECIPE_COLLECTION_ADD_MEALS_BOARD_ROUTE =
  '/recipeCollections/board/:boardID/menu/:menuID/addMeals/board/:recipesBoardId';
export const END_USER_RECIPE_COLLECTION_ADD_MEALS_MULTI_MENUS_ROUTE =
  '/recipeCollections/board/:boardID/menu/:menuID/addMeals/recipesMultiMenus';
export const END_USER_FIND_ALTERNATIVES_ROUTE = '/alternatives/:calendarDay';
export const END_USER_FIND_ALTERNATIVES_WITH_MEAL_DETAIL_ROUTE =
  '/alternatives/:calendarDay/mealDetail/:mealDetailMealID';
export const END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_ROUTE =
  '/alternatives/:calendarDay/searchMeals';
export const END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_TOP_RESULTS_ROUTE =
  '/alternatives/:calendarDay/searchMeals/topResults';
export const END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_TOP_RESULTS_WITH_MEAL_DETAIL_ROUTE =
  '/alternatives/:calendarDay/searchMeals/topResults/mealDetail/:mealDetailMealID';
export const END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_GROUP_RESULTS_ROUTE =
  '/alternatives/:calendarDay/searchMeals/groupResults/:groupLabel';
export const END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_GROUP_RESULTS_WITH_MEAL_DETAIL_ROUTE =
  '/alternatives/:calendarDay/searchMeals/groupResults/:groupLabel/mealDetail/:mealDetailMealID';
export const END_USER_FIND_ALTERNATIVES_BOARD_ROUTE =
  '/alternatives/:calendarDay/results/board/:recipesBoardId';
export const END_USER_FIND_ALTERNATIVES_BOARD_WITH_MEAL_DETAIL_ROUTE =
  '/alternatives/:calendarDay/results/board/:recipesBoardId/mealDetail/:mealDetailMealID';
export const END_USER_FIND_ALTERNATIVES_MULTI_MENUS_ROUTE =
  '/alternatives/:calendarDay/results/recipesMultiMenus';
export const END_USER_FIND_ALTERNATIVES_MULTI_MENUS_WITH_MEAL_DETAIL_ROUTE =
  '/alternatives/:calendarDay/results/recipesMultiMenus/mealDetail/:mealDetailMealID';
export const END_USER_FIND_ALTERNATIVES_GRC_RECIPE_CATEGORY_ROUTE =
  '/alternatives/:calendarDay/results/grcRecipeCategory/:categoryID';
export const END_USER_FIND_ALTERNATIVES_GRC_RECIPE_ROUTE =
  '/alternatives/:calendarDay/results/grcRecipe/:grcRecipeId';
export const END_USER_CONTENT_BOARD_ROUTE = '/content/board/:boardID';
export const END_USER_CONTENT_BOARD_LANE_ROUTE =
  '/content/board/:boardID/lane/:laneID';
// export const END_USER_CONTENT_BOARD_DETAIL_ROUTE =
//   '/content/board/:boardID/lane/:laneID/entry/:entryID';
export const SPACE_ONBOARDING_AFTER_SIGNUP_ROUTE = '/onboard';
export const INITIAL_PROGRAMME_SELECTION_ROUTE = '/initialProgrammeSelection';
export const SMORG_STUDIO_MEMBERSHIP_TIERS_ROUTE = '/membership-tiers';
export const SMORG_STUDIO_MEMBERSHIP_TIER_ROUTE =
  '/membership-tiers/:membershipTierID';
export const SMORG_STUDIO_MEMBERSHIPS_ROUTE = '/memberships';
export const STRIPE_CONNECT_OAUTH_RETURN_URL = '/stripe-connect';
export const STRIPE_CHECKOUT_RETURN_URL = '/stripe-checkout';
export const SMORG_STUDIO_SPACE_EDIT_ROUTE = '/space';
export const SMORG_STUDIO_SPACE_THEME_EDIT_ROUTE = '/space/theme';
export const SMORG_STUDIO_SPACE_LANDING_PAGE_EDIT_ROUTE = '/space/landingPage';
export const SMORG_STUDIO_SPACE_SUBSCRIPTION_PAGE_EDIT_ROUTE =
  '/space/subscriptionPage';
export const SMORG_STUDIO_SPACE_SPLASH_PAGE_EDIT_ROUTE = '/space/splashPage';
export const SMORG_STUDIO_SPACE_DESKTOP_BANNER_IMAGE_EDIT_ROUTE =
  '/space/desktopBannerImage';
export const SMORG_STUDIO_SPACE_MY_DAY_CONTENT_CONFIG_ROUTE =
  '/space/myDayContent';
export const SMORG_STUDIO_SPACE_SHARED_CONTENT_BOARDS_ROUTE =
  '/spaceSharedContentBoards';
export const SMORG_STUDIO_SPACE_SHARED_CONTENT_BOARD_ROUTE =
  '/spaceSharedContentBoards/:sharedContentBoardID';
export const SMORG_STUDIO_SPACE_SHARED_CONTENT_BOARD_ENTRY_ROUTE =
  '/spaceSharedContentBoards/:sharedContentBoardID/entry/:entryId';
export const SMORG_STUDIO_SPACE_PUBLISHED_SEARCH_TAGS_ROUTE =
  '/space/publishedSearchTags';
export const END_USER_PREFERENCES_ACCOUNT_INFORMATION_MOBILE_ROUTE =
  '/user/account';
export const END_USER_PREFERENCES_PERSONAL_INFORMATION_MOBILE_ROUTE =
  '/user/personal';
export const END_USER_CHAT_ROUTE = '/chat';
export const END_USER_CHAT_GROUP_ROUTE = '/chat/:groupID';

export const routeAbsoluteUrl = (path) => `${window.location.origin}${path}`;

export const ACTION_KEY_MY_DAY = 'my_day';
export const ACTION_KEY_RECIPES = 'recipes';
export const ACTION_KEY_PLANNER = 'planner';
export const ACTION_KEY_RECIPE_COLLECTIONS = 'recipe_collections';
export const ACTION_KEY_SHARED_PROGRAMMES = 'shared_programmes';

const currentPathStartsWith = (str, locationPathname) => {
  return (
    matchPath(`${str}`, locationPathname) ||
    matchPath(`${str}/*`, locationPathname)
  );
};

export const mobileNavigationActiveKey = (locationPathname) => {
  let bottomNavigationActiveKey;
  if (currentPathStartsWith(MY_DAY_ROUTE, locationPathname)) {
    bottomNavigationActiveKey = ACTION_KEY_MY_DAY;
  }
  if (currentPathStartsWith('/recipes', locationPathname)) {
    bottomNavigationActiveKey = ACTION_KEY_RECIPES;
  }
  if (currentPathStartsWith('/board', locationPathname)) {
    bottomNavigationActiveKey = ACTION_KEY_RECIPES;
  }
  if (currentPathStartsWith('/planner', locationPathname)) {
    bottomNavigationActiveKey = ACTION_KEY_PLANNER;
  }
  if (currentPathStartsWith('/shared_programmes', locationPathname)) {
    bottomNavigationActiveKey = ACTION_KEY_SHARED_PROGRAMMES;
  }
  if (currentPathStartsWith('/recipeCollections', locationPathname)) {
    bottomNavigationActiveKey = ACTION_KEY_RECIPE_COLLECTIONS;
  }
  return bottomNavigationActiveKey;
};
