import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import PopupTitle from '../common/popup_title';
import { entryTypeForGroup } from '../common/meal_search_hook';
import { ensureObjectsLoadedAction } from '../../action_creators/recipes_action_creators';
import MealDetailModal from '../meal/meal_detail_modal';
import RecipeCollectionsSearchResultItem from './recipe_collections_search_result_item';
import { isUserSpaceMembershipDefaultSpaceSelector } from '../../reducers/user_reducer';
import NarrowEndUserMoreButton from '../meal/narrow_end_user_more_button';
import RelaxSearchCriteriaChips from '../common/relax_search_results_chips';

const RecipeCollectionsSearchGroupResultsView = ({
  groupResults,
  onBack,
  onDismiss,
  onLoadMore,
  destinationBoardID,
  destinationMenuID,
  onClick,
  searchCriteria,
  onChangeSearchCriteria,
}) => {
  const dispatch = useDispatch();

  const isCommunityUser = useSelector(
    isUserSpaceMembershipDefaultSpaceSelector,
  );

  const entryType = entryTypeForGroup(groupResults?.groupLabel);

  const objectIDs = (groupResults?.data || []).map(
    (result) => result.mealID || result.grcRecipeID,
  );
  const [showDetailForMealID, setShowDetailForMealID] = useState(null);

  const meals = useSelector((state) => state.meals);

  const sharedMeals = useSelector((state) => state.sharedMeals);

  const visibleMeal =
    showDetailForMealID &&
    (meals[showDetailForMealID] || sharedMeals[showDetailForMealID]);

  const onDismissDetail = () => setShowDetailForMealID(null);

  useEffect(() => {
    dispatch(ensureObjectsLoadedAction(entryType, objectIDs));
  }, [dispatch, entryType, JSON.stringify(objectIDs)]);

  if (!groupResults) {
    return null;
  }

  console.log({ nextOffset: groupResults.nextOffset });

  const showNutritionMetrics = [
    'calories',
    'fat',
    'carbohydrate',
    'protein',
    'fibre',
  ];

  const alwaysShowNutrition = !isCommunityUser;

  if (visibleMeal) {
    return (
      <MealDetailModal
        meal={visibleMeal}
        menu={null}
        isReadOnly
        alwaysShowNutrition={alwaysShowNutrition}
        savingInProgress={false}
        showNutritionMetrics={showNutritionMetrics}
        derivedNutrition={visibleMeal.derivedNutrition}
        canScaleIngredients={false}
        scaleToServings={1}
        currentlyEditingInline={{ section: '', itemId: null }}
        currentTextRef={{ current: null }}
        ingredientSuggestions={[]}
        ingredientSuggestionsNetworkState={{ loading: false }}
        onInlineEditFocus={() => {}}
        onInlineEditChange={() => {}}
        onInlineEditBlur={() => {}}
        onAddArraySectionItem={() => {}}
        onRemoveArraySectionItem={() => {}}
        onIngredientCheckChange={() => {}}
        onEditScaleToServings={() => {}}
        onChangeScaleToServings={() => {}}
        resetScaleToServings={() => {}}
        onNewImageChosen={() => {}}
        onRuleChanged={() => {}}
        onAddonChange={() => {}}
        onTagsChange={() => {}}
        onNewImageUrlSet={() => {}}
        onDialogClose={onDismissDetail}
      />
    );
  }

  return (
    <>
      <PopupTitle
        hideBorder
        titleText={groupResults.groupLabel}
        closeButtonEnabled
        backButtonEnabled
        onClickBack={onBack}
        onClickClose={onDismiss}
      />
      <div className="alternatives-page">
        <Grid container style={{ padding: 10 }}>
          <Grid item xs={12}>
            <h2>{groupResults.groupLabel}</h2>
          </Grid>
          <Grid item xs={12}>
            <RelaxSearchCriteriaChips
              searchCriteria={searchCriteria}
              onChangeSearchCriteria={onChangeSearchCriteria}
            />
          </Grid>
          {!groupResults.isLoading &&
            !groupResults.moreAvailable &&
            groupResults.data.length === 0 && (
              <p style={{ margin: '23px' }}>No results found</p>
            )}
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {groupResults.data.map((result) => {
                const id = result.mealID || result.grcRecipeID;
                return (
                  <Grid item xs={6} sm={4} md={3} lg={2} key={id}>
                    <RecipeCollectionsSearchResultItem
                      key={id}
                      entryType={entryType}
                      objectID={id}
                      title={result.title}
                      destinationBoardID={destinationBoardID}
                      destinationMenuID={destinationMenuID}
                      onClick={onClick}
                    />
                  </Grid>
                );
              })}
              {groupResults.isLoading && (
                <Grid item xs={6} sm={4} md={3} lg={2}>
                  <NarrowEndUserMoreButton isLoading onClick={() => {}} />
                </Grid>
              )}
              {!groupResults.isLoading && groupResults.moreAvailable && (
                <Grid item xs={6} sm={4} md={3} lg={2}>
                  <NarrowEndUserMoreButton
                    onClick={() =>
                      onLoadMore(
                        groupResults.groupLabel,
                        groupResults.nextOffset,
                      )
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

RecipeCollectionsSearchGroupResultsView.propTypes = {
  groupResults: PropTypes.arrayOf().isRequired,
  onBack: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  destinationBoardID: PropTypes.string.isRequired,
  destinationMenuID: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  searchCriteria: PropTypes.shape({
    searchString: PropTypes.string,
    mealTypes: PropTypes.arrayOf(PropTypes.string),
    excludeIngredients: PropTypes.arrayOf(PropTypes.string),
    includeIngredients: PropTypes.arrayOf(PropTypes.string),
    dietaryPreferences: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onChangeSearchCriteria: PropTypes.func.isRequired,
};

export default RecipeCollectionsSearchGroupResultsView;
