import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import { Box, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import PopupTitle from '../common/popup_title';
import { entryTypeForGroup } from '../common/meal_search_hook';
import { ensureObjectsLoadedAction } from '../../action_creators/recipes_action_creators';
import MealDetailModal from '../meal/meal_detail_modal';
import EndUserFindAlternativesItem from './end_user_find_alternatives_item';
import EndUserFindAlternativesSearchGroupResultsView from './end_user_find_alternatives_search_group_results_view';
import { END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_TOP_RESULTS_WITH_MEAL_DETAIL_ROUTE } from '../../services/routes';
import { linkWithQueryParams } from '../../services/urls';
import { isUserSpaceMembershipDefaultSpaceSelector } from '../../reducers/user_reducer';

const EndUserFindAlternativesSearchTopResultsView = ({
  results,
  onGoToGroup,
  onLoadMore,
  onBack,
  onDismiss,
  calendarDay,
  mealType,
  mealIDToReplace,
  searchCriteria,
  onChangeSearchCriteria,
}) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const isCommunityUser = useSelector(
    isUserSpaceMembershipDefaultSpaceSelector,
  );

  const { mealDetailMealID: showDetailForMealID } = useParams();

  const isBlank = results.every((group) => group.data.length === 0);

  const objectIDsByGroup = results.map((group) => ({
    groupLabel: group.groupLabel,
    entryType: entryTypeForGroup(group.groupLabel),
    objectIDs: group.data.map((result) => result.mealID || result.grcRecipeID),
  }));

  const meals = useSelector((state) => state.meals);

  const sharedMeals = useSelector((state) => state.sharedMeals);

  const visibleMeal =
    showDetailForMealID &&
    (meals[showDetailForMealID] || sharedMeals[showDetailForMealID]);

  const onClickResultDetail = (mealID) => {
    navigate(
      linkWithQueryParams(
        formatRoute(
          END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_TOP_RESULTS_WITH_MEAL_DETAIL_ROUTE,
          {
            calendarDay,
            mealDetailMealID: mealID,
          },
        ),
        { mealType, mealId: mealIDToReplace },
      ),
    );
  };

  const onDismissDetail = () => navigate(-1);

  useEffect(() => {
    objectIDsByGroup.forEach((group) =>
      dispatch(ensureObjectsLoadedAction(group.entryType, group.objectIDs)),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(objectIDsByGroup)]);

  const showNutritionMetrics = [
    'calories',
    'fat',
    'carbohydrate',
    'protein',
    'fibre',
  ];

  const groupsWithNonEmptyResults = results.filter(
    (group) => group.isLoading || group.moreAvailable || group.data.length > 0,
  );

  const theOnlyGroupLabel = results.length === 1 && results[0].groupLabel;

  const theOneGroupLabelWithNonEmptyResults =
    groupsWithNonEmptyResults.length === 1 &&
    groupsWithNonEmptyResults[0].groupLabel;

  const labelOfSingleGroupToDisplay =
    theOneGroupLabelWithNonEmptyResults || theOnlyGroupLabel;

  const alwaysShowNutrition = !isCommunityUser;

  if (visibleMeal) {
    return (
      <MealDetailModal
        meal={visibleMeal}
        menu={null}
        isReadOnly
        alwaysShowNutrition={alwaysShowNutrition}
        savingInProgress={false}
        showNutritionMetrics={showNutritionMetrics}
        derivedNutrition={visibleMeal.derivedNutrition}
        canScaleIngredients={false}
        scaleToServings={1}
        currentlyEditingInline={{ section: '', itemId: null }}
        currentTextRef={{ current: null }}
        ingredientSuggestions={[]}
        ingredientSuggestionsNetworkState={{ loading: false }}
        onInlineEditFocus={() => {}}
        onInlineEditChange={() => {}}
        onInlineEditBlur={() => {}}
        onAddArraySectionItem={() => {}}
        onRemoveArraySectionItem={() => {}}
        onIngredientCheckChange={() => {}}
        onEditScaleToServings={() => {}}
        onChangeScaleToServings={() => {}}
        resetScaleToServings={() => {}}
        onNewImageChosen={() => {}}
        onRuleChanged={() => {}}
        onAddonChange={() => {}}
        onTagsChange={() => {}}
        onNewImageUrlSet={() => {}}
        onDialogClose={onDismissDetail}
      />
    );
  }

  if (labelOfSingleGroupToDisplay) {
    const groupResults = results.find(
      (group) => group.groupLabel === labelOfSingleGroupToDisplay,
    );
    return (
      <EndUserFindAlternativesSearchGroupResultsView
        groupResults={groupResults}
        onLoadMore={onLoadMore}
        onBack={onBack}
        onDismiss={onDismiss}
        calendarDay={calendarDay}
        mealType={mealType}
        mealIDToReplace={mealIDToReplace}
        searchCriteria={searchCriteria}
        onChangeSearchCriteria={onChangeSearchCriteria}
      />
    );
  }

  return (
    <>
      <div
        className="topNavBarMobile topNavBarMobileLight"
        style={{ color: 'white', flexDirection: 'column' }}>
        <PopupTitle
          hideBorder
          titleText="Search results"
          backButtonEnabled
          closeButtonEnabled
          onClickBack={onBack}
          onClickClose={onDismiss}
        />
      </div>

      <div className="alternatives-page">
        {isBlank && <p style={{ margin: '23px' }}>No results found</p>}
        {results.map((group) => {
          if (group.data.length === 0) {
            return null;
          }
          return (
            <Grid container key={group.groupLabel} style={{ padding: 10 }}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={8}>
                    <h2 style={{ margin: '23px 0 5px' }}>{group.groupLabel}</h2>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '27px',
                        marginBottom: 0,
                      }}>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        href="#"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onGoToGroup(group.groupLabel);
                        }}>
                        View all
                      </a>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <div style={{ display: 'flex', overflowX: 'auto' }}>
                      {group.data.map((result) => {
                        const id = result.mealID || result.grcRecipeID;
                        return (
                          <EndUserFindAlternativesItem
                            key={id}
                            mealID={id}
                            calendarDay={calendarDay}
                            mealType={mealType}
                            mealIDToReplace={mealIDToReplace}
                            onClick={onClickResultDetail}
                          />
                        );
                      })}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </div>
    </>
  );
};

EndUserFindAlternativesSearchTopResultsView.propTypes = {
  results: PropTypes.arrayOf().isRequired,
  onGoToGroup: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  calendarDay: PropTypes.string.isRequired,
  mealType: PropTypes.string,
  mealIDToReplace: PropTypes.string,
  searchCriteria: PropTypes.shape({
    searchString: PropTypes.string,
    mealTypes: PropTypes.arrayOf(PropTypes.string),
    excludeIngredients: PropTypes.arrayOf(PropTypes.string),
    includeIngredients: PropTypes.arrayOf(PropTypes.string),
    dietaryPreferences: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onChangeSearchCriteria: PropTypes.func.isRequired,
};

EndUserFindAlternativesSearchTopResultsView.defaultProps = {
  mealType: null,
  mealIDToReplace: null,
};

export default EndUserFindAlternativesSearchTopResultsView;
