import { v4 as uuidv4 } from 'uuid';
import { ActivityObjectType, EntryType, OriginObjectType } from '../API';
import {
  addSharedContentEntryOperation,
  createSharedContentBoardOperation,
  getSharedContentBoardOperation,
  removeSharedContentEntriesOperation,
  removeSharedContentEntryOperation,
  updateSharedContentBoardOperation,
} from '../operations/content_entry_operations';
import {
  emailSpaceUrlToSelfOperation,
  getSpaceOperation,
  updateSpaceOperation,
  updateSpacePrivateConfigOperation,
} from '../operations/spaces_operations';
import { cloneObject } from '../operations/utils';
import { currentCreatorSpaceSelector } from '../reducers/user_reducer';
import {
  applySmorgStudioSpaceWhiteLabelRules,
  spaceContentBoardEntryByEntryID,
  spaceContentBoardEntryByObjectID,
} from '../services/spaces';
import { reportActivitySignalOnObjectAction } from './user_action_creators';
import { deduplicate } from '../services/arrays';
import {
  membershipAdminChangePasswordOperation,
  membershipAdminSetExpiresAtOperation,
} from '../operations/smorg_studio_memberships_operations';

export const updateSpaceAction = (space) => {
  return async (dispatch) => {
    dispatch({ type: 'SPACE_UPDATED', space });
    applySmorgStudioSpaceWhiteLabelRules(space.whiteLabelElements);
    dispatch(
      reportActivitySignalOnObjectAction(ActivityObjectType.SPACE, null),
    );
    dispatch(syncSpaceAction(space.id));
  };
};

export const syncSpaceAction = (spaceID) => {
  return async (dispatch, getState) => {
    const space = getState().spaces.find((s) => s.id === spaceID);
    try {
      const updatedSpace = await updateSpaceOperation(space);
      dispatch({
        type: 'SPACE_UPDATED',
        space: updatedSpace,
      });
    } catch (e) {
      console.log(e);
      const spaceFromBackend = await getSpaceOperation(spaceID);
      dispatch({
        type: 'SPACE_UPDATED',
        space: spaceFromBackend,
      });
    }
  };
};

export const emailSpaceUrlToSelfAction = (spaceUrl) => {
  return async (dispatch) => {
    await emailSpaceUrlToSelfOperation(spaceUrl);
    dispatch({
      type: 'SET_GLOBAL_SNACKBAR',
      notificationText: 'Email sent',
    });
  };
};

export const updateSpacePrivateConfigAction = (spacePrivateConfig) => {
  return async (dispatch) => {
    dispatch({
      type: 'SPACE_PRIVATE_CONFIG_UPDATED',
      spacePrivateConfig,
    });
    await updateSpacePrivateConfigOperation(spacePrivateConfig);
  };
};

export const updateSpaceStripeConfigAction = (spaceStripeConfig) => {
  return async (dispatch, getState) => {
    const { spacePrivateConfig } = getState();
    if (spaceStripeConfig) {
      const updatedSpacePrivateConfig = {
        ...(spacePrivateConfig || {}),
        stripeConfig: spaceStripeConfig,
      };
      dispatch(updateSpacePrivateConfigAction(updatedSpacePrivateConfig));
    }
  };
};

export const ensureSpaceContentBoardExistsAction = () => {
  return async (dispatch, getState) => {
    const space = currentCreatorSpaceSelector(getState());
    if ((space.spaceSharedContentBoardIDs || []).length === 0) {
      dispatch(createSharedContentBoardAction('App content', () => {}));
    }
  };
};

export const spaceContentBoardEntryQuickDuplicateAction = (
  parentID,
  entryID,
) => {
  return async (dispatch, getState) => {
    const spaceContentBoard = getState().sharedContentBoards.find(
      (b) => b.id === parentID,
    );
    const entry = spaceContentBoardEntryByEntryID(spaceContentBoard, entryID);
    if (!entry) {
      return;
    }
    let newObject = null;

    if (entry.entryType === EntryType.CONTENT_ENTRY) {
      const clonedSharedContentEntry = cloneObject(
        getState().sharedContentEntries[entry.objectID],
      );
      console.log({ clonedSharedContentEntry });
      const insertedSharedContentEntry = await addSharedContentEntryOperation(
        parentID,
        {
          ...clonedSharedContentEntry,
          origin: {
            originObjectType: OriginObjectType.CONTENT_ENTRY,
            originObjectID: entry.objectID,
          },
        },
      );
      newObject = insertedSharedContentEntry;
    }
    dispatch({
      type: 'SPACE_CONTENT_BOARD_ENTRY_QUICK_DUPLICATE',
      parentID,
      entryID,
      entryType: entry.entryType,
      newObject,
    });
    dispatch(
      reportActivitySignalOnObjectAction(
        ActivityObjectType.SHARED_CONTENT_BOARDS,
        parentID,
      ),
    );
    dispatch(syncSharedContentBoardAction(parentID));
  };
};

export const spaceContentBoardNewContentEntryAddedAction = (
  parentID,
  laneID,
  sharedContentEntryWithoutId,
  cb,
) => {
  return async (dispatch, getState) => {
    try {
      const sharedContentEntry = await addSharedContentEntryOperation(
        parentID,
        {
          ...sharedContentEntryWithoutId,
          origin: {
            originObjectType: OriginObjectType.CONTENT_ENTRY,
            originObjectID: '00000000-0000-0000-0000-000000000000',
          },
        },
      );
      if (!sharedContentEntry) {
        return;
      }
      dispatch({
        type: 'SPACE_CONTENT_BOARD_ENTRY_ADDED',
        parentID,
        entryID: uuidv4(),
        entryType: EntryType.CONTENT_ENTRY,
        objectID: sharedContentEntry.id,
        laneID,
        object: sharedContentEntry,
      });
      if (cb) {
        const entry = spaceContentBoardEntryByObjectID(
          getState().sharedContentBoards.find((b) => b.id === parentID),
          sharedContentEntry.id,
        );
        if (entry) {
          cb(entry.id);
        }
      }
    } finally {
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.SHARED_CONTENT_BOARDS,
          parentID,
        ),
      );
      dispatch(syncSharedContentBoardAction(parentID));
    }
  };
};

export const spaceContentBoardLaneAddedAction = (parentID, id, title) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'SPACE_CONTENT_BOARD_LANE_ADDED',
        parentID,
        laneID: id,
        title,
      });
    } finally {
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.SHARED_CONTENT_BOARDS,
          parentID,
        ),
      );
      dispatch(syncSharedContentBoardAction(parentID));
    }
  };
};

export const spaceContentBoardLaneDeletedAction = (parentID, laneID) => {
  return async (dispatch, getState) => {
    try {
      const spaceContentBoard = getState().sharedContentBoards.find(
        (b) => b.id === parentID,
      );
      const objectIDs = spaceContentBoard.lanes
        .find((l) => l.id === laneID)
        .entries.map((e) => e.objectID);
      try {
        await removeSharedContentEntriesOperation(objectIDs);
        // TODO use result
      } catch (e) {
        console.warn(e);
      }
      dispatch({ type: 'SPACE_CONTENT_BOARD_LANE_DELETED', parentID, laneID });
    } finally {
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.SHARED_CONTENT_BOARDS,
          parentID,
        ),
      );
      dispatch(syncSharedContentBoardAction(parentID));
    }
  };
};

export const spaceContentBoardLaneMovedAction = (
  parentID,
  laneID,
  removedIndex,
  addedIndex,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'SPACE_CONTENT_BOARD_LANE_MOVED',
      parentID,
      laneID,
      removedIndex,
      addedIndex,
    });
    dispatch(
      reportActivitySignalOnObjectAction(
        ActivityObjectType.SHARED_CONTENT_BOARDS,
        parentID,
      ),
    );
    dispatch(syncSharedContentBoardAction(parentID));
  };
};

export const spaceContentBoardLaneRenamedAction = (
  parentID,
  laneID,
  updatedTitle,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'SPACE_CONTENT_BOARD_LANE_RENAMED',
      parentID,
      laneID,
      updatedTitle,
    });
    dispatch(
      reportActivitySignalOnObjectAction(
        ActivityObjectType.SHARED_CONTENT_BOARDS,
        parentID,
      ),
    );
    dispatch(syncSharedContentBoardAction(parentID));
  };
};

export const spaceContentBoardEntryMovedAction = (
  parentID,
  entryID,
  sourceLaneID,
  targetLaneID,
  toPosition,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'SPACE_CONTENT_BOARD_ENTRY_MOVED',
      parentID,
      entryID,
      sourceLaneID,
      targetLaneID,
      toPosition,
    });
    dispatch(
      reportActivitySignalOnObjectAction(
        ActivityObjectType.SHARED_CONTENT_BOARDS,
        parentID,
      ),
    );
    dispatch(syncSharedContentBoardAction(parentID));
  };
};

export const spaceContentBoardEntryDeletedAction = (
  parentID,
  laneID,
  entryID,
) => {
  return async (dispatch, getState) => {
    const spaceContentBoard = getState().sharedContentBoards.find(
      (b) => b.id === parentID,
    );
    const entry = spaceContentBoardEntryByEntryID(spaceContentBoard, entryID);
    if (!entry) {
      return;
    }
    const { objectID } = entry;
    try {
      if (entry.programmeEntryType === EntryType.CONTENT_ENTRY) {
        await removeSharedContentEntryOperation(objectID);
      }
    } finally {
      dispatch({
        type: 'SPACE_CONTENT_BOARD_ENTRY_DELETED',
        parentID,
        laneID,
        entryID,
        objectID,
      });
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.SHARED_CONTENT_BOARDS,
          parentID,
        ),
      );
      dispatch(syncSharedContentBoardAction(parentID));
    }
  };
};

export const spaceMyDaySectionsUpdatedAction = (myDayScreenSections) => {
  return async (dispatch, getState) => {
    const space = currentCreatorSpaceSelector(getState());
    try {
      dispatch({
        type: 'SPACE_MY_DAY_SCREEN_SECTIONS_UPDATED',
        myDayScreenSections,
      });
    } finally {
      dispatch(
        reportActivitySignalOnObjectAction(ActivityObjectType.SPACE, null),
      );
      dispatch(syncSpaceAction(space.id));
    }
  };
};

export const spaceMyDayWelcomeVideoUpdatedAction = (videoPlaylistItem) => {
  return async (dispatch, getState) => {
    const space = currentCreatorSpaceSelector(getState());
    try {
      dispatch({
        type: 'SPACE_MY_DAY_WELCOME_VIDEO_UPDATED',
        videoPlaylistItem,
      });
    } finally {
      dispatch(
        reportActivitySignalOnObjectAction(ActivityObjectType.SPACE, null),
      );
      dispatch(syncSpaceAction(space.id));
    }
  };
};

export const spacePublishedSearchTagsUpdatedAction = (publishedSearchTags) => {
  return async (dispatch, getState) => {
    const space = currentCreatorSpaceSelector(getState());
    try {
      dispatch({
        type: 'SPACE_PUBLISHED_SEARCH_TAGS_UPDATED',
        publishedSearchTags,
      });
    } finally {
      dispatch(
        reportActivitySignalOnObjectAction(ActivityObjectType.SPACE, null),
      );
      dispatch(syncSpaceAction(space.id));
    }
  };
};

export const publishSharedBoardToSpaceAction = (sharedBoardID, cb) => {
  return async (dispatch, getState) => {
    const space = currentCreatorSpaceSelector(getState());
    const updatedSpace = {
      ...space,
      sharedRecipesBoardIDs: deduplicate([
        ...(space.sharedRecipesBoardIDs || []),
        sharedBoardID,
      ]),
    };
    dispatch(updateSpaceAction(updatedSpace));
    if (cb) {
      cb();
    }
  };
};

export const unpublishSharedBoardFromSpaceAction = (sharedBoardID, cb) => {
  return async (dispatch, getState) => {
    const space = currentCreatorSpaceSelector(getState());
    const updatedSpace = {
      ...space,
      sharedRecipesBoardIDs: (space.sharedRecipesBoardIDs || []).filter(
        (id) => id !== sharedBoardID,
      ),
    };
    dispatch(updateSpaceAction(updatedSpace));
    if (cb) {
      cb();
    }
  };
};

export const spaceSetDefaultSharedProgrammeIDForNewUsersAction = (
  sharedProgrammeID,
) => {
  return async (dispatch, getState) => {
    const space = currentCreatorSpaceSelector(getState());
    const updatedSpace = {
      ...space,
      defaultSharedProgrammeIDForNewUsers: sharedProgrammeID,
    };
    dispatch(updateSpaceAction(updatedSpace));
  };
};

export const membershipAdminSetExpiresAtAction = (
  spaceMembershipID,
  expiresAt,
) => {
  return async (dispatch) => {
    const newSmorgStudioMembership = await membershipAdminSetExpiresAtOperation(
      spaceMembershipID,
      expiresAt,
    );
    dispatch({
      type: 'SMORG_STUDIO_MEMBERSHIP_UPDATED',
      smorgStudioMembership: newSmorgStudioMembership,
    });
  };
};

export const membershipAdminChangePasswordAction = (
  spaceMembershipID,
  newPassword,
) => {
  return async () => {
    await membershipAdminChangePasswordOperation(
      spaceMembershipID,
      newPassword,
    );
  };
};

export const syncSharedContentBoardAction = (sharedContentBoardID) => {
  return async (dispatch, getState) => {
    const board = getState().sharedContentBoards.find(
      (b) => b.id === sharedContentBoardID,
    );
    try {
      const updatedBoard = await updateSharedContentBoardOperation(board);
      dispatch({
        type: 'SHARED_CONTENT_BOARD_UPDATED',
        updatedBoard,
      });
    } catch (e) {
      console.log(e);
      const sharedContentBoardFromBackend =
        await getSharedContentBoardOperation(sharedContentBoardID);
      dispatch({
        type: 'SHARED_CONTENT_BOARD_UPDATED',
        updatedBoard: sharedContentBoardFromBackend,
      });
    }
  };
};

export const createSharedContentBoardAction = (title, callback) => {
  return async (dispatch, getState) => {
    const space = currentCreatorSpaceSelector(getState());
    try {
      const sharedContentBoard = await createSharedContentBoardOperation({
        id: uuidv4(),
        title,
        lanes: [],
      });
      dispatch({
        type: 'SHARED_CONTENT_BOARD_BOARD_CREATED',
        sharedContentBoard,
      });
      // dispatch(
      //   trackAction(['Recipes board created'], ['numRecipesBoardsCreated']),
      // );
      dispatch(syncSpaceAction(space.id));
      callback(sharedContentBoard);
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.SHARED_CONTENT_BOARDS,
          sharedContentBoard.id,
        ),
      );
    } catch (e) {
      callback(e);
    }
  };
};

export const removeSharedContentBoardAction = (sharedContentBoardID) => {
  return async (dispatch, getState) => {
    const space = currentCreatorSpaceSelector(getState());
    try {
      const sharedContentBoard = getState().sharedContentBoards.find(
        (b) => b.id === sharedContentBoardID,
      );
      const objectIDs = sharedContentBoard.lanes
        .flatMap((l) => l.entries)
        .map((e) => e.objectID);
      await Promise.all(objectIDs.map(removeSharedContentEntryOperation));
      dispatch({
        type: 'SHARED_CONTENT_BOARD_BOARD_DELETED',
        sharedContentBoardID,
      });
    } finally {
      dispatch(syncSpaceAction(space.id));
    }
  };
};
