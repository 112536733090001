import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../common/logo';
import PingIndicator from '../common/ping_indicator';
import MealBasketWidget from '../meal/meal_basket_widget';
import { signOutAction } from '../../action_creators/user_action_creators';
import {
  anySharedProgrammesAvailableSelector,
  currentEndUserSpaceIDSelector,
  isUserSpaceMembershipDefaultSpaceSelector,
  userIsCreatorSelector,
} from '../../reducers/user_reducer';
import {
  END_USER_RECIPES_ROUTE,
  END_USER_SHARED_PROGRAMMES_ROUTE,
  LOGIN_ROUTE,
  SMORG_STUDIO_MEMBERSHIP_TIERS_ROUTE,
  SMORG_STUDIO_MEMBERSHIPS_ROUTE,
  SMORG_STUDIO_SPACE_EDIT_ROUTE,
  SMORG_STUDIO_SPACE_SHARED_CONTENT_BOARD_ENTRY_ROUTE,
  SMORG_STUDIO_SPACE_SHARED_CONTENT_BOARD_ROUTE,
  SMORG_STUDIO_SPACE_SHARED_CONTENT_BOARDS_ROUTE,
} from '../../services/routes';
import { linkWithQueryParams } from '../../services/urls';
import { doesSpaceProductizeRecipesBoardsDirectly } from '../../services/productized_shared_boards';
import AccountActionsPopupMenuTopnav from './account_actions_popup_menu_topnav';
import { anySmorgStudioMembershipAvailableSelector } from '../../reducers/spaces_reducer';

const TopNavBar = ({ mealBasketWidgetEnabled }) => {
  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const spaceID = useSelector(currentEndUserSpaceIDSelector);

  const handleSignOut = () => {
    onClosePopover();
    console.log('Signing out');
    dispatch(signOutAction());
    navigate(
      linkWithQueryParams(LOGIN_ROUTE, {
        intoSpace: spaceID,
      }),
    );
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const onClosePopover = () => {
    setAnchorEl(null);
  };

  const userIsCreator = useSelector(userIsCreatorSelector);

  const isSpaceContentSection =
    matchPath(
      SMORG_STUDIO_SPACE_SHARED_CONTENT_BOARDS_ROUTE,
      location.pathname,
    ) ||
    matchPath(
      SMORG_STUDIO_SPACE_SHARED_CONTENT_BOARD_ROUTE,
      location.pathname,
    ) ||
    matchPath(
      SMORG_STUDIO_SPACE_SHARED_CONTENT_BOARD_ENTRY_ROUTE,
      location.pathname,
    );

  const isCommunityUser = useSelector(
    isUserSpaceMembershipDefaultSpaceSelector,
  );

  const isSpaceWithProductizedRecipesBoards =
    doesSpaceProductizeRecipesBoardsDirectly(spaceID);

  const anyProgrammesAvailable = useSelector(
    anySharedProgrammesAvailableSelector,
  );

  const anySmorgStudioMembershipAvailable = useSelector(
    anySmorgStudioMembershipAvailableSelector,
  );

  const isProgramsAvailable =
    !isSpaceWithProductizedRecipesBoards &&
    !isCommunityUser &&
    anyProgrammesAvailable;

  return (
    <>
      <div className="topNavBar">
        <div className="sections">
          <Logo userIsCreator={userIsCreator} />
          <Link
            to={END_USER_RECIPES_ROUTE}
            className={location.pathname.startsWith('/recipes') && 'current'}>
            Recipes
          </Link>
          {!userIsCreator && (
            <Link
              to="/planner"
              className={location.pathname.startsWith('/planner') && 'current'}>
              Planner
            </Link>
          )}
          {!userIsCreator && isProgramsAvailable && (
            <Link
              to={END_USER_SHARED_PROGRAMMES_ROUTE}
              className={
                location.pathname.startsWith(
                  END_USER_SHARED_PROGRAMMES_ROUTE,
                ) && 'current'
              }>
              Programs
            </Link>
          )}
          {!userIsCreator && (
            <Link
              to="/products"
              className={
                location.pathname.startsWith('/products') && 'current'
              }>
              Shopping Lists
            </Link>
          )}
          {userIsCreator && (
            <Link
              to="/programmes"
              className={
                location.pathname.startsWith('/programmes') && 'current'
              }>
              Programs
            </Link>
          )}
          {userIsCreator && (
            <Link
              to={SMORG_STUDIO_MEMBERSHIP_TIERS_ROUTE}
              className={
                location.pathname.startsWith(
                  SMORG_STUDIO_MEMBERSHIP_TIERS_ROUTE,
                ) && 'current'
              }>
              Memberships
            </Link>
          )}
          {userIsCreator && (
            <Link
              to={SMORG_STUDIO_SPACE_SHARED_CONTENT_BOARDS_ROUTE}
              className={isSpaceContentSection && 'current'}>
              Content
            </Link>
          )}
          {userIsCreator && anySmorgStudioMembershipAvailable && (
            <Link
              to={SMORG_STUDIO_MEMBERSHIPS_ROUTE}
              className={
                location.pathname.startsWith(SMORG_STUDIO_MEMBERSHIPS_ROUTE) &&
                'current'
              }>
              Manage Members
            </Link>
          )}
          {userIsCreator && (
            <Link
              to={SMORG_STUDIO_SPACE_EDIT_ROUTE}
              className={
                location.pathname.startsWith(SMORG_STUDIO_SPACE_EDIT_ROUTE) &&
                !isSpaceContentSection &&
                'current'
              }>
              Your App
            </Link>
          )}
          <PingIndicator />
        </div>
        <div className="globalLinks">
          {mealBasketWidgetEnabled && <MealBasketWidget />}
          <div>
            <IconButton
              style={{ color: 'white' }}
              onClick={() => {
                window.open('https://www.help.smorg.io', '_blank');
              }}
              title="Help">
              <HelpOutlineIcon />
            </IconButton>
          </div>
          <div>
            <IconButton
              style={{ color: 'white' }}
              onClick={(ev) => {
                setAnchorEl(ev.target);
              }}
              title="Account">
              <AccountCircleIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <AccountActionsPopupMenuTopnav
        anchorEl={anchorEl}
        handleClose={onClosePopover}
        handleSignOut={handleSignOut}
      />
    </>
  );
};

TopNavBar.propTypes = {
  mealBasketWidgetEnabled: PropTypes.bool,
};

TopNavBar.defaultProps = {
  mealBasketWidgetEnabled: false,
};

export default TopNavBar;
