/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type SearchCriteria = {
  searchString?: string | null,
  locales?: Array< string > | null,
  parentIDs?: Array< string > | null,
  mealTypes?: Array< string > | null,
  cookTimeMin?: number | null,
  cookTimeMax?: number | null,
  excludeIngredients?: Array< string > | null,
  includeIngredients?: Array< string > | null,
  dietaryPreferences?: Array< string > | null,
  perMealNutritionConstraints?: Array< NutritionMetricConstraintInput > | null,
  tags?: Array< string > | null,
};

export type NutritionMetricConstraintInput = {
  id: string,
  nutritionMetric: string,
  operator: ConstraintOperator,
  value: string,
  units: string,
};

export enum ConstraintOperator {
  LESS_THAN = "LESS_THAN",
  GREATER_THAN = "GREATER_THAN",
}


export type GRCResultPage = {
  __typename: "GRCResultPage",
  data:  Array<GRCResult >,
  nextOffset: number,
  moreAvailable: boolean,
  estimatedTotalResults?: number | null,
};

export type GRCResult = {
  __typename: "GRCResult",
  grcRecipeID: string,
  title: string,
  imageUrl?: string | null,
  mealTypes?: Array< string > | null,
  score?: number | null,
};

export type OwnMealsResultPage = {
  __typename: "OwnMealsResultPage",
  data:  Array<OwnMealResult >,
  nextOffset: number,
  moreAvailable: boolean,
  estimatedTotalResults?: number | null,
};

export type OwnMealResult = {
  __typename: "OwnMealResult",
  mealID: string,
  parentID: string,
  title: string,
  imageUrl?: string | null,
  mealTypes?: Array< string > | null,
  score?: number | null,
};

export type GRCRecipeCategory = {
  __typename: "GRCRecipeCategory",
  id: string,
  title: string,
};

export type AddRecipeResult = {
  __typename: "AddRecipeResult",
  recipe?: Recipe | null,
  error?: string | null,
};

export type Recipe = {
  __typename: "Recipe",
  title: string,
  shortDescription?: string | null,
  imageUrl?: string | null,
  recipeUrl?: string | null,
  publisher?: string | null,
  chef?: string | null,
  mealiqId?: string | null,
  nutrition?: Nutrition | null,
  mealTypes?: Array< string > | null,
  tags?: Array< string > | null,
  supportedDiets?: Array< string > | null,
  ingredients:  Array<RecipeIngredient >,
  preparations:  Array<RecipePreparation >,
  utensils?:  Array<RecipeUtensil > | null,
  servings?: number | null,
  cookTime?: number | null,
  prepTime?: number | null,
  totalTime?: number | null,
  recipeNotes?: string | null,
};

export type Nutrition = {
  __typename: "Nutrition",
  calories?: number | null,
  protein?: number | null,
  carbohydrate?: number | null,
  fat?: number | null,
  fibre?: number | null,
  sugar?: number | null,
  saturates?: number | null,
  salt?: number | null,
  veg_weight?: number | null,
  cholesterol?: number | null,
  calcium?: number | null,
  iron?: number | null,
  magnesium?: number | null,
  potassium?: number | null,
  vitamin_a?: number | null,
  vitamin_b1?: number | null,
  vitamin_b2?: number | null,
  vitamin_b3?: number | null,
  vitamin_b5?: number | null,
  vitamin_b6?: number | null,
  vitamin_b12?: number | null,
  vitamin_c?: number | null,
  vitamin_d?: number | null,
  vitamin_e?: number | null,
  vitamin_k?: number | null,
  phosphorous?: number | null,
  zinc?: number | null,
  copper?: number | null,
  manganese?: number | null,
  selenium?: number | null,
  folate?: number | null,
  omega3_dha?: number | null,
  omega3_dpa?: number | null,
  omega3_epa?: number | null,
  sucrose?: number | null,
  glucose?: number | null,
  fructose?: number | null,
  lactose?: number | null,
  maltose?: number | null,
};

export type RecipeIngredient = {
  __typename: "RecipeIngredient",
  id?: string | null,
  fullText: string,
  structuredIngredient?: StructuredIngredient | null,
  tokFullText?: string | null,
  tokens?:  Array<IngredientToken | null > | null,
  scalingRules?: string | null,
  checkedInIngredientsList?: boolean | null,
};

export type StructuredIngredient = {
  __typename: "StructuredIngredient",
  name: string,
  quantity?: number | null,
  unitOfMeasure?: string | null,
  normalisedQuantity?: number | null,
  linkedIngredient?: LinkedIngredientDetails | null,
  resolvedNutrition?: boolean | null,
  resolvedNutritionError?: string | null,
};

export type LinkedIngredientDetails = {
  __typename: "LinkedIngredientDetails",
  status: boolean,
  linkedIngredientName: string,
};

export type IngredientToken = {
  __typename: "IngredientToken",
  fromChar: number,
  toChar: number,
  type?: string | null,
  label?: string | null,
};

export type RecipePreparation = {
  __typename: "RecipePreparation",
  id?: string | null,
  fullText: string,
};

export type RecipeUtensil = {
  __typename: "RecipeUtensil",
  id?: string | null,
  fullText: string,
};

export type DerivedNutritionStructuredIngredientInput = {
  ingredientID?: string | null,
  name: string,
  quantity?: number | null,
  unitOfMeasure?: string | null,
  linkedIngredientStatus?: boolean | null,
  linkedIngredientName?: string | null,
};

export type DerivedNutrition = {
  __typename: "DerivedNutrition",
  totalNutritionPerServing: Nutrition,
  ingredientNutrition:  Array<DerivedIngredientNutrition >,
  foodAllergens?: Array< string > | null,
  foodPreferences?: Array< string > | null,
  status?: boolean | null,
  macroRatio?: MacroRatio | null,
};

export type DerivedIngredientNutrition = {
  __typename: "DerivedIngredientNutrition",
  ingredientID?: string | null,
  nutrition: Nutrition,
  resolvedNutrition?: boolean | null,
  error?: string | null,
  resolvedIngredientWeight?: number | null,
};

export type MacroRatio = {
  __typename: "MacroRatio",
  carbohydrate?: number | null,
  protein?: number | null,
  fat?: number | null,
};

export type DerivedNutritionMealInput = {
  mealID: string,
  servings: number,
  structuredIngredients: Array< DerivedNutritionStructuredIngredientInput >,
  publisher?: string | null,
  chef?: string | null,
};

export type DerivedNutritionMealResult = {
  __typename: "DerivedNutritionMealResult",
  mealID: string,
  derivedNutrition: DerivedNutrition,
};

export type RecommenderMealInput = {
  id: string,
  servings: number,
  recipes: Array< RecommenderRecipeInput >,
};

export type RecommenderRecipeInput = {
  mealiqId?: string | null,
  ingredients: Array< RecommenderIngredientInput >,
  servings?: number | null,
};

export type RecommenderIngredientInput = {
  name: string,
  quantity: number,
  unitOfMeasure: string,
  linkedIngredient: LinkedIngredientDetailsInput,
};

export type LinkedIngredientDetailsInput = {
  status: boolean,
  linkedIngredientName: string,
};

export type PreviousShoppingListItemInput = {
  name: string,
  total?: Array< PreviousShoppingListUnitQuantityInput > | null,
  quantities?: Array< PreviousShoppingListUnitQuantityInput > | null,
  aisleLocation?: string | null,
  forMealIds?: Array< string > | null,
};

export type PreviousShoppingListUnitQuantityInput = {
  quantity?: number | null,
  prettyQuantity?: string | null,
  unitOfMeasure?: string | null,
};

export type ShoppingListItem = {
  __typename: "ShoppingListItem",
  id: string,
  name: string,
  total?:  Array<UnitQuantity > | null,
  quantities?:  Array<UnitQuantity > | null,
  linkedIngredient?: LinkedIngredientDetails | null,
  aisleLocation?: string | null,
  forMealIds?: Array< string > | null,
  checked?: boolean | null,
};

export type UnitQuantity = {
  __typename: "UnitQuantity",
  quantity?: number | null,
  prettyQuantity?: string | null,
  unitOfMeasure?: string | null,
};

export type IngredientSuggestionSearchInput = {
  id?: string | null,
  fullText: string,
  structuredIngredient: RecommenderIngredientInput,
  tokFullText: string,
  tokens: Array< IngredientSuggestionTokenInput >,
};

export type IngredientSuggestionTokenInput = {
  fromChar: number,
  toChar: number,
  label: string,
};

export type IngredientSuggestionResponse = {
  __typename: "IngredientSuggestionResponse",
  suggestions:  Array<IngredientSuggestion >,
};

export type IngredientSuggestion = {
  __typename: "IngredientSuggestion",
  suggestionText: string,
  suggestionType?: string | null,
  highlightTokens?:  Array<IngredientSuggestionToken > | null,
};

export type IngredientSuggestionToken = {
  __typename: "IngredientSuggestionToken",
  fromChar: number,
  toChar: number,
};

export type IngredientScaleInput = {
  fullText: string,
  structuredIngredient: RecommenderIngredientInput,
  tokFullText: string,
  tokens: Array< IngredientSuggestionTokenInput >,
  scalingRules: string,
};

export type EmailShoppingListInput = {
  title: string,
  items: Array< EmailShoppingListItemInput >,
};

export type EmailShoppingListItemInput = {
  name: string,
  total: PreviousShoppingListUnitQuantityInput,
  aisleLocation?: string | null,
  forMealNames?: Array< string > | null,
};

export type MixpanelGeolocation = {
  __typename: "MixpanelGeolocation",
  countryCode: string,
};

export type GenerateImageResult = {
  __typename: "GenerateImageResult",
  jobID: string,
  jobStatus: JobStatus,
  imageUrl?: string | null,
};

export enum JobStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}


export type GenerateRecipeResult = {
  __typename: "GenerateRecipeResult",
  jobID: string,
  jobStatus: JobStatus,
  recipe?: Recipe | null,
};

export type ModerateRecipeResult = {
  __typename: "ModerateRecipeResult",
  jobID: string,
  jobStatus: JobStatus,
  moderatedIngredients: Array< string >,
  moderatedPreparations: Array< string >,
};

export type ServiceStatus = {
  __typename: "ServiceStatus",
  overallStatus: string,
  publicIp?: string | null,
};

export type AccountActivitySignalReport = {
  spaceID: string,
  friendlyUsername?: string | null,
  lastActivityObjectType?: ActivityObjectType | null,
  lastActivityObjectID?: string | null,
};

export enum ActivityObjectType {
  RECIPES = "RECIPES",
  PROGRAMMES = "PROGRAMMES",
  SPACE = "SPACE",
  SHARED_CONTENT_BOARDS = "SHARED_CONTENT_BOARDS",
}


export type OnboardingAnswerInput = {
  questionID: string,
  answer: string,
};

export enum SpaceMembershipReferrer {
  END_USER_VIA_WEB_APP = "END_USER_VIA_WEB_APP",
  END_USER_VIA_IOS_APP = "END_USER_VIA_IOS_APP",
  END_USER_VIA_ANDROID_APP = "END_USER_VIA_ANDROID_APP",
  SMORG_PUBLIC_API_PROVISIONING = "SMORG_PUBLIC_API_PROVISIONING",
}


export type SpaceMembership = {
  __typename: "SpaceMembership",
  spaceID: string,
  memberID: string,
  state: SpaceMembershipState,
  correlatedUserID?: string | null,
  expiresAt?: string | null,
  visibleProgrammeIDsOverride?: Array< string > | null,
  membershipTierID?: string | null,
  stripeCustomerID?: string | null,
  stripeSubscriptionID?: string | null,
  onboardingAnswers?:  Array<OnboardingAnswer > | null,
  onboardingAnswersLastUpdatedAt?: string | null,
  customTargetCalories?: number | null,
  welcomeVideoWatched?: boolean | null,
  initialProgrammeSelectionLastOfferedAt?: string | null,
  chatNickname?: string | null,
  referrer?: SpaceMembershipReferrer | null,
  appStoreSubscriptionID?: string | null,
  groups?: Array< string | null > | null,
  createdAt: string,
  id: string,
  updatedAt: string,
};

export enum SpaceMembershipState {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  LAPSED = "LAPSED",
}


export type OnboardingAnswer = {
  __typename: "OnboardingAnswer",
  questionID: string,
  answer: string,
};

export type UpdateMembershipResponse = {
  __typename: "UpdateMembershipResponse",
  spaceMembershipID: string,
  appStoreTransactionReceipt: string,
  result?: UpdateMembershipResult | null,
  membershipExpiresAt?: string | null,
};

export enum UpdateMembershipResult {
  OK = "OK",
  REJECTED = "REJECTED",
}


export type MenuRecommendationRequest = {
  locales?: Array< string > | null,
  mealTypes: Array< string >,
  numDays: number,
  servings?: number | null,
  constraints?: NutritionConstraintsInput | null,
  personalisationData?: MenuRecommendationPersonalisationDataInput | null,
  existingDays: Array< ExistingMenuDayInput >,
  startDayIndex?: number | null,
  alternativeMealParentIDs?: Array< string > | null,
  calorieSplits?: Array< CalorieSplitInput > | null,
  personalisedMealScaling?: boolean | null,
  sources?: Array< RecommendationSource > | null,
  targetCaloriesPerDay?: number | null,
};

export type NutritionConstraintsInput = {
  nutritionMetricConstraints?: Array< NutritionMetricConstraintInput > | null,
  excludeIngredients?: Array< string > | null,
  foodPreferences?: Array< FoodPreference > | null,
};

export enum FoodPreference {
  VEGETARIAN = "VEGETARIAN",
  VEGAN = "VEGAN",
}


export type MenuRecommendationPersonalisationDataInput = {
  age?: number | null,
  gender?: string | null,
  weight?: number | null,
  height?: number | null,
  goal?: string | null,
  exerciseLevel?: string | null,
  targetWeight?: number | null,
  allergens?: Array< string > | null,
};

export type ExistingMenuDayInput = {
  dayIndex: number,
  entries: Array< ExistingMenuEntryInput >,
};

export type ExistingMenuEntryInput = {
  entryType: string,
  mealType: string,
  meals: Array< ExistingMealInput >,
};

export type ExistingMealInput = {
  mealSource: string,
  id: string,
  servings: number,
  categoryTags: Array< string >,
  scaleFactor: number,
};

export type CalorieSplitInput = {
  mealType: string,
  fraction: number,
};

export enum RecommendationSource {
  GRC = "GRC",
  SMORG = "SMORG",
}


export type RecommendedMenu = {
  __typename: "RecommendedMenu",
  days:  Array<RecommendedMenuDay >,
  errorMessage?: string | null,
};

export type RecommendedMenuDay = {
  __typename: "RecommendedMenuDay",
  dayIndex: number,
  entries:  Array<RecommendedMenuEntry >,
};

export type RecommendedMenuEntry = {
  __typename: "RecommendedMenuEntry",
  entryType: string,
  mealType: string,
  meals:  Array<RecommendedMeal >,
};

export type RecommendedMeal = {
  __typename: "RecommendedMeal",
  mealSource: string,
  id: string,
  servings: number,
  categoryTags: Array< string >,
  scaleFactor: number,
  scaledIngredientsFullText?: Array< string > | null,
  scaledNutrition?: Nutrition | null,
  scaledStructuredIngredients?:  Array<StructuredIngredient > | null,
  scaledDerivedIngredientNutrition?:  Array<DerivedIngredientNutrition > | null,
};

export type SpaceStripeConfig = {
  __typename: "SpaceStripeConfig",
  environment: StripeEnvironment,
  connectedAccountID: string,
  chargesEnabled: boolean,
  stripeProductID?: string | null,
};

export enum StripeEnvironment {
  LIVE = "LIVE",
  TEST = "TEST",
}


export enum SubscriptionDuration {
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  ANNUALLY = "ANNUALLY",
}


export type StripeCheckoutSession = {
  __typename: "StripeCheckoutSession",
  url: string,
};

export enum StripeCheckoutSessionState {
  PENDING = "PENDING",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
}


export type MembershipTier = {
  __typename: "MembershipTier",
  spaceID: string,
  title: string,
  shortDescription?: string | null,
  monthlyPrice: string,
  quarterlyPrice?: string | null,
  annualPrice?: string | null,
  freeTrialDurationDays?: number | null,
  rank?: number | null,
  currencyCode?: string | null,
  state: MembershipTierState,
  stripeProductID?: string | null,
  stripePriceID?: string | null,
  stripePriceIDQuarterly?: string | null,
  stripePriceIDAnnual?: string | null,
  groups?: Array< string | null > | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export enum MembershipTierState {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export type VideoPlaylistItem = {
  __typename: "VideoPlaylistItem",
  videoID: string,
  title: string,
  description?: string | null,
  thumbnailImageUrl?: string | null,
};

export type ChatEnrollResult = {
  __typename: "ChatEnrollResult",
  status: string,
  authToken?: string | null,
};

export type ChatLoginResult = {
  __typename: "ChatLoginResult",
  authToken: string,
};

export type ChatGroup = {
  __typename: "ChatGroup",
  guid: string,
  name: string,
};

export type SmorgStudioMembership = {
  __typename: "SmorgStudioMembership",
  id: string,
  createdAt: string,
  spaceID: string,
  memberID: string,
  email: string,
  givenName?: string | null,
  familyName?: string | null,
  state: SpaceMembershipState,
  correlatedUserID?: string | null,
  expiresAt?: string | null,
  membershipTierID?: string | null,
  stripeCustomerID?: string | null,
  stripeSubscriptionID?: string | null,
  onboardingAnswers?:  Array<OnboardingAnswer > | null,
  onboardingAnswersLastUpdatedAt?: string | null,
  customTargetCalories?: number | null,
  welcomeVideoWatched?: boolean | null,
  initialProgrammeSelectionLastOfferedAt?: string | null,
  chatNickname?: string | null,
  referrer?: SpaceMembershipReferrer | null,
  appStoreSubscriptionID?: string | null,
};

export type PasswordChangeResult = {
  __typename: "PasswordChangeResult",
  success: boolean,
  message?: string | null,
};

export type CreateZapierRestHookInput = {
  spaceID: string,
  hookUrl: string,
  hookKey: string,
  enabled: boolean,
  id?: string | null,
};

export type ModelZapierRestHookConditionInput = {
  spaceID?: ModelIDInput | null,
  hookUrl?: ModelStringInput | null,
  hookKey?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  and?: Array< ModelZapierRestHookConditionInput | null > | null,
  or?: Array< ModelZapierRestHookConditionInput | null > | null,
  not?: ModelZapierRestHookConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ZapierRestHook = {
  __typename: "ZapierRestHook",
  spaceID: string,
  hookUrl: string,
  hookKey: string,
  enabled: boolean,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateZapierRestHookInput = {
  spaceID?: string | null,
  hookUrl?: string | null,
  hookKey?: string | null,
  enabled?: boolean | null,
  id: string,
};

export type DeleteZapierRestHookInput = {
  id: string,
};

export type CreateUserProfileInput = {
  id?: string | null,
  showOnboarding?: boolean | null,
  consentToProductComms?: boolean | null,
  plannerViewWeekStartDay?: number | null,
  geoRegion?: string | null,
  countryCode?: string | null,
  mealPlanReminderSchedule?: string | null,
  showMealDetailGuide?: boolean | null,
  recentlyUsedTags?: Array< string | null > | null,
  activitySummary?: UserActivitySummaryInput | null,
  enabledFeatures?: Array< string | null > | null,
  requestedFeatures?: Array< string | null > | null,
  seenMessages?: Array< string | null > | null,
  recentlyUsedRecommenderMealTypes?: Array< string > | null,
  recentlyUsedRecommenderCalorieSplits?: Array< CalorieSplitInput > | null,
  recentlyUsedSearchCriteria?: PersistedSearchCriteriaInput | null,
};

export type UserActivitySummaryInput = {
  numMealsViewed?: number | null,
  numMealsViewedMobile?: number | null,
  numShoppingListsViewed?: number | null,
  numShoppingListsViewedMobile?: number | null,
  numMealsEdited?: number | null,
  numNutritionClicks?: number | null,
  numMealsPlanned?: number | null,
  numMealsAdded?: number | null,
  numMealsImported?: number | null,
  numMealsAddedManually?: number | null,
  numPlansViewed?: number | null,
  numShoppingListsCopiedToClipboard?: number | null,
  numShoppingListsEmailed?: number | null,
  numShoppingListsCreated?: number | null,
  numShops?: number | null,
  numNotesAdded?: number | null,
  numNotesViewed?: number | null,
  numNotesViewedMobile?: number | null,
  numRecipesBoardsCreated?: number | null,
  numRecipesBoardsShared?: number | null,
};

export type PersistedSearchCriteriaInput = {
  searchString?: string | null,
  mealTypes?: Array< string > | null,
  cookTimeMin?: number | null,
  cookTimeMax?: number | null,
  excludeIngredients?: Array< string > | null,
  includeIngredients?: Array< string > | null,
  dietaryPreferences?: Array< string > | null,
  perMealNutritionConstraints?: Array< NutritionMetricConstraintInput > | null,
  tags?: Array< string > | null,
};

export type ModelUserProfileConditionInput = {
  showOnboarding?: ModelBooleanInput | null,
  consentToProductComms?: ModelBooleanInput | null,
  plannerViewWeekStartDay?: ModelIntInput | null,
  geoRegion?: ModelStringInput | null,
  countryCode?: ModelStringInput | null,
  mealPlanReminderSchedule?: ModelStringInput | null,
  showMealDetailGuide?: ModelBooleanInput | null,
  recentlyUsedTags?: ModelStringInput | null,
  enabledFeatures?: ModelStringInput | null,
  requestedFeatures?: ModelStringInput | null,
  seenMessages?: ModelStringInput | null,
  recentlyUsedRecommenderMealTypes?: ModelStringInput | null,
  and?: Array< ModelUserProfileConditionInput | null > | null,
  or?: Array< ModelUserProfileConditionInput | null > | null,
  not?: ModelUserProfileConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UserProfile = {
  __typename: "UserProfile",
  id: string,
  showOnboarding?: boolean | null,
  consentToProductComms?: boolean | null,
  plannerViewWeekStartDay?: number | null,
  geoRegion?: string | null,
  countryCode?: string | null,
  mealPlanReminderSchedule?: string | null,
  showMealDetailGuide?: boolean | null,
  recentlyUsedTags?: Array< string | null > | null,
  activitySummary?: UserActivitySummary | null,
  enabledFeatures?: Array< string | null > | null,
  requestedFeatures?: Array< string | null > | null,
  seenMessages?: Array< string | null > | null,
  recentlyUsedRecommenderMealTypes?: Array< string > | null,
  recentlyUsedRecommenderCalorieSplits?:  Array<CalorieSplit > | null,
  recentlyUsedSearchCriteria?: PersistedSearchCriteria | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UserActivitySummary = {
  __typename: "UserActivitySummary",
  numMealsViewed?: number | null,
  numMealsViewedMobile?: number | null,
  numShoppingListsViewed?: number | null,
  numShoppingListsViewedMobile?: number | null,
  numMealsEdited?: number | null,
  numNutritionClicks?: number | null,
  numMealsPlanned?: number | null,
  numMealsAdded?: number | null,
  numMealsImported?: number | null,
  numMealsAddedManually?: number | null,
  numPlansViewed?: number | null,
  numShoppingListsCopiedToClipboard?: number | null,
  numShoppingListsEmailed?: number | null,
  numShoppingListsCreated?: number | null,
  numShops?: number | null,
  numNotesAdded?: number | null,
  numNotesViewed?: number | null,
  numNotesViewedMobile?: number | null,
  numRecipesBoardsCreated?: number | null,
  numRecipesBoardsShared?: number | null,
};

export type CalorieSplit = {
  __typename: "CalorieSplit",
  mealType: string,
  fraction: number,
};

export type PersistedSearchCriteria = {
  __typename: "PersistedSearchCriteria",
  searchString?: string | null,
  mealTypes?: Array< string > | null,
  cookTimeMin?: number | null,
  cookTimeMax?: number | null,
  excludeIngredients?: Array< string > | null,
  includeIngredients?: Array< string > | null,
  dietaryPreferences?: Array< string > | null,
  perMealNutritionConstraints?:  Array<NutritionMetricConstraint > | null,
  tags?: Array< string > | null,
};

export type NutritionMetricConstraint = {
  __typename: "NutritionMetricConstraint",
  id: string,
  nutritionMetric: string,
  operator: ConstraintOperator,
  value: string,
  units: string,
};

export type UpdateUserProfileInput = {
  id: string,
  showOnboarding?: boolean | null,
  consentToProductComms?: boolean | null,
  plannerViewWeekStartDay?: number | null,
  geoRegion?: string | null,
  countryCode?: string | null,
  mealPlanReminderSchedule?: string | null,
  showMealDetailGuide?: boolean | null,
  recentlyUsedTags?: Array< string | null > | null,
  activitySummary?: UserActivitySummaryInput | null,
  enabledFeatures?: Array< string | null > | null,
  requestedFeatures?: Array< string | null > | null,
  seenMessages?: Array< string | null > | null,
  recentlyUsedRecommenderMealTypes?: Array< string > | null,
  recentlyUsedRecommenderCalorieSplits?: Array< CalorieSplitInput > | null,
  recentlyUsedSearchCriteria?: PersistedSearchCriteriaInput | null,
};

export type DeleteUserProfileInput = {
  id: string,
};

export type CreateObjectEditEventInput = {
  id?: string | null,
  objectID: string,
  objectType: EntryType,
  section: string,
  itemID?: string | null,
  oldValue?: string | null,
  createdAt?: string | null,
  groups?: Array< string | null > | null,
};

export enum EntryType {
  MEAL = "MEAL",
  NOTE = "NOTE",
  GRC_RECIPE = "GRC_RECIPE",
  CONTENT_ENTRY = "CONTENT_ENTRY",
}


export type ModelObjectEditEventConditionInput = {
  objectID?: ModelIDInput | null,
  objectType?: ModelEntryTypeInput | null,
  section?: ModelStringInput | null,
  itemID?: ModelStringInput | null,
  oldValue?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  groups?: ModelStringInput | null,
  and?: Array< ModelObjectEditEventConditionInput | null > | null,
  or?: Array< ModelObjectEditEventConditionInput | null > | null,
  not?: ModelObjectEditEventConditionInput | null,
};

export type ModelEntryTypeInput = {
  eq?: EntryType | null,
  ne?: EntryType | null,
};

export type ObjectEditEvent = {
  __typename: "ObjectEditEvent",
  id: string,
  objectID: string,
  objectType: EntryType,
  section: string,
  itemID?: string | null,
  oldValue?: string | null,
  createdAt: string,
  groups?: Array< string | null > | null,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateObjectEditEventInput = {
  id: string,
  objectID?: string | null,
  objectType?: EntryType | null,
  section?: string | null,
  itemID?: string | null,
  oldValue?: string | null,
  createdAt?: string | null,
  groups?: Array< string | null > | null,
};

export type DeleteObjectEditEventInput = {
  id: string,
};

export type CreateMealInput = {
  id?: string | null,
  schemaVersion: number,
  smorgBoardID: string,
  recipes: Array< RecipeInput >,
  addons?: Array< MealAddOnInput > | null,
  additionallyReferredToBy?: Array< ReferenceTypeInput > | null,
  origin?: OriginInput | null,
  derivedNutrition?: DerivedNutritionInput | null,
  spaceMembershipID?: string | null,
  groups?: Array< string | null > | null,
};

export type RecipeInput = {
  title: string,
  shortDescription?: string | null,
  imageUrl?: string | null,
  recipeUrl?: string | null,
  publisher?: string | null,
  chef?: string | null,
  mealiqId?: string | null,
  nutrition?: NutritionInput | null,
  mealTypes?: Array< string > | null,
  tags?: Array< string > | null,
  supportedDiets?: Array< string > | null,
  ingredients: Array< RecipeIngredientInput >,
  preparations: Array< RecipePreparationInput >,
  utensils?: Array< RecipeUtensilInput > | null,
  servings?: number | null,
  cookTime?: number | null,
  prepTime?: number | null,
  totalTime?: number | null,
  recipeNotes?: string | null,
};

export type NutritionInput = {
  calories?: number | null,
  protein?: number | null,
  carbohydrate?: number | null,
  fat?: number | null,
  fibre?: number | null,
  sugar?: number | null,
  saturates?: number | null,
  salt?: number | null,
  veg_weight?: number | null,
  cholesterol?: number | null,
  calcium?: number | null,
  iron?: number | null,
  magnesium?: number | null,
  potassium?: number | null,
  vitamin_a?: number | null,
  vitamin_b1?: number | null,
  vitamin_b2?: number | null,
  vitamin_b3?: number | null,
  vitamin_b5?: number | null,
  vitamin_b6?: number | null,
  vitamin_b12?: number | null,
  vitamin_c?: number | null,
  vitamin_d?: number | null,
  vitamin_e?: number | null,
  vitamin_k?: number | null,
  phosphorous?: number | null,
  zinc?: number | null,
  copper?: number | null,
  manganese?: number | null,
  selenium?: number | null,
  folate?: number | null,
  omega3_dha?: number | null,
  omega3_dpa?: number | null,
  omega3_epa?: number | null,
  sucrose?: number | null,
  glucose?: number | null,
  fructose?: number | null,
  lactose?: number | null,
  maltose?: number | null,
};

export type RecipeIngredientInput = {
  id?: string | null,
  fullText: string,
  structuredIngredient?: StructuredIngredientInput | null,
  tokFullText?: string | null,
  tokens?: Array< IngredientTokenInput | null > | null,
  scalingRules?: string | null,
  checkedInIngredientsList?: boolean | null,
};

export type StructuredIngredientInput = {
  name: string,
  quantity?: number | null,
  unitOfMeasure?: string | null,
  normalisedQuantity?: number | null,
  linkedIngredient?: LinkedIngredientDetailsInput | null,
  resolvedNutrition?: boolean | null,
  resolvedNutritionError?: string | null,
};

export type IngredientTokenInput = {
  fromChar: number,
  toChar: number,
  type?: string | null,
  label?: string | null,
};

export type RecipePreparationInput = {
  id?: string | null,
  fullText: string,
};

export type RecipeUtensilInput = {
  id?: string | null,
  fullText: string,
};

export type MealAddOnInput = {
  name: string,
};

export type ReferenceTypeInput = {
  sourceType: ReferenceSourceType,
  sourceID: string,
};

export enum ReferenceSourceType {
  PROGRAMME = "PROGRAMME",
}


export type OriginInput = {
  originObjectType: OriginObjectType,
  originObjectID: string,
};

export enum OriginObjectType {
  MEAL = "MEAL",
  RECIPE_URL = "RECIPE_URL",
  GRC_RECIPE = "GRC_RECIPE",
  CONTENT_ENTRY = "CONTENT_ENTRY",
}


export type DerivedNutritionInput = {
  totalNutritionPerServing: NutritionInput,
  ingredientNutrition: Array< DerivedIngredientNutritionInput >,
  foodAllergens?: Array< string > | null,
  foodPreferences?: Array< string > | null,
  status?: boolean | null,
  macroRatio?: MacroRatioInput | null,
};

export type DerivedIngredientNutritionInput = {
  ingredientID?: string | null,
  nutrition: NutritionInput,
  resolvedNutrition?: boolean | null,
  error?: string | null,
  resolvedIngredientWeight?: number | null,
};

export type MacroRatioInput = {
  carbohydrate?: number | null,
  protein?: number | null,
  fat?: number | null,
};

export type ModelMealConditionInput = {
  schemaVersion?: ModelIntInput | null,
  smorgBoardID?: ModelIDInput | null,
  spaceMembershipID?: ModelIDInput | null,
  groups?: ModelStringInput | null,
  and?: Array< ModelMealConditionInput | null > | null,
  or?: Array< ModelMealConditionInput | null > | null,
  not?: ModelMealConditionInput | null,
};

export type Meal = {
  __typename: "Meal",
  id: string,
  schemaVersion: number,
  smorgBoardID: string,
  recipes:  Array<Recipe >,
  addons?:  Array<MealAddOn > | null,
  additionallyReferredToBy?:  Array<ReferenceType > | null,
  origin?: Origin | null,
  derivedNutrition?: DerivedNutrition | null,
  spaceMembershipID?: string | null,
  groups?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type MealAddOn = {
  __typename: "MealAddOn",
  name: string,
};

export type ReferenceType = {
  __typename: "ReferenceType",
  sourceType: ReferenceSourceType,
  sourceID: string,
};

export type Origin = {
  __typename: "Origin",
  originObjectType: OriginObjectType,
  originObjectID: string,
};

export type UpdateMealInput = {
  id: string,
  schemaVersion?: number | null,
  smorgBoardID?: string | null,
  recipes?: Array< RecipeInput > | null,
  addons?: Array< MealAddOnInput > | null,
  additionallyReferredToBy?: Array< ReferenceTypeInput > | null,
  origin?: OriginInput | null,
  derivedNutrition?: DerivedNutritionInput | null,
  spaceMembershipID?: string | null,
  groups?: Array< string | null > | null,
};

export type DeleteMealInput = {
  id: string,
};

export type CreateSmorgBoardInput = {
  id?: string | null,
  schemaVersion: number,
  title: string,
  shortDescription?: string | null,
  coverImageUrl?: string | null,
  menus: Array< MenuInput >,
  shareRecords?: Array< RecipesBoardShareRecordInput | null > | null,
  spaceMembershipID?: string | null,
  embeddedInContainerType?: ContainerType | null,
  embeddedInContainerID?: string | null,
  groups?: Array< string | null > | null,
  availableInMembershipTierIDs?: Array< string > | null,
  isMyFavouritesBoard?: boolean | null,
};

export type MenuInput = {
  id: string,
  title: string,
  mealIDs: Array< string >,
};

export type RecipesBoardShareRecordInput = {
  sharedBoardID: string,
  version: string,
  updatedOn: string,
};

export enum ContainerType {
  PROGRAMME = "PROGRAMME",
  SHARED_PROGRAMME = "SHARED_PROGRAMME",
}


export type ModelSmorgBoardConditionInput = {
  schemaVersion?: ModelIntInput | null,
  title?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  coverImageUrl?: ModelStringInput | null,
  spaceMembershipID?: ModelIDInput | null,
  embeddedInContainerType?: ModelContainerTypeInput | null,
  embeddedInContainerID?: ModelIDInput | null,
  groups?: ModelStringInput | null,
  availableInMembershipTierIDs?: ModelIDInput | null,
  isMyFavouritesBoard?: ModelBooleanInput | null,
  and?: Array< ModelSmorgBoardConditionInput | null > | null,
  or?: Array< ModelSmorgBoardConditionInput | null > | null,
  not?: ModelSmorgBoardConditionInput | null,
};

export type ModelContainerTypeInput = {
  eq?: ContainerType | null,
  ne?: ContainerType | null,
};

export type SmorgBoard = {
  __typename: "SmorgBoard",
  id: string,
  schemaVersion: number,
  title: string,
  shortDescription?: string | null,
  coverImageUrl?: string | null,
  menus:  Array<Menu >,
  shareRecords?:  Array<RecipesBoardShareRecord | null > | null,
  spaceMembershipID?: string | null,
  embeddedInContainerType?: ContainerType | null,
  embeddedInContainerID?: string | null,
  groups?: Array< string | null > | null,
  availableInMembershipTierIDs?: Array< string > | null,
  isMyFavouritesBoard?: boolean | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type Menu = {
  __typename: "Menu",
  id: string,
  title: string,
  mealIDs: Array< string >,
};

export type RecipesBoardShareRecord = {
  __typename: "RecipesBoardShareRecord",
  sharedBoardID: string,
  version: string,
  updatedOn: string,
};

export type UpdateSmorgBoardInput = {
  id: string,
  schemaVersion?: number | null,
  title?: string | null,
  shortDescription?: string | null,
  coverImageUrl?: string | null,
  menus?: Array< MenuInput > | null,
  shareRecords?: Array< RecipesBoardShareRecordInput | null > | null,
  spaceMembershipID?: string | null,
  embeddedInContainerType?: ContainerType | null,
  embeddedInContainerID?: string | null,
  groups?: Array< string | null > | null,
  availableInMembershipTierIDs?: Array< string > | null,
  isMyFavouritesBoard?: boolean | null,
};

export type DeleteSmorgBoardInput = {
  id: string,
};

export type CreateSharedMealInput = {
  id?: string | null,
  schemaVersion: number,
  sharedBoardID: string,
  recipes: Array< RecipeInput >,
  origin?: OriginInput | null,
  derivedNutrition?: DerivedNutritionInput | null,
};

export type ModelSharedMealConditionInput = {
  schemaVersion?: ModelIntInput | null,
  sharedBoardID?: ModelIDInput | null,
  and?: Array< ModelSharedMealConditionInput | null > | null,
  or?: Array< ModelSharedMealConditionInput | null > | null,
  not?: ModelSharedMealConditionInput | null,
};

export type SharedMeal = {
  __typename: "SharedMeal",
  id: string,
  schemaVersion: number,
  sharedBoardID: string,
  recipes:  Array<Recipe >,
  origin?: Origin | null,
  derivedNutrition?: DerivedNutrition | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSharedMealInput = {
  id: string,
  schemaVersion?: number | null,
  sharedBoardID?: string | null,
  recipes?: Array< RecipeInput > | null,
  origin?: OriginInput | null,
  derivedNutrition?: DerivedNutritionInput | null,
};

export type DeleteSharedMealInput = {
  id: string,
};

export type CreateSharedBoardInput = {
  id?: string | null,
  schemaVersion: number,
  version?: string | null,
  accessLevel?: SharedBoardAccessLevel | null,
  title?: string | null,
  shortDescription?: string | null,
  coverImageUrl?: string | null,
  menus: Array< SharedMenuInput >,
  availableInMembershipTierIDs?: Array< string > | null,
};

export enum SharedBoardAccessLevel {
  NONE = "NONE",
  PUBLIC = "PUBLIC",
}


export type SharedMenuInput = {
  id?: string | null,
  title: string,
  sharedMealIDs: Array< string >,
};

export type ModelSharedBoardConditionInput = {
  schemaVersion?: ModelIntInput | null,
  version?: ModelIDInput | null,
  accessLevel?: ModelSharedBoardAccessLevelInput | null,
  title?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  coverImageUrl?: ModelStringInput | null,
  availableInMembershipTierIDs?: ModelIDInput | null,
  and?: Array< ModelSharedBoardConditionInput | null > | null,
  or?: Array< ModelSharedBoardConditionInput | null > | null,
  not?: ModelSharedBoardConditionInput | null,
};

export type ModelSharedBoardAccessLevelInput = {
  eq?: SharedBoardAccessLevel | null,
  ne?: SharedBoardAccessLevel | null,
};

export type SharedBoard = {
  __typename: "SharedBoard",
  id: string,
  schemaVersion: number,
  version?: string | null,
  accessLevel?: SharedBoardAccessLevel | null,
  title?: string | null,
  shortDescription?: string | null,
  coverImageUrl?: string | null,
  menus:  Array<SharedMenu >,
  availableInMembershipTierIDs?: Array< string > | null,
  createdAt: string,
  updatedAt: string,
};

export type SharedMenu = {
  __typename: "SharedMenu",
  id?: string | null,
  title: string,
  sharedMealIDs: Array< string >,
};

export type UpdateSharedBoardInput = {
  id: string,
  schemaVersion?: number | null,
  version?: string | null,
  accessLevel?: SharedBoardAccessLevel | null,
  title?: string | null,
  shortDescription?: string | null,
  coverImageUrl?: string | null,
  menus?: Array< SharedMenuInput > | null,
  availableInMembershipTierIDs?: Array< string > | null,
};

export type DeleteSharedBoardInput = {
  id: string,
};

export type CreateNoteInput = {
  id?: string | null,
  parentID: string,
  title: string,
  description?: string | null,
  spaceMembershipID?: string | null,
  groups?: Array< string | null > | null,
};

export type ModelNoteConditionInput = {
  parentID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  spaceMembershipID?: ModelIDInput | null,
  groups?: ModelStringInput | null,
  and?: Array< ModelNoteConditionInput | null > | null,
  or?: Array< ModelNoteConditionInput | null > | null,
  not?: ModelNoteConditionInput | null,
};

export type Note = {
  __typename: "Note",
  id: string,
  parentID: string,
  title: string,
  description?: string | null,
  spaceMembershipID?: string | null,
  groups?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateNoteInput = {
  id: string,
  parentID?: string | null,
  title?: string | null,
  description?: string | null,
  spaceMembershipID?: string | null,
  groups?: Array< string | null > | null,
};

export type DeleteNoteInput = {
  id: string,
};

export type CreateContentEntryInput = {
  id?: string | null,
  parentID: string,
  title: string,
  body?: string | null,
  coverImageUrl?: string | null,
  tags: Array< string >,
  appLink?: AppLinkInput | null,
  groups?: Array< string | null > | null,
};

export type AppLinkInput = {
  appLinkType: AppLinkType,
  appLinkScreen?: AppLinkScreen | null,
  url?: string | null,
  objectID?: string | null,
};

export enum AppLinkType {
  APP_SCREEN = "APP_SCREEN",
  URL = "URL",
}


export enum AppLinkScreen {
  RECIPES = "RECIPES",
  PLANNER = "PLANNER",
  SHOPPING_LISTS = "SHOPPING_LISTS",
  SHARED_PROGRAMMES = "SHARED_PROGRAMMES",
  CHAT = "CHAT",
  SHARED_CONTENT_BOARDS = "SHARED_CONTENT_BOARDS",
}


export type ModelContentEntryConditionInput = {
  parentID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  body?: ModelStringInput | null,
  coverImageUrl?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  groups?: ModelStringInput | null,
  and?: Array< ModelContentEntryConditionInput | null > | null,
  or?: Array< ModelContentEntryConditionInput | null > | null,
  not?: ModelContentEntryConditionInput | null,
};

export type ContentEntry = {
  __typename: "ContentEntry",
  id: string,
  parentID: string,
  title: string,
  body?: string | null,
  coverImageUrl?: string | null,
  tags: Array< string >,
  appLink?: AppLink | null,
  groups?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type AppLink = {
  __typename: "AppLink",
  appLinkType: AppLinkType,
  appLinkScreen?: AppLinkScreen | null,
  url?: string | null,
  objectID?: string | null,
};

export type UpdateContentEntryInput = {
  id: string,
  parentID?: string | null,
  title?: string | null,
  body?: string | null,
  coverImageUrl?: string | null,
  tags?: Array< string > | null,
  appLink?: AppLinkInput | null,
  groups?: Array< string | null > | null,
};

export type DeleteContentEntryInput = {
  id: string,
};

export type CreateSharedContentEntryInput = {
  id?: string | null,
  parentID: string,
  title: string,
  body?: string | null,
  coverImageUrl?: string | null,
  tags: Array< string >,
  origin: OriginInput,
  appLink?: AppLinkInput | null,
};

export type ModelSharedContentEntryConditionInput = {
  parentID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  body?: ModelStringInput | null,
  coverImageUrl?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  and?: Array< ModelSharedContentEntryConditionInput | null > | null,
  or?: Array< ModelSharedContentEntryConditionInput | null > | null,
  not?: ModelSharedContentEntryConditionInput | null,
};

export type SharedContentEntry = {
  __typename: "SharedContentEntry",
  id: string,
  parentID: string,
  title: string,
  body?: string | null,
  coverImageUrl?: string | null,
  tags: Array< string >,
  origin: Origin,
  appLink?: AppLink | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSharedContentEntryInput = {
  id: string,
  parentID?: string | null,
  title?: string | null,
  body?: string | null,
  coverImageUrl?: string | null,
  tags?: Array< string > | null,
  origin?: OriginInput | null,
  appLink?: AppLinkInput | null,
};

export type DeleteSharedContentEntryInput = {
  id: string,
};

export type CreatePlannerWeekInput = {
  id?: string | null,
  schemaVersion: number,
  spaceMembershipID?: string | null,
  startDate: string,
  days: Array< PlannerDayInput >,
};

export type PlannerDayInput = {
  entries: Array< PlannerEntryInput >,
};

export type PlannerEntryInput = {
  plannerEntryType?: PlannerEntryType | null,
  mealID?: string | null,
  objectID?: string | null,
};

export enum PlannerEntryType {
  MEAL = "MEAL",
  NOTE = "NOTE",
}


export type ModelPlannerWeekConditionInput = {
  schemaVersion?: ModelIntInput | null,
  spaceMembershipID?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  and?: Array< ModelPlannerWeekConditionInput | null > | null,
  or?: Array< ModelPlannerWeekConditionInput | null > | null,
  not?: ModelPlannerWeekConditionInput | null,
};

export type PlannerWeek = {
  __typename: "PlannerWeek",
  id: string,
  schemaVersion: number,
  spaceMembershipID?: string | null,
  startDate: string,
  days:  Array<PlannerDay >,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type PlannerDay = {
  __typename: "PlannerDay",
  entries:  Array<PlannerEntry >,
};

export type PlannerEntry = {
  __typename: "PlannerEntry",
  plannerEntryType?: PlannerEntryType | null,
  mealID?: string | null,
  objectID?: string | null,
};

export type UpdatePlannerWeekInput = {
  id: string,
  schemaVersion?: number | null,
  spaceMembershipID?: string | null,
  startDate?: string | null,
  days?: Array< PlannerDayInput > | null,
};

export type DeletePlannerWeekInput = {
  id: string,
};

export type CreateShoppingListInput = {
  id?: string | null,
  schemaVersion: number,
  spaceMembershipID?: string | null,
  productsBoardID: string,
  title: string,
  shortDescription?: string | null,
  items: Array< ShoppingListItemInput >,
  mealIDs?: Array< string > | null,
  mealImageUrls?: Array< string > | null,
  meals?: Array< ShoppingListMealInput > | null,
  activityRecords?: Array< ShoppingListActivityInput > | null,
  userItems?: Array< ShoppingListItemInput > | null,
};

export type ShoppingListItemInput = {
  id: string,
  name: string,
  total?: Array< UnitQuantityInput > | null,
  quantities?: Array< UnitQuantityInput > | null,
  linkedIngredient?: LinkedIngredientDetailsInput | null,
  aisleLocation?: string | null,
  forMealIds?: Array< string > | null,
  checked?: boolean | null,
};

export type UnitQuantityInput = {
  quantity?: number | null,
  prettyQuantity?: string | null,
  unitOfMeasure?: string | null,
};

export type ShoppingListMealInput = {
  mealID?: string | null,
  recipes: Array< RecipeInput >,
};

export type ShoppingListActivityInput = {
  activityType: ShoppingActivityType,
  occurredOn: string,
  vendorName?: string | null,
  transactionID?: string | null,
  shoppingListItemIDs?: Array< string > | null,
};

export enum ShoppingActivityType {
  SHOPPED = "SHOPPED",
}


export type ModelShoppingListConditionInput = {
  schemaVersion?: ModelIntInput | null,
  spaceMembershipID?: ModelIDInput | null,
  productsBoardID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  mealIDs?: ModelIDInput | null,
  mealImageUrls?: ModelStringInput | null,
  and?: Array< ModelShoppingListConditionInput | null > | null,
  or?: Array< ModelShoppingListConditionInput | null > | null,
  not?: ModelShoppingListConditionInput | null,
};

export type ShoppingList = {
  __typename: "ShoppingList",
  id: string,
  schemaVersion: number,
  spaceMembershipID?: string | null,
  productsBoardID: string,
  title: string,
  shortDescription?: string | null,
  items:  Array<ShoppingListItem >,
  mealIDs?: Array< string > | null,
  mealImageUrls?: Array< string > | null,
  meals?:  Array<ShoppingListMeal > | null,
  activityRecords?:  Array<ShoppingListActivity > | null,
  userItems?:  Array<ShoppingListItem > | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ShoppingListMeal = {
  __typename: "ShoppingListMeal",
  mealID?: string | null,
  recipes:  Array<Recipe >,
};

export type ShoppingListActivity = {
  __typename: "ShoppingListActivity",
  activityType: ShoppingActivityType,
  occurredOn: string,
  vendorName?: string | null,
  transactionID?: string | null,
  shoppingListItemIDs?: Array< string > | null,
};

export type UpdateShoppingListInput = {
  id: string,
  schemaVersion?: number | null,
  spaceMembershipID?: string | null,
  productsBoardID?: string | null,
  title?: string | null,
  shortDescription?: string | null,
  items?: Array< ShoppingListItemInput > | null,
  mealIDs?: Array< string > | null,
  mealImageUrls?: Array< string > | null,
  meals?: Array< ShoppingListMealInput > | null,
  activityRecords?: Array< ShoppingListActivityInput > | null,
  userItems?: Array< ShoppingListItemInput > | null,
};

export type DeleteShoppingListInput = {
  id: string,
};

export type CreateProductsBoardInput = {
  id?: string | null,
  schemaVersion: number,
  title: string,
  spaceMembershipID?: string | null,
  shoppingListGroups: Array< ShoppingListGroupInput >,
};

export type ShoppingListGroupInput = {
  id: string,
  title: string,
  shoppingListIDs?: Array< string > | null,
};

export type ModelProductsBoardConditionInput = {
  schemaVersion?: ModelIntInput | null,
  title?: ModelStringInput | null,
  spaceMembershipID?: ModelIDInput | null,
  and?: Array< ModelProductsBoardConditionInput | null > | null,
  or?: Array< ModelProductsBoardConditionInput | null > | null,
  not?: ModelProductsBoardConditionInput | null,
};

export type ProductsBoard = {
  __typename: "ProductsBoard",
  id: string,
  schemaVersion: number,
  title: string,
  spaceMembershipID?: string | null,
  shoppingListGroups:  Array<ShoppingListGroup >,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ShoppingListGroup = {
  __typename: "ShoppingListGroup",
  id: string,
  title: string,
  shoppingListIDs?: Array< string > | null,
};

export type UpdateProductsBoardInput = {
  id: string,
  schemaVersion?: number | null,
  title?: string | null,
  spaceMembershipID?: string | null,
  shoppingListGroups?: Array< ShoppingListGroupInput > | null,
};

export type DeleteProductsBoardInput = {
  id: string,
};

export type CreateGlobalTagInput = {
  id?: string | null,
  tag: string,
  count: number,
};

export type ModelGlobalTagConditionInput = {
  count?: ModelIntInput | null,
  and?: Array< ModelGlobalTagConditionInput | null > | null,
  or?: Array< ModelGlobalTagConditionInput | null > | null,
  not?: ModelGlobalTagConditionInput | null,
};

export type GlobalTag = {
  __typename: "GlobalTag",
  id: string,
  tag: string,
  count: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateGlobalTagInput = {
  id: string,
  tag: string,
  count?: number | null,
};

export type DeleteGlobalTagInput = {
  id: string,
  tag: string,
};

export type CreateIngredientEditEventInput = {
  mealID: string,
  recipeID: string,
  editMethod?: EditMethod | null,
  ingredientID: string,
  fullTextBefore: string,
  fullTextAfter: string,
  groups?: Array< string | null > | null,
  id?: string | null,
};

export enum EditMethod {
  TEXT_TYPED = "TEXT_TYPED",
  OPTION_SELECTED = "OPTION_SELECTED",
}


export type ModelIngredientEditEventConditionInput = {
  mealID?: ModelIDInput | null,
  recipeID?: ModelStringInput | null,
  editMethod?: ModelEditMethodInput | null,
  ingredientID?: ModelIDInput | null,
  fullTextBefore?: ModelStringInput | null,
  fullTextAfter?: ModelStringInput | null,
  groups?: ModelStringInput | null,
  and?: Array< ModelIngredientEditEventConditionInput | null > | null,
  or?: Array< ModelIngredientEditEventConditionInput | null > | null,
  not?: ModelIngredientEditEventConditionInput | null,
};

export type ModelEditMethodInput = {
  eq?: EditMethod | null,
  ne?: EditMethod | null,
};

export type IngredientEditEvent = {
  __typename: "IngredientEditEvent",
  mealID: string,
  recipeID: string,
  editMethod?: EditMethod | null,
  ingredientID: string,
  fullTextBefore: string,
  fullTextAfter: string,
  groups?: Array< string | null > | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateIngredientEditEventInput = {
  mealID?: string | null,
  recipeID?: string | null,
  editMethod?: EditMethod | null,
  ingredientID?: string | null,
  fullTextBefore?: string | null,
  fullTextAfter?: string | null,
  groups?: Array< string | null > | null,
  id: string,
};

export type DeleteIngredientEditEventInput = {
  id: string,
};

export type CreateIngredientEditSuggestionInput = {
  id?: string | null,
  inputFullText: string,
  suggestion: string,
  weight: number,
};

export type ModelIngredientEditSuggestionConditionInput = {
  suggestion?: ModelStringInput | null,
  weight?: ModelFloatInput | null,
  and?: Array< ModelIngredientEditSuggestionConditionInput | null > | null,
  or?: Array< ModelIngredientEditSuggestionConditionInput | null > | null,
  not?: ModelIngredientEditSuggestionConditionInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type IngredientEditSuggestion = {
  __typename: "IngredientEditSuggestion",
  id: string,
  inputFullText: string,
  suggestion: string,
  weight: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateIngredientEditSuggestionInput = {
  id: string,
  inputFullText: string,
  suggestion?: string | null,
  weight?: number | null,
};

export type DeleteIngredientEditSuggestionInput = {
  id: string,
  inputFullText: string,
};

export type CreateIngredientEditSuggestionByIngredientIDInput = {
  id?: string | null,
  ingredientID: string,
  suggestion: string,
  weight: number,
};

export type ModelIngredientEditSuggestionByIngredientIDConditionInput = {
  suggestion?: ModelStringInput | null,
  weight?: ModelFloatInput | null,
  and?: Array< ModelIngredientEditSuggestionByIngredientIDConditionInput | null > | null,
  or?: Array< ModelIngredientEditSuggestionByIngredientIDConditionInput | null > | null,
  not?: ModelIngredientEditSuggestionByIngredientIDConditionInput | null,
};

export type IngredientEditSuggestionByIngredientID = {
  __typename: "IngredientEditSuggestionByIngredientID",
  id: string,
  ingredientID: string,
  suggestion: string,
  weight: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateIngredientEditSuggestionByIngredientIDInput = {
  id: string,
  ingredientID: string,
  suggestion?: string | null,
  weight?: number | null,
};

export type DeleteIngredientEditSuggestionByIngredientIDInput = {
  id: string,
  ingredientID: string,
};

export type CreateProgrammeInput = {
  id?: string | null,
  title: string,
  shortDescription: string,
  description?: string | null,
  coverImageUrl?: string | null,
  plans: Array< ProgrammePlanInput >,
  locales?: Array< string > | null,
  mealTypes?: Array< string > | null,
  calorieSplits?: Array< CalorieSplitInput > | null,
  nutritionConstraints?: NutritionConstraintsInput | null,
  showNutritionToUsers?: boolean | null,
  copyMealsExactly?: boolean | null,
  personalisedMealScaling?: boolean | null,
  recipesBoard?: EmbeddedProgrammeRecipesBoardInput | null,
  databaseRecipesBoard?: EmbeddedProgrammeRecipesBoardInput | null,
  recipesBoardIDs?: Array< string > | null,
  onboardingConfiguration?: ProgrammeOnboardingConfigurationInput | null,
  categoryTags?: Array< string > | null,
  shareRecords?: Array< ProgrammeShareRecordInput | null > | null,
  availableInMembershipTierIDs?: Array< string > | null,
  groups?: Array< string | null > | null,
};

export type ProgrammePlanInput = {
  id: string,
  title: string,
  shortDescription: string,
  description?: string | null,
  coverImageUrl?: string | null,
  days: Array< ProgrammePlanDayInput >,
  nutritionConstraints?: NutritionConstraintsInput | null,
  showNutritionToUsers?: boolean | null,
};

export type ProgrammePlanDayInput = {
  id: string,
  title: string,
  entries: Array< ProgrammeEntryInput >,
};

export type ProgrammeEntryInput = {
  id: string,
  programmeEntryType: EntryType,
  objectID: string,
};

export type EmbeddedProgrammeRecipesBoardInput = {
  id: string,
  title: string,
  menus: Array< MenuInput >,
  embeddedInContainerType?: ContainerType | null,
  embeddedInContainerID?: string | null,
};

export type ProgrammeOnboardingConfigurationInput = {
  questionIDs: Array< string >,
};

export type ProgrammeShareRecordInput = {
  sharedProgrammeID: string,
  version: string,
  updatedOn: string,
};

export type ModelProgrammeConditionInput = {
  title?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  description?: ModelStringInput | null,
  coverImageUrl?: ModelStringInput | null,
  locales?: ModelStringInput | null,
  mealTypes?: ModelStringInput | null,
  showNutritionToUsers?: ModelBooleanInput | null,
  copyMealsExactly?: ModelBooleanInput | null,
  personalisedMealScaling?: ModelBooleanInput | null,
  recipesBoardIDs?: ModelIDInput | null,
  categoryTags?: ModelStringInput | null,
  availableInMembershipTierIDs?: ModelIDInput | null,
  groups?: ModelStringInput | null,
  and?: Array< ModelProgrammeConditionInput | null > | null,
  or?: Array< ModelProgrammeConditionInput | null > | null,
  not?: ModelProgrammeConditionInput | null,
};

export type Programme = {
  __typename: "Programme",
  id: string,
  title: string,
  shortDescription: string,
  description?: string | null,
  coverImageUrl?: string | null,
  plans:  Array<ProgrammePlan >,
  locales?: Array< string > | null,
  mealTypes?: Array< string > | null,
  calorieSplits?:  Array<CalorieSplit > | null,
  nutritionConstraints?: NutritionConstraints | null,
  showNutritionToUsers?: boolean | null,
  copyMealsExactly?: boolean | null,
  personalisedMealScaling?: boolean | null,
  recipesBoard?: EmbeddedProgrammeRecipesBoard | null,
  databaseRecipesBoard?: EmbeddedProgrammeRecipesBoard | null,
  recipesBoardIDs?: Array< string > | null,
  onboardingConfiguration?: ProgrammeOnboardingConfiguration | null,
  categoryTags?: Array< string > | null,
  shareRecords?:  Array<ProgrammeShareRecord | null > | null,
  availableInMembershipTierIDs?: Array< string > | null,
  groups?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ProgrammePlan = {
  __typename: "ProgrammePlan",
  id: string,
  title: string,
  shortDescription: string,
  description?: string | null,
  coverImageUrl?: string | null,
  days:  Array<ProgrammePlanDay >,
  nutritionConstraints?: NutritionConstraints | null,
  showNutritionToUsers?: boolean | null,
};

export type ProgrammePlanDay = {
  __typename: "ProgrammePlanDay",
  id: string,
  title: string,
  entries:  Array<ProgrammeEntry >,
};

export type ProgrammeEntry = {
  __typename: "ProgrammeEntry",
  id: string,
  programmeEntryType: EntryType,
  objectID: string,
};

export type NutritionConstraints = {
  __typename: "NutritionConstraints",
  nutritionMetricConstraints?:  Array<NutritionMetricConstraint > | null,
  excludeIngredients?: Array< string > | null,
  foodPreferences?: Array< FoodPreference > | null,
};

export type EmbeddedProgrammeRecipesBoard = {
  __typename: "EmbeddedProgrammeRecipesBoard",
  id: string,
  title: string,
  menus:  Array<Menu >,
  embeddedInContainerType?: ContainerType | null,
  embeddedInContainerID?: string | null,
};

export type ProgrammeOnboardingConfiguration = {
  __typename: "ProgrammeOnboardingConfiguration",
  questionIDs: Array< string >,
};

export type ProgrammeShareRecord = {
  __typename: "ProgrammeShareRecord",
  sharedProgrammeID: string,
  version: string,
  updatedOn: string,
};

export type UpdateProgrammeInput = {
  id: string,
  title?: string | null,
  shortDescription?: string | null,
  description?: string | null,
  coverImageUrl?: string | null,
  plans?: Array< ProgrammePlanInput > | null,
  locales?: Array< string > | null,
  mealTypes?: Array< string > | null,
  calorieSplits?: Array< CalorieSplitInput > | null,
  nutritionConstraints?: NutritionConstraintsInput | null,
  showNutritionToUsers?: boolean | null,
  copyMealsExactly?: boolean | null,
  personalisedMealScaling?: boolean | null,
  recipesBoard?: EmbeddedProgrammeRecipesBoardInput | null,
  databaseRecipesBoard?: EmbeddedProgrammeRecipesBoardInput | null,
  recipesBoardIDs?: Array< string > | null,
  onboardingConfiguration?: ProgrammeOnboardingConfigurationInput | null,
  categoryTags?: Array< string > | null,
  shareRecords?: Array< ProgrammeShareRecordInput | null > | null,
  availableInMembershipTierIDs?: Array< string > | null,
  groups?: Array< string | null > | null,
};

export type DeleteProgrammeInput = {
  id: string,
};

export type CreateSharedProgrammeInput = {
  spaceID: string,
  programmeID: string,
  title: string,
  shortDescription: string,
  description?: string | null,
  coverImageUrl?: string | null,
  plans: Array< ProgrammePlanInput >,
  locales?: Array< string > | null,
  mealTypes?: Array< string > | null,
  calorieSplits?: Array< CalorieSplitInput > | null,
  nutritionConstraints?: NutritionConstraintsInput | null,
  showNutritionToUsers?: boolean | null,
  copyMealsExactly?: boolean | null,
  personalisedMealScaling?: boolean | null,
  recipesBoard?: EmbeddedProgrammeRecipesBoardInput | null,
  databaseRecipesBoard?: EmbeddedProgrammeRecipesBoardInput | null,
  sharedRecipesBoardIDs?: Array< string > | null,
  onboardingConfiguration?: ProgrammeOnboardingConfigurationInput | null,
  categoryTags?: Array< string > | null,
  version: string,
  availableInMembershipTierIDs?: Array< string > | null,
  groups?: Array< string | null > | null,
  id?: string | null,
};

export type ModelSharedProgrammeConditionInput = {
  spaceID?: ModelIDInput | null,
  programmeID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  description?: ModelStringInput | null,
  coverImageUrl?: ModelStringInput | null,
  locales?: ModelStringInput | null,
  mealTypes?: ModelStringInput | null,
  showNutritionToUsers?: ModelBooleanInput | null,
  copyMealsExactly?: ModelBooleanInput | null,
  personalisedMealScaling?: ModelBooleanInput | null,
  sharedRecipesBoardIDs?: ModelIDInput | null,
  categoryTags?: ModelStringInput | null,
  version?: ModelIDInput | null,
  availableInMembershipTierIDs?: ModelIDInput | null,
  groups?: ModelStringInput | null,
  and?: Array< ModelSharedProgrammeConditionInput | null > | null,
  or?: Array< ModelSharedProgrammeConditionInput | null > | null,
  not?: ModelSharedProgrammeConditionInput | null,
};

export type SharedProgramme = {
  __typename: "SharedProgramme",
  spaceID: string,
  programmeID: string,
  title: string,
  shortDescription: string,
  description?: string | null,
  coverImageUrl?: string | null,
  plans:  Array<ProgrammePlan >,
  locales?: Array< string > | null,
  mealTypes?: Array< string > | null,
  calorieSplits?:  Array<CalorieSplit > | null,
  nutritionConstraints?: NutritionConstraints | null,
  showNutritionToUsers?: boolean | null,
  copyMealsExactly?: boolean | null,
  personalisedMealScaling?: boolean | null,
  recipesBoard?: EmbeddedProgrammeRecipesBoard | null,
  databaseRecipesBoard?: EmbeddedProgrammeRecipesBoard | null,
  sharedRecipesBoardIDs?: Array< string > | null,
  onboardingConfiguration?: ProgrammeOnboardingConfiguration | null,
  categoryTags?: Array< string > | null,
  version: string,
  availableInMembershipTierIDs?: Array< string > | null,
  groups?: Array< string | null > | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateSharedProgrammeInput = {
  spaceID?: string | null,
  programmeID?: string | null,
  title?: string | null,
  shortDescription?: string | null,
  description?: string | null,
  coverImageUrl?: string | null,
  plans?: Array< ProgrammePlanInput > | null,
  locales?: Array< string > | null,
  mealTypes?: Array< string > | null,
  calorieSplits?: Array< CalorieSplitInput > | null,
  nutritionConstraints?: NutritionConstraintsInput | null,
  showNutritionToUsers?: boolean | null,
  copyMealsExactly?: boolean | null,
  personalisedMealScaling?: boolean | null,
  recipesBoard?: EmbeddedProgrammeRecipesBoardInput | null,
  databaseRecipesBoard?: EmbeddedProgrammeRecipesBoardInput | null,
  sharedRecipesBoardIDs?: Array< string > | null,
  onboardingConfiguration?: ProgrammeOnboardingConfigurationInput | null,
  categoryTags?: Array< string > | null,
  version?: string | null,
  availableInMembershipTierIDs?: Array< string > | null,
  groups?: Array< string | null > | null,
  id: string,
};

export type DeleteSharedProgrammeInput = {
  id: string,
};

export type CreateUserProgrammeEnrollmentInput = {
  spaceMembershipID: string,
  sharedProgrammeID: string,
  enrolledAt: string,
  onboardingAnswers: Array< OnboardingAnswerInput >,
  endedAt?: string | null,
  eventRecords?: Array< UserProgrammeEnrollmentEventRecordInput > | null,
  customTargetCalories?: number | null,
  id?: string | null,
};

export type UserProgrammeEnrollmentEventRecordInput = {
  calendarDay: string,
  eventType: UserProgrammeEnrollmentEventType,
  updatedOn: string,
};

export enum UserProgrammeEnrollmentEventType {
  USER_ENROLLED = "USER_ENROLLED",
  USER_STARTED = "USER_STARTED",
}


export type ModelUserProgrammeEnrollmentConditionInput = {
  spaceMembershipID?: ModelIDInput | null,
  sharedProgrammeID?: ModelIDInput | null,
  enrolledAt?: ModelStringInput | null,
  endedAt?: ModelStringInput | null,
  customTargetCalories?: ModelFloatInput | null,
  and?: Array< ModelUserProgrammeEnrollmentConditionInput | null > | null,
  or?: Array< ModelUserProgrammeEnrollmentConditionInput | null > | null,
  not?: ModelUserProgrammeEnrollmentConditionInput | null,
};

export type UserProgrammeEnrollment = {
  __typename: "UserProgrammeEnrollment",
  spaceMembershipID: string,
  sharedProgrammeID: string,
  enrolledAt: string,
  onboardingAnswers:  Array<OnboardingAnswer >,
  endedAt?: string | null,
  eventRecords?:  Array<UserProgrammeEnrollmentEventRecord > | null,
  customTargetCalories?: number | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UserProgrammeEnrollmentEventRecord = {
  __typename: "UserProgrammeEnrollmentEventRecord",
  calendarDay: string,
  eventType: UserProgrammeEnrollmentEventType,
  updatedOn: string,
};

export type UpdateUserProgrammeEnrollmentInput = {
  spaceMembershipID?: string | null,
  sharedProgrammeID?: string | null,
  enrolledAt?: string | null,
  onboardingAnswers?: Array< OnboardingAnswerInput > | null,
  endedAt?: string | null,
  eventRecords?: Array< UserProgrammeEnrollmentEventRecordInput > | null,
  customTargetCalories?: number | null,
  id: string,
};

export type DeleteUserProgrammeEnrollmentInput = {
  id: string,
};

export type CreateGRCRecipeInput = {
  grcRecipeID: string,
  recipe: RecipeInput,
  derivedNutrition?: DerivedNutritionInput | null,
};

export type ModelGRCRecipeConditionInput = {
  and?: Array< ModelGRCRecipeConditionInput | null > | null,
  or?: Array< ModelGRCRecipeConditionInput | null > | null,
  not?: ModelGRCRecipeConditionInput | null,
};

export type GRCRecipe = {
  __typename: "GRCRecipe",
  grcRecipeID: string,
  recipe: Recipe,
  derivedNutrition?: DerivedNutrition | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateGRCRecipeInput = {
  grcRecipeID: string,
  recipe?: RecipeInput | null,
  derivedNutrition?: DerivedNutritionInput | null,
};

export type DeleteGRCRecipeInput = {
  grcRecipeID: string,
};

export type CreateSharedContentBoardInput = {
  id?: string | null,
  title: string,
  lanes: Array< SharedContentBoardLaneInput >,
};

export type SharedContentBoardLaneInput = {
  id: string,
  title: string,
  entries: Array< SharedContentBoardEntryInput >,
};

export type SharedContentBoardEntryInput = {
  id: string,
  entryType: EntryType,
  objectID: string,
};

export type ModelSharedContentBoardConditionInput = {
  title?: ModelStringInput | null,
  and?: Array< ModelSharedContentBoardConditionInput | null > | null,
  or?: Array< ModelSharedContentBoardConditionInput | null > | null,
  not?: ModelSharedContentBoardConditionInput | null,
};

export type SharedContentBoard = {
  __typename: "SharedContentBoard",
  id: string,
  title: string,
  lanes:  Array<SharedContentBoardLane >,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type SharedContentBoardLane = {
  __typename: "SharedContentBoardLane",
  id: string,
  title: string,
  entries:  Array<SharedContentBoardEntry >,
};

export type SharedContentBoardEntry = {
  __typename: "SharedContentBoardEntry",
  id: string,
  entryType: EntryType,
  objectID: string,
};

export type UpdateSharedContentBoardInput = {
  id: string,
  title?: string | null,
  lanes?: Array< SharedContentBoardLaneInput > | null,
};

export type DeleteSharedContentBoardInput = {
  id: string,
};

export type CreateSpaceIAPSubscriptionInput = {
  spaceID: string,
  productID: string,
  title: string,
  shortDescription?: string | null,
  currencyCode: string,
  priceStr: string,
  bundleID: string,
  duration?: SubscriptionDuration | null,
  signUpButtonLabelOverride?: string | null,
  appStoreID?: AppStoreID | null,
  rank?: number | null,
  id?: string | null,
};

export enum AppStoreID {
  APPLE_APP_STORE = "APPLE_APP_STORE",
  GOOGLE_PLAY_STORE = "GOOGLE_PLAY_STORE",
}


export type ModelSpaceIAPSubscriptionConditionInput = {
  spaceID?: ModelIDInput | null,
  productID?: ModelStringInput | null,
  title?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  currencyCode?: ModelStringInput | null,
  priceStr?: ModelStringInput | null,
  bundleID?: ModelStringInput | null,
  duration?: ModelSubscriptionDurationInput | null,
  signUpButtonLabelOverride?: ModelStringInput | null,
  appStoreID?: ModelAppStoreIDInput | null,
  rank?: ModelIntInput | null,
  and?: Array< ModelSpaceIAPSubscriptionConditionInput | null > | null,
  or?: Array< ModelSpaceIAPSubscriptionConditionInput | null > | null,
  not?: ModelSpaceIAPSubscriptionConditionInput | null,
};

export type ModelSubscriptionDurationInput = {
  eq?: SubscriptionDuration | null,
  ne?: SubscriptionDuration | null,
};

export type ModelAppStoreIDInput = {
  eq?: AppStoreID | null,
  ne?: AppStoreID | null,
};

export type SpaceIAPSubscription = {
  __typename: "SpaceIAPSubscription",
  spaceID: string,
  productID: string,
  title: string,
  shortDescription?: string | null,
  currencyCode: string,
  priceStr: string,
  bundleID: string,
  duration?: SubscriptionDuration | null,
  signUpButtonLabelOverride?: string | null,
  appStoreID?: AppStoreID | null,
  rank?: number | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateSpaceIAPSubscriptionInput = {
  spaceID?: string | null,
  productID?: string | null,
  title?: string | null,
  shortDescription?: string | null,
  currencyCode?: string | null,
  priceStr?: string | null,
  bundleID?: string | null,
  duration?: SubscriptionDuration | null,
  signUpButtonLabelOverride?: string | null,
  appStoreID?: AppStoreID | null,
  rank?: number | null,
  id: string,
};

export type DeleteSpaceIAPSubscriptionInput = {
  id: string,
};

export type CreateSpaceInput = {
  title: string,
  shortDescription?: string | null,
  whiteLabelElements?: SpaceWhiteLabelElementsInput | null,
  adminUserIDs?: Array< string > | null,
  landingPageContent?: string | null,
  landingPageStyle?: LandingPageStyleInput | null,
  landingPageCoverImageUrl?: string | null,
  isDefaultSpace?: boolean | null,
  sharedProgrammeConfiguration?: SpaceSharedProgrammesConfigurationInput | null,
  productPreviewSections?: Array< SpaceProductPreviewSectionInput > | null,
  videoPlaylists?: Array< VideoPlaylistInput > | null,
  spaceContentBoard?: SpaceContentBoardInput | null,
  spaceSharedContentBoardIDs?: Array< string > | null,
  welcomeVideo?: VideoPlaylistItemInput | null,
  myDayScreenSections?: Array< SpacePublishedSectionConfigInput > | null,
  subscriptionBenefitsContent?: string | null,
  subscriptionPageStyle?: SubscriptionPageStyleInput | null,
  groups?: Array< string | null > | null,
  sharedRecipesBoardIDs?: Array< string > | null,
  defaultSharedProgrammeIDForNewUsers?: string | null,
  appStoreLinks?: Array< SpaceAppStoreLinkInput > | null,
  chatConfig?: SpaceChatConfigInput | null,
  publishedSearchTags?: Array< SpacePublishedSearchTagInput > | null,
  id?: string | null,
};

export type SpaceWhiteLabelElementsInput = {
  primaryMainColor?: string | null,
  backgroundStyle?: BackgroundStyle | null,
  backgroundColor?: string | null,
  backgroundGradientColor?: string | null,
  cardBackgroundColor?: string | null,
  iconUrl?: string | null,
  splashImageUrl?: string | null,
  myDayBackgroundImageUrl?: string | null,
  desktopBannerImageUrl?: string | null,
  termsUrl?: string | null,
  privacyPolicyUrl?: string | null,
};

export enum BackgroundStyle {
  SOLID = "SOLID",
  LINEAR_GRADIENT = "LINEAR_GRADIENT",
}


export type LandingPageStyleInput = {
  backgroundColor?: string | null,
};

export type SpaceSharedProgrammesConfigurationInput = {
  sharedProgrammeCategories?: Array< SpaceSharedProgrammeCategoryConfigurationInput | null > | null,
};

export type SpaceSharedProgrammeCategoryConfigurationInput = {
  categoryTag: string,
  orderedProgrammeIDs: Array< string >,
};

export type SpaceProductPreviewSectionInput = {
  title: string,
  productPreviews: Array< SpaceProductPreviewInput >,
};

export type SpaceProductPreviewInput = {
  productType: SpaceProductType,
  productObjectID: string,
  title: string,
  shortDescription: string,
  coverImageUrl?: string | null,
  availableInMembershipTierIDs?: Array< string > | null,
};

export enum SpaceProductType {
  PROGRAMME = "PROGRAMME",
}


export type VideoPlaylistInput = {
  title: string,
  playlistID: string,
  items: Array< VideoPlaylistItemInput >,
};

export type VideoPlaylistItemInput = {
  videoID: string,
  title: string,
  description?: string | null,
  thumbnailImageUrl?: string | null,
};

export type SpaceContentBoardInput = {
  id: string,
  title: string,
  lanes: Array< SpaceContentLaneInput >,
};

export type SpaceContentLaneInput = {
  id: string,
  title: string,
  entries: Array< SpaceContentEntryInput >,
};

export type SpaceContentEntryInput = {
  id: string,
  entryType: EntryType,
  objectID: string,
};

export type SpacePublishedSectionConfigInput = {
  publishedSectionType?: SpacePublishedSectionType | null,
  spaceContentLaneID?: string | null,
};

export enum SpacePublishedSectionType {
  CONTENT_ENTRIES = "CONTENT_ENTRIES",
  LATEST_RECIPE_COLLECTIONS = "LATEST_RECIPE_COLLECTIONS",
  TODAYS_MEALS = "TODAYS_MEALS",
  TODAYS_LESSONS = "TODAYS_LESSONS",
  SHOPPING_LISTS = "SHOPPING_LISTS",
}


export type SubscriptionPageStyleInput = {
  backgroundColor?: string | null,
};

export type SpaceAppStoreLinkInput = {
  appStoreID: AppStoreID,
  appUrl: string,
};

export type SpaceChatConfigInput = {
  appID?: string | null,
  appRegion?: string | null,
};

export type SpacePublishedSearchTagInput = {
  id: string,
  tag: string,
};

export type ModelSpaceConditionInput = {
  title?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  adminUserIDs?: ModelIDInput | null,
  landingPageContent?: ModelStringInput | null,
  landingPageCoverImageUrl?: ModelStringInput | null,
  isDefaultSpace?: ModelBooleanInput | null,
  spaceSharedContentBoardIDs?: ModelIDInput | null,
  subscriptionBenefitsContent?: ModelStringInput | null,
  groups?: ModelStringInput | null,
  sharedRecipesBoardIDs?: ModelIDInput | null,
  defaultSharedProgrammeIDForNewUsers?: ModelIDInput | null,
  and?: Array< ModelSpaceConditionInput | null > | null,
  or?: Array< ModelSpaceConditionInput | null > | null,
  not?: ModelSpaceConditionInput | null,
};

export type Space = {
  __typename: "Space",
  title: string,
  shortDescription?: string | null,
  whiteLabelElements?: SpaceWhiteLabelElements | null,
  adminUserIDs?: Array< string > | null,
  landingPageContent?: string | null,
  landingPageStyle?: LandingPageStyle | null,
  landingPageCoverImageUrl?: string | null,
  isDefaultSpace?: boolean | null,
  sharedProgrammeConfiguration?: SpaceSharedProgrammesConfiguration | null,
  productPreviewSections?:  Array<SpaceProductPreviewSection > | null,
  videoPlaylists?:  Array<VideoPlaylist > | null,
  spaceContentBoard?: SpaceContentBoard | null,
  spaceSharedContentBoardIDs?: Array< string > | null,
  welcomeVideo?: VideoPlaylistItem | null,
  myDayScreenSections?:  Array<SpacePublishedSectionConfig > | null,
  subscriptionBenefitsContent?: string | null,
  subscriptionPageStyle?: SubscriptionPageStyle | null,
  groups?: Array< string | null > | null,
  sharedRecipesBoardIDs?: Array< string > | null,
  defaultSharedProgrammeIDForNewUsers?: string | null,
  appStoreLinks?:  Array<SpaceAppStoreLink > | null,
  chatConfig?: SpaceChatConfig | null,
  publishedSearchTags?:  Array<SpacePublishedSearchTag > | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type SpaceWhiteLabelElements = {
  __typename: "SpaceWhiteLabelElements",
  primaryMainColor?: string | null,
  backgroundStyle?: BackgroundStyle | null,
  backgroundColor?: string | null,
  backgroundGradientColor?: string | null,
  cardBackgroundColor?: string | null,
  iconUrl?: string | null,
  splashImageUrl?: string | null,
  myDayBackgroundImageUrl?: string | null,
  desktopBannerImageUrl?: string | null,
  termsUrl?: string | null,
  privacyPolicyUrl?: string | null,
};

export type LandingPageStyle = {
  __typename: "LandingPageStyle",
  backgroundColor?: string | null,
};

export type SpaceSharedProgrammesConfiguration = {
  __typename: "SpaceSharedProgrammesConfiguration",
  sharedProgrammeCategories?:  Array<SpaceSharedProgrammeCategoryConfiguration | null > | null,
};

export type SpaceSharedProgrammeCategoryConfiguration = {
  __typename: "SpaceSharedProgrammeCategoryConfiguration",
  categoryTag: string,
  orderedProgrammeIDs: Array< string >,
};

export type SpaceProductPreviewSection = {
  __typename: "SpaceProductPreviewSection",
  title: string,
  productPreviews:  Array<SpaceProductPreview >,
};

export type SpaceProductPreview = {
  __typename: "SpaceProductPreview",
  productType: SpaceProductType,
  productObjectID: string,
  title: string,
  shortDescription: string,
  coverImageUrl?: string | null,
  availableInMembershipTierIDs?: Array< string > | null,
};

export type VideoPlaylist = {
  __typename: "VideoPlaylist",
  title: string,
  playlistID: string,
  items:  Array<VideoPlaylistItem >,
};

export type SpaceContentBoard = {
  __typename: "SpaceContentBoard",
  id: string,
  title: string,
  lanes:  Array<SpaceContentLane >,
};

export type SpaceContentLane = {
  __typename: "SpaceContentLane",
  id: string,
  title: string,
  entries:  Array<SpaceContentEntry >,
};

export type SpaceContentEntry = {
  __typename: "SpaceContentEntry",
  id: string,
  entryType: EntryType,
  objectID: string,
};

export type SpacePublishedSectionConfig = {
  __typename: "SpacePublishedSectionConfig",
  publishedSectionType?: SpacePublishedSectionType | null,
  spaceContentLaneID?: string | null,
};

export type SubscriptionPageStyle = {
  __typename: "SubscriptionPageStyle",
  backgroundColor?: string | null,
};

export type SpaceAppStoreLink = {
  __typename: "SpaceAppStoreLink",
  appStoreID: AppStoreID,
  appUrl: string,
};

export type SpaceChatConfig = {
  __typename: "SpaceChatConfig",
  appID?: string | null,
  appRegion?: string | null,
};

export type SpacePublishedSearchTag = {
  __typename: "SpacePublishedSearchTag",
  id: string,
  tag: string,
};

export type UpdateSpaceInput = {
  title?: string | null,
  shortDescription?: string | null,
  whiteLabelElements?: SpaceWhiteLabelElementsInput | null,
  adminUserIDs?: Array< string > | null,
  landingPageContent?: string | null,
  landingPageStyle?: LandingPageStyleInput | null,
  landingPageCoverImageUrl?: string | null,
  isDefaultSpace?: boolean | null,
  sharedProgrammeConfiguration?: SpaceSharedProgrammesConfigurationInput | null,
  productPreviewSections?: Array< SpaceProductPreviewSectionInput > | null,
  videoPlaylists?: Array< VideoPlaylistInput > | null,
  spaceContentBoard?: SpaceContentBoardInput | null,
  spaceSharedContentBoardIDs?: Array< string > | null,
  welcomeVideo?: VideoPlaylistItemInput | null,
  myDayScreenSections?: Array< SpacePublishedSectionConfigInput > | null,
  subscriptionBenefitsContent?: string | null,
  subscriptionPageStyle?: SubscriptionPageStyleInput | null,
  groups?: Array< string | null > | null,
  sharedRecipesBoardIDs?: Array< string > | null,
  defaultSharedProgrammeIDForNewUsers?: string | null,
  appStoreLinks?: Array< SpaceAppStoreLinkInput > | null,
  chatConfig?: SpaceChatConfigInput | null,
  publishedSearchTags?: Array< SpacePublishedSearchTagInput > | null,
  id: string,
};

export type DeleteSpaceInput = {
  id: string,
};

export type CreateSpaceMembershipInput = {
  spaceID: string,
  memberID: string,
  state: SpaceMembershipState,
  correlatedUserID?: string | null,
  expiresAt?: string | null,
  visibleProgrammeIDsOverride?: Array< string > | null,
  membershipTierID?: string | null,
  stripeCustomerID?: string | null,
  stripeSubscriptionID?: string | null,
  onboardingAnswers?: Array< OnboardingAnswerInput > | null,
  onboardingAnswersLastUpdatedAt?: string | null,
  customTargetCalories?: number | null,
  welcomeVideoWatched?: boolean | null,
  initialProgrammeSelectionLastOfferedAt?: string | null,
  chatNickname?: string | null,
  referrer?: SpaceMembershipReferrer | null,
  appStoreSubscriptionID?: string | null,
  groups?: Array< string | null > | null,
  createdAt?: string | null,
  id?: string | null,
};

export type ModelSpaceMembershipConditionInput = {
  spaceID?: ModelIDInput | null,
  memberID?: ModelStringInput | null,
  state?: ModelSpaceMembershipStateInput | null,
  correlatedUserID?: ModelStringInput | null,
  expiresAt?: ModelStringInput | null,
  visibleProgrammeIDsOverride?: ModelIDInput | null,
  membershipTierID?: ModelIDInput | null,
  stripeCustomerID?: ModelStringInput | null,
  stripeSubscriptionID?: ModelStringInput | null,
  onboardingAnswersLastUpdatedAt?: ModelStringInput | null,
  customTargetCalories?: ModelFloatInput | null,
  welcomeVideoWatched?: ModelBooleanInput | null,
  initialProgrammeSelectionLastOfferedAt?: ModelStringInput | null,
  chatNickname?: ModelStringInput | null,
  referrer?: ModelSpaceMembershipReferrerInput | null,
  appStoreSubscriptionID?: ModelStringInput | null,
  groups?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelSpaceMembershipConditionInput | null > | null,
  or?: Array< ModelSpaceMembershipConditionInput | null > | null,
  not?: ModelSpaceMembershipConditionInput | null,
};

export type ModelSpaceMembershipStateInput = {
  eq?: SpaceMembershipState | null,
  ne?: SpaceMembershipState | null,
};

export type ModelSpaceMembershipReferrerInput = {
  eq?: SpaceMembershipReferrer | null,
  ne?: SpaceMembershipReferrer | null,
};

export type UpdateSpaceMembershipInput = {
  spaceID?: string | null,
  memberID?: string | null,
  state?: SpaceMembershipState | null,
  correlatedUserID?: string | null,
  expiresAt?: string | null,
  visibleProgrammeIDsOverride?: Array< string > | null,
  membershipTierID?: string | null,
  stripeCustomerID?: string | null,
  stripeSubscriptionID?: string | null,
  onboardingAnswers?: Array< OnboardingAnswerInput > | null,
  onboardingAnswersLastUpdatedAt?: string | null,
  customTargetCalories?: number | null,
  welcomeVideoWatched?: boolean | null,
  initialProgrammeSelectionLastOfferedAt?: string | null,
  chatNickname?: string | null,
  referrer?: SpaceMembershipReferrer | null,
  appStoreSubscriptionID?: string | null,
  groups?: Array< string | null > | null,
  createdAt?: string | null,
  id: string,
};

export type DeleteSpaceMembershipInput = {
  id: string,
};

export type CreateSpaceMembershipEventInput = {
  spaceMembershipID: string,
  memberID: string,
  eventType?: SpaceMembershipEventType | null,
  transactionSource?: SubscriptionTransactionSource | null,
  transactionID?: string | null,
  details?: string | null,
  createdAt?: string | null,
  id?: string | null,
};

export enum SpaceMembershipEventType {
  CREATED = "CREATED",
  SUBSCRIPTION_LAPSED = "SUBSCRIPTION_LAPSED",
  PAYMENT_INITIATED = "PAYMENT_INITIATED",
  PAYMENT_SUCCEEDED = "PAYMENT_SUCCEEDED",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  CANCELLATION_REQUESTED_BY_MEMBER = "CANCELLATION_REQUESTED_BY_MEMBER",
  CANCELLED = "CANCELLED",
}


export enum SubscriptionTransactionSource {
  STRIPE = "STRIPE",
  APPLE_IAP = "APPLE_IAP",
  GOOGLE_PLAY_PURCHASE = "GOOGLE_PLAY_PURCHASE",
}


export type ModelSpaceMembershipEventConditionInput = {
  spaceMembershipID?: ModelIDInput | null,
  memberID?: ModelStringInput | null,
  eventType?: ModelSpaceMembershipEventTypeInput | null,
  transactionSource?: ModelSubscriptionTransactionSourceInput | null,
  transactionID?: ModelStringInput | null,
  details?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelSpaceMembershipEventConditionInput | null > | null,
  or?: Array< ModelSpaceMembershipEventConditionInput | null > | null,
  not?: ModelSpaceMembershipEventConditionInput | null,
};

export type ModelSpaceMembershipEventTypeInput = {
  eq?: SpaceMembershipEventType | null,
  ne?: SpaceMembershipEventType | null,
};

export type ModelSubscriptionTransactionSourceInput = {
  eq?: SubscriptionTransactionSource | null,
  ne?: SubscriptionTransactionSource | null,
};

export type SpaceMembershipEvent = {
  __typename: "SpaceMembershipEvent",
  spaceMembershipID: string,
  memberID: string,
  eventType?: SpaceMembershipEventType | null,
  transactionSource?: SubscriptionTransactionSource | null,
  transactionID?: string | null,
  details?: string | null,
  createdAt: string,
  id: string,
  updatedAt: string,
};

export type UpdateSpaceMembershipEventInput = {
  spaceMembershipID?: string | null,
  memberID?: string | null,
  eventType?: SpaceMembershipEventType | null,
  transactionSource?: SubscriptionTransactionSource | null,
  transactionID?: string | null,
  details?: string | null,
  createdAt?: string | null,
  id: string,
};

export type DeleteSpaceMembershipEventInput = {
  id: string,
};

export type CreateUserMyDayActionRecordInput = {
  userProgrammeEnrollmentID: string,
  programmeEntryType: EntryType,
  objectID: string,
  calendarDay: string,
  actionType: MyDayActionType,
  id?: string | null,
};

export enum MyDayActionType {
  COMPLETED = "COMPLETED",
  SKIPPED = "SKIPPED",
  UNSKIPPED = "UNSKIPPED",
}


export type ModelUserMyDayActionRecordConditionInput = {
  userProgrammeEnrollmentID?: ModelIDInput | null,
  programmeEntryType?: ModelEntryTypeInput | null,
  objectID?: ModelIDInput | null,
  calendarDay?: ModelStringInput | null,
  actionType?: ModelMyDayActionTypeInput | null,
  and?: Array< ModelUserMyDayActionRecordConditionInput | null > | null,
  or?: Array< ModelUserMyDayActionRecordConditionInput | null > | null,
  not?: ModelUserMyDayActionRecordConditionInput | null,
};

export type ModelMyDayActionTypeInput = {
  eq?: MyDayActionType | null,
  ne?: MyDayActionType | null,
};

export type UserMyDayActionRecord = {
  __typename: "UserMyDayActionRecord",
  userProgrammeEnrollmentID: string,
  programmeEntryType: EntryType,
  objectID: string,
  calendarDay: string,
  actionType: MyDayActionType,
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateUserMyDayActionRecordInput = {
  userProgrammeEnrollmentID?: string | null,
  programmeEntryType?: EntryType | null,
  objectID?: string | null,
  calendarDay?: string | null,
  actionType?: MyDayActionType | null,
  id: string,
};

export type DeleteUserMyDayActionRecordInput = {
  id: string,
};

export type CreateMembershipTierInput = {
  spaceID: string,
  title: string,
  shortDescription?: string | null,
  monthlyPrice: string,
  quarterlyPrice?: string | null,
  annualPrice?: string | null,
  freeTrialDurationDays?: number | null,
  rank?: number | null,
  currencyCode?: string | null,
  state: MembershipTierState,
  stripeProductID?: string | null,
  stripePriceID?: string | null,
  stripePriceIDQuarterly?: string | null,
  stripePriceIDAnnual?: string | null,
  groups?: Array< string | null > | null,
  id?: string | null,
};

export type ModelMembershipTierConditionInput = {
  spaceID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  monthlyPrice?: ModelStringInput | null,
  quarterlyPrice?: ModelStringInput | null,
  annualPrice?: ModelStringInput | null,
  freeTrialDurationDays?: ModelIntInput | null,
  rank?: ModelIntInput | null,
  currencyCode?: ModelStringInput | null,
  state?: ModelMembershipTierStateInput | null,
  stripeProductID?: ModelStringInput | null,
  stripePriceID?: ModelStringInput | null,
  stripePriceIDQuarterly?: ModelStringInput | null,
  stripePriceIDAnnual?: ModelStringInput | null,
  groups?: ModelStringInput | null,
  and?: Array< ModelMembershipTierConditionInput | null > | null,
  or?: Array< ModelMembershipTierConditionInput | null > | null,
  not?: ModelMembershipTierConditionInput | null,
};

export type ModelMembershipTierStateInput = {
  eq?: MembershipTierState | null,
  ne?: MembershipTierState | null,
};

export type UpdateMembershipTierInput = {
  spaceID?: string | null,
  title?: string | null,
  shortDescription?: string | null,
  monthlyPrice?: string | null,
  quarterlyPrice?: string | null,
  annualPrice?: string | null,
  freeTrialDurationDays?: number | null,
  rank?: number | null,
  currencyCode?: string | null,
  state?: MembershipTierState | null,
  stripeProductID?: string | null,
  stripePriceID?: string | null,
  stripePriceIDQuarterly?: string | null,
  stripePriceIDAnnual?: string | null,
  groups?: Array< string | null > | null,
  id: string,
};

export type DeleteMembershipTierInput = {
  id: string,
};

export type CreateSpacePrivateConfigInput = {
  id?: string | null,
  spaceID: string,
  stripeConfig?: SpaceStripeConfigInput | null,
  groups?: Array< string | null > | null,
};

export type SpaceStripeConfigInput = {
  environment: StripeEnvironment,
  connectedAccountID: string,
  chargesEnabled: boolean,
  stripeProductID?: string | null,
};

export type ModelSpacePrivateConfigConditionInput = {
  spaceID?: ModelIDInput | null,
  groups?: ModelStringInput | null,
  and?: Array< ModelSpacePrivateConfigConditionInput | null > | null,
  or?: Array< ModelSpacePrivateConfigConditionInput | null > | null,
  not?: ModelSpacePrivateConfigConditionInput | null,
};

export type SpacePrivateConfig = {
  __typename: "SpacePrivateConfig",
  id: string,
  spaceID: string,
  stripeConfig?: SpaceStripeConfig | null,
  groups?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateSpacePrivateConfigInput = {
  id: string,
  spaceID?: string | null,
  stripeConfig?: SpaceStripeConfigInput | null,
  groups?: Array< string | null > | null,
};

export type DeleteSpacePrivateConfigInput = {
  id: string,
};

export type CreateAccountActivitySignalInput = {
  id?: string | null,
  spaceID: string,
  groups?: Array< string | null > | null,
  lastActivityAt: string,
  friendlyUsername?: string | null,
  lastActivityObjectType?: ActivityObjectType | null,
  lastActivityObjectID?: string | null,
  sourceIp?: string | null,
};

export type ModelAccountActivitySignalConditionInput = {
  spaceID?: ModelIDInput | null,
  groups?: ModelStringInput | null,
  lastActivityAt?: ModelStringInput | null,
  friendlyUsername?: ModelStringInput | null,
  lastActivityObjectType?: ModelActivityObjectTypeInput | null,
  lastActivityObjectID?: ModelIDInput | null,
  sourceIp?: ModelStringInput | null,
  and?: Array< ModelAccountActivitySignalConditionInput | null > | null,
  or?: Array< ModelAccountActivitySignalConditionInput | null > | null,
  not?: ModelAccountActivitySignalConditionInput | null,
};

export type ModelActivityObjectTypeInput = {
  eq?: ActivityObjectType | null,
  ne?: ActivityObjectType | null,
};

export type AccountActivitySignal = {
  __typename: "AccountActivitySignal",
  id: string,
  spaceID: string,
  groups?: Array< string | null > | null,
  lastActivityAt: string,
  friendlyUsername?: string | null,
  lastActivityObjectType?: ActivityObjectType | null,
  lastActivityObjectID?: string | null,
  sourceIp?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateAccountActivitySignalInput = {
  id: string,
  spaceID?: string | null,
  groups?: Array< string | null > | null,
  lastActivityAt?: string | null,
  friendlyUsername?: string | null,
  lastActivityObjectType?: ActivityObjectType | null,
  lastActivityObjectID?: string | null,
  sourceIp?: string | null,
};

export type DeleteAccountActivitySignalInput = {
  id: string,
};

export type IngredientTreeNode = {
  __typename: "IngredientTreeNode",
  ingredientName: string,
  parentIngredientName?: string | null,
};

export type ModelZapierRestHookFilterInput = {
  spaceID?: ModelIDInput | null,
  hookUrl?: ModelStringInput | null,
  hookKey?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  and?: Array< ModelZapierRestHookFilterInput | null > | null,
  or?: Array< ModelZapierRestHookFilterInput | null > | null,
  not?: ModelZapierRestHookFilterInput | null,
};

export type ModelZapierRestHookConnection = {
  __typename: "ModelZapierRestHookConnection",
  items:  Array<ZapierRestHook | null >,
  nextToken?: string | null,
};

export type ModelUserProfileFilterInput = {
  id?: ModelIDInput | null,
  showOnboarding?: ModelBooleanInput | null,
  consentToProductComms?: ModelBooleanInput | null,
  plannerViewWeekStartDay?: ModelIntInput | null,
  geoRegion?: ModelStringInput | null,
  countryCode?: ModelStringInput | null,
  mealPlanReminderSchedule?: ModelStringInput | null,
  showMealDetailGuide?: ModelBooleanInput | null,
  recentlyUsedTags?: ModelStringInput | null,
  enabledFeatures?: ModelStringInput | null,
  requestedFeatures?: ModelStringInput | null,
  seenMessages?: ModelStringInput | null,
  recentlyUsedRecommenderMealTypes?: ModelStringInput | null,
  and?: Array< ModelUserProfileFilterInput | null > | null,
  or?: Array< ModelUserProfileFilterInput | null > | null,
  not?: ModelUserProfileFilterInput | null,
};

export type ModelUserProfileConnection = {
  __typename: "ModelUserProfileConnection",
  items:  Array<UserProfile | null >,
  nextToken?: string | null,
};

export type ModelObjectEditEventFilterInput = {
  id?: ModelIDInput | null,
  objectID?: ModelIDInput | null,
  objectType?: ModelEntryTypeInput | null,
  section?: ModelStringInput | null,
  itemID?: ModelStringInput | null,
  oldValue?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  groups?: ModelStringInput | null,
  and?: Array< ModelObjectEditEventFilterInput | null > | null,
  or?: Array< ModelObjectEditEventFilterInput | null > | null,
  not?: ModelObjectEditEventFilterInput | null,
};

export type ModelObjectEditEventConnection = {
  __typename: "ModelObjectEditEventConnection",
  items:  Array<ObjectEditEvent | null >,
  nextToken?: string | null,
};

export type ModelObjectEditEventByObjectIDCompositeKeyConditionInput = {
  eq?: ModelObjectEditEventByObjectIDCompositeKeyInput | null,
  le?: ModelObjectEditEventByObjectIDCompositeKeyInput | null,
  lt?: ModelObjectEditEventByObjectIDCompositeKeyInput | null,
  ge?: ModelObjectEditEventByObjectIDCompositeKeyInput | null,
  gt?: ModelObjectEditEventByObjectIDCompositeKeyInput | null,
  between?: Array< ModelObjectEditEventByObjectIDCompositeKeyInput | null > | null,
  beginsWith?: ModelObjectEditEventByObjectIDCompositeKeyInput | null,
};

export type ModelObjectEditEventByObjectIDCompositeKeyInput = {
  itemID?: string | null,
  section?: string | null,
  createdAt?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelMealFilterInput = {
  id?: ModelIDInput | null,
  schemaVersion?: ModelIntInput | null,
  smorgBoardID?: ModelIDInput | null,
  spaceMembershipID?: ModelIDInput | null,
  groups?: ModelStringInput | null,
  and?: Array< ModelMealFilterInput | null > | null,
  or?: Array< ModelMealFilterInput | null > | null,
  not?: ModelMealFilterInput | null,
};

export type ModelMealConnection = {
  __typename: "ModelMealConnection",
  items:  Array<Meal | null >,
  nextToken?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelSmorgBoardFilterInput = {
  id?: ModelIDInput | null,
  schemaVersion?: ModelIntInput | null,
  title?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  coverImageUrl?: ModelStringInput | null,
  spaceMembershipID?: ModelIDInput | null,
  embeddedInContainerType?: ModelContainerTypeInput | null,
  embeddedInContainerID?: ModelIDInput | null,
  groups?: ModelStringInput | null,
  availableInMembershipTierIDs?: ModelIDInput | null,
  isMyFavouritesBoard?: ModelBooleanInput | null,
  and?: Array< ModelSmorgBoardFilterInput | null > | null,
  or?: Array< ModelSmorgBoardFilterInput | null > | null,
  not?: ModelSmorgBoardFilterInput | null,
};

export type ModelSmorgBoardConnection = {
  __typename: "ModelSmorgBoardConnection",
  items:  Array<SmorgBoard | null >,
  nextToken?: string | null,
};

export type ModelSharedMealFilterInput = {
  id?: ModelIDInput | null,
  schemaVersion?: ModelIntInput | null,
  sharedBoardID?: ModelIDInput | null,
  and?: Array< ModelSharedMealFilterInput | null > | null,
  or?: Array< ModelSharedMealFilterInput | null > | null,
  not?: ModelSharedMealFilterInput | null,
};

export type ModelSharedMealConnection = {
  __typename: "ModelSharedMealConnection",
  items:  Array<SharedMeal | null >,
  nextToken?: string | null,
};

export type ModelSharedBoardFilterInput = {
  id?: ModelIDInput | null,
  schemaVersion?: ModelIntInput | null,
  version?: ModelIDInput | null,
  accessLevel?: ModelSharedBoardAccessLevelInput | null,
  title?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  coverImageUrl?: ModelStringInput | null,
  availableInMembershipTierIDs?: ModelIDInput | null,
  and?: Array< ModelSharedBoardFilterInput | null > | null,
  or?: Array< ModelSharedBoardFilterInput | null > | null,
  not?: ModelSharedBoardFilterInput | null,
};

export type ModelSharedBoardConnection = {
  __typename: "ModelSharedBoardConnection",
  items:  Array<SharedBoard | null >,
  nextToken?: string | null,
};

export type ModelNoteFilterInput = {
  id?: ModelIDInput | null,
  parentID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  spaceMembershipID?: ModelIDInput | null,
  groups?: ModelStringInput | null,
  and?: Array< ModelNoteFilterInput | null > | null,
  or?: Array< ModelNoteFilterInput | null > | null,
  not?: ModelNoteFilterInput | null,
};

export type ModelNoteConnection = {
  __typename: "ModelNoteConnection",
  items:  Array<Note | null >,
  nextToken?: string | null,
};

export type ModelContentEntryFilterInput = {
  id?: ModelIDInput | null,
  parentID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  body?: ModelStringInput | null,
  coverImageUrl?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  groups?: ModelStringInput | null,
  and?: Array< ModelContentEntryFilterInput | null > | null,
  or?: Array< ModelContentEntryFilterInput | null > | null,
  not?: ModelContentEntryFilterInput | null,
};

export type ModelContentEntryConnection = {
  __typename: "ModelContentEntryConnection",
  items:  Array<ContentEntry | null >,
  nextToken?: string | null,
};

export type ModelSharedContentEntryFilterInput = {
  id?: ModelIDInput | null,
  parentID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  body?: ModelStringInput | null,
  coverImageUrl?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  and?: Array< ModelSharedContentEntryFilterInput | null > | null,
  or?: Array< ModelSharedContentEntryFilterInput | null > | null,
  not?: ModelSharedContentEntryFilterInput | null,
};

export type ModelSharedContentEntryConnection = {
  __typename: "ModelSharedContentEntryConnection",
  items:  Array<SharedContentEntry | null >,
  nextToken?: string | null,
};

export type ModelPlannerWeekFilterInput = {
  id?: ModelIDInput | null,
  schemaVersion?: ModelIntInput | null,
  spaceMembershipID?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  and?: Array< ModelPlannerWeekFilterInput | null > | null,
  or?: Array< ModelPlannerWeekFilterInput | null > | null,
  not?: ModelPlannerWeekFilterInput | null,
};

export type ModelPlannerWeekConnection = {
  __typename: "ModelPlannerWeekConnection",
  items:  Array<PlannerWeek | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelShoppingListFilterInput = {
  id?: ModelIDInput | null,
  schemaVersion?: ModelIntInput | null,
  spaceMembershipID?: ModelIDInput | null,
  productsBoardID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  mealIDs?: ModelIDInput | null,
  mealImageUrls?: ModelStringInput | null,
  and?: Array< ModelShoppingListFilterInput | null > | null,
  or?: Array< ModelShoppingListFilterInput | null > | null,
  not?: ModelShoppingListFilterInput | null,
};

export type ModelShoppingListConnection = {
  __typename: "ModelShoppingListConnection",
  items:  Array<ShoppingList | null >,
  nextToken?: string | null,
};

export type ModelProductsBoardFilterInput = {
  id?: ModelIDInput | null,
  schemaVersion?: ModelIntInput | null,
  title?: ModelStringInput | null,
  spaceMembershipID?: ModelIDInput | null,
  and?: Array< ModelProductsBoardFilterInput | null > | null,
  or?: Array< ModelProductsBoardFilterInput | null > | null,
  not?: ModelProductsBoardFilterInput | null,
};

export type ModelProductsBoardConnection = {
  __typename: "ModelProductsBoardConnection",
  items:  Array<ProductsBoard | null >,
  nextToken?: string | null,
};

export type ModelGlobalTagFilterInput = {
  id?: ModelIDInput | null,
  tag?: ModelStringInput | null,
  count?: ModelIntInput | null,
  and?: Array< ModelGlobalTagFilterInput | null > | null,
  or?: Array< ModelGlobalTagFilterInput | null > | null,
  not?: ModelGlobalTagFilterInput | null,
};

export type ModelGlobalTagConnection = {
  __typename: "ModelGlobalTagConnection",
  items:  Array<GlobalTag | null >,
  nextToken?: string | null,
};

export type ModelIngredientEditEventFilterInput = {
  mealID?: ModelIDInput | null,
  recipeID?: ModelStringInput | null,
  editMethod?: ModelEditMethodInput | null,
  ingredientID?: ModelIDInput | null,
  fullTextBefore?: ModelStringInput | null,
  fullTextAfter?: ModelStringInput | null,
  groups?: ModelStringInput | null,
  and?: Array< ModelIngredientEditEventFilterInput | null > | null,
  or?: Array< ModelIngredientEditEventFilterInput | null > | null,
  not?: ModelIngredientEditEventFilterInput | null,
};

export type ModelIngredientEditEventConnection = {
  __typename: "ModelIngredientEditEventConnection",
  items:  Array<IngredientEditEvent | null >,
  nextToken?: string | null,
};

export type ModelIngredientEditSuggestionFilterInput = {
  id?: ModelIDInput | null,
  inputFullText?: ModelStringInput | null,
  suggestion?: ModelStringInput | null,
  weight?: ModelFloatInput | null,
  and?: Array< ModelIngredientEditSuggestionFilterInput | null > | null,
  or?: Array< ModelIngredientEditSuggestionFilterInput | null > | null,
  not?: ModelIngredientEditSuggestionFilterInput | null,
};

export type ModelIngredientEditSuggestionConnection = {
  __typename: "ModelIngredientEditSuggestionConnection",
  items:  Array<IngredientEditSuggestion | null >,
  nextToken?: string | null,
};

export type ModelFloatKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIngredientEditSuggestionByIngredientIDFilterInput = {
  id?: ModelIDInput | null,
  ingredientID?: ModelStringInput | null,
  suggestion?: ModelStringInput | null,
  weight?: ModelFloatInput | null,
  and?: Array< ModelIngredientEditSuggestionByIngredientIDFilterInput | null > | null,
  or?: Array< ModelIngredientEditSuggestionByIngredientIDFilterInput | null > | null,
  not?: ModelIngredientEditSuggestionByIngredientIDFilterInput | null,
};

export type ModelIngredientEditSuggestionByIngredientIDConnection = {
  __typename: "ModelIngredientEditSuggestionByIngredientIDConnection",
  items:  Array<IngredientEditSuggestionByIngredientID | null >,
  nextToken?: string | null,
};

export type ModelProgrammeFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  description?: ModelStringInput | null,
  coverImageUrl?: ModelStringInput | null,
  locales?: ModelStringInput | null,
  mealTypes?: ModelStringInput | null,
  showNutritionToUsers?: ModelBooleanInput | null,
  copyMealsExactly?: ModelBooleanInput | null,
  personalisedMealScaling?: ModelBooleanInput | null,
  recipesBoardIDs?: ModelIDInput | null,
  categoryTags?: ModelStringInput | null,
  availableInMembershipTierIDs?: ModelIDInput | null,
  groups?: ModelStringInput | null,
  and?: Array< ModelProgrammeFilterInput | null > | null,
  or?: Array< ModelProgrammeFilterInput | null > | null,
  not?: ModelProgrammeFilterInput | null,
};

export type ModelProgrammeConnection = {
  __typename: "ModelProgrammeConnection",
  items:  Array<Programme | null >,
  nextToken?: string | null,
};

export type ModelSharedProgrammeFilterInput = {
  spaceID?: ModelIDInput | null,
  programmeID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  description?: ModelStringInput | null,
  coverImageUrl?: ModelStringInput | null,
  locales?: ModelStringInput | null,
  mealTypes?: ModelStringInput | null,
  showNutritionToUsers?: ModelBooleanInput | null,
  copyMealsExactly?: ModelBooleanInput | null,
  personalisedMealScaling?: ModelBooleanInput | null,
  sharedRecipesBoardIDs?: ModelIDInput | null,
  categoryTags?: ModelStringInput | null,
  version?: ModelIDInput | null,
  availableInMembershipTierIDs?: ModelIDInput | null,
  groups?: ModelStringInput | null,
  and?: Array< ModelSharedProgrammeFilterInput | null > | null,
  or?: Array< ModelSharedProgrammeFilterInput | null > | null,
  not?: ModelSharedProgrammeFilterInput | null,
};

export type ModelSharedProgrammeConnection = {
  __typename: "ModelSharedProgrammeConnection",
  items:  Array<SharedProgramme | null >,
  nextToken?: string | null,
};

export type ModelUserProgrammeEnrollmentFilterInput = {
  spaceMembershipID?: ModelIDInput | null,
  sharedProgrammeID?: ModelIDInput | null,
  enrolledAt?: ModelStringInput | null,
  endedAt?: ModelStringInput | null,
  customTargetCalories?: ModelFloatInput | null,
  and?: Array< ModelUserProgrammeEnrollmentFilterInput | null > | null,
  or?: Array< ModelUserProgrammeEnrollmentFilterInput | null > | null,
  not?: ModelUserProgrammeEnrollmentFilterInput | null,
};

export type ModelUserProgrammeEnrollmentConnection = {
  __typename: "ModelUserProgrammeEnrollmentConnection",
  items:  Array<UserProgrammeEnrollment | null >,
  nextToken?: string | null,
};

export type ModelGRCRecipeFilterInput = {
  grcRecipeID?: ModelIDInput | null,
  and?: Array< ModelGRCRecipeFilterInput | null > | null,
  or?: Array< ModelGRCRecipeFilterInput | null > | null,
  not?: ModelGRCRecipeFilterInput | null,
};

export type ModelGRCRecipeConnection = {
  __typename: "ModelGRCRecipeConnection",
  items:  Array<GRCRecipe | null >,
  nextToken?: string | null,
};

export type ModelSharedContentBoardFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  and?: Array< ModelSharedContentBoardFilterInput | null > | null,
  or?: Array< ModelSharedContentBoardFilterInput | null > | null,
  not?: ModelSharedContentBoardFilterInput | null,
};

export type ModelSharedContentBoardConnection = {
  __typename: "ModelSharedContentBoardConnection",
  items:  Array<SharedContentBoard | null >,
  nextToken?: string | null,
};

export type ModelSpaceIAPSubscriptionFilterInput = {
  spaceID?: ModelIDInput | null,
  productID?: ModelStringInput | null,
  title?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  currencyCode?: ModelStringInput | null,
  priceStr?: ModelStringInput | null,
  bundleID?: ModelStringInput | null,
  duration?: ModelSubscriptionDurationInput | null,
  signUpButtonLabelOverride?: ModelStringInput | null,
  appStoreID?: ModelAppStoreIDInput | null,
  rank?: ModelIntInput | null,
  and?: Array< ModelSpaceIAPSubscriptionFilterInput | null > | null,
  or?: Array< ModelSpaceIAPSubscriptionFilterInput | null > | null,
  not?: ModelSpaceIAPSubscriptionFilterInput | null,
};

export type ModelSpaceIAPSubscriptionConnection = {
  __typename: "ModelSpaceIAPSubscriptionConnection",
  items:  Array<SpaceIAPSubscription | null >,
  nextToken?: string | null,
};

export type ModelSpaceFilterInput = {
  title?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  adminUserIDs?: ModelIDInput | null,
  landingPageContent?: ModelStringInput | null,
  landingPageCoverImageUrl?: ModelStringInput | null,
  isDefaultSpace?: ModelBooleanInput | null,
  spaceSharedContentBoardIDs?: ModelIDInput | null,
  subscriptionBenefitsContent?: ModelStringInput | null,
  groups?: ModelStringInput | null,
  sharedRecipesBoardIDs?: ModelIDInput | null,
  defaultSharedProgrammeIDForNewUsers?: ModelIDInput | null,
  and?: Array< ModelSpaceFilterInput | null > | null,
  or?: Array< ModelSpaceFilterInput | null > | null,
  not?: ModelSpaceFilterInput | null,
};

export type ModelSpaceConnection = {
  __typename: "ModelSpaceConnection",
  items:  Array<Space | null >,
  nextToken?: string | null,
};

export type ModelSpaceMembershipFilterInput = {
  spaceID?: ModelIDInput | null,
  memberID?: ModelStringInput | null,
  state?: ModelSpaceMembershipStateInput | null,
  correlatedUserID?: ModelStringInput | null,
  expiresAt?: ModelStringInput | null,
  visibleProgrammeIDsOverride?: ModelIDInput | null,
  membershipTierID?: ModelIDInput | null,
  stripeCustomerID?: ModelStringInput | null,
  stripeSubscriptionID?: ModelStringInput | null,
  onboardingAnswersLastUpdatedAt?: ModelStringInput | null,
  customTargetCalories?: ModelFloatInput | null,
  welcomeVideoWatched?: ModelBooleanInput | null,
  initialProgrammeSelectionLastOfferedAt?: ModelStringInput | null,
  chatNickname?: ModelStringInput | null,
  referrer?: ModelSpaceMembershipReferrerInput | null,
  appStoreSubscriptionID?: ModelStringInput | null,
  groups?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelSpaceMembershipFilterInput | null > | null,
  or?: Array< ModelSpaceMembershipFilterInput | null > | null,
  not?: ModelSpaceMembershipFilterInput | null,
};

export type ModelSpaceMembershipConnection = {
  __typename: "ModelSpaceMembershipConnection",
  items:  Array<SpaceMembership | null >,
  nextToken?: string | null,
};

export type ModelSpaceMembershipEventFilterInput = {
  spaceMembershipID?: ModelIDInput | null,
  memberID?: ModelStringInput | null,
  eventType?: ModelSpaceMembershipEventTypeInput | null,
  transactionSource?: ModelSubscriptionTransactionSourceInput | null,
  transactionID?: ModelStringInput | null,
  details?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelSpaceMembershipEventFilterInput | null > | null,
  or?: Array< ModelSpaceMembershipEventFilterInput | null > | null,
  not?: ModelSpaceMembershipEventFilterInput | null,
};

export type ModelSpaceMembershipEventConnection = {
  __typename: "ModelSpaceMembershipEventConnection",
  items:  Array<SpaceMembershipEvent | null >,
  nextToken?: string | null,
};

export type ModelUserMyDayActionRecordFilterInput = {
  userProgrammeEnrollmentID?: ModelIDInput | null,
  programmeEntryType?: ModelEntryTypeInput | null,
  objectID?: ModelIDInput | null,
  calendarDay?: ModelStringInput | null,
  actionType?: ModelMyDayActionTypeInput | null,
  and?: Array< ModelUserMyDayActionRecordFilterInput | null > | null,
  or?: Array< ModelUserMyDayActionRecordFilterInput | null > | null,
  not?: ModelUserMyDayActionRecordFilterInput | null,
};

export type ModelUserMyDayActionRecordConnection = {
  __typename: "ModelUserMyDayActionRecordConnection",
  items:  Array<UserMyDayActionRecord | null >,
  nextToken?: string | null,
};

export type ModelMembershipTierFilterInput = {
  spaceID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  monthlyPrice?: ModelStringInput | null,
  quarterlyPrice?: ModelStringInput | null,
  annualPrice?: ModelStringInput | null,
  freeTrialDurationDays?: ModelIntInput | null,
  rank?: ModelIntInput | null,
  currencyCode?: ModelStringInput | null,
  state?: ModelMembershipTierStateInput | null,
  stripeProductID?: ModelStringInput | null,
  stripePriceID?: ModelStringInput | null,
  stripePriceIDQuarterly?: ModelStringInput | null,
  stripePriceIDAnnual?: ModelStringInput | null,
  groups?: ModelStringInput | null,
  and?: Array< ModelMembershipTierFilterInput | null > | null,
  or?: Array< ModelMembershipTierFilterInput | null > | null,
  not?: ModelMembershipTierFilterInput | null,
};

export type ModelMembershipTierConnection = {
  __typename: "ModelMembershipTierConnection",
  items:  Array<MembershipTier | null >,
  nextToken?: string | null,
};

export type ModelSpacePrivateConfigFilterInput = {
  id?: ModelIDInput | null,
  spaceID?: ModelIDInput | null,
  groups?: ModelStringInput | null,
  and?: Array< ModelSpacePrivateConfigFilterInput | null > | null,
  or?: Array< ModelSpacePrivateConfigFilterInput | null > | null,
  not?: ModelSpacePrivateConfigFilterInput | null,
};

export type ModelSpacePrivateConfigConnection = {
  __typename: "ModelSpacePrivateConfigConnection",
  items:  Array<SpacePrivateConfig | null >,
  nextToken?: string | null,
};

export type ModelAccountActivitySignalFilterInput = {
  id?: ModelIDInput | null,
  spaceID?: ModelIDInput | null,
  groups?: ModelStringInput | null,
  lastActivityAt?: ModelStringInput | null,
  friendlyUsername?: ModelStringInput | null,
  lastActivityObjectType?: ModelActivityObjectTypeInput | null,
  lastActivityObjectID?: ModelIDInput | null,
  sourceIp?: ModelStringInput | null,
  and?: Array< ModelAccountActivitySignalFilterInput | null > | null,
  or?: Array< ModelAccountActivitySignalFilterInput | null > | null,
  not?: ModelAccountActivitySignalFilterInput | null,
};

export type ModelAccountActivitySignalConnection = {
  __typename: "ModelAccountActivitySignalConnection",
  items:  Array<AccountActivitySignal | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionZapierRestHookFilterInput = {
  spaceID?: ModelSubscriptionIDInput | null,
  hookUrl?: ModelSubscriptionStringInput | null,
  hookKey?: ModelSubscriptionStringInput | null,
  enabled?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionZapierRestHookFilterInput | null > | null,
  or?: Array< ModelSubscriptionZapierRestHookFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionUserProfileFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  showOnboarding?: ModelSubscriptionBooleanInput | null,
  consentToProductComms?: ModelSubscriptionBooleanInput | null,
  plannerViewWeekStartDay?: ModelSubscriptionIntInput | null,
  geoRegion?: ModelSubscriptionStringInput | null,
  countryCode?: ModelSubscriptionStringInput | null,
  mealPlanReminderSchedule?: ModelSubscriptionStringInput | null,
  showMealDetailGuide?: ModelSubscriptionBooleanInput | null,
  recentlyUsedTags?: ModelSubscriptionStringInput | null,
  enabledFeatures?: ModelSubscriptionStringInput | null,
  requestedFeatures?: ModelSubscriptionStringInput | null,
  seenMessages?: ModelSubscriptionStringInput | null,
  recentlyUsedRecommenderMealTypes?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserProfileFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserProfileFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionObjectEditEventFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  objectID?: ModelSubscriptionIDInput | null,
  objectType?: ModelSubscriptionStringInput | null,
  section?: ModelSubscriptionStringInput | null,
  itemID?: ModelSubscriptionStringInput | null,
  oldValue?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionObjectEditEventFilterInput | null > | null,
  or?: Array< ModelSubscriptionObjectEditEventFilterInput | null > | null,
};

export type ModelSubscriptionMealFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  schemaVersion?: ModelSubscriptionIntInput | null,
  smorgBoardID?: ModelSubscriptionIDInput | null,
  spaceMembershipID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionMealFilterInput | null > | null,
  or?: Array< ModelSubscriptionMealFilterInput | null > | null,
};

export type ModelSubscriptionSmorgBoardFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  schemaVersion?: ModelSubscriptionIntInput | null,
  title?: ModelSubscriptionStringInput | null,
  shortDescription?: ModelSubscriptionStringInput | null,
  coverImageUrl?: ModelSubscriptionStringInput | null,
  spaceMembershipID?: ModelSubscriptionIDInput | null,
  embeddedInContainerType?: ModelSubscriptionStringInput | null,
  embeddedInContainerID?: ModelSubscriptionIDInput | null,
  availableInMembershipTierIDs?: ModelSubscriptionIDInput | null,
  isMyFavouritesBoard?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionSmorgBoardFilterInput | null > | null,
  or?: Array< ModelSubscriptionSmorgBoardFilterInput | null > | null,
};

export type ModelSubscriptionSharedMealFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  schemaVersion?: ModelSubscriptionIntInput | null,
  sharedBoardID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionSharedMealFilterInput | null > | null,
  or?: Array< ModelSubscriptionSharedMealFilterInput | null > | null,
};

export type ModelSubscriptionSharedBoardFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  schemaVersion?: ModelSubscriptionIntInput | null,
  version?: ModelSubscriptionIDInput | null,
  accessLevel?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  shortDescription?: ModelSubscriptionStringInput | null,
  coverImageUrl?: ModelSubscriptionStringInput | null,
  availableInMembershipTierIDs?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionSharedBoardFilterInput | null > | null,
  or?: Array< ModelSubscriptionSharedBoardFilterInput | null > | null,
};

export type ModelSubscriptionNoteFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  parentID?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  spaceMembershipID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionNoteFilterInput | null > | null,
  or?: Array< ModelSubscriptionNoteFilterInput | null > | null,
};

export type ModelSubscriptionContentEntryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  parentID?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  body?: ModelSubscriptionStringInput | null,
  coverImageUrl?: ModelSubscriptionStringInput | null,
  tags?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionContentEntryFilterInput | null > | null,
  or?: Array< ModelSubscriptionContentEntryFilterInput | null > | null,
};

export type ModelSubscriptionSharedContentEntryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  parentID?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  body?: ModelSubscriptionStringInput | null,
  coverImageUrl?: ModelSubscriptionStringInput | null,
  tags?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSharedContentEntryFilterInput | null > | null,
  or?: Array< ModelSubscriptionSharedContentEntryFilterInput | null > | null,
};

export type ModelSubscriptionPlannerWeekFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  schemaVersion?: ModelSubscriptionIntInput | null,
  spaceMembershipID?: ModelSubscriptionIDInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPlannerWeekFilterInput | null > | null,
  or?: Array< ModelSubscriptionPlannerWeekFilterInput | null > | null,
};

export type ModelSubscriptionShoppingListFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  schemaVersion?: ModelSubscriptionIntInput | null,
  spaceMembershipID?: ModelSubscriptionIDInput | null,
  productsBoardID?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  shortDescription?: ModelSubscriptionStringInput | null,
  mealIDs?: ModelSubscriptionIDInput | null,
  mealImageUrls?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionShoppingListFilterInput | null > | null,
  or?: Array< ModelSubscriptionShoppingListFilterInput | null > | null,
};

export type ModelSubscriptionProductsBoardFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  schemaVersion?: ModelSubscriptionIntInput | null,
  title?: ModelSubscriptionStringInput | null,
  spaceMembershipID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionProductsBoardFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductsBoardFilterInput | null > | null,
};

export type ModelSubscriptionGlobalTagFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tag?: ModelSubscriptionStringInput | null,
  count?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionGlobalTagFilterInput | null > | null,
  or?: Array< ModelSubscriptionGlobalTagFilterInput | null > | null,
};

export type ModelSubscriptionIngredientEditEventFilterInput = {
  mealID?: ModelSubscriptionIDInput | null,
  recipeID?: ModelSubscriptionStringInput | null,
  editMethod?: ModelSubscriptionStringInput | null,
  ingredientID?: ModelSubscriptionIDInput | null,
  fullTextBefore?: ModelSubscriptionStringInput | null,
  fullTextAfter?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionIngredientEditEventFilterInput | null > | null,
  or?: Array< ModelSubscriptionIngredientEditEventFilterInput | null > | null,
};

export type ModelSubscriptionIngredientEditSuggestionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  inputFullText?: ModelSubscriptionStringInput | null,
  suggestion?: ModelSubscriptionStringInput | null,
  weight?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionIngredientEditSuggestionFilterInput | null > | null,
  or?: Array< ModelSubscriptionIngredientEditSuggestionFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionIngredientEditSuggestionByIngredientIDFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  ingredientID?: ModelSubscriptionStringInput | null,
  suggestion?: ModelSubscriptionStringInput | null,
  weight?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionIngredientEditSuggestionByIngredientIDFilterInput | null > | null,
  or?: Array< ModelSubscriptionIngredientEditSuggestionByIngredientIDFilterInput | null > | null,
};

export type ModelSubscriptionProgrammeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  shortDescription?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  coverImageUrl?: ModelSubscriptionStringInput | null,
  locales?: ModelSubscriptionStringInput | null,
  mealTypes?: ModelSubscriptionStringInput | null,
  showNutritionToUsers?: ModelSubscriptionBooleanInput | null,
  copyMealsExactly?: ModelSubscriptionBooleanInput | null,
  personalisedMealScaling?: ModelSubscriptionBooleanInput | null,
  recipesBoardIDs?: ModelSubscriptionIDInput | null,
  categoryTags?: ModelSubscriptionStringInput | null,
  availableInMembershipTierIDs?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionProgrammeFilterInput | null > | null,
  or?: Array< ModelSubscriptionProgrammeFilterInput | null > | null,
};

export type ModelSubscriptionSharedProgrammeFilterInput = {
  spaceID?: ModelSubscriptionIDInput | null,
  programmeID?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  shortDescription?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  coverImageUrl?: ModelSubscriptionStringInput | null,
  locales?: ModelSubscriptionStringInput | null,
  mealTypes?: ModelSubscriptionStringInput | null,
  showNutritionToUsers?: ModelSubscriptionBooleanInput | null,
  copyMealsExactly?: ModelSubscriptionBooleanInput | null,
  personalisedMealScaling?: ModelSubscriptionBooleanInput | null,
  sharedRecipesBoardIDs?: ModelSubscriptionIDInput | null,
  categoryTags?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionIDInput | null,
  availableInMembershipTierIDs?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionSharedProgrammeFilterInput | null > | null,
  or?: Array< ModelSubscriptionSharedProgrammeFilterInput | null > | null,
};

export type ModelSubscriptionUserProgrammeEnrollmentFilterInput = {
  spaceMembershipID?: ModelSubscriptionIDInput | null,
  sharedProgrammeID?: ModelSubscriptionIDInput | null,
  enrolledAt?: ModelSubscriptionStringInput | null,
  endedAt?: ModelSubscriptionStringInput | null,
  customTargetCalories?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionUserProgrammeEnrollmentFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserProgrammeEnrollmentFilterInput | null > | null,
};

export type ModelSubscriptionGRCRecipeFilterInput = {
  grcRecipeID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionGRCRecipeFilterInput | null > | null,
  or?: Array< ModelSubscriptionGRCRecipeFilterInput | null > | null,
};

export type ModelSubscriptionSharedContentBoardFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSharedContentBoardFilterInput | null > | null,
  or?: Array< ModelSubscriptionSharedContentBoardFilterInput | null > | null,
};

export type ModelSubscriptionSpaceIAPSubscriptionFilterInput = {
  spaceID?: ModelSubscriptionIDInput | null,
  productID?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  shortDescription?: ModelSubscriptionStringInput | null,
  currencyCode?: ModelSubscriptionStringInput | null,
  priceStr?: ModelSubscriptionStringInput | null,
  bundleID?: ModelSubscriptionStringInput | null,
  duration?: ModelSubscriptionStringInput | null,
  signUpButtonLabelOverride?: ModelSubscriptionStringInput | null,
  appStoreID?: ModelSubscriptionStringInput | null,
  rank?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionSpaceIAPSubscriptionFilterInput | null > | null,
  or?: Array< ModelSubscriptionSpaceIAPSubscriptionFilterInput | null > | null,
};

export type ModelSubscriptionSpaceFilterInput = {
  title?: ModelSubscriptionStringInput | null,
  shortDescription?: ModelSubscriptionStringInput | null,
  adminUserIDs?: ModelSubscriptionIDInput | null,
  landingPageContent?: ModelSubscriptionStringInput | null,
  landingPageCoverImageUrl?: ModelSubscriptionStringInput | null,
  isDefaultSpace?: ModelSubscriptionBooleanInput | null,
  spaceSharedContentBoardIDs?: ModelSubscriptionIDInput | null,
  subscriptionBenefitsContent?: ModelSubscriptionStringInput | null,
  sharedRecipesBoardIDs?: ModelSubscriptionIDInput | null,
  defaultSharedProgrammeIDForNewUsers?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionSpaceFilterInput | null > | null,
  or?: Array< ModelSubscriptionSpaceFilterInput | null > | null,
};

export type ModelSubscriptionSpaceMembershipFilterInput = {
  spaceID?: ModelSubscriptionIDInput | null,
  state?: ModelSubscriptionStringInput | null,
  correlatedUserID?: ModelSubscriptionStringInput | null,
  expiresAt?: ModelSubscriptionStringInput | null,
  visibleProgrammeIDsOverride?: ModelSubscriptionIDInput | null,
  membershipTierID?: ModelSubscriptionIDInput | null,
  stripeCustomerID?: ModelSubscriptionStringInput | null,
  stripeSubscriptionID?: ModelSubscriptionStringInput | null,
  onboardingAnswersLastUpdatedAt?: ModelSubscriptionStringInput | null,
  customTargetCalories?: ModelSubscriptionFloatInput | null,
  welcomeVideoWatched?: ModelSubscriptionBooleanInput | null,
  initialProgrammeSelectionLastOfferedAt?: ModelSubscriptionStringInput | null,
  chatNickname?: ModelSubscriptionStringInput | null,
  referrer?: ModelSubscriptionStringInput | null,
  appStoreSubscriptionID?: ModelSubscriptionStringInput | null,
  groups?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSpaceMembershipFilterInput | null > | null,
  or?: Array< ModelSubscriptionSpaceMembershipFilterInput | null > | null,
};

export type ModelSubscriptionSpaceMembershipEventFilterInput = {
  spaceMembershipID?: ModelSubscriptionIDInput | null,
  eventType?: ModelSubscriptionStringInput | null,
  transactionSource?: ModelSubscriptionStringInput | null,
  transactionID?: ModelSubscriptionStringInput | null,
  details?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSpaceMembershipEventFilterInput | null > | null,
  or?: Array< ModelSubscriptionSpaceMembershipEventFilterInput | null > | null,
};

export type ModelSubscriptionUserMyDayActionRecordFilterInput = {
  userProgrammeEnrollmentID?: ModelSubscriptionIDInput | null,
  programmeEntryType?: ModelSubscriptionStringInput | null,
  objectID?: ModelSubscriptionIDInput | null,
  calendarDay?: ModelSubscriptionStringInput | null,
  actionType?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserMyDayActionRecordFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserMyDayActionRecordFilterInput | null > | null,
};

export type ModelSubscriptionMembershipTierFilterInput = {
  spaceID?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  shortDescription?: ModelSubscriptionStringInput | null,
  monthlyPrice?: ModelSubscriptionStringInput | null,
  quarterlyPrice?: ModelSubscriptionStringInput | null,
  annualPrice?: ModelSubscriptionStringInput | null,
  freeTrialDurationDays?: ModelSubscriptionIntInput | null,
  rank?: ModelSubscriptionIntInput | null,
  currencyCode?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  stripeProductID?: ModelSubscriptionStringInput | null,
  stripePriceID?: ModelSubscriptionStringInput | null,
  stripePriceIDQuarterly?: ModelSubscriptionStringInput | null,
  stripePriceIDAnnual?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMembershipTierFilterInput | null > | null,
  or?: Array< ModelSubscriptionMembershipTierFilterInput | null > | null,
};

export type ModelSubscriptionSpacePrivateConfigFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  spaceID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionSpacePrivateConfigFilterInput | null > | null,
  or?: Array< ModelSubscriptionSpacePrivateConfigFilterInput | null > | null,
};

export type ModelSubscriptionAccountActivitySignalFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  spaceID?: ModelSubscriptionIDInput | null,
  lastActivityAt?: ModelSubscriptionStringInput | null,
  friendlyUsername?: ModelSubscriptionStringInput | null,
  lastActivityObjectType?: ModelSubscriptionStringInput | null,
  lastActivityObjectID?: ModelSubscriptionIDInput | null,
  sourceIp?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAccountActivitySignalFilterInput | null > | null,
  or?: Array< ModelSubscriptionAccountActivitySignalFilterInput | null > | null,
};

export type AdvancedSearchGRCMutationVariables = {
  searchCriteria: SearchCriteria,
  offset?: number | null,
};

export type AdvancedSearchGRCMutation = {
  advancedSearchGRC:  {
    __typename: "GRCResultPage",
    data:  Array< {
      __typename: "GRCResult",
      grcRecipeID: string,
      title: string,
      imageUrl?: string | null,
      mealTypes?: Array< string > | null,
      score?: number | null,
    } >,
    nextOffset: number,
    moreAvailable: boolean,
    estimatedTotalResults?: number | null,
  },
};

export type AdvancedSearchOwnMealsMutationVariables = {
  searchCriteria: SearchCriteria,
  offset?: number | null,
};

export type AdvancedSearchOwnMealsMutation = {
  advancedSearchOwnMeals:  {
    __typename: "OwnMealsResultPage",
    data:  Array< {
      __typename: "OwnMealResult",
      mealID: string,
      parentID: string,
      title: string,
      imageUrl?: string | null,
      mealTypes?: Array< string > | null,
      score?: number | null,
    } >,
    nextOffset: number,
    moreAvailable: boolean,
    estimatedTotalResults?: number | null,
  },
};

export type GetGRCRecipeCategoriesMutationVariables = {
  spaceID: string,
};

export type GetGRCRecipeCategoriesMutation = {
  getGRCRecipeCategories:  Array< {
    __typename: "GRCRecipeCategory",
    id: string,
    title: string,
  } >,
};

export type GetGRCResultsMutationVariables = {
  spaceID: string,
  grcRecipeCategoryID: string,
  offset?: number | null,
};

export type GetGRCResultsMutation = {
  getGRCResults:  {
    __typename: "GRCResultPage",
    data:  Array< {
      __typename: "GRCResult",
      grcRecipeID: string,
      title: string,
      imageUrl?: string | null,
      mealTypes?: Array< string > | null,
      score?: number | null,
    } >,
    nextOffset: number,
    moreAvailable: boolean,
    estimatedTotalResults?: number | null,
  },
};

export type AddRecipeMutationVariables = {
  url: string,
};

export type AddRecipeMutation = {
  addRecipe:  {
    __typename: "AddRecipeResult",
    recipe?:  {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    } | null,
    error?: string | null,
  },
};

export type AddRecipesBulkMutationVariables = {
  ldjsonListStr: string,
};

export type AddRecipesBulkMutation = {
  addRecipesBulk:  Array< {
    __typename: "Recipe",
    title: string,
    shortDescription?: string | null,
    imageUrl?: string | null,
    recipeUrl?: string | null,
    publisher?: string | null,
    chef?: string | null,
    mealiqId?: string | null,
    nutrition?:  {
      __typename: "Nutrition",
      calories?: number | null,
      protein?: number | null,
      carbohydrate?: number | null,
      fat?: number | null,
      fibre?: number | null,
      sugar?: number | null,
      saturates?: number | null,
      salt?: number | null,
      veg_weight?: number | null,
      cholesterol?: number | null,
      calcium?: number | null,
      iron?: number | null,
      magnesium?: number | null,
      potassium?: number | null,
      vitamin_a?: number | null,
      vitamin_b1?: number | null,
      vitamin_b2?: number | null,
      vitamin_b3?: number | null,
      vitamin_b5?: number | null,
      vitamin_b6?: number | null,
      vitamin_b12?: number | null,
      vitamin_c?: number | null,
      vitamin_d?: number | null,
      vitamin_e?: number | null,
      vitamin_k?: number | null,
      phosphorous?: number | null,
      zinc?: number | null,
      copper?: number | null,
      manganese?: number | null,
      selenium?: number | null,
      folate?: number | null,
      omega3_dha?: number | null,
      omega3_dpa?: number | null,
      omega3_epa?: number | null,
      sucrose?: number | null,
      glucose?: number | null,
      fructose?: number | null,
      lactose?: number | null,
      maltose?: number | null,
    } | null,
    mealTypes?: Array< string > | null,
    tags?: Array< string > | null,
    supportedDiets?: Array< string > | null,
    ingredients:  Array< {
      __typename: "RecipeIngredient",
      id?: string | null,
      fullText: string,
      structuredIngredient?:  {
        __typename: "StructuredIngredient",
        name: string,
        quantity?: number | null,
        unitOfMeasure?: string | null,
        normalisedQuantity?: number | null,
        linkedIngredient?:  {
          __typename: "LinkedIngredientDetails",
          status: boolean,
          linkedIngredientName: string,
        } | null,
        resolvedNutrition?: boolean | null,
        resolvedNutritionError?: string | null,
      } | null,
      tokFullText?: string | null,
      tokens?:  Array< {
        __typename: "IngredientToken",
        fromChar: number,
        toChar: number,
        type?: string | null,
        label?: string | null,
      } | null > | null,
      scalingRules?: string | null,
      checkedInIngredientsList?: boolean | null,
    } >,
    preparations:  Array< {
      __typename: "RecipePreparation",
      id?: string | null,
      fullText: string,
    } >,
    utensils?:  Array< {
      __typename: "RecipeUtensil",
      id?: string | null,
      fullText: string,
    } > | null,
    servings?: number | null,
    cookTime?: number | null,
    prepTime?: number | null,
    totalTime?: number | null,
    recipeNotes?: string | null,
  } >,
};

export type AnalyzeIngredientsMutationVariables = {
  ingsFullText: Array< string >,
  customerFoodDataID?: string | null,
};

export type AnalyzeIngredientsMutation = {
  analyzeIngredients:  Array< {
    __typename: "RecipeIngredient",
    id?: string | null,
    fullText: string,
    structuredIngredient?:  {
      __typename: "StructuredIngredient",
      name: string,
      quantity?: number | null,
      unitOfMeasure?: string | null,
      normalisedQuantity?: number | null,
      linkedIngredient?:  {
        __typename: "LinkedIngredientDetails",
        status: boolean,
        linkedIngredientName: string,
      } | null,
      resolvedNutrition?: boolean | null,
      resolvedNutritionError?: string | null,
    } | null,
    tokFullText?: string | null,
    tokens?:  Array< {
      __typename: "IngredientToken",
      fromChar: number,
      toChar: number,
      type?: string | null,
      label?: string | null,
    } | null > | null,
    scalingRules?: string | null,
    checkedInIngredientsList?: boolean | null,
  } >,
};

export type DeriveNutritionMutationVariables = {
  servings: number,
  structuredIngredients: Array< DerivedNutritionStructuredIngredientInput >,
  publisher?: string | null,
  chef?: string | null,
  customerFoodDataID?: string | null,
};

export type DeriveNutritionMutation = {
  deriveNutrition:  {
    __typename: "DerivedNutrition",
    totalNutritionPerServing:  {
      __typename: "Nutrition",
      calories?: number | null,
      protein?: number | null,
      carbohydrate?: number | null,
      fat?: number | null,
      fibre?: number | null,
      sugar?: number | null,
      saturates?: number | null,
      salt?: number | null,
      veg_weight?: number | null,
      cholesterol?: number | null,
      calcium?: number | null,
      iron?: number | null,
      magnesium?: number | null,
      potassium?: number | null,
      vitamin_a?: number | null,
      vitamin_b1?: number | null,
      vitamin_b2?: number | null,
      vitamin_b3?: number | null,
      vitamin_b5?: number | null,
      vitamin_b6?: number | null,
      vitamin_b12?: number | null,
      vitamin_c?: number | null,
      vitamin_d?: number | null,
      vitamin_e?: number | null,
      vitamin_k?: number | null,
      phosphorous?: number | null,
      zinc?: number | null,
      copper?: number | null,
      manganese?: number | null,
      selenium?: number | null,
      folate?: number | null,
      omega3_dha?: number | null,
      omega3_dpa?: number | null,
      omega3_epa?: number | null,
      sucrose?: number | null,
      glucose?: number | null,
      fructose?: number | null,
      lactose?: number | null,
      maltose?: number | null,
    },
    ingredientNutrition:  Array< {
      __typename: "DerivedIngredientNutrition",
      ingredientID?: string | null,
      nutrition:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      resolvedNutrition?: boolean | null,
      error?: string | null,
      resolvedIngredientWeight?: number | null,
    } >,
    foodAllergens?: Array< string > | null,
    foodPreferences?: Array< string > | null,
    status?: boolean | null,
    macroRatio?:  {
      __typename: "MacroRatio",
      carbohydrate?: number | null,
      protein?: number | null,
      fat?: number | null,
    } | null,
  },
};

export type DeriveNutritionMealsMutationVariables = {
  meals: Array< DerivedNutritionMealInput >,
  customerFoodDataID?: string | null,
};

export type DeriveNutritionMealsMutation = {
  deriveNutritionMeals:  Array< {
    __typename: "DerivedNutritionMealResult",
    mealID: string,
    derivedNutrition:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    },
  } >,
};

export type CompileIngredientCatalogMutationVariables = {
  menu: Array< RecommenderMealInput >,
  previousIngredientCatalog?: Array< PreviousShoppingListItemInput > | null,
  locale?: string | null,
};

export type CompileIngredientCatalogMutation = {
  compileIngredientCatalog:  Array< {
    __typename: "ShoppingListItem",
    id: string,
    name: string,
    total?:  Array< {
      __typename: "UnitQuantity",
      quantity?: number | null,
      prettyQuantity?: string | null,
      unitOfMeasure?: string | null,
    } > | null,
    quantities?:  Array< {
      __typename: "UnitQuantity",
      quantity?: number | null,
      prettyQuantity?: string | null,
      unitOfMeasure?: string | null,
    } > | null,
    linkedIngredient?:  {
      __typename: "LinkedIngredientDetails",
      status: boolean,
      linkedIngredientName: string,
    } | null,
    aisleLocation?: string | null,
    forMealIds?: Array< string > | null,
    checked?: boolean | null,
  } >,
};

export type GetSuggestionsMutationVariables = {
  searches: Array< IngredientSuggestionSearchInput >,
  locale?: string | null,
};

export type GetSuggestionsMutation = {
  getSuggestions:  Array< {
    __typename: "IngredientSuggestionResponse",
    suggestions:  Array< {
      __typename: "IngredientSuggestion",
      suggestionText: string,
      suggestionType?: string | null,
      highlightTokens?:  Array< {
        __typename: "IngredientSuggestionToken",
        fromChar: number,
        toChar: number,
      } > | null,
    } >,
  } >,
};

export type ScaleIngredientsMutationVariables = {
  scaleFactor: number,
  ingredients: Array< IngredientScaleInput >,
};

export type ScaleIngredientsMutation = {
  scaleIngredients:  Array< {
    __typename: "RecipeIngredient",
    id?: string | null,
    fullText: string,
    structuredIngredient?:  {
      __typename: "StructuredIngredient",
      name: string,
      quantity?: number | null,
      unitOfMeasure?: string | null,
      normalisedQuantity?: number | null,
      linkedIngredient?:  {
        __typename: "LinkedIngredientDetails",
        status: boolean,
        linkedIngredientName: string,
      } | null,
      resolvedNutrition?: boolean | null,
      resolvedNutritionError?: string | null,
    } | null,
    tokFullText?: string | null,
    tokens?:  Array< {
      __typename: "IngredientToken",
      fromChar: number,
      toChar: number,
      type?: string | null,
      label?: string | null,
    } | null > | null,
    scalingRules?: string | null,
    checkedInIngredientsList?: boolean | null,
  } >,
};

export type LocaliseIngredientsMutationVariables = {
  ingredients: Array< IngredientSuggestionSearchInput >,
  localiseWhat: string,
  targetLocale: string,
};

export type LocaliseIngredientsMutation = {
  localiseIngredients:  Array< {
    __typename: "RecipeIngredient",
    id?: string | null,
    fullText: string,
    structuredIngredient?:  {
      __typename: "StructuredIngredient",
      name: string,
      quantity?: number | null,
      unitOfMeasure?: string | null,
      normalisedQuantity?: number | null,
      linkedIngredient?:  {
        __typename: "LinkedIngredientDetails",
        status: boolean,
        linkedIngredientName: string,
      } | null,
      resolvedNutrition?: boolean | null,
      resolvedNutritionError?: string | null,
    } | null,
    tokFullText?: string | null,
    tokens?:  Array< {
      __typename: "IngredientToken",
      fromChar: number,
      toChar: number,
      type?: string | null,
      label?: string | null,
    } | null > | null,
    scalingRules?: string | null,
    checkedInIngredientsList?: boolean | null,
  } >,
};

export type UpdateContactAttributeMutationVariables = {
  email: string,
  attributeName: string,
  attributeValue?: string | null,
};

export type UpdateContactAttributeMutation = {
  updateContactAttribute: string,
};

export type EmailShoppingListMutationVariables = {
  shoppingList: EmailShoppingListInput,
  recipient: string,
  senderName?: string | null,
};

export type EmailShoppingListMutation = {
  emailShoppingList: string,
};

export type EmailSpaceUrlToSelfMutationVariables = {
  spaceUrl: string,
};

export type EmailSpaceUrlToSelfMutation = {
  emailSpaceUrlToSelf: string,
};

export type AdminEmailUserRequestedFeatureMutationVariables = {
  userId: string,
  userEmail: string,
  featureCode: string,
};

export type AdminEmailUserRequestedFeatureMutation = {
  adminEmailUserRequestedFeature: string,
};

export type AdminEmailUserDeletedMutationVariables = {
  userId: string,
  userEmail: string,
};

export type AdminEmailUserDeletedMutation = {
  adminEmailUserDeleted: string,
};

export type GetMixpanelUserGeolocationMutationVariables = {
  mixpanelDistinctId: string,
};

export type GetMixpanelUserGeolocationMutation = {
  getMixpanelUserGeolocation:  {
    __typename: "MixpanelGeolocation",
    countryCode: string,
  },
};

export type GenerateRecipeAIMutationVariables = {
  prompt: string,
};

export type GenerateRecipeAIMutation = {
  generateRecipeAI:  {
    __typename: "AddRecipeResult",
    recipe?:  {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    } | null,
    error?: string | null,
  },
};

export type BeginGenerateRecipeImageAIMutationVariables = {
  prompt: string,
  size: string,
};

export type BeginGenerateRecipeImageAIMutation = {
  beginGenerateRecipeImageAI: string,
};

export type CheckGenerateRecipeImageAIMutationVariables = {
  jobID: string,
};

export type CheckGenerateRecipeImageAIMutation = {
  checkGenerateRecipeImageAI:  {
    __typename: "GenerateImageResult",
    jobID: string,
    jobStatus: JobStatus,
    imageUrl?: string | null,
  },
};

export type BeginGenerateRecipeAIMutationVariables = {
  prompt: string,
};

export type BeginGenerateRecipeAIMutation = {
  beginGenerateRecipeAI: string,
};

export type CheckGenerateRecipeAIMutationVariables = {
  jobID: string,
};

export type CheckGenerateRecipeAIMutation = {
  checkGenerateRecipeAI:  {
    __typename: "GenerateRecipeResult",
    jobID: string,
    jobStatus: JobStatus,
    recipe?:  {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    } | null,
  },
};

export type BeginModerateRecipeAIMutationVariables = {
  ingredients?: Array< string > | null,
  preparations: Array< string >,
};

export type BeginModerateRecipeAIMutation = {
  beginModerateRecipeAI: string,
};

export type CheckModerateRecipeAIMutationVariables = {
  jobID: string,
};

export type CheckModerateRecipeAIMutation = {
  checkModerateRecipeAI:  {
    __typename: "ModerateRecipeResult",
    jobID: string,
    jobStatus: JobStatus,
    moderatedIngredients: Array< string >,
    moderatedPreparations: Array< string >,
  },
};

export type PingRecipesServiceMutation = {
  pingRecipesService:  {
    __typename: "ServiceStatus",
    overallStatus: string,
    publicIp?: string | null,
  },
};

export type ReportAccountActivitySignalMutationVariables = {
  accountActivitySignal: AccountActivitySignalReport,
};

export type ReportAccountActivitySignalMutation = {
  reportAccountActivitySignal:  {
    __typename: "ServiceStatus",
    overallStatus: string,
    publicIp?: string | null,
  },
};

export type CreateMySpaceMembershipMutationVariables = {
  spaceID: string,
  isPreactivated?: boolean | null,
  onboardingAnswers?: Array< OnboardingAnswerInput > | null,
  onboardingAnswersLastUpdatedAt?: string | null,
  referrer?: SpaceMembershipReferrer | null,
};

export type CreateMySpaceMembershipMutation = {
  createMySpaceMembership:  {
    __typename: "SpaceMembership",
    spaceID: string,
    memberID: string,
    state: SpaceMembershipState,
    correlatedUserID?: string | null,
    expiresAt?: string | null,
    visibleProgrammeIDsOverride?: Array< string > | null,
    membershipTierID?: string | null,
    stripeCustomerID?: string | null,
    stripeSubscriptionID?: string | null,
    onboardingAnswers?:  Array< {
      __typename: "OnboardingAnswer",
      questionID: string,
      answer: string,
    } > | null,
    onboardingAnswersLastUpdatedAt?: string | null,
    customTargetCalories?: number | null,
    welcomeVideoWatched?: boolean | null,
    initialProgrammeSelectionLastOfferedAt?: string | null,
    chatNickname?: string | null,
    referrer?: SpaceMembershipReferrer | null,
    appStoreSubscriptionID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  },
};

export type UpdateMembershipWithAppleIAPMutationVariables = {
  spaceMembershipID: string,
  appStoreTransactionReceipt: string,
};

export type UpdateMembershipWithAppleIAPMutation = {
  updateMembershipWithAppleIAP:  {
    __typename: "UpdateMembershipResponse",
    spaceMembershipID: string,
    appStoreTransactionReceipt: string,
    result?: UpdateMembershipResult | null,
    membershipExpiresAt?: string | null,
  },
};

export type UpdateMembershipWithGooglePlayPurchaseMutationVariables = {
  spaceMembershipID: string,
  googlePlayTransactionReceipt: string,
};

export type UpdateMembershipWithGooglePlayPurchaseMutation = {
  updateMembershipWithGooglePlayPurchase:  {
    __typename: "UpdateMembershipResponse",
    spaceMembershipID: string,
    appStoreTransactionReceipt: string,
    result?: UpdateMembershipResult | null,
    membershipExpiresAt?: string | null,
  },
};

export type UpdateMembershipNoTierAvailableMutationVariables = {
  spaceMembershipID: string,
};

export type UpdateMembershipNoTierAvailableMutation = {
  updateMembershipNoTierAvailable:  {
    __typename: "UpdateMembershipResponse",
    spaceMembershipID: string,
    appStoreTransactionReceipt: string,
    result?: UpdateMembershipResult | null,
    membershipExpiresAt?: string | null,
  },
};

export type SyncSpaceMembershipMutationVariables = {
  spaceMembershipID: string,
  onboardingAnswers: Array< OnboardingAnswerInput >,
  onboardingAnswersLastUpdatedAt: string,
  customTargetCalories?: number | null,
  welcomeVideoWatched?: boolean | null,
  initialProgrammeSelectionLastOfferedAt?: string | null,
  chatNickname?: string | null,
};

export type SyncSpaceMembershipMutation = {
  syncSpaceMembership:  {
    __typename: "SpaceMembership",
    spaceID: string,
    memberID: string,
    state: SpaceMembershipState,
    correlatedUserID?: string | null,
    expiresAt?: string | null,
    visibleProgrammeIDsOverride?: Array< string > | null,
    membershipTierID?: string | null,
    stripeCustomerID?: string | null,
    stripeSubscriptionID?: string | null,
    onboardingAnswers?:  Array< {
      __typename: "OnboardingAnswer",
      questionID: string,
      answer: string,
    } > | null,
    onboardingAnswersLastUpdatedAt?: string | null,
    customTargetCalories?: number | null,
    welcomeVideoWatched?: boolean | null,
    initialProgrammeSelectionLastOfferedAt?: string | null,
    chatNickname?: string | null,
    referrer?: SpaceMembershipReferrer | null,
    appStoreSubscriptionID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  },
};

export type RecommendMealsMutationVariables = {
  menuRecommendationRequest: MenuRecommendationRequest,
};

export type RecommendMealsMutation = {
  recommendMeals:  {
    __typename: "RecommendedMenu",
    days:  Array< {
      __typename: "RecommendedMenuDay",
      dayIndex: number,
      entries:  Array< {
        __typename: "RecommendedMenuEntry",
        entryType: string,
        mealType: string,
        meals:  Array< {
          __typename: "RecommendedMeal",
          mealSource: string,
          id: string,
          servings: number,
          categoryTags: Array< string >,
          scaleFactor: number,
          scaledIngredientsFullText?: Array< string > | null,
          scaledNutrition?:  {
            __typename: "Nutrition",
            calories?: number | null,
            protein?: number | null,
            carbohydrate?: number | null,
            fat?: number | null,
            fibre?: number | null,
            sugar?: number | null,
            saturates?: number | null,
            salt?: number | null,
            veg_weight?: number | null,
            cholesterol?: number | null,
            calcium?: number | null,
            iron?: number | null,
            magnesium?: number | null,
            potassium?: number | null,
            vitamin_a?: number | null,
            vitamin_b1?: number | null,
            vitamin_b2?: number | null,
            vitamin_b3?: number | null,
            vitamin_b5?: number | null,
            vitamin_b6?: number | null,
            vitamin_b12?: number | null,
            vitamin_c?: number | null,
            vitamin_d?: number | null,
            vitamin_e?: number | null,
            vitamin_k?: number | null,
            phosphorous?: number | null,
            zinc?: number | null,
            copper?: number | null,
            manganese?: number | null,
            selenium?: number | null,
            folate?: number | null,
            omega3_dha?: number | null,
            omega3_dpa?: number | null,
            omega3_epa?: number | null,
            sucrose?: number | null,
            glucose?: number | null,
            fructose?: number | null,
            lactose?: number | null,
            maltose?: number | null,
          } | null,
          scaledStructuredIngredients?:  Array< {
            __typename: "StructuredIngredient",
            name: string,
            quantity?: number | null,
            unitOfMeasure?: string | null,
            normalisedQuantity?: number | null,
            linkedIngredient?:  {
              __typename: "LinkedIngredientDetails",
              status: boolean,
              linkedIngredientName: string,
            } | null,
            resolvedNutrition?: boolean | null,
            resolvedNutritionError?: string | null,
          } > | null,
          scaledDerivedIngredientNutrition?:  Array< {
            __typename: "DerivedIngredientNutrition",
            ingredientID?: string | null,
            nutrition:  {
              __typename: "Nutrition",
              calories?: number | null,
              protein?: number | null,
              carbohydrate?: number | null,
              fat?: number | null,
              fibre?: number | null,
              sugar?: number | null,
              saturates?: number | null,
              salt?: number | null,
              veg_weight?: number | null,
              cholesterol?: number | null,
              calcium?: number | null,
              iron?: number | null,
              magnesium?: number | null,
              potassium?: number | null,
              vitamin_a?: number | null,
              vitamin_b1?: number | null,
              vitamin_b2?: number | null,
              vitamin_b3?: number | null,
              vitamin_b5?: number | null,
              vitamin_b6?: number | null,
              vitamin_b12?: number | null,
              vitamin_c?: number | null,
              vitamin_d?: number | null,
              vitamin_e?: number | null,
              vitamin_k?: number | null,
              phosphorous?: number | null,
              zinc?: number | null,
              copper?: number | null,
              manganese?: number | null,
              selenium?: number | null,
              folate?: number | null,
              omega3_dha?: number | null,
              omega3_dpa?: number | null,
              omega3_epa?: number | null,
              sucrose?: number | null,
              glucose?: number | null,
              fructose?: number | null,
              lactose?: number | null,
              maltose?: number | null,
            },
            resolvedNutrition?: boolean | null,
            error?: string | null,
            resolvedIngredientWeight?: number | null,
          } > | null,
        } >,
      } >,
    } >,
    errorMessage?: string | null,
  },
};

export type ExpandIngredientsForExclusionMutationVariables = {
  rootIngredientNames: Array< string >,
  locales?: Array< string > | null,
};

export type ExpandIngredientsForExclusionMutation = {
  expandIngredientsForExclusion: Array< string >,
};

export type GetNewStripeConfigMutationVariables = {
  authorizationCode: string,
};

export type GetNewStripeConfigMutation = {
  getNewStripeConfig:  {
    __typename: "SpaceStripeConfig",
    environment: StripeEnvironment,
    connectedAccountID: string,
    chargesEnabled: boolean,
    stripeProductID?: string | null,
  },
};

export type CreateStripeCheckoutSessionMutationVariables = {
  membershipTierID: string,
  spaceMembershipID: string,
  returnUrl: string,
  duration?: SubscriptionDuration | null,
};

export type CreateStripeCheckoutSessionMutation = {
  createStripeCheckoutSession:  {
    __typename: "StripeCheckoutSession",
    url: string,
  },
};

export type CheckStripeCheckoutSessionMutationVariables = {
  spaceMembershipID: string,
};

export type CheckStripeCheckoutSessionMutation = {
  checkStripeCheckoutSession: StripeCheckoutSessionState,
};

export type CancelSubscriptionMutationVariables = {
  spaceMembershipID: string,
};

export type CancelSubscriptionMutation = {
  cancelSubscription: string,
};

export type ActivateMembershipTierMutationVariables = {
  membershipTierID: string,
};

export type ActivateMembershipTierMutation = {
  activateMembershipTier?:  {
    __typename: "MembershipTier",
    spaceID: string,
    title: string,
    shortDescription?: string | null,
    monthlyPrice: string,
    quarterlyPrice?: string | null,
    annualPrice?: string | null,
    freeTrialDurationDays?: number | null,
    rank?: number | null,
    currencyCode?: string | null,
    state: MembershipTierState,
    stripeProductID?: string | null,
    stripePriceID?: string | null,
    stripePriceIDQuarterly?: string | null,
    stripePriceIDAnnual?: string | null,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListYoutubePlaylistItemsMutationVariables = {
  playlistID: string,
};

export type ListYoutubePlaylistItemsMutation = {
  listYoutubePlaylistItems?:  Array< {
    __typename: "VideoPlaylistItem",
    videoID: string,
    title: string,
    description?: string | null,
    thumbnailImageUrl?: string | null,
  } > | null,
};

export type EnrollUserIntoChatMutationVariables = {
  spaceMembershipID: string,
  userName: string,
};

export type EnrollUserIntoChatMutation = {
  enrollUserIntoChat:  {
    __typename: "ChatEnrollResult",
    status: string,
    authToken?: string | null,
  },
};

export type LogInUserToChatMutationVariables = {
  spaceMembershipID: string,
};

export type LogInUserToChatMutation = {
  logInUserToChat:  {
    __typename: "ChatLoginResult",
    authToken: string,
  },
};

export type AdminListChatGroupsMutation = {
  adminListChatGroups:  Array< {
    __typename: "ChatGroup",
    guid: string,
    name: string,
  } >,
};

export type ListSmorgStudioMembershipsMutation = {
  listSmorgStudioMemberships:  Array< {
    __typename: "SmorgStudioMembership",
    id: string,
    createdAt: string,
    spaceID: string,
    memberID: string,
    email: string,
    givenName?: string | null,
    familyName?: string | null,
    state: SpaceMembershipState,
    correlatedUserID?: string | null,
    expiresAt?: string | null,
    membershipTierID?: string | null,
    stripeCustomerID?: string | null,
    stripeSubscriptionID?: string | null,
    onboardingAnswers?:  Array< {
      __typename: "OnboardingAnswer",
      questionID: string,
      answer: string,
    } > | null,
    onboardingAnswersLastUpdatedAt?: string | null,
    customTargetCalories?: number | null,
    welcomeVideoWatched?: boolean | null,
    initialProgrammeSelectionLastOfferedAt?: string | null,
    chatNickname?: string | null,
    referrer?: SpaceMembershipReferrer | null,
    appStoreSubscriptionID?: string | null,
  } >,
};

export type SmorgStudioMembershipAdminSetExpiresAtMutationVariables = {
  spaceMembershipID: string,
  expiresAt: string,
};

export type SmorgStudioMembershipAdminSetExpiresAtMutation = {
  smorgStudioMembershipAdminSetExpiresAt:  {
    __typename: "SmorgStudioMembership",
    id: string,
    createdAt: string,
    spaceID: string,
    memberID: string,
    email: string,
    givenName?: string | null,
    familyName?: string | null,
    state: SpaceMembershipState,
    correlatedUserID?: string | null,
    expiresAt?: string | null,
    membershipTierID?: string | null,
    stripeCustomerID?: string | null,
    stripeSubscriptionID?: string | null,
    onboardingAnswers?:  Array< {
      __typename: "OnboardingAnswer",
      questionID: string,
      answer: string,
    } > | null,
    onboardingAnswersLastUpdatedAt?: string | null,
    customTargetCalories?: number | null,
    welcomeVideoWatched?: boolean | null,
    initialProgrammeSelectionLastOfferedAt?: string | null,
    chatNickname?: string | null,
    referrer?: SpaceMembershipReferrer | null,
    appStoreSubscriptionID?: string | null,
  },
};

export type SmorgStudioMembershipAdminSetPasswordMutationVariables = {
  spaceMembershipID: string,
  cleartextPassword: string,
};

export type SmorgStudioMembershipAdminSetPasswordMutation = {
  smorgStudioMembershipAdminSetPassword:  {
    __typename: "PasswordChangeResult",
    success: boolean,
    message?: string | null,
  },
};

export type CreateZapierRestHookMutationVariables = {
  input: CreateZapierRestHookInput,
  condition?: ModelZapierRestHookConditionInput | null,
};

export type CreateZapierRestHookMutation = {
  createZapierRestHook?:  {
    __typename: "ZapierRestHook",
    spaceID: string,
    hookUrl: string,
    hookKey: string,
    enabled: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateZapierRestHookMutationVariables = {
  input: UpdateZapierRestHookInput,
  condition?: ModelZapierRestHookConditionInput | null,
};

export type UpdateZapierRestHookMutation = {
  updateZapierRestHook?:  {
    __typename: "ZapierRestHook",
    spaceID: string,
    hookUrl: string,
    hookKey: string,
    enabled: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteZapierRestHookMutationVariables = {
  input: DeleteZapierRestHookInput,
  condition?: ModelZapierRestHookConditionInput | null,
};

export type DeleteZapierRestHookMutation = {
  deleteZapierRestHook?:  {
    __typename: "ZapierRestHook",
    spaceID: string,
    hookUrl: string,
    hookKey: string,
    enabled: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserProfileMutationVariables = {
  input: CreateUserProfileInput,
  condition?: ModelUserProfileConditionInput | null,
};

export type CreateUserProfileMutation = {
  createUserProfile?:  {
    __typename: "UserProfile",
    id: string,
    showOnboarding?: boolean | null,
    consentToProductComms?: boolean | null,
    plannerViewWeekStartDay?: number | null,
    geoRegion?: string | null,
    countryCode?: string | null,
    mealPlanReminderSchedule?: string | null,
    showMealDetailGuide?: boolean | null,
    recentlyUsedTags?: Array< string | null > | null,
    activitySummary?:  {
      __typename: "UserActivitySummary",
      numMealsViewed?: number | null,
      numMealsViewedMobile?: number | null,
      numShoppingListsViewed?: number | null,
      numShoppingListsViewedMobile?: number | null,
      numMealsEdited?: number | null,
      numNutritionClicks?: number | null,
      numMealsPlanned?: number | null,
      numMealsAdded?: number | null,
      numMealsImported?: number | null,
      numMealsAddedManually?: number | null,
      numPlansViewed?: number | null,
      numShoppingListsCopiedToClipboard?: number | null,
      numShoppingListsEmailed?: number | null,
      numShoppingListsCreated?: number | null,
      numShops?: number | null,
      numNotesAdded?: number | null,
      numNotesViewed?: number | null,
      numNotesViewedMobile?: number | null,
      numRecipesBoardsCreated?: number | null,
      numRecipesBoardsShared?: number | null,
    } | null,
    enabledFeatures?: Array< string | null > | null,
    requestedFeatures?: Array< string | null > | null,
    seenMessages?: Array< string | null > | null,
    recentlyUsedRecommenderMealTypes?: Array< string > | null,
    recentlyUsedRecommenderCalorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    recentlyUsedSearchCriteria?:  {
      __typename: "PersistedSearchCriteria",
      searchString?: string | null,
      mealTypes?: Array< string > | null,
      cookTimeMin?: number | null,
      cookTimeMax?: number | null,
      excludeIngredients?: Array< string > | null,
      includeIngredients?: Array< string > | null,
      dietaryPreferences?: Array< string > | null,
      perMealNutritionConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      tags?: Array< string > | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateUserProfileMutationVariables = {
  input: UpdateUserProfileInput,
  condition?: ModelUserProfileConditionInput | null,
};

export type UpdateUserProfileMutation = {
  updateUserProfile?:  {
    __typename: "UserProfile",
    id: string,
    showOnboarding?: boolean | null,
    consentToProductComms?: boolean | null,
    plannerViewWeekStartDay?: number | null,
    geoRegion?: string | null,
    countryCode?: string | null,
    mealPlanReminderSchedule?: string | null,
    showMealDetailGuide?: boolean | null,
    recentlyUsedTags?: Array< string | null > | null,
    activitySummary?:  {
      __typename: "UserActivitySummary",
      numMealsViewed?: number | null,
      numMealsViewedMobile?: number | null,
      numShoppingListsViewed?: number | null,
      numShoppingListsViewedMobile?: number | null,
      numMealsEdited?: number | null,
      numNutritionClicks?: number | null,
      numMealsPlanned?: number | null,
      numMealsAdded?: number | null,
      numMealsImported?: number | null,
      numMealsAddedManually?: number | null,
      numPlansViewed?: number | null,
      numShoppingListsCopiedToClipboard?: number | null,
      numShoppingListsEmailed?: number | null,
      numShoppingListsCreated?: number | null,
      numShops?: number | null,
      numNotesAdded?: number | null,
      numNotesViewed?: number | null,
      numNotesViewedMobile?: number | null,
      numRecipesBoardsCreated?: number | null,
      numRecipesBoardsShared?: number | null,
    } | null,
    enabledFeatures?: Array< string | null > | null,
    requestedFeatures?: Array< string | null > | null,
    seenMessages?: Array< string | null > | null,
    recentlyUsedRecommenderMealTypes?: Array< string > | null,
    recentlyUsedRecommenderCalorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    recentlyUsedSearchCriteria?:  {
      __typename: "PersistedSearchCriteria",
      searchString?: string | null,
      mealTypes?: Array< string > | null,
      cookTimeMin?: number | null,
      cookTimeMax?: number | null,
      excludeIngredients?: Array< string > | null,
      includeIngredients?: Array< string > | null,
      dietaryPreferences?: Array< string > | null,
      perMealNutritionConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      tags?: Array< string > | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteUserProfileMutationVariables = {
  input: DeleteUserProfileInput,
  condition?: ModelUserProfileConditionInput | null,
};

export type DeleteUserProfileMutation = {
  deleteUserProfile?:  {
    __typename: "UserProfile",
    id: string,
    showOnboarding?: boolean | null,
    consentToProductComms?: boolean | null,
    plannerViewWeekStartDay?: number | null,
    geoRegion?: string | null,
    countryCode?: string | null,
    mealPlanReminderSchedule?: string | null,
    showMealDetailGuide?: boolean | null,
    recentlyUsedTags?: Array< string | null > | null,
    activitySummary?:  {
      __typename: "UserActivitySummary",
      numMealsViewed?: number | null,
      numMealsViewedMobile?: number | null,
      numShoppingListsViewed?: number | null,
      numShoppingListsViewedMobile?: number | null,
      numMealsEdited?: number | null,
      numNutritionClicks?: number | null,
      numMealsPlanned?: number | null,
      numMealsAdded?: number | null,
      numMealsImported?: number | null,
      numMealsAddedManually?: number | null,
      numPlansViewed?: number | null,
      numShoppingListsCopiedToClipboard?: number | null,
      numShoppingListsEmailed?: number | null,
      numShoppingListsCreated?: number | null,
      numShops?: number | null,
      numNotesAdded?: number | null,
      numNotesViewed?: number | null,
      numNotesViewedMobile?: number | null,
      numRecipesBoardsCreated?: number | null,
      numRecipesBoardsShared?: number | null,
    } | null,
    enabledFeatures?: Array< string | null > | null,
    requestedFeatures?: Array< string | null > | null,
    seenMessages?: Array< string | null > | null,
    recentlyUsedRecommenderMealTypes?: Array< string > | null,
    recentlyUsedRecommenderCalorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    recentlyUsedSearchCriteria?:  {
      __typename: "PersistedSearchCriteria",
      searchString?: string | null,
      mealTypes?: Array< string > | null,
      cookTimeMin?: number | null,
      cookTimeMax?: number | null,
      excludeIngredients?: Array< string > | null,
      includeIngredients?: Array< string > | null,
      dietaryPreferences?: Array< string > | null,
      perMealNutritionConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      tags?: Array< string > | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateObjectEditEventMutationVariables = {
  input: CreateObjectEditEventInput,
  condition?: ModelObjectEditEventConditionInput | null,
};

export type CreateObjectEditEventMutation = {
  createObjectEditEvent?:  {
    __typename: "ObjectEditEvent",
    id: string,
    objectID: string,
    objectType: EntryType,
    section: string,
    itemID?: string | null,
    oldValue?: string | null,
    createdAt: string,
    groups?: Array< string | null > | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateObjectEditEventMutationVariables = {
  input: UpdateObjectEditEventInput,
  condition?: ModelObjectEditEventConditionInput | null,
};

export type UpdateObjectEditEventMutation = {
  updateObjectEditEvent?:  {
    __typename: "ObjectEditEvent",
    id: string,
    objectID: string,
    objectType: EntryType,
    section: string,
    itemID?: string | null,
    oldValue?: string | null,
    createdAt: string,
    groups?: Array< string | null > | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteObjectEditEventMutationVariables = {
  input: DeleteObjectEditEventInput,
  condition?: ModelObjectEditEventConditionInput | null,
};

export type DeleteObjectEditEventMutation = {
  deleteObjectEditEvent?:  {
    __typename: "ObjectEditEvent",
    id: string,
    objectID: string,
    objectType: EntryType,
    section: string,
    itemID?: string | null,
    oldValue?: string | null,
    createdAt: string,
    groups?: Array< string | null > | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateMealMutationVariables = {
  input: CreateMealInput,
  condition?: ModelMealConditionInput | null,
};

export type CreateMealMutation = {
  createMeal?:  {
    __typename: "Meal",
    id: string,
    schemaVersion: number,
    smorgBoardID: string,
    recipes:  Array< {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    } >,
    addons?:  Array< {
      __typename: "MealAddOn",
      name: string,
    } > | null,
    additionallyReferredToBy?:  Array< {
      __typename: "ReferenceType",
      sourceType: ReferenceSourceType,
      sourceID: string,
    } > | null,
    origin?:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    } | null,
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    spaceMembershipID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateMealMutationVariables = {
  input: UpdateMealInput,
  condition?: ModelMealConditionInput | null,
};

export type UpdateMealMutation = {
  updateMeal?:  {
    __typename: "Meal",
    id: string,
    schemaVersion: number,
    smorgBoardID: string,
    recipes:  Array< {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    } >,
    addons?:  Array< {
      __typename: "MealAddOn",
      name: string,
    } > | null,
    additionallyReferredToBy?:  Array< {
      __typename: "ReferenceType",
      sourceType: ReferenceSourceType,
      sourceID: string,
    } > | null,
    origin?:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    } | null,
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    spaceMembershipID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteMealMutationVariables = {
  input: DeleteMealInput,
  condition?: ModelMealConditionInput | null,
};

export type DeleteMealMutation = {
  deleteMeal?:  {
    __typename: "Meal",
    id: string,
    schemaVersion: number,
    smorgBoardID: string,
    recipes:  Array< {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    } >,
    addons?:  Array< {
      __typename: "MealAddOn",
      name: string,
    } > | null,
    additionallyReferredToBy?:  Array< {
      __typename: "ReferenceType",
      sourceType: ReferenceSourceType,
      sourceID: string,
    } > | null,
    origin?:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    } | null,
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    spaceMembershipID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateSmorgBoardMutationVariables = {
  input: CreateSmorgBoardInput,
  condition?: ModelSmorgBoardConditionInput | null,
};

export type CreateSmorgBoardMutation = {
  createSmorgBoard?:  {
    __typename: "SmorgBoard",
    id: string,
    schemaVersion: number,
    title: string,
    shortDescription?: string | null,
    coverImageUrl?: string | null,
    menus:  Array< {
      __typename: "Menu",
      id: string,
      title: string,
      mealIDs: Array< string >,
    } >,
    shareRecords?:  Array< {
      __typename: "RecipesBoardShareRecord",
      sharedBoardID: string,
      version: string,
      updatedOn: string,
    } | null > | null,
    spaceMembershipID?: string | null,
    embeddedInContainerType?: ContainerType | null,
    embeddedInContainerID?: string | null,
    groups?: Array< string | null > | null,
    availableInMembershipTierIDs?: Array< string > | null,
    isMyFavouritesBoard?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateSmorgBoardMutationVariables = {
  input: UpdateSmorgBoardInput,
  condition?: ModelSmorgBoardConditionInput | null,
};

export type UpdateSmorgBoardMutation = {
  updateSmorgBoard?:  {
    __typename: "SmorgBoard",
    id: string,
    schemaVersion: number,
    title: string,
    shortDescription?: string | null,
    coverImageUrl?: string | null,
    menus:  Array< {
      __typename: "Menu",
      id: string,
      title: string,
      mealIDs: Array< string >,
    } >,
    shareRecords?:  Array< {
      __typename: "RecipesBoardShareRecord",
      sharedBoardID: string,
      version: string,
      updatedOn: string,
    } | null > | null,
    spaceMembershipID?: string | null,
    embeddedInContainerType?: ContainerType | null,
    embeddedInContainerID?: string | null,
    groups?: Array< string | null > | null,
    availableInMembershipTierIDs?: Array< string > | null,
    isMyFavouritesBoard?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteSmorgBoardMutationVariables = {
  input: DeleteSmorgBoardInput,
  condition?: ModelSmorgBoardConditionInput | null,
};

export type DeleteSmorgBoardMutation = {
  deleteSmorgBoard?:  {
    __typename: "SmorgBoard",
    id: string,
    schemaVersion: number,
    title: string,
    shortDescription?: string | null,
    coverImageUrl?: string | null,
    menus:  Array< {
      __typename: "Menu",
      id: string,
      title: string,
      mealIDs: Array< string >,
    } >,
    shareRecords?:  Array< {
      __typename: "RecipesBoardShareRecord",
      sharedBoardID: string,
      version: string,
      updatedOn: string,
    } | null > | null,
    spaceMembershipID?: string | null,
    embeddedInContainerType?: ContainerType | null,
    embeddedInContainerID?: string | null,
    groups?: Array< string | null > | null,
    availableInMembershipTierIDs?: Array< string > | null,
    isMyFavouritesBoard?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateSharedMealMutationVariables = {
  input: CreateSharedMealInput,
  condition?: ModelSharedMealConditionInput | null,
};

export type CreateSharedMealMutation = {
  createSharedMeal?:  {
    __typename: "SharedMeal",
    id: string,
    schemaVersion: number,
    sharedBoardID: string,
    recipes:  Array< {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    } >,
    origin?:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    } | null,
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSharedMealMutationVariables = {
  input: UpdateSharedMealInput,
  condition?: ModelSharedMealConditionInput | null,
};

export type UpdateSharedMealMutation = {
  updateSharedMeal?:  {
    __typename: "SharedMeal",
    id: string,
    schemaVersion: number,
    sharedBoardID: string,
    recipes:  Array< {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    } >,
    origin?:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    } | null,
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSharedMealMutationVariables = {
  input: DeleteSharedMealInput,
  condition?: ModelSharedMealConditionInput | null,
};

export type DeleteSharedMealMutation = {
  deleteSharedMeal?:  {
    __typename: "SharedMeal",
    id: string,
    schemaVersion: number,
    sharedBoardID: string,
    recipes:  Array< {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    } >,
    origin?:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    } | null,
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSharedBoardMutationVariables = {
  input: CreateSharedBoardInput,
  condition?: ModelSharedBoardConditionInput | null,
};

export type CreateSharedBoardMutation = {
  createSharedBoard?:  {
    __typename: "SharedBoard",
    id: string,
    schemaVersion: number,
    version?: string | null,
    accessLevel?: SharedBoardAccessLevel | null,
    title?: string | null,
    shortDescription?: string | null,
    coverImageUrl?: string | null,
    menus:  Array< {
      __typename: "SharedMenu",
      id?: string | null,
      title: string,
      sharedMealIDs: Array< string >,
    } >,
    availableInMembershipTierIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSharedBoardMutationVariables = {
  input: UpdateSharedBoardInput,
  condition?: ModelSharedBoardConditionInput | null,
};

export type UpdateSharedBoardMutation = {
  updateSharedBoard?:  {
    __typename: "SharedBoard",
    id: string,
    schemaVersion: number,
    version?: string | null,
    accessLevel?: SharedBoardAccessLevel | null,
    title?: string | null,
    shortDescription?: string | null,
    coverImageUrl?: string | null,
    menus:  Array< {
      __typename: "SharedMenu",
      id?: string | null,
      title: string,
      sharedMealIDs: Array< string >,
    } >,
    availableInMembershipTierIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSharedBoardMutationVariables = {
  input: DeleteSharedBoardInput,
  condition?: ModelSharedBoardConditionInput | null,
};

export type DeleteSharedBoardMutation = {
  deleteSharedBoard?:  {
    __typename: "SharedBoard",
    id: string,
    schemaVersion: number,
    version?: string | null,
    accessLevel?: SharedBoardAccessLevel | null,
    title?: string | null,
    shortDescription?: string | null,
    coverImageUrl?: string | null,
    menus:  Array< {
      __typename: "SharedMenu",
      id?: string | null,
      title: string,
      sharedMealIDs: Array< string >,
    } >,
    availableInMembershipTierIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNoteMutationVariables = {
  input: CreateNoteInput,
  condition?: ModelNoteConditionInput | null,
};

export type CreateNoteMutation = {
  createNote?:  {
    __typename: "Note",
    id: string,
    parentID: string,
    title: string,
    description?: string | null,
    spaceMembershipID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateNoteMutationVariables = {
  input: UpdateNoteInput,
  condition?: ModelNoteConditionInput | null,
};

export type UpdateNoteMutation = {
  updateNote?:  {
    __typename: "Note",
    id: string,
    parentID: string,
    title: string,
    description?: string | null,
    spaceMembershipID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteNoteMutationVariables = {
  input: DeleteNoteInput,
  condition?: ModelNoteConditionInput | null,
};

export type DeleteNoteMutation = {
  deleteNote?:  {
    __typename: "Note",
    id: string,
    parentID: string,
    title: string,
    description?: string | null,
    spaceMembershipID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateContentEntryMutationVariables = {
  input: CreateContentEntryInput,
  condition?: ModelContentEntryConditionInput | null,
};

export type CreateContentEntryMutation = {
  createContentEntry?:  {
    __typename: "ContentEntry",
    id: string,
    parentID: string,
    title: string,
    body?: string | null,
    coverImageUrl?: string | null,
    tags: Array< string >,
    appLink?:  {
      __typename: "AppLink",
      appLinkType: AppLinkType,
      appLinkScreen?: AppLinkScreen | null,
      url?: string | null,
      objectID?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateContentEntryMutationVariables = {
  input: UpdateContentEntryInput,
  condition?: ModelContentEntryConditionInput | null,
};

export type UpdateContentEntryMutation = {
  updateContentEntry?:  {
    __typename: "ContentEntry",
    id: string,
    parentID: string,
    title: string,
    body?: string | null,
    coverImageUrl?: string | null,
    tags: Array< string >,
    appLink?:  {
      __typename: "AppLink",
      appLinkType: AppLinkType,
      appLinkScreen?: AppLinkScreen | null,
      url?: string | null,
      objectID?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteContentEntryMutationVariables = {
  input: DeleteContentEntryInput,
  condition?: ModelContentEntryConditionInput | null,
};

export type DeleteContentEntryMutation = {
  deleteContentEntry?:  {
    __typename: "ContentEntry",
    id: string,
    parentID: string,
    title: string,
    body?: string | null,
    coverImageUrl?: string | null,
    tags: Array< string >,
    appLink?:  {
      __typename: "AppLink",
      appLinkType: AppLinkType,
      appLinkScreen?: AppLinkScreen | null,
      url?: string | null,
      objectID?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateSharedContentEntryMutationVariables = {
  input: CreateSharedContentEntryInput,
  condition?: ModelSharedContentEntryConditionInput | null,
};

export type CreateSharedContentEntryMutation = {
  createSharedContentEntry?:  {
    __typename: "SharedContentEntry",
    id: string,
    parentID: string,
    title: string,
    body?: string | null,
    coverImageUrl?: string | null,
    tags: Array< string >,
    origin:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    },
    appLink?:  {
      __typename: "AppLink",
      appLinkType: AppLinkType,
      appLinkScreen?: AppLinkScreen | null,
      url?: string | null,
      objectID?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSharedContentEntryMutationVariables = {
  input: UpdateSharedContentEntryInput,
  condition?: ModelSharedContentEntryConditionInput | null,
};

export type UpdateSharedContentEntryMutation = {
  updateSharedContentEntry?:  {
    __typename: "SharedContentEntry",
    id: string,
    parentID: string,
    title: string,
    body?: string | null,
    coverImageUrl?: string | null,
    tags: Array< string >,
    origin:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    },
    appLink?:  {
      __typename: "AppLink",
      appLinkType: AppLinkType,
      appLinkScreen?: AppLinkScreen | null,
      url?: string | null,
      objectID?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSharedContentEntryMutationVariables = {
  input: DeleteSharedContentEntryInput,
  condition?: ModelSharedContentEntryConditionInput | null,
};

export type DeleteSharedContentEntryMutation = {
  deleteSharedContentEntry?:  {
    __typename: "SharedContentEntry",
    id: string,
    parentID: string,
    title: string,
    body?: string | null,
    coverImageUrl?: string | null,
    tags: Array< string >,
    origin:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    },
    appLink?:  {
      __typename: "AppLink",
      appLinkType: AppLinkType,
      appLinkScreen?: AppLinkScreen | null,
      url?: string | null,
      objectID?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePlannerWeekMutationVariables = {
  input: CreatePlannerWeekInput,
  condition?: ModelPlannerWeekConditionInput | null,
};

export type CreatePlannerWeekMutation = {
  createPlannerWeek?:  {
    __typename: "PlannerWeek",
    id: string,
    schemaVersion: number,
    spaceMembershipID?: string | null,
    startDate: string,
    days:  Array< {
      __typename: "PlannerDay",
      entries:  Array< {
        __typename: "PlannerEntry",
        plannerEntryType?: PlannerEntryType | null,
        mealID?: string | null,
        objectID?: string | null,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdatePlannerWeekMutationVariables = {
  input: UpdatePlannerWeekInput,
  condition?: ModelPlannerWeekConditionInput | null,
};

export type UpdatePlannerWeekMutation = {
  updatePlannerWeek?:  {
    __typename: "PlannerWeek",
    id: string,
    schemaVersion: number,
    spaceMembershipID?: string | null,
    startDate: string,
    days:  Array< {
      __typename: "PlannerDay",
      entries:  Array< {
        __typename: "PlannerEntry",
        plannerEntryType?: PlannerEntryType | null,
        mealID?: string | null,
        objectID?: string | null,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeletePlannerWeekMutationVariables = {
  input: DeletePlannerWeekInput,
  condition?: ModelPlannerWeekConditionInput | null,
};

export type DeletePlannerWeekMutation = {
  deletePlannerWeek?:  {
    __typename: "PlannerWeek",
    id: string,
    schemaVersion: number,
    spaceMembershipID?: string | null,
    startDate: string,
    days:  Array< {
      __typename: "PlannerDay",
      entries:  Array< {
        __typename: "PlannerEntry",
        plannerEntryType?: PlannerEntryType | null,
        mealID?: string | null,
        objectID?: string | null,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateShoppingListMutationVariables = {
  input: CreateShoppingListInput,
  condition?: ModelShoppingListConditionInput | null,
};

export type CreateShoppingListMutation = {
  createShoppingList?:  {
    __typename: "ShoppingList",
    id: string,
    schemaVersion: number,
    spaceMembershipID?: string | null,
    productsBoardID: string,
    title: string,
    shortDescription?: string | null,
    items:  Array< {
      __typename: "ShoppingListItem",
      id: string,
      name: string,
      total?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      quantities?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      linkedIngredient?:  {
        __typename: "LinkedIngredientDetails",
        status: boolean,
        linkedIngredientName: string,
      } | null,
      aisleLocation?: string | null,
      forMealIds?: Array< string > | null,
      checked?: boolean | null,
    } >,
    mealIDs?: Array< string > | null,
    mealImageUrls?: Array< string > | null,
    meals?:  Array< {
      __typename: "ShoppingListMeal",
      mealID?: string | null,
      recipes:  Array< {
        __typename: "Recipe",
        title: string,
        shortDescription?: string | null,
        imageUrl?: string | null,
        recipeUrl?: string | null,
        publisher?: string | null,
        chef?: string | null,
        mealiqId?: string | null,
        nutrition?:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        } | null,
        mealTypes?: Array< string > | null,
        tags?: Array< string > | null,
        supportedDiets?: Array< string > | null,
        ingredients:  Array< {
          __typename: "RecipeIngredient",
          id?: string | null,
          fullText: string,
          structuredIngredient?:  {
            __typename: "StructuredIngredient",
            name: string,
            quantity?: number | null,
            unitOfMeasure?: string | null,
            normalisedQuantity?: number | null,
            linkedIngredient?:  {
              __typename: "LinkedIngredientDetails",
              status: boolean,
              linkedIngredientName: string,
            } | null,
            resolvedNutrition?: boolean | null,
            resolvedNutritionError?: string | null,
          } | null,
          tokFullText?: string | null,
          tokens?:  Array< {
            __typename: "IngredientToken",
            fromChar: number,
            toChar: number,
            type?: string | null,
            label?: string | null,
          } | null > | null,
          scalingRules?: string | null,
          checkedInIngredientsList?: boolean | null,
        } >,
        preparations:  Array< {
          __typename: "RecipePreparation",
          id?: string | null,
          fullText: string,
        } >,
        utensils?:  Array< {
          __typename: "RecipeUtensil",
          id?: string | null,
          fullText: string,
        } > | null,
        servings?: number | null,
        cookTime?: number | null,
        prepTime?: number | null,
        totalTime?: number | null,
        recipeNotes?: string | null,
      } >,
    } > | null,
    activityRecords?:  Array< {
      __typename: "ShoppingListActivity",
      activityType: ShoppingActivityType,
      occurredOn: string,
      vendorName?: string | null,
      transactionID?: string | null,
      shoppingListItemIDs?: Array< string > | null,
    } > | null,
    userItems?:  Array< {
      __typename: "ShoppingListItem",
      id: string,
      name: string,
      total?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      quantities?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      linkedIngredient?:  {
        __typename: "LinkedIngredientDetails",
        status: boolean,
        linkedIngredientName: string,
      } | null,
      aisleLocation?: string | null,
      forMealIds?: Array< string > | null,
      checked?: boolean | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateShoppingListMutationVariables = {
  input: UpdateShoppingListInput,
  condition?: ModelShoppingListConditionInput | null,
};

export type UpdateShoppingListMutation = {
  updateShoppingList?:  {
    __typename: "ShoppingList",
    id: string,
    schemaVersion: number,
    spaceMembershipID?: string | null,
    productsBoardID: string,
    title: string,
    shortDescription?: string | null,
    items:  Array< {
      __typename: "ShoppingListItem",
      id: string,
      name: string,
      total?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      quantities?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      linkedIngredient?:  {
        __typename: "LinkedIngredientDetails",
        status: boolean,
        linkedIngredientName: string,
      } | null,
      aisleLocation?: string | null,
      forMealIds?: Array< string > | null,
      checked?: boolean | null,
    } >,
    mealIDs?: Array< string > | null,
    mealImageUrls?: Array< string > | null,
    meals?:  Array< {
      __typename: "ShoppingListMeal",
      mealID?: string | null,
      recipes:  Array< {
        __typename: "Recipe",
        title: string,
        shortDescription?: string | null,
        imageUrl?: string | null,
        recipeUrl?: string | null,
        publisher?: string | null,
        chef?: string | null,
        mealiqId?: string | null,
        nutrition?:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        } | null,
        mealTypes?: Array< string > | null,
        tags?: Array< string > | null,
        supportedDiets?: Array< string > | null,
        ingredients:  Array< {
          __typename: "RecipeIngredient",
          id?: string | null,
          fullText: string,
          structuredIngredient?:  {
            __typename: "StructuredIngredient",
            name: string,
            quantity?: number | null,
            unitOfMeasure?: string | null,
            normalisedQuantity?: number | null,
            linkedIngredient?:  {
              __typename: "LinkedIngredientDetails",
              status: boolean,
              linkedIngredientName: string,
            } | null,
            resolvedNutrition?: boolean | null,
            resolvedNutritionError?: string | null,
          } | null,
          tokFullText?: string | null,
          tokens?:  Array< {
            __typename: "IngredientToken",
            fromChar: number,
            toChar: number,
            type?: string | null,
            label?: string | null,
          } | null > | null,
          scalingRules?: string | null,
          checkedInIngredientsList?: boolean | null,
        } >,
        preparations:  Array< {
          __typename: "RecipePreparation",
          id?: string | null,
          fullText: string,
        } >,
        utensils?:  Array< {
          __typename: "RecipeUtensil",
          id?: string | null,
          fullText: string,
        } > | null,
        servings?: number | null,
        cookTime?: number | null,
        prepTime?: number | null,
        totalTime?: number | null,
        recipeNotes?: string | null,
      } >,
    } > | null,
    activityRecords?:  Array< {
      __typename: "ShoppingListActivity",
      activityType: ShoppingActivityType,
      occurredOn: string,
      vendorName?: string | null,
      transactionID?: string | null,
      shoppingListItemIDs?: Array< string > | null,
    } > | null,
    userItems?:  Array< {
      __typename: "ShoppingListItem",
      id: string,
      name: string,
      total?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      quantities?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      linkedIngredient?:  {
        __typename: "LinkedIngredientDetails",
        status: boolean,
        linkedIngredientName: string,
      } | null,
      aisleLocation?: string | null,
      forMealIds?: Array< string > | null,
      checked?: boolean | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteShoppingListMutationVariables = {
  input: DeleteShoppingListInput,
  condition?: ModelShoppingListConditionInput | null,
};

export type DeleteShoppingListMutation = {
  deleteShoppingList?:  {
    __typename: "ShoppingList",
    id: string,
    schemaVersion: number,
    spaceMembershipID?: string | null,
    productsBoardID: string,
    title: string,
    shortDescription?: string | null,
    items:  Array< {
      __typename: "ShoppingListItem",
      id: string,
      name: string,
      total?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      quantities?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      linkedIngredient?:  {
        __typename: "LinkedIngredientDetails",
        status: boolean,
        linkedIngredientName: string,
      } | null,
      aisleLocation?: string | null,
      forMealIds?: Array< string > | null,
      checked?: boolean | null,
    } >,
    mealIDs?: Array< string > | null,
    mealImageUrls?: Array< string > | null,
    meals?:  Array< {
      __typename: "ShoppingListMeal",
      mealID?: string | null,
      recipes:  Array< {
        __typename: "Recipe",
        title: string,
        shortDescription?: string | null,
        imageUrl?: string | null,
        recipeUrl?: string | null,
        publisher?: string | null,
        chef?: string | null,
        mealiqId?: string | null,
        nutrition?:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        } | null,
        mealTypes?: Array< string > | null,
        tags?: Array< string > | null,
        supportedDiets?: Array< string > | null,
        ingredients:  Array< {
          __typename: "RecipeIngredient",
          id?: string | null,
          fullText: string,
          structuredIngredient?:  {
            __typename: "StructuredIngredient",
            name: string,
            quantity?: number | null,
            unitOfMeasure?: string | null,
            normalisedQuantity?: number | null,
            linkedIngredient?:  {
              __typename: "LinkedIngredientDetails",
              status: boolean,
              linkedIngredientName: string,
            } | null,
            resolvedNutrition?: boolean | null,
            resolvedNutritionError?: string | null,
          } | null,
          tokFullText?: string | null,
          tokens?:  Array< {
            __typename: "IngredientToken",
            fromChar: number,
            toChar: number,
            type?: string | null,
            label?: string | null,
          } | null > | null,
          scalingRules?: string | null,
          checkedInIngredientsList?: boolean | null,
        } >,
        preparations:  Array< {
          __typename: "RecipePreparation",
          id?: string | null,
          fullText: string,
        } >,
        utensils?:  Array< {
          __typename: "RecipeUtensil",
          id?: string | null,
          fullText: string,
        } > | null,
        servings?: number | null,
        cookTime?: number | null,
        prepTime?: number | null,
        totalTime?: number | null,
        recipeNotes?: string | null,
      } >,
    } > | null,
    activityRecords?:  Array< {
      __typename: "ShoppingListActivity",
      activityType: ShoppingActivityType,
      occurredOn: string,
      vendorName?: string | null,
      transactionID?: string | null,
      shoppingListItemIDs?: Array< string > | null,
    } > | null,
    userItems?:  Array< {
      __typename: "ShoppingListItem",
      id: string,
      name: string,
      total?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      quantities?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      linkedIngredient?:  {
        __typename: "LinkedIngredientDetails",
        status: boolean,
        linkedIngredientName: string,
      } | null,
      aisleLocation?: string | null,
      forMealIds?: Array< string > | null,
      checked?: boolean | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateProductsBoardMutationVariables = {
  input: CreateProductsBoardInput,
  condition?: ModelProductsBoardConditionInput | null,
};

export type CreateProductsBoardMutation = {
  createProductsBoard?:  {
    __typename: "ProductsBoard",
    id: string,
    schemaVersion: number,
    title: string,
    spaceMembershipID?: string | null,
    shoppingListGroups:  Array< {
      __typename: "ShoppingListGroup",
      id: string,
      title: string,
      shoppingListIDs?: Array< string > | null,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateProductsBoardMutationVariables = {
  input: UpdateProductsBoardInput,
  condition?: ModelProductsBoardConditionInput | null,
};

export type UpdateProductsBoardMutation = {
  updateProductsBoard?:  {
    __typename: "ProductsBoard",
    id: string,
    schemaVersion: number,
    title: string,
    spaceMembershipID?: string | null,
    shoppingListGroups:  Array< {
      __typename: "ShoppingListGroup",
      id: string,
      title: string,
      shoppingListIDs?: Array< string > | null,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteProductsBoardMutationVariables = {
  input: DeleteProductsBoardInput,
  condition?: ModelProductsBoardConditionInput | null,
};

export type DeleteProductsBoardMutation = {
  deleteProductsBoard?:  {
    __typename: "ProductsBoard",
    id: string,
    schemaVersion: number,
    title: string,
    spaceMembershipID?: string | null,
    shoppingListGroups:  Array< {
      __typename: "ShoppingListGroup",
      id: string,
      title: string,
      shoppingListIDs?: Array< string > | null,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateGlobalTagMutationVariables = {
  input: CreateGlobalTagInput,
  condition?: ModelGlobalTagConditionInput | null,
};

export type CreateGlobalTagMutation = {
  createGlobalTag?:  {
    __typename: "GlobalTag",
    id: string,
    tag: string,
    count: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGlobalTagMutationVariables = {
  input: UpdateGlobalTagInput,
  condition?: ModelGlobalTagConditionInput | null,
};

export type UpdateGlobalTagMutation = {
  updateGlobalTag?:  {
    __typename: "GlobalTag",
    id: string,
    tag: string,
    count: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGlobalTagMutationVariables = {
  input: DeleteGlobalTagInput,
  condition?: ModelGlobalTagConditionInput | null,
};

export type DeleteGlobalTagMutation = {
  deleteGlobalTag?:  {
    __typename: "GlobalTag",
    id: string,
    tag: string,
    count: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateIngredientEditEventMutationVariables = {
  input: CreateIngredientEditEventInput,
  condition?: ModelIngredientEditEventConditionInput | null,
};

export type CreateIngredientEditEventMutation = {
  createIngredientEditEvent?:  {
    __typename: "IngredientEditEvent",
    mealID: string,
    recipeID: string,
    editMethod?: EditMethod | null,
    ingredientID: string,
    fullTextBefore: string,
    fullTextAfter: string,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateIngredientEditEventMutationVariables = {
  input: UpdateIngredientEditEventInput,
  condition?: ModelIngredientEditEventConditionInput | null,
};

export type UpdateIngredientEditEventMutation = {
  updateIngredientEditEvent?:  {
    __typename: "IngredientEditEvent",
    mealID: string,
    recipeID: string,
    editMethod?: EditMethod | null,
    ingredientID: string,
    fullTextBefore: string,
    fullTextAfter: string,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteIngredientEditEventMutationVariables = {
  input: DeleteIngredientEditEventInput,
  condition?: ModelIngredientEditEventConditionInput | null,
};

export type DeleteIngredientEditEventMutation = {
  deleteIngredientEditEvent?:  {
    __typename: "IngredientEditEvent",
    mealID: string,
    recipeID: string,
    editMethod?: EditMethod | null,
    ingredientID: string,
    fullTextBefore: string,
    fullTextAfter: string,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateIngredientEditSuggestionMutationVariables = {
  input: CreateIngredientEditSuggestionInput,
  condition?: ModelIngredientEditSuggestionConditionInput | null,
};

export type CreateIngredientEditSuggestionMutation = {
  createIngredientEditSuggestion?:  {
    __typename: "IngredientEditSuggestion",
    id: string,
    inputFullText: string,
    suggestion: string,
    weight: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateIngredientEditSuggestionMutationVariables = {
  input: UpdateIngredientEditSuggestionInput,
  condition?: ModelIngredientEditSuggestionConditionInput | null,
};

export type UpdateIngredientEditSuggestionMutation = {
  updateIngredientEditSuggestion?:  {
    __typename: "IngredientEditSuggestion",
    id: string,
    inputFullText: string,
    suggestion: string,
    weight: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteIngredientEditSuggestionMutationVariables = {
  input: DeleteIngredientEditSuggestionInput,
  condition?: ModelIngredientEditSuggestionConditionInput | null,
};

export type DeleteIngredientEditSuggestionMutation = {
  deleteIngredientEditSuggestion?:  {
    __typename: "IngredientEditSuggestion",
    id: string,
    inputFullText: string,
    suggestion: string,
    weight: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateIngredientEditSuggestionByIngredientIDMutationVariables = {
  input: CreateIngredientEditSuggestionByIngredientIDInput,
  condition?: ModelIngredientEditSuggestionByIngredientIDConditionInput | null,
};

export type CreateIngredientEditSuggestionByIngredientIDMutation = {
  createIngredientEditSuggestionByIngredientID?:  {
    __typename: "IngredientEditSuggestionByIngredientID",
    id: string,
    ingredientID: string,
    suggestion: string,
    weight: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateIngredientEditSuggestionByIngredientIDMutationVariables = {
  input: UpdateIngredientEditSuggestionByIngredientIDInput,
  condition?: ModelIngredientEditSuggestionByIngredientIDConditionInput | null,
};

export type UpdateIngredientEditSuggestionByIngredientIDMutation = {
  updateIngredientEditSuggestionByIngredientID?:  {
    __typename: "IngredientEditSuggestionByIngredientID",
    id: string,
    ingredientID: string,
    suggestion: string,
    weight: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteIngredientEditSuggestionByIngredientIDMutationVariables = {
  input: DeleteIngredientEditSuggestionByIngredientIDInput,
  condition?: ModelIngredientEditSuggestionByIngredientIDConditionInput | null,
};

export type DeleteIngredientEditSuggestionByIngredientIDMutation = {
  deleteIngredientEditSuggestionByIngredientID?:  {
    __typename: "IngredientEditSuggestionByIngredientID",
    id: string,
    ingredientID: string,
    suggestion: string,
    weight: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProgrammeMutationVariables = {
  input: CreateProgrammeInput,
  condition?: ModelProgrammeConditionInput | null,
};

export type CreateProgrammeMutation = {
  createProgramme?:  {
    __typename: "Programme",
    id: string,
    title: string,
    shortDescription: string,
    description?: string | null,
    coverImageUrl?: string | null,
    plans:  Array< {
      __typename: "ProgrammePlan",
      id: string,
      title: string,
      shortDescription: string,
      description?: string | null,
      coverImageUrl?: string | null,
      days:  Array< {
        __typename: "ProgrammePlanDay",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "ProgrammeEntry",
          id: string,
          programmeEntryType: EntryType,
          objectID: string,
        } >,
      } >,
      nutritionConstraints?:  {
        __typename: "NutritionConstraints",
        nutritionMetricConstraints?:  Array< {
          __typename: "NutritionMetricConstraint",
          id: string,
          nutritionMetric: string,
          operator: ConstraintOperator,
          value: string,
          units: string,
        } > | null,
        excludeIngredients?: Array< string > | null,
        foodPreferences?: Array< FoodPreference > | null,
      } | null,
      showNutritionToUsers?: boolean | null,
    } >,
    locales?: Array< string > | null,
    mealTypes?: Array< string > | null,
    calorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    nutritionConstraints?:  {
      __typename: "NutritionConstraints",
      nutritionMetricConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      excludeIngredients?: Array< string > | null,
      foodPreferences?: Array< FoodPreference > | null,
    } | null,
    showNutritionToUsers?: boolean | null,
    copyMealsExactly?: boolean | null,
    personalisedMealScaling?: boolean | null,
    recipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    databaseRecipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    recipesBoardIDs?: Array< string > | null,
    onboardingConfiguration?:  {
      __typename: "ProgrammeOnboardingConfiguration",
      questionIDs: Array< string >,
    } | null,
    categoryTags?: Array< string > | null,
    shareRecords?:  Array< {
      __typename: "ProgrammeShareRecord",
      sharedProgrammeID: string,
      version: string,
      updatedOn: string,
    } | null > | null,
    availableInMembershipTierIDs?: Array< string > | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateProgrammeMutationVariables = {
  input: UpdateProgrammeInput,
  condition?: ModelProgrammeConditionInput | null,
};

export type UpdateProgrammeMutation = {
  updateProgramme?:  {
    __typename: "Programme",
    id: string,
    title: string,
    shortDescription: string,
    description?: string | null,
    coverImageUrl?: string | null,
    plans:  Array< {
      __typename: "ProgrammePlan",
      id: string,
      title: string,
      shortDescription: string,
      description?: string | null,
      coverImageUrl?: string | null,
      days:  Array< {
        __typename: "ProgrammePlanDay",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "ProgrammeEntry",
          id: string,
          programmeEntryType: EntryType,
          objectID: string,
        } >,
      } >,
      nutritionConstraints?:  {
        __typename: "NutritionConstraints",
        nutritionMetricConstraints?:  Array< {
          __typename: "NutritionMetricConstraint",
          id: string,
          nutritionMetric: string,
          operator: ConstraintOperator,
          value: string,
          units: string,
        } > | null,
        excludeIngredients?: Array< string > | null,
        foodPreferences?: Array< FoodPreference > | null,
      } | null,
      showNutritionToUsers?: boolean | null,
    } >,
    locales?: Array< string > | null,
    mealTypes?: Array< string > | null,
    calorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    nutritionConstraints?:  {
      __typename: "NutritionConstraints",
      nutritionMetricConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      excludeIngredients?: Array< string > | null,
      foodPreferences?: Array< FoodPreference > | null,
    } | null,
    showNutritionToUsers?: boolean | null,
    copyMealsExactly?: boolean | null,
    personalisedMealScaling?: boolean | null,
    recipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    databaseRecipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    recipesBoardIDs?: Array< string > | null,
    onboardingConfiguration?:  {
      __typename: "ProgrammeOnboardingConfiguration",
      questionIDs: Array< string >,
    } | null,
    categoryTags?: Array< string > | null,
    shareRecords?:  Array< {
      __typename: "ProgrammeShareRecord",
      sharedProgrammeID: string,
      version: string,
      updatedOn: string,
    } | null > | null,
    availableInMembershipTierIDs?: Array< string > | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteProgrammeMutationVariables = {
  input: DeleteProgrammeInput,
  condition?: ModelProgrammeConditionInput | null,
};

export type DeleteProgrammeMutation = {
  deleteProgramme?:  {
    __typename: "Programme",
    id: string,
    title: string,
    shortDescription: string,
    description?: string | null,
    coverImageUrl?: string | null,
    plans:  Array< {
      __typename: "ProgrammePlan",
      id: string,
      title: string,
      shortDescription: string,
      description?: string | null,
      coverImageUrl?: string | null,
      days:  Array< {
        __typename: "ProgrammePlanDay",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "ProgrammeEntry",
          id: string,
          programmeEntryType: EntryType,
          objectID: string,
        } >,
      } >,
      nutritionConstraints?:  {
        __typename: "NutritionConstraints",
        nutritionMetricConstraints?:  Array< {
          __typename: "NutritionMetricConstraint",
          id: string,
          nutritionMetric: string,
          operator: ConstraintOperator,
          value: string,
          units: string,
        } > | null,
        excludeIngredients?: Array< string > | null,
        foodPreferences?: Array< FoodPreference > | null,
      } | null,
      showNutritionToUsers?: boolean | null,
    } >,
    locales?: Array< string > | null,
    mealTypes?: Array< string > | null,
    calorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    nutritionConstraints?:  {
      __typename: "NutritionConstraints",
      nutritionMetricConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      excludeIngredients?: Array< string > | null,
      foodPreferences?: Array< FoodPreference > | null,
    } | null,
    showNutritionToUsers?: boolean | null,
    copyMealsExactly?: boolean | null,
    personalisedMealScaling?: boolean | null,
    recipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    databaseRecipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    recipesBoardIDs?: Array< string > | null,
    onboardingConfiguration?:  {
      __typename: "ProgrammeOnboardingConfiguration",
      questionIDs: Array< string >,
    } | null,
    categoryTags?: Array< string > | null,
    shareRecords?:  Array< {
      __typename: "ProgrammeShareRecord",
      sharedProgrammeID: string,
      version: string,
      updatedOn: string,
    } | null > | null,
    availableInMembershipTierIDs?: Array< string > | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateSharedProgrammeMutationVariables = {
  input: CreateSharedProgrammeInput,
  condition?: ModelSharedProgrammeConditionInput | null,
};

export type CreateSharedProgrammeMutation = {
  createSharedProgramme?:  {
    __typename: "SharedProgramme",
    spaceID: string,
    programmeID: string,
    title: string,
    shortDescription: string,
    description?: string | null,
    coverImageUrl?: string | null,
    plans:  Array< {
      __typename: "ProgrammePlan",
      id: string,
      title: string,
      shortDescription: string,
      description?: string | null,
      coverImageUrl?: string | null,
      days:  Array< {
        __typename: "ProgrammePlanDay",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "ProgrammeEntry",
          id: string,
          programmeEntryType: EntryType,
          objectID: string,
        } >,
      } >,
      nutritionConstraints?:  {
        __typename: "NutritionConstraints",
        nutritionMetricConstraints?:  Array< {
          __typename: "NutritionMetricConstraint",
          id: string,
          nutritionMetric: string,
          operator: ConstraintOperator,
          value: string,
          units: string,
        } > | null,
        excludeIngredients?: Array< string > | null,
        foodPreferences?: Array< FoodPreference > | null,
      } | null,
      showNutritionToUsers?: boolean | null,
    } >,
    locales?: Array< string > | null,
    mealTypes?: Array< string > | null,
    calorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    nutritionConstraints?:  {
      __typename: "NutritionConstraints",
      nutritionMetricConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      excludeIngredients?: Array< string > | null,
      foodPreferences?: Array< FoodPreference > | null,
    } | null,
    showNutritionToUsers?: boolean | null,
    copyMealsExactly?: boolean | null,
    personalisedMealScaling?: boolean | null,
    recipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    databaseRecipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    sharedRecipesBoardIDs?: Array< string > | null,
    onboardingConfiguration?:  {
      __typename: "ProgrammeOnboardingConfiguration",
      questionIDs: Array< string >,
    } | null,
    categoryTags?: Array< string > | null,
    version: string,
    availableInMembershipTierIDs?: Array< string > | null,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateSharedProgrammeMutationVariables = {
  input: UpdateSharedProgrammeInput,
  condition?: ModelSharedProgrammeConditionInput | null,
};

export type UpdateSharedProgrammeMutation = {
  updateSharedProgramme?:  {
    __typename: "SharedProgramme",
    spaceID: string,
    programmeID: string,
    title: string,
    shortDescription: string,
    description?: string | null,
    coverImageUrl?: string | null,
    plans:  Array< {
      __typename: "ProgrammePlan",
      id: string,
      title: string,
      shortDescription: string,
      description?: string | null,
      coverImageUrl?: string | null,
      days:  Array< {
        __typename: "ProgrammePlanDay",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "ProgrammeEntry",
          id: string,
          programmeEntryType: EntryType,
          objectID: string,
        } >,
      } >,
      nutritionConstraints?:  {
        __typename: "NutritionConstraints",
        nutritionMetricConstraints?:  Array< {
          __typename: "NutritionMetricConstraint",
          id: string,
          nutritionMetric: string,
          operator: ConstraintOperator,
          value: string,
          units: string,
        } > | null,
        excludeIngredients?: Array< string > | null,
        foodPreferences?: Array< FoodPreference > | null,
      } | null,
      showNutritionToUsers?: boolean | null,
    } >,
    locales?: Array< string > | null,
    mealTypes?: Array< string > | null,
    calorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    nutritionConstraints?:  {
      __typename: "NutritionConstraints",
      nutritionMetricConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      excludeIngredients?: Array< string > | null,
      foodPreferences?: Array< FoodPreference > | null,
    } | null,
    showNutritionToUsers?: boolean | null,
    copyMealsExactly?: boolean | null,
    personalisedMealScaling?: boolean | null,
    recipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    databaseRecipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    sharedRecipesBoardIDs?: Array< string > | null,
    onboardingConfiguration?:  {
      __typename: "ProgrammeOnboardingConfiguration",
      questionIDs: Array< string >,
    } | null,
    categoryTags?: Array< string > | null,
    version: string,
    availableInMembershipTierIDs?: Array< string > | null,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteSharedProgrammeMutationVariables = {
  input: DeleteSharedProgrammeInput,
  condition?: ModelSharedProgrammeConditionInput | null,
};

export type DeleteSharedProgrammeMutation = {
  deleteSharedProgramme?:  {
    __typename: "SharedProgramme",
    spaceID: string,
    programmeID: string,
    title: string,
    shortDescription: string,
    description?: string | null,
    coverImageUrl?: string | null,
    plans:  Array< {
      __typename: "ProgrammePlan",
      id: string,
      title: string,
      shortDescription: string,
      description?: string | null,
      coverImageUrl?: string | null,
      days:  Array< {
        __typename: "ProgrammePlanDay",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "ProgrammeEntry",
          id: string,
          programmeEntryType: EntryType,
          objectID: string,
        } >,
      } >,
      nutritionConstraints?:  {
        __typename: "NutritionConstraints",
        nutritionMetricConstraints?:  Array< {
          __typename: "NutritionMetricConstraint",
          id: string,
          nutritionMetric: string,
          operator: ConstraintOperator,
          value: string,
          units: string,
        } > | null,
        excludeIngredients?: Array< string > | null,
        foodPreferences?: Array< FoodPreference > | null,
      } | null,
      showNutritionToUsers?: boolean | null,
    } >,
    locales?: Array< string > | null,
    mealTypes?: Array< string > | null,
    calorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    nutritionConstraints?:  {
      __typename: "NutritionConstraints",
      nutritionMetricConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      excludeIngredients?: Array< string > | null,
      foodPreferences?: Array< FoodPreference > | null,
    } | null,
    showNutritionToUsers?: boolean | null,
    copyMealsExactly?: boolean | null,
    personalisedMealScaling?: boolean | null,
    recipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    databaseRecipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    sharedRecipesBoardIDs?: Array< string > | null,
    onboardingConfiguration?:  {
      __typename: "ProgrammeOnboardingConfiguration",
      questionIDs: Array< string >,
    } | null,
    categoryTags?: Array< string > | null,
    version: string,
    availableInMembershipTierIDs?: Array< string > | null,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateUserProgrammeEnrollmentMutationVariables = {
  input: CreateUserProgrammeEnrollmentInput,
  condition?: ModelUserProgrammeEnrollmentConditionInput | null,
};

export type CreateUserProgrammeEnrollmentMutation = {
  createUserProgrammeEnrollment?:  {
    __typename: "UserProgrammeEnrollment",
    spaceMembershipID: string,
    sharedProgrammeID: string,
    enrolledAt: string,
    onboardingAnswers:  Array< {
      __typename: "OnboardingAnswer",
      questionID: string,
      answer: string,
    } >,
    endedAt?: string | null,
    eventRecords?:  Array< {
      __typename: "UserProgrammeEnrollmentEventRecord",
      calendarDay: string,
      eventType: UserProgrammeEnrollmentEventType,
      updatedOn: string,
    } > | null,
    customTargetCalories?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateUserProgrammeEnrollmentMutationVariables = {
  input: UpdateUserProgrammeEnrollmentInput,
  condition?: ModelUserProgrammeEnrollmentConditionInput | null,
};

export type UpdateUserProgrammeEnrollmentMutation = {
  updateUserProgrammeEnrollment?:  {
    __typename: "UserProgrammeEnrollment",
    spaceMembershipID: string,
    sharedProgrammeID: string,
    enrolledAt: string,
    onboardingAnswers:  Array< {
      __typename: "OnboardingAnswer",
      questionID: string,
      answer: string,
    } >,
    endedAt?: string | null,
    eventRecords?:  Array< {
      __typename: "UserProgrammeEnrollmentEventRecord",
      calendarDay: string,
      eventType: UserProgrammeEnrollmentEventType,
      updatedOn: string,
    } > | null,
    customTargetCalories?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteUserProgrammeEnrollmentMutationVariables = {
  input: DeleteUserProgrammeEnrollmentInput,
  condition?: ModelUserProgrammeEnrollmentConditionInput | null,
};

export type DeleteUserProgrammeEnrollmentMutation = {
  deleteUserProgrammeEnrollment?:  {
    __typename: "UserProgrammeEnrollment",
    spaceMembershipID: string,
    sharedProgrammeID: string,
    enrolledAt: string,
    onboardingAnswers:  Array< {
      __typename: "OnboardingAnswer",
      questionID: string,
      answer: string,
    } >,
    endedAt?: string | null,
    eventRecords?:  Array< {
      __typename: "UserProgrammeEnrollmentEventRecord",
      calendarDay: string,
      eventType: UserProgrammeEnrollmentEventType,
      updatedOn: string,
    } > | null,
    customTargetCalories?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateGRCRecipeMutationVariables = {
  input: CreateGRCRecipeInput,
  condition?: ModelGRCRecipeConditionInput | null,
};

export type CreateGRCRecipeMutation = {
  createGRCRecipe?:  {
    __typename: "GRCRecipe",
    grcRecipeID: string,
    recipe:  {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    },
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGRCRecipeMutationVariables = {
  input: UpdateGRCRecipeInput,
  condition?: ModelGRCRecipeConditionInput | null,
};

export type UpdateGRCRecipeMutation = {
  updateGRCRecipe?:  {
    __typename: "GRCRecipe",
    grcRecipeID: string,
    recipe:  {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    },
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGRCRecipeMutationVariables = {
  input: DeleteGRCRecipeInput,
  condition?: ModelGRCRecipeConditionInput | null,
};

export type DeleteGRCRecipeMutation = {
  deleteGRCRecipe?:  {
    __typename: "GRCRecipe",
    grcRecipeID: string,
    recipe:  {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    },
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSharedContentBoardMutationVariables = {
  input: CreateSharedContentBoardInput,
  condition?: ModelSharedContentBoardConditionInput | null,
};

export type CreateSharedContentBoardMutation = {
  createSharedContentBoard?:  {
    __typename: "SharedContentBoard",
    id: string,
    title: string,
    lanes:  Array< {
      __typename: "SharedContentBoardLane",
      id: string,
      title: string,
      entries:  Array< {
        __typename: "SharedContentBoardEntry",
        id: string,
        entryType: EntryType,
        objectID: string,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateSharedContentBoardMutationVariables = {
  input: UpdateSharedContentBoardInput,
  condition?: ModelSharedContentBoardConditionInput | null,
};

export type UpdateSharedContentBoardMutation = {
  updateSharedContentBoard?:  {
    __typename: "SharedContentBoard",
    id: string,
    title: string,
    lanes:  Array< {
      __typename: "SharedContentBoardLane",
      id: string,
      title: string,
      entries:  Array< {
        __typename: "SharedContentBoardEntry",
        id: string,
        entryType: EntryType,
        objectID: string,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteSharedContentBoardMutationVariables = {
  input: DeleteSharedContentBoardInput,
  condition?: ModelSharedContentBoardConditionInput | null,
};

export type DeleteSharedContentBoardMutation = {
  deleteSharedContentBoard?:  {
    __typename: "SharedContentBoard",
    id: string,
    title: string,
    lanes:  Array< {
      __typename: "SharedContentBoardLane",
      id: string,
      title: string,
      entries:  Array< {
        __typename: "SharedContentBoardEntry",
        id: string,
        entryType: EntryType,
        objectID: string,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateSpaceIAPSubscriptionMutationVariables = {
  input: CreateSpaceIAPSubscriptionInput,
  condition?: ModelSpaceIAPSubscriptionConditionInput | null,
};

export type CreateSpaceIAPSubscriptionMutation = {
  createSpaceIAPSubscription?:  {
    __typename: "SpaceIAPSubscription",
    spaceID: string,
    productID: string,
    title: string,
    shortDescription?: string | null,
    currencyCode: string,
    priceStr: string,
    bundleID: string,
    duration?: SubscriptionDuration | null,
    signUpButtonLabelOverride?: string | null,
    appStoreID?: AppStoreID | null,
    rank?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateSpaceIAPSubscriptionMutationVariables = {
  input: UpdateSpaceIAPSubscriptionInput,
  condition?: ModelSpaceIAPSubscriptionConditionInput | null,
};

export type UpdateSpaceIAPSubscriptionMutation = {
  updateSpaceIAPSubscription?:  {
    __typename: "SpaceIAPSubscription",
    spaceID: string,
    productID: string,
    title: string,
    shortDescription?: string | null,
    currencyCode: string,
    priceStr: string,
    bundleID: string,
    duration?: SubscriptionDuration | null,
    signUpButtonLabelOverride?: string | null,
    appStoreID?: AppStoreID | null,
    rank?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteSpaceIAPSubscriptionMutationVariables = {
  input: DeleteSpaceIAPSubscriptionInput,
  condition?: ModelSpaceIAPSubscriptionConditionInput | null,
};

export type DeleteSpaceIAPSubscriptionMutation = {
  deleteSpaceIAPSubscription?:  {
    __typename: "SpaceIAPSubscription",
    spaceID: string,
    productID: string,
    title: string,
    shortDescription?: string | null,
    currencyCode: string,
    priceStr: string,
    bundleID: string,
    duration?: SubscriptionDuration | null,
    signUpButtonLabelOverride?: string | null,
    appStoreID?: AppStoreID | null,
    rank?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateSpaceMutationVariables = {
  input: CreateSpaceInput,
  condition?: ModelSpaceConditionInput | null,
};

export type CreateSpaceMutation = {
  createSpace?:  {
    __typename: "Space",
    title: string,
    shortDescription?: string | null,
    whiteLabelElements?:  {
      __typename: "SpaceWhiteLabelElements",
      primaryMainColor?: string | null,
      backgroundStyle?: BackgroundStyle | null,
      backgroundColor?: string | null,
      backgroundGradientColor?: string | null,
      cardBackgroundColor?: string | null,
      iconUrl?: string | null,
      splashImageUrl?: string | null,
      myDayBackgroundImageUrl?: string | null,
      desktopBannerImageUrl?: string | null,
      termsUrl?: string | null,
      privacyPolicyUrl?: string | null,
    } | null,
    adminUserIDs?: Array< string > | null,
    landingPageContent?: string | null,
    landingPageStyle?:  {
      __typename: "LandingPageStyle",
      backgroundColor?: string | null,
    } | null,
    landingPageCoverImageUrl?: string | null,
    isDefaultSpace?: boolean | null,
    sharedProgrammeConfiguration?:  {
      __typename: "SpaceSharedProgrammesConfiguration",
      sharedProgrammeCategories?:  Array< {
        __typename: "SpaceSharedProgrammeCategoryConfiguration",
        categoryTag: string,
        orderedProgrammeIDs: Array< string >,
      } | null > | null,
    } | null,
    productPreviewSections?:  Array< {
      __typename: "SpaceProductPreviewSection",
      title: string,
      productPreviews:  Array< {
        __typename: "SpaceProductPreview",
        productType: SpaceProductType,
        productObjectID: string,
        title: string,
        shortDescription: string,
        coverImageUrl?: string | null,
        availableInMembershipTierIDs?: Array< string > | null,
      } >,
    } > | null,
    videoPlaylists?:  Array< {
      __typename: "VideoPlaylist",
      title: string,
      playlistID: string,
      items:  Array< {
        __typename: "VideoPlaylistItem",
        videoID: string,
        title: string,
        description?: string | null,
        thumbnailImageUrl?: string | null,
      } >,
    } > | null,
    spaceContentBoard?:  {
      __typename: "SpaceContentBoard",
      id: string,
      title: string,
      lanes:  Array< {
        __typename: "SpaceContentLane",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "SpaceContentEntry",
          id: string,
          entryType: EntryType,
          objectID: string,
        } >,
      } >,
    } | null,
    spaceSharedContentBoardIDs?: Array< string > | null,
    welcomeVideo?:  {
      __typename: "VideoPlaylistItem",
      videoID: string,
      title: string,
      description?: string | null,
      thumbnailImageUrl?: string | null,
    } | null,
    myDayScreenSections?:  Array< {
      __typename: "SpacePublishedSectionConfig",
      publishedSectionType?: SpacePublishedSectionType | null,
      spaceContentLaneID?: string | null,
    } > | null,
    subscriptionBenefitsContent?: string | null,
    subscriptionPageStyle?:  {
      __typename: "SubscriptionPageStyle",
      backgroundColor?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    sharedRecipesBoardIDs?: Array< string > | null,
    defaultSharedProgrammeIDForNewUsers?: string | null,
    appStoreLinks?:  Array< {
      __typename: "SpaceAppStoreLink",
      appStoreID: AppStoreID,
      appUrl: string,
    } > | null,
    chatConfig?:  {
      __typename: "SpaceChatConfig",
      appID?: string | null,
      appRegion?: string | null,
    } | null,
    publishedSearchTags?:  Array< {
      __typename: "SpacePublishedSearchTag",
      id: string,
      tag: string,
    } > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateSpaceMutationVariables = {
  input: UpdateSpaceInput,
  condition?: ModelSpaceConditionInput | null,
};

export type UpdateSpaceMutation = {
  updateSpace?:  {
    __typename: "Space",
    title: string,
    shortDescription?: string | null,
    whiteLabelElements?:  {
      __typename: "SpaceWhiteLabelElements",
      primaryMainColor?: string | null,
      backgroundStyle?: BackgroundStyle | null,
      backgroundColor?: string | null,
      backgroundGradientColor?: string | null,
      cardBackgroundColor?: string | null,
      iconUrl?: string | null,
      splashImageUrl?: string | null,
      myDayBackgroundImageUrl?: string | null,
      desktopBannerImageUrl?: string | null,
      termsUrl?: string | null,
      privacyPolicyUrl?: string | null,
    } | null,
    adminUserIDs?: Array< string > | null,
    landingPageContent?: string | null,
    landingPageStyle?:  {
      __typename: "LandingPageStyle",
      backgroundColor?: string | null,
    } | null,
    landingPageCoverImageUrl?: string | null,
    isDefaultSpace?: boolean | null,
    sharedProgrammeConfiguration?:  {
      __typename: "SpaceSharedProgrammesConfiguration",
      sharedProgrammeCategories?:  Array< {
        __typename: "SpaceSharedProgrammeCategoryConfiguration",
        categoryTag: string,
        orderedProgrammeIDs: Array< string >,
      } | null > | null,
    } | null,
    productPreviewSections?:  Array< {
      __typename: "SpaceProductPreviewSection",
      title: string,
      productPreviews:  Array< {
        __typename: "SpaceProductPreview",
        productType: SpaceProductType,
        productObjectID: string,
        title: string,
        shortDescription: string,
        coverImageUrl?: string | null,
        availableInMembershipTierIDs?: Array< string > | null,
      } >,
    } > | null,
    videoPlaylists?:  Array< {
      __typename: "VideoPlaylist",
      title: string,
      playlistID: string,
      items:  Array< {
        __typename: "VideoPlaylistItem",
        videoID: string,
        title: string,
        description?: string | null,
        thumbnailImageUrl?: string | null,
      } >,
    } > | null,
    spaceContentBoard?:  {
      __typename: "SpaceContentBoard",
      id: string,
      title: string,
      lanes:  Array< {
        __typename: "SpaceContentLane",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "SpaceContentEntry",
          id: string,
          entryType: EntryType,
          objectID: string,
        } >,
      } >,
    } | null,
    spaceSharedContentBoardIDs?: Array< string > | null,
    welcomeVideo?:  {
      __typename: "VideoPlaylistItem",
      videoID: string,
      title: string,
      description?: string | null,
      thumbnailImageUrl?: string | null,
    } | null,
    myDayScreenSections?:  Array< {
      __typename: "SpacePublishedSectionConfig",
      publishedSectionType?: SpacePublishedSectionType | null,
      spaceContentLaneID?: string | null,
    } > | null,
    subscriptionBenefitsContent?: string | null,
    subscriptionPageStyle?:  {
      __typename: "SubscriptionPageStyle",
      backgroundColor?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    sharedRecipesBoardIDs?: Array< string > | null,
    defaultSharedProgrammeIDForNewUsers?: string | null,
    appStoreLinks?:  Array< {
      __typename: "SpaceAppStoreLink",
      appStoreID: AppStoreID,
      appUrl: string,
    } > | null,
    chatConfig?:  {
      __typename: "SpaceChatConfig",
      appID?: string | null,
      appRegion?: string | null,
    } | null,
    publishedSearchTags?:  Array< {
      __typename: "SpacePublishedSearchTag",
      id: string,
      tag: string,
    } > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteSpaceMutationVariables = {
  input: DeleteSpaceInput,
  condition?: ModelSpaceConditionInput | null,
};

export type DeleteSpaceMutation = {
  deleteSpace?:  {
    __typename: "Space",
    title: string,
    shortDescription?: string | null,
    whiteLabelElements?:  {
      __typename: "SpaceWhiteLabelElements",
      primaryMainColor?: string | null,
      backgroundStyle?: BackgroundStyle | null,
      backgroundColor?: string | null,
      backgroundGradientColor?: string | null,
      cardBackgroundColor?: string | null,
      iconUrl?: string | null,
      splashImageUrl?: string | null,
      myDayBackgroundImageUrl?: string | null,
      desktopBannerImageUrl?: string | null,
      termsUrl?: string | null,
      privacyPolicyUrl?: string | null,
    } | null,
    adminUserIDs?: Array< string > | null,
    landingPageContent?: string | null,
    landingPageStyle?:  {
      __typename: "LandingPageStyle",
      backgroundColor?: string | null,
    } | null,
    landingPageCoverImageUrl?: string | null,
    isDefaultSpace?: boolean | null,
    sharedProgrammeConfiguration?:  {
      __typename: "SpaceSharedProgrammesConfiguration",
      sharedProgrammeCategories?:  Array< {
        __typename: "SpaceSharedProgrammeCategoryConfiguration",
        categoryTag: string,
        orderedProgrammeIDs: Array< string >,
      } | null > | null,
    } | null,
    productPreviewSections?:  Array< {
      __typename: "SpaceProductPreviewSection",
      title: string,
      productPreviews:  Array< {
        __typename: "SpaceProductPreview",
        productType: SpaceProductType,
        productObjectID: string,
        title: string,
        shortDescription: string,
        coverImageUrl?: string | null,
        availableInMembershipTierIDs?: Array< string > | null,
      } >,
    } > | null,
    videoPlaylists?:  Array< {
      __typename: "VideoPlaylist",
      title: string,
      playlistID: string,
      items:  Array< {
        __typename: "VideoPlaylistItem",
        videoID: string,
        title: string,
        description?: string | null,
        thumbnailImageUrl?: string | null,
      } >,
    } > | null,
    spaceContentBoard?:  {
      __typename: "SpaceContentBoard",
      id: string,
      title: string,
      lanes:  Array< {
        __typename: "SpaceContentLane",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "SpaceContentEntry",
          id: string,
          entryType: EntryType,
          objectID: string,
        } >,
      } >,
    } | null,
    spaceSharedContentBoardIDs?: Array< string > | null,
    welcomeVideo?:  {
      __typename: "VideoPlaylistItem",
      videoID: string,
      title: string,
      description?: string | null,
      thumbnailImageUrl?: string | null,
    } | null,
    myDayScreenSections?:  Array< {
      __typename: "SpacePublishedSectionConfig",
      publishedSectionType?: SpacePublishedSectionType | null,
      spaceContentLaneID?: string | null,
    } > | null,
    subscriptionBenefitsContent?: string | null,
    subscriptionPageStyle?:  {
      __typename: "SubscriptionPageStyle",
      backgroundColor?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    sharedRecipesBoardIDs?: Array< string > | null,
    defaultSharedProgrammeIDForNewUsers?: string | null,
    appStoreLinks?:  Array< {
      __typename: "SpaceAppStoreLink",
      appStoreID: AppStoreID,
      appUrl: string,
    } > | null,
    chatConfig?:  {
      __typename: "SpaceChatConfig",
      appID?: string | null,
      appRegion?: string | null,
    } | null,
    publishedSearchTags?:  Array< {
      __typename: "SpacePublishedSearchTag",
      id: string,
      tag: string,
    } > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateSpaceMembershipMutationVariables = {
  input: CreateSpaceMembershipInput,
  condition?: ModelSpaceMembershipConditionInput | null,
};

export type CreateSpaceMembershipMutation = {
  createSpaceMembership?:  {
    __typename: "SpaceMembership",
    spaceID: string,
    memberID: string,
    state: SpaceMembershipState,
    correlatedUserID?: string | null,
    expiresAt?: string | null,
    visibleProgrammeIDsOverride?: Array< string > | null,
    membershipTierID?: string | null,
    stripeCustomerID?: string | null,
    stripeSubscriptionID?: string | null,
    onboardingAnswers?:  Array< {
      __typename: "OnboardingAnswer",
      questionID: string,
      answer: string,
    } > | null,
    onboardingAnswersLastUpdatedAt?: string | null,
    customTargetCalories?: number | null,
    welcomeVideoWatched?: boolean | null,
    initialProgrammeSelectionLastOfferedAt?: string | null,
    chatNickname?: string | null,
    referrer?: SpaceMembershipReferrer | null,
    appStoreSubscriptionID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type UpdateSpaceMembershipMutationVariables = {
  input: UpdateSpaceMembershipInput,
  condition?: ModelSpaceMembershipConditionInput | null,
};

export type UpdateSpaceMembershipMutation = {
  updateSpaceMembership?:  {
    __typename: "SpaceMembership",
    spaceID: string,
    memberID: string,
    state: SpaceMembershipState,
    correlatedUserID?: string | null,
    expiresAt?: string | null,
    visibleProgrammeIDsOverride?: Array< string > | null,
    membershipTierID?: string | null,
    stripeCustomerID?: string | null,
    stripeSubscriptionID?: string | null,
    onboardingAnswers?:  Array< {
      __typename: "OnboardingAnswer",
      questionID: string,
      answer: string,
    } > | null,
    onboardingAnswersLastUpdatedAt?: string | null,
    customTargetCalories?: number | null,
    welcomeVideoWatched?: boolean | null,
    initialProgrammeSelectionLastOfferedAt?: string | null,
    chatNickname?: string | null,
    referrer?: SpaceMembershipReferrer | null,
    appStoreSubscriptionID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type DeleteSpaceMembershipMutationVariables = {
  input: DeleteSpaceMembershipInput,
  condition?: ModelSpaceMembershipConditionInput | null,
};

export type DeleteSpaceMembershipMutation = {
  deleteSpaceMembership?:  {
    __typename: "SpaceMembership",
    spaceID: string,
    memberID: string,
    state: SpaceMembershipState,
    correlatedUserID?: string | null,
    expiresAt?: string | null,
    visibleProgrammeIDsOverride?: Array< string > | null,
    membershipTierID?: string | null,
    stripeCustomerID?: string | null,
    stripeSubscriptionID?: string | null,
    onboardingAnswers?:  Array< {
      __typename: "OnboardingAnswer",
      questionID: string,
      answer: string,
    } > | null,
    onboardingAnswersLastUpdatedAt?: string | null,
    customTargetCalories?: number | null,
    welcomeVideoWatched?: boolean | null,
    initialProgrammeSelectionLastOfferedAt?: string | null,
    chatNickname?: string | null,
    referrer?: SpaceMembershipReferrer | null,
    appStoreSubscriptionID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type CreateSpaceMembershipEventMutationVariables = {
  input: CreateSpaceMembershipEventInput,
  condition?: ModelSpaceMembershipEventConditionInput | null,
};

export type CreateSpaceMembershipEventMutation = {
  createSpaceMembershipEvent?:  {
    __typename: "SpaceMembershipEvent",
    spaceMembershipID: string,
    memberID: string,
    eventType?: SpaceMembershipEventType | null,
    transactionSource?: SubscriptionTransactionSource | null,
    transactionID?: string | null,
    details?: string | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type UpdateSpaceMembershipEventMutationVariables = {
  input: UpdateSpaceMembershipEventInput,
  condition?: ModelSpaceMembershipEventConditionInput | null,
};

export type UpdateSpaceMembershipEventMutation = {
  updateSpaceMembershipEvent?:  {
    __typename: "SpaceMembershipEvent",
    spaceMembershipID: string,
    memberID: string,
    eventType?: SpaceMembershipEventType | null,
    transactionSource?: SubscriptionTransactionSource | null,
    transactionID?: string | null,
    details?: string | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type DeleteSpaceMembershipEventMutationVariables = {
  input: DeleteSpaceMembershipEventInput,
  condition?: ModelSpaceMembershipEventConditionInput | null,
};

export type DeleteSpaceMembershipEventMutation = {
  deleteSpaceMembershipEvent?:  {
    __typename: "SpaceMembershipEvent",
    spaceMembershipID: string,
    memberID: string,
    eventType?: SpaceMembershipEventType | null,
    transactionSource?: SubscriptionTransactionSource | null,
    transactionID?: string | null,
    details?: string | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMyDayActionRecordMutationVariables = {
  input: CreateUserMyDayActionRecordInput,
  condition?: ModelUserMyDayActionRecordConditionInput | null,
};

export type CreateUserMyDayActionRecordMutation = {
  createUserMyDayActionRecord?:  {
    __typename: "UserMyDayActionRecord",
    userProgrammeEnrollmentID: string,
    programmeEntryType: EntryType,
    objectID: string,
    calendarDay: string,
    actionType: MyDayActionType,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateUserMyDayActionRecordMutationVariables = {
  input: UpdateUserMyDayActionRecordInput,
  condition?: ModelUserMyDayActionRecordConditionInput | null,
};

export type UpdateUserMyDayActionRecordMutation = {
  updateUserMyDayActionRecord?:  {
    __typename: "UserMyDayActionRecord",
    userProgrammeEnrollmentID: string,
    programmeEntryType: EntryType,
    objectID: string,
    calendarDay: string,
    actionType: MyDayActionType,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteUserMyDayActionRecordMutationVariables = {
  input: DeleteUserMyDayActionRecordInput,
  condition?: ModelUserMyDayActionRecordConditionInput | null,
};

export type DeleteUserMyDayActionRecordMutation = {
  deleteUserMyDayActionRecord?:  {
    __typename: "UserMyDayActionRecord",
    userProgrammeEnrollmentID: string,
    programmeEntryType: EntryType,
    objectID: string,
    calendarDay: string,
    actionType: MyDayActionType,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateMembershipTierMutationVariables = {
  input: CreateMembershipTierInput,
  condition?: ModelMembershipTierConditionInput | null,
};

export type CreateMembershipTierMutation = {
  createMembershipTier?:  {
    __typename: "MembershipTier",
    spaceID: string,
    title: string,
    shortDescription?: string | null,
    monthlyPrice: string,
    quarterlyPrice?: string | null,
    annualPrice?: string | null,
    freeTrialDurationDays?: number | null,
    rank?: number | null,
    currencyCode?: string | null,
    state: MembershipTierState,
    stripeProductID?: string | null,
    stripePriceID?: string | null,
    stripePriceIDQuarterly?: string | null,
    stripePriceIDAnnual?: string | null,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateMembershipTierMutationVariables = {
  input: UpdateMembershipTierInput,
  condition?: ModelMembershipTierConditionInput | null,
};

export type UpdateMembershipTierMutation = {
  updateMembershipTier?:  {
    __typename: "MembershipTier",
    spaceID: string,
    title: string,
    shortDescription?: string | null,
    monthlyPrice: string,
    quarterlyPrice?: string | null,
    annualPrice?: string | null,
    freeTrialDurationDays?: number | null,
    rank?: number | null,
    currencyCode?: string | null,
    state: MembershipTierState,
    stripeProductID?: string | null,
    stripePriceID?: string | null,
    stripePriceIDQuarterly?: string | null,
    stripePriceIDAnnual?: string | null,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteMembershipTierMutationVariables = {
  input: DeleteMembershipTierInput,
  condition?: ModelMembershipTierConditionInput | null,
};

export type DeleteMembershipTierMutation = {
  deleteMembershipTier?:  {
    __typename: "MembershipTier",
    spaceID: string,
    title: string,
    shortDescription?: string | null,
    monthlyPrice: string,
    quarterlyPrice?: string | null,
    annualPrice?: string | null,
    freeTrialDurationDays?: number | null,
    rank?: number | null,
    currencyCode?: string | null,
    state: MembershipTierState,
    stripeProductID?: string | null,
    stripePriceID?: string | null,
    stripePriceIDQuarterly?: string | null,
    stripePriceIDAnnual?: string | null,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateSpacePrivateConfigMutationVariables = {
  input: CreateSpacePrivateConfigInput,
  condition?: ModelSpacePrivateConfigConditionInput | null,
};

export type CreateSpacePrivateConfigMutation = {
  createSpacePrivateConfig?:  {
    __typename: "SpacePrivateConfig",
    id: string,
    spaceID: string,
    stripeConfig?:  {
      __typename: "SpaceStripeConfig",
      environment: StripeEnvironment,
      connectedAccountID: string,
      chargesEnabled: boolean,
      stripeProductID?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateSpacePrivateConfigMutationVariables = {
  input: UpdateSpacePrivateConfigInput,
  condition?: ModelSpacePrivateConfigConditionInput | null,
};

export type UpdateSpacePrivateConfigMutation = {
  updateSpacePrivateConfig?:  {
    __typename: "SpacePrivateConfig",
    id: string,
    spaceID: string,
    stripeConfig?:  {
      __typename: "SpaceStripeConfig",
      environment: StripeEnvironment,
      connectedAccountID: string,
      chargesEnabled: boolean,
      stripeProductID?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteSpacePrivateConfigMutationVariables = {
  input: DeleteSpacePrivateConfigInput,
  condition?: ModelSpacePrivateConfigConditionInput | null,
};

export type DeleteSpacePrivateConfigMutation = {
  deleteSpacePrivateConfig?:  {
    __typename: "SpacePrivateConfig",
    id: string,
    spaceID: string,
    stripeConfig?:  {
      __typename: "SpaceStripeConfig",
      environment: StripeEnvironment,
      connectedAccountID: string,
      chargesEnabled: boolean,
      stripeProductID?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateAccountActivitySignalMutationVariables = {
  input: CreateAccountActivitySignalInput,
  condition?: ModelAccountActivitySignalConditionInput | null,
};

export type CreateAccountActivitySignalMutation = {
  createAccountActivitySignal?:  {
    __typename: "AccountActivitySignal",
    id: string,
    spaceID: string,
    groups?: Array< string | null > | null,
    lastActivityAt: string,
    friendlyUsername?: string | null,
    lastActivityObjectType?: ActivityObjectType | null,
    lastActivityObjectID?: string | null,
    sourceIp?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateAccountActivitySignalMutationVariables = {
  input: UpdateAccountActivitySignalInput,
  condition?: ModelAccountActivitySignalConditionInput | null,
};

export type UpdateAccountActivitySignalMutation = {
  updateAccountActivitySignal?:  {
    __typename: "AccountActivitySignal",
    id: string,
    spaceID: string,
    groups?: Array< string | null > | null,
    lastActivityAt: string,
    friendlyUsername?: string | null,
    lastActivityObjectType?: ActivityObjectType | null,
    lastActivityObjectID?: string | null,
    sourceIp?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteAccountActivitySignalMutationVariables = {
  input: DeleteAccountActivitySignalInput,
  condition?: ModelAccountActivitySignalConditionInput | null,
};

export type DeleteAccountActivitySignalMutation = {
  deleteAccountActivitySignal?:  {
    __typename: "AccountActivitySignal",
    id: string,
    spaceID: string,
    groups?: Array< string | null > | null,
    lastActivityAt: string,
    friendlyUsername?: string | null,
    lastActivityObjectType?: ActivityObjectType | null,
    lastActivityObjectID?: string | null,
    sourceIp?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateContactMutationVariables = {
  email: string,
  givenName?: string | null,
  familyName?: string | null,
};

export type CreateContactMutation = {
  createContact: string,
};

export type EmailWelcomeMessageMutationVariables = {
  recipient: string,
  givenName?: string | null,
  familyName?: string | null,
};

export type EmailWelcomeMessageMutation = {
  emailWelcomeMessage: string,
};

export type EmailHealthProWelcomeMessageMutationVariables = {
  recipient: string,
  givenName?: string | null,
  familyName?: string | null,
};

export type EmailHealthProWelcomeMessageMutation = {
  emailHealthProWelcomeMessage: string,
};

export type EmailWelcomeToSpaceMessageMutationVariables = {
  recipient: string,
  givenName?: string | null,
  familyName?: string | null,
  spaceTitle: string,
  spaceSubscribeUrl: string,
};

export type EmailWelcomeToSpaceMessageMutation = {
  emailWelcomeToSpaceMessage: string,
};

export type GetIngredientTreeNodesMutationVariables = {
  locale?: string | null,
};

export type GetIngredientTreeNodesMutation = {
  getIngredientTreeNodes?:  Array< {
    __typename: "IngredientTreeNode",
    ingredientName: string,
    parentIngredientName?: string | null,
  } > | null,
};

export type GetZapierRestHookQueryVariables = {
  id: string,
};

export type GetZapierRestHookQuery = {
  getZapierRestHook?:  {
    __typename: "ZapierRestHook",
    spaceID: string,
    hookUrl: string,
    hookKey: string,
    enabled: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListZapierRestHooksQueryVariables = {
  filter?: ModelZapierRestHookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListZapierRestHooksQuery = {
  listZapierRestHooks?:  {
    __typename: "ModelZapierRestHookConnection",
    items:  Array< {
      __typename: "ZapierRestHook",
      spaceID: string,
      hookUrl: string,
      hookKey: string,
      enabled: boolean,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserProfileQueryVariables = {
  id: string,
};

export type GetUserProfileQuery = {
  getUserProfile?:  {
    __typename: "UserProfile",
    id: string,
    showOnboarding?: boolean | null,
    consentToProductComms?: boolean | null,
    plannerViewWeekStartDay?: number | null,
    geoRegion?: string | null,
    countryCode?: string | null,
    mealPlanReminderSchedule?: string | null,
    showMealDetailGuide?: boolean | null,
    recentlyUsedTags?: Array< string | null > | null,
    activitySummary?:  {
      __typename: "UserActivitySummary",
      numMealsViewed?: number | null,
      numMealsViewedMobile?: number | null,
      numShoppingListsViewed?: number | null,
      numShoppingListsViewedMobile?: number | null,
      numMealsEdited?: number | null,
      numNutritionClicks?: number | null,
      numMealsPlanned?: number | null,
      numMealsAdded?: number | null,
      numMealsImported?: number | null,
      numMealsAddedManually?: number | null,
      numPlansViewed?: number | null,
      numShoppingListsCopiedToClipboard?: number | null,
      numShoppingListsEmailed?: number | null,
      numShoppingListsCreated?: number | null,
      numShops?: number | null,
      numNotesAdded?: number | null,
      numNotesViewed?: number | null,
      numNotesViewedMobile?: number | null,
      numRecipesBoardsCreated?: number | null,
      numRecipesBoardsShared?: number | null,
    } | null,
    enabledFeatures?: Array< string | null > | null,
    requestedFeatures?: Array< string | null > | null,
    seenMessages?: Array< string | null > | null,
    recentlyUsedRecommenderMealTypes?: Array< string > | null,
    recentlyUsedRecommenderCalorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    recentlyUsedSearchCriteria?:  {
      __typename: "PersistedSearchCriteria",
      searchString?: string | null,
      mealTypes?: Array< string > | null,
      cookTimeMin?: number | null,
      cookTimeMax?: number | null,
      excludeIngredients?: Array< string > | null,
      includeIngredients?: Array< string > | null,
      dietaryPreferences?: Array< string > | null,
      perMealNutritionConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      tags?: Array< string > | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListUserProfilesQueryVariables = {
  filter?: ModelUserProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserProfilesQuery = {
  listUserProfiles?:  {
    __typename: "ModelUserProfileConnection",
    items:  Array< {
      __typename: "UserProfile",
      id: string,
      showOnboarding?: boolean | null,
      consentToProductComms?: boolean | null,
      plannerViewWeekStartDay?: number | null,
      geoRegion?: string | null,
      countryCode?: string | null,
      mealPlanReminderSchedule?: string | null,
      showMealDetailGuide?: boolean | null,
      recentlyUsedTags?: Array< string | null > | null,
      activitySummary?:  {
        __typename: "UserActivitySummary",
        numMealsViewed?: number | null,
        numMealsViewedMobile?: number | null,
        numShoppingListsViewed?: number | null,
        numShoppingListsViewedMobile?: number | null,
        numMealsEdited?: number | null,
        numNutritionClicks?: number | null,
        numMealsPlanned?: number | null,
        numMealsAdded?: number | null,
        numMealsImported?: number | null,
        numMealsAddedManually?: number | null,
        numPlansViewed?: number | null,
        numShoppingListsCopiedToClipboard?: number | null,
        numShoppingListsEmailed?: number | null,
        numShoppingListsCreated?: number | null,
        numShops?: number | null,
        numNotesAdded?: number | null,
        numNotesViewed?: number | null,
        numNotesViewedMobile?: number | null,
        numRecipesBoardsCreated?: number | null,
        numRecipesBoardsShared?: number | null,
      } | null,
      enabledFeatures?: Array< string | null > | null,
      requestedFeatures?: Array< string | null > | null,
      seenMessages?: Array< string | null > | null,
      recentlyUsedRecommenderMealTypes?: Array< string > | null,
      recentlyUsedRecommenderCalorieSplits?:  Array< {
        __typename: "CalorieSplit",
        mealType: string,
        fraction: number,
      } > | null,
      recentlyUsedSearchCriteria?:  {
        __typename: "PersistedSearchCriteria",
        searchString?: string | null,
        mealTypes?: Array< string > | null,
        cookTimeMin?: number | null,
        cookTimeMax?: number | null,
        excludeIngredients?: Array< string > | null,
        includeIngredients?: Array< string > | null,
        dietaryPreferences?: Array< string > | null,
        perMealNutritionConstraints?:  Array< {
          __typename: "NutritionMetricConstraint",
          id: string,
          nutritionMetric: string,
          operator: ConstraintOperator,
          value: string,
          units: string,
        } > | null,
        tags?: Array< string > | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetObjectEditEventQueryVariables = {
  id: string,
};

export type GetObjectEditEventQuery = {
  getObjectEditEvent?:  {
    __typename: "ObjectEditEvent",
    id: string,
    objectID: string,
    objectType: EntryType,
    section: string,
    itemID?: string | null,
    oldValue?: string | null,
    createdAt: string,
    groups?: Array< string | null > | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListObjectEditEventsQueryVariables = {
  filter?: ModelObjectEditEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListObjectEditEventsQuery = {
  listObjectEditEvents?:  {
    __typename: "ModelObjectEditEventConnection",
    items:  Array< {
      __typename: "ObjectEditEvent",
      id: string,
      objectID: string,
      objectType: EntryType,
      section: string,
      itemID?: string | null,
      oldValue?: string | null,
      createdAt: string,
      groups?: Array< string | null > | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ObjectEditEventByObjectIDQueryVariables = {
  objectID: string,
  itemIDSectionCreatedAt?: ModelObjectEditEventByObjectIDCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelObjectEditEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ObjectEditEventByObjectIDQuery = {
  objectEditEventByObjectID?:  {
    __typename: "ModelObjectEditEventConnection",
    items:  Array< {
      __typename: "ObjectEditEvent",
      id: string,
      objectID: string,
      objectType: EntryType,
      section: string,
      itemID?: string | null,
      oldValue?: string | null,
      createdAt: string,
      groups?: Array< string | null > | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMealQueryVariables = {
  id: string,
};

export type GetMealQuery = {
  getMeal?:  {
    __typename: "Meal",
    id: string,
    schemaVersion: number,
    smorgBoardID: string,
    recipes:  Array< {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    } >,
    addons?:  Array< {
      __typename: "MealAddOn",
      name: string,
    } > | null,
    additionallyReferredToBy?:  Array< {
      __typename: "ReferenceType",
      sourceType: ReferenceSourceType,
      sourceID: string,
    } > | null,
    origin?:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    } | null,
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    spaceMembershipID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListMealsQueryVariables = {
  filter?: ModelMealFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMealsQuery = {
  listMeals?:  {
    __typename: "ModelMealConnection",
    items:  Array< {
      __typename: "Meal",
      id: string,
      schemaVersion: number,
      smorgBoardID: string,
      recipes:  Array< {
        __typename: "Recipe",
        title: string,
        shortDescription?: string | null,
        imageUrl?: string | null,
        recipeUrl?: string | null,
        publisher?: string | null,
        chef?: string | null,
        mealiqId?: string | null,
        nutrition?:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        } | null,
        mealTypes?: Array< string > | null,
        tags?: Array< string > | null,
        supportedDiets?: Array< string > | null,
        ingredients:  Array< {
          __typename: "RecipeIngredient",
          id?: string | null,
          fullText: string,
          structuredIngredient?:  {
            __typename: "StructuredIngredient",
            name: string,
            quantity?: number | null,
            unitOfMeasure?: string | null,
            normalisedQuantity?: number | null,
            linkedIngredient?:  {
              __typename: "LinkedIngredientDetails",
              status: boolean,
              linkedIngredientName: string,
            } | null,
            resolvedNutrition?: boolean | null,
            resolvedNutritionError?: string | null,
          } | null,
          tokFullText?: string | null,
          tokens?:  Array< {
            __typename: "IngredientToken",
            fromChar: number,
            toChar: number,
            type?: string | null,
            label?: string | null,
          } | null > | null,
          scalingRules?: string | null,
          checkedInIngredientsList?: boolean | null,
        } >,
        preparations:  Array< {
          __typename: "RecipePreparation",
          id?: string | null,
          fullText: string,
        } >,
        utensils?:  Array< {
          __typename: "RecipeUtensil",
          id?: string | null,
          fullText: string,
        } > | null,
        servings?: number | null,
        cookTime?: number | null,
        prepTime?: number | null,
        totalTime?: number | null,
        recipeNotes?: string | null,
      } >,
      addons?:  Array< {
        __typename: "MealAddOn",
        name: string,
      } > | null,
      additionallyReferredToBy?:  Array< {
        __typename: "ReferenceType",
        sourceType: ReferenceSourceType,
        sourceID: string,
      } > | null,
      origin?:  {
        __typename: "Origin",
        originObjectType: OriginObjectType,
        originObjectID: string,
      } | null,
      derivedNutrition?:  {
        __typename: "DerivedNutrition",
        totalNutritionPerServing:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        ingredientNutrition:  Array< {
          __typename: "DerivedIngredientNutrition",
          ingredientID?: string | null,
          nutrition:  {
            __typename: "Nutrition",
            calories?: number | null,
            protein?: number | null,
            carbohydrate?: number | null,
            fat?: number | null,
            fibre?: number | null,
            sugar?: number | null,
            saturates?: number | null,
            salt?: number | null,
            veg_weight?: number | null,
            cholesterol?: number | null,
            calcium?: number | null,
            iron?: number | null,
            magnesium?: number | null,
            potassium?: number | null,
            vitamin_a?: number | null,
            vitamin_b1?: number | null,
            vitamin_b2?: number | null,
            vitamin_b3?: number | null,
            vitamin_b5?: number | null,
            vitamin_b6?: number | null,
            vitamin_b12?: number | null,
            vitamin_c?: number | null,
            vitamin_d?: number | null,
            vitamin_e?: number | null,
            vitamin_k?: number | null,
            phosphorous?: number | null,
            zinc?: number | null,
            copper?: number | null,
            manganese?: number | null,
            selenium?: number | null,
            folate?: number | null,
            omega3_dha?: number | null,
            omega3_dpa?: number | null,
            omega3_epa?: number | null,
            sucrose?: number | null,
            glucose?: number | null,
            fructose?: number | null,
            lactose?: number | null,
            maltose?: number | null,
          },
          resolvedNutrition?: boolean | null,
          error?: string | null,
          resolvedIngredientWeight?: number | null,
        } >,
        foodAllergens?: Array< string > | null,
        foodPreferences?: Array< string > | null,
        status?: boolean | null,
        macroRatio?:  {
          __typename: "MacroRatio",
          carbohydrate?: number | null,
          protein?: number | null,
          fat?: number | null,
        } | null,
      } | null,
      spaceMembershipID?: string | null,
      groups?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MealBySmorgBoardQueryVariables = {
  smorgBoardID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMealFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MealBySmorgBoardQuery = {
  mealBySmorgBoard?:  {
    __typename: "ModelMealConnection",
    items:  Array< {
      __typename: "Meal",
      id: string,
      schemaVersion: number,
      smorgBoardID: string,
      recipes:  Array< {
        __typename: "Recipe",
        title: string,
        shortDescription?: string | null,
        imageUrl?: string | null,
        recipeUrl?: string | null,
        publisher?: string | null,
        chef?: string | null,
        mealiqId?: string | null,
        nutrition?:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        } | null,
        mealTypes?: Array< string > | null,
        tags?: Array< string > | null,
        supportedDiets?: Array< string > | null,
        ingredients:  Array< {
          __typename: "RecipeIngredient",
          id?: string | null,
          fullText: string,
          structuredIngredient?:  {
            __typename: "StructuredIngredient",
            name: string,
            quantity?: number | null,
            unitOfMeasure?: string | null,
            normalisedQuantity?: number | null,
            linkedIngredient?:  {
              __typename: "LinkedIngredientDetails",
              status: boolean,
              linkedIngredientName: string,
            } | null,
            resolvedNutrition?: boolean | null,
            resolvedNutritionError?: string | null,
          } | null,
          tokFullText?: string | null,
          tokens?:  Array< {
            __typename: "IngredientToken",
            fromChar: number,
            toChar: number,
            type?: string | null,
            label?: string | null,
          } | null > | null,
          scalingRules?: string | null,
          checkedInIngredientsList?: boolean | null,
        } >,
        preparations:  Array< {
          __typename: "RecipePreparation",
          id?: string | null,
          fullText: string,
        } >,
        utensils?:  Array< {
          __typename: "RecipeUtensil",
          id?: string | null,
          fullText: string,
        } > | null,
        servings?: number | null,
        cookTime?: number | null,
        prepTime?: number | null,
        totalTime?: number | null,
        recipeNotes?: string | null,
      } >,
      addons?:  Array< {
        __typename: "MealAddOn",
        name: string,
      } > | null,
      additionallyReferredToBy?:  Array< {
        __typename: "ReferenceType",
        sourceType: ReferenceSourceType,
        sourceID: string,
      } > | null,
      origin?:  {
        __typename: "Origin",
        originObjectType: OriginObjectType,
        originObjectID: string,
      } | null,
      derivedNutrition?:  {
        __typename: "DerivedNutrition",
        totalNutritionPerServing:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        ingredientNutrition:  Array< {
          __typename: "DerivedIngredientNutrition",
          ingredientID?: string | null,
          nutrition:  {
            __typename: "Nutrition",
            calories?: number | null,
            protein?: number | null,
            carbohydrate?: number | null,
            fat?: number | null,
            fibre?: number | null,
            sugar?: number | null,
            saturates?: number | null,
            salt?: number | null,
            veg_weight?: number | null,
            cholesterol?: number | null,
            calcium?: number | null,
            iron?: number | null,
            magnesium?: number | null,
            potassium?: number | null,
            vitamin_a?: number | null,
            vitamin_b1?: number | null,
            vitamin_b2?: number | null,
            vitamin_b3?: number | null,
            vitamin_b5?: number | null,
            vitamin_b6?: number | null,
            vitamin_b12?: number | null,
            vitamin_c?: number | null,
            vitamin_d?: number | null,
            vitamin_e?: number | null,
            vitamin_k?: number | null,
            phosphorous?: number | null,
            zinc?: number | null,
            copper?: number | null,
            manganese?: number | null,
            selenium?: number | null,
            folate?: number | null,
            omega3_dha?: number | null,
            omega3_dpa?: number | null,
            omega3_epa?: number | null,
            sucrose?: number | null,
            glucose?: number | null,
            fructose?: number | null,
            lactose?: number | null,
            maltose?: number | null,
          },
          resolvedNutrition?: boolean | null,
          error?: string | null,
          resolvedIngredientWeight?: number | null,
        } >,
        foodAllergens?: Array< string > | null,
        foodPreferences?: Array< string > | null,
        status?: boolean | null,
        macroRatio?:  {
          __typename: "MacroRatio",
          carbohydrate?: number | null,
          protein?: number | null,
          fat?: number | null,
        } | null,
      } | null,
      spaceMembershipID?: string | null,
      groups?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MealBySpaceMembershipQueryVariables = {
  spaceMembershipID: string,
  smorgBoardID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMealFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MealBySpaceMembershipQuery = {
  mealBySpaceMembership?:  {
    __typename: "ModelMealConnection",
    items:  Array< {
      __typename: "Meal",
      id: string,
      schemaVersion: number,
      smorgBoardID: string,
      recipes:  Array< {
        __typename: "Recipe",
        title: string,
        shortDescription?: string | null,
        imageUrl?: string | null,
        recipeUrl?: string | null,
        publisher?: string | null,
        chef?: string | null,
        mealiqId?: string | null,
        nutrition?:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        } | null,
        mealTypes?: Array< string > | null,
        tags?: Array< string > | null,
        supportedDiets?: Array< string > | null,
        ingredients:  Array< {
          __typename: "RecipeIngredient",
          id?: string | null,
          fullText: string,
          structuredIngredient?:  {
            __typename: "StructuredIngredient",
            name: string,
            quantity?: number | null,
            unitOfMeasure?: string | null,
            normalisedQuantity?: number | null,
            linkedIngredient?:  {
              __typename: "LinkedIngredientDetails",
              status: boolean,
              linkedIngredientName: string,
            } | null,
            resolvedNutrition?: boolean | null,
            resolvedNutritionError?: string | null,
          } | null,
          tokFullText?: string | null,
          tokens?:  Array< {
            __typename: "IngredientToken",
            fromChar: number,
            toChar: number,
            type?: string | null,
            label?: string | null,
          } | null > | null,
          scalingRules?: string | null,
          checkedInIngredientsList?: boolean | null,
        } >,
        preparations:  Array< {
          __typename: "RecipePreparation",
          id?: string | null,
          fullText: string,
        } >,
        utensils?:  Array< {
          __typename: "RecipeUtensil",
          id?: string | null,
          fullText: string,
        } > | null,
        servings?: number | null,
        cookTime?: number | null,
        prepTime?: number | null,
        totalTime?: number | null,
        recipeNotes?: string | null,
      } >,
      addons?:  Array< {
        __typename: "MealAddOn",
        name: string,
      } > | null,
      additionallyReferredToBy?:  Array< {
        __typename: "ReferenceType",
        sourceType: ReferenceSourceType,
        sourceID: string,
      } > | null,
      origin?:  {
        __typename: "Origin",
        originObjectType: OriginObjectType,
        originObjectID: string,
      } | null,
      derivedNutrition?:  {
        __typename: "DerivedNutrition",
        totalNutritionPerServing:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        ingredientNutrition:  Array< {
          __typename: "DerivedIngredientNutrition",
          ingredientID?: string | null,
          nutrition:  {
            __typename: "Nutrition",
            calories?: number | null,
            protein?: number | null,
            carbohydrate?: number | null,
            fat?: number | null,
            fibre?: number | null,
            sugar?: number | null,
            saturates?: number | null,
            salt?: number | null,
            veg_weight?: number | null,
            cholesterol?: number | null,
            calcium?: number | null,
            iron?: number | null,
            magnesium?: number | null,
            potassium?: number | null,
            vitamin_a?: number | null,
            vitamin_b1?: number | null,
            vitamin_b2?: number | null,
            vitamin_b3?: number | null,
            vitamin_b5?: number | null,
            vitamin_b6?: number | null,
            vitamin_b12?: number | null,
            vitamin_c?: number | null,
            vitamin_d?: number | null,
            vitamin_e?: number | null,
            vitamin_k?: number | null,
            phosphorous?: number | null,
            zinc?: number | null,
            copper?: number | null,
            manganese?: number | null,
            selenium?: number | null,
            folate?: number | null,
            omega3_dha?: number | null,
            omega3_dpa?: number | null,
            omega3_epa?: number | null,
            sucrose?: number | null,
            glucose?: number | null,
            fructose?: number | null,
            lactose?: number | null,
            maltose?: number | null,
          },
          resolvedNutrition?: boolean | null,
          error?: string | null,
          resolvedIngredientWeight?: number | null,
        } >,
        foodAllergens?: Array< string > | null,
        foodPreferences?: Array< string > | null,
        status?: boolean | null,
        macroRatio?:  {
          __typename: "MacroRatio",
          carbohydrate?: number | null,
          protein?: number | null,
          fat?: number | null,
        } | null,
      } | null,
      spaceMembershipID?: string | null,
      groups?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSmorgBoardQueryVariables = {
  id: string,
};

export type GetSmorgBoardQuery = {
  getSmorgBoard?:  {
    __typename: "SmorgBoard",
    id: string,
    schemaVersion: number,
    title: string,
    shortDescription?: string | null,
    coverImageUrl?: string | null,
    menus:  Array< {
      __typename: "Menu",
      id: string,
      title: string,
      mealIDs: Array< string >,
    } >,
    shareRecords?:  Array< {
      __typename: "RecipesBoardShareRecord",
      sharedBoardID: string,
      version: string,
      updatedOn: string,
    } | null > | null,
    spaceMembershipID?: string | null,
    embeddedInContainerType?: ContainerType | null,
    embeddedInContainerID?: string | null,
    groups?: Array< string | null > | null,
    availableInMembershipTierIDs?: Array< string > | null,
    isMyFavouritesBoard?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListSmorgBoardsQueryVariables = {
  filter?: ModelSmorgBoardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSmorgBoardsQuery = {
  listSmorgBoards?:  {
    __typename: "ModelSmorgBoardConnection",
    items:  Array< {
      __typename: "SmorgBoard",
      id: string,
      schemaVersion: number,
      title: string,
      shortDescription?: string | null,
      coverImageUrl?: string | null,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      shareRecords?:  Array< {
        __typename: "RecipesBoardShareRecord",
        sharedBoardID: string,
        version: string,
        updatedOn: string,
      } | null > | null,
      spaceMembershipID?: string | null,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
      groups?: Array< string | null > | null,
      availableInMembershipTierIDs?: Array< string > | null,
      isMyFavouritesBoard?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SmorgBoardBySpaceMembershipQueryVariables = {
  spaceMembershipID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSmorgBoardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SmorgBoardBySpaceMembershipQuery = {
  smorgBoardBySpaceMembership?:  {
    __typename: "ModelSmorgBoardConnection",
    items:  Array< {
      __typename: "SmorgBoard",
      id: string,
      schemaVersion: number,
      title: string,
      shortDescription?: string | null,
      coverImageUrl?: string | null,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      shareRecords?:  Array< {
        __typename: "RecipesBoardShareRecord",
        sharedBoardID: string,
        version: string,
        updatedOn: string,
      } | null > | null,
      spaceMembershipID?: string | null,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
      groups?: Array< string | null > | null,
      availableInMembershipTierIDs?: Array< string > | null,
      isMyFavouritesBoard?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSharedMealQueryVariables = {
  id: string,
};

export type GetSharedMealQuery = {
  getSharedMeal?:  {
    __typename: "SharedMeal",
    id: string,
    schemaVersion: number,
    sharedBoardID: string,
    recipes:  Array< {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    } >,
    origin?:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    } | null,
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSharedMealsQueryVariables = {
  filter?: ModelSharedMealFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSharedMealsQuery = {
  listSharedMeals?:  {
    __typename: "ModelSharedMealConnection",
    items:  Array< {
      __typename: "SharedMeal",
      id: string,
      schemaVersion: number,
      sharedBoardID: string,
      recipes:  Array< {
        __typename: "Recipe",
        title: string,
        shortDescription?: string | null,
        imageUrl?: string | null,
        recipeUrl?: string | null,
        publisher?: string | null,
        chef?: string | null,
        mealiqId?: string | null,
        nutrition?:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        } | null,
        mealTypes?: Array< string > | null,
        tags?: Array< string > | null,
        supportedDiets?: Array< string > | null,
        ingredients:  Array< {
          __typename: "RecipeIngredient",
          id?: string | null,
          fullText: string,
          structuredIngredient?:  {
            __typename: "StructuredIngredient",
            name: string,
            quantity?: number | null,
            unitOfMeasure?: string | null,
            normalisedQuantity?: number | null,
            linkedIngredient?:  {
              __typename: "LinkedIngredientDetails",
              status: boolean,
              linkedIngredientName: string,
            } | null,
            resolvedNutrition?: boolean | null,
            resolvedNutritionError?: string | null,
          } | null,
          tokFullText?: string | null,
          tokens?:  Array< {
            __typename: "IngredientToken",
            fromChar: number,
            toChar: number,
            type?: string | null,
            label?: string | null,
          } | null > | null,
          scalingRules?: string | null,
          checkedInIngredientsList?: boolean | null,
        } >,
        preparations:  Array< {
          __typename: "RecipePreparation",
          id?: string | null,
          fullText: string,
        } >,
        utensils?:  Array< {
          __typename: "RecipeUtensil",
          id?: string | null,
          fullText: string,
        } > | null,
        servings?: number | null,
        cookTime?: number | null,
        prepTime?: number | null,
        totalTime?: number | null,
        recipeNotes?: string | null,
      } >,
      origin?:  {
        __typename: "Origin",
        originObjectType: OriginObjectType,
        originObjectID: string,
      } | null,
      derivedNutrition?:  {
        __typename: "DerivedNutrition",
        totalNutritionPerServing:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        ingredientNutrition:  Array< {
          __typename: "DerivedIngredientNutrition",
          ingredientID?: string | null,
          nutrition:  {
            __typename: "Nutrition",
            calories?: number | null,
            protein?: number | null,
            carbohydrate?: number | null,
            fat?: number | null,
            fibre?: number | null,
            sugar?: number | null,
            saturates?: number | null,
            salt?: number | null,
            veg_weight?: number | null,
            cholesterol?: number | null,
            calcium?: number | null,
            iron?: number | null,
            magnesium?: number | null,
            potassium?: number | null,
            vitamin_a?: number | null,
            vitamin_b1?: number | null,
            vitamin_b2?: number | null,
            vitamin_b3?: number | null,
            vitamin_b5?: number | null,
            vitamin_b6?: number | null,
            vitamin_b12?: number | null,
            vitamin_c?: number | null,
            vitamin_d?: number | null,
            vitamin_e?: number | null,
            vitamin_k?: number | null,
            phosphorous?: number | null,
            zinc?: number | null,
            copper?: number | null,
            manganese?: number | null,
            selenium?: number | null,
            folate?: number | null,
            omega3_dha?: number | null,
            omega3_dpa?: number | null,
            omega3_epa?: number | null,
            sucrose?: number | null,
            glucose?: number | null,
            fructose?: number | null,
            lactose?: number | null,
            maltose?: number | null,
          },
          resolvedNutrition?: boolean | null,
          error?: string | null,
          resolvedIngredientWeight?: number | null,
        } >,
        foodAllergens?: Array< string > | null,
        foodPreferences?: Array< string > | null,
        status?: boolean | null,
        macroRatio?:  {
          __typename: "MacroRatio",
          carbohydrate?: number | null,
          protein?: number | null,
          fat?: number | null,
        } | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SharedMealBySharedBoardQueryVariables = {
  sharedBoardID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSharedMealFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SharedMealBySharedBoardQuery = {
  sharedMealBySharedBoard?:  {
    __typename: "ModelSharedMealConnection",
    items:  Array< {
      __typename: "SharedMeal",
      id: string,
      schemaVersion: number,
      sharedBoardID: string,
      recipes:  Array< {
        __typename: "Recipe",
        title: string,
        shortDescription?: string | null,
        imageUrl?: string | null,
        recipeUrl?: string | null,
        publisher?: string | null,
        chef?: string | null,
        mealiqId?: string | null,
        nutrition?:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        } | null,
        mealTypes?: Array< string > | null,
        tags?: Array< string > | null,
        supportedDiets?: Array< string > | null,
        ingredients:  Array< {
          __typename: "RecipeIngredient",
          id?: string | null,
          fullText: string,
          structuredIngredient?:  {
            __typename: "StructuredIngredient",
            name: string,
            quantity?: number | null,
            unitOfMeasure?: string | null,
            normalisedQuantity?: number | null,
            linkedIngredient?:  {
              __typename: "LinkedIngredientDetails",
              status: boolean,
              linkedIngredientName: string,
            } | null,
            resolvedNutrition?: boolean | null,
            resolvedNutritionError?: string | null,
          } | null,
          tokFullText?: string | null,
          tokens?:  Array< {
            __typename: "IngredientToken",
            fromChar: number,
            toChar: number,
            type?: string | null,
            label?: string | null,
          } | null > | null,
          scalingRules?: string | null,
          checkedInIngredientsList?: boolean | null,
        } >,
        preparations:  Array< {
          __typename: "RecipePreparation",
          id?: string | null,
          fullText: string,
        } >,
        utensils?:  Array< {
          __typename: "RecipeUtensil",
          id?: string | null,
          fullText: string,
        } > | null,
        servings?: number | null,
        cookTime?: number | null,
        prepTime?: number | null,
        totalTime?: number | null,
        recipeNotes?: string | null,
      } >,
      origin?:  {
        __typename: "Origin",
        originObjectType: OriginObjectType,
        originObjectID: string,
      } | null,
      derivedNutrition?:  {
        __typename: "DerivedNutrition",
        totalNutritionPerServing:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        ingredientNutrition:  Array< {
          __typename: "DerivedIngredientNutrition",
          ingredientID?: string | null,
          nutrition:  {
            __typename: "Nutrition",
            calories?: number | null,
            protein?: number | null,
            carbohydrate?: number | null,
            fat?: number | null,
            fibre?: number | null,
            sugar?: number | null,
            saturates?: number | null,
            salt?: number | null,
            veg_weight?: number | null,
            cholesterol?: number | null,
            calcium?: number | null,
            iron?: number | null,
            magnesium?: number | null,
            potassium?: number | null,
            vitamin_a?: number | null,
            vitamin_b1?: number | null,
            vitamin_b2?: number | null,
            vitamin_b3?: number | null,
            vitamin_b5?: number | null,
            vitamin_b6?: number | null,
            vitamin_b12?: number | null,
            vitamin_c?: number | null,
            vitamin_d?: number | null,
            vitamin_e?: number | null,
            vitamin_k?: number | null,
            phosphorous?: number | null,
            zinc?: number | null,
            copper?: number | null,
            manganese?: number | null,
            selenium?: number | null,
            folate?: number | null,
            omega3_dha?: number | null,
            omega3_dpa?: number | null,
            omega3_epa?: number | null,
            sucrose?: number | null,
            glucose?: number | null,
            fructose?: number | null,
            lactose?: number | null,
            maltose?: number | null,
          },
          resolvedNutrition?: boolean | null,
          error?: string | null,
          resolvedIngredientWeight?: number | null,
        } >,
        foodAllergens?: Array< string > | null,
        foodPreferences?: Array< string > | null,
        status?: boolean | null,
        macroRatio?:  {
          __typename: "MacroRatio",
          carbohydrate?: number | null,
          protein?: number | null,
          fat?: number | null,
        } | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSharedBoardQueryVariables = {
  id: string,
};

export type GetSharedBoardQuery = {
  getSharedBoard?:  {
    __typename: "SharedBoard",
    id: string,
    schemaVersion: number,
    version?: string | null,
    accessLevel?: SharedBoardAccessLevel | null,
    title?: string | null,
    shortDescription?: string | null,
    coverImageUrl?: string | null,
    menus:  Array< {
      __typename: "SharedMenu",
      id?: string | null,
      title: string,
      sharedMealIDs: Array< string >,
    } >,
    availableInMembershipTierIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSharedBoardsQueryVariables = {
  filter?: ModelSharedBoardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSharedBoardsQuery = {
  listSharedBoards?:  {
    __typename: "ModelSharedBoardConnection",
    items:  Array< {
      __typename: "SharedBoard",
      id: string,
      schemaVersion: number,
      version?: string | null,
      accessLevel?: SharedBoardAccessLevel | null,
      title?: string | null,
      shortDescription?: string | null,
      coverImageUrl?: string | null,
      menus:  Array< {
        __typename: "SharedMenu",
        id?: string | null,
        title: string,
        sharedMealIDs: Array< string >,
      } >,
      availableInMembershipTierIDs?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNoteQueryVariables = {
  id: string,
};

export type GetNoteQuery = {
  getNote?:  {
    __typename: "Note",
    id: string,
    parentID: string,
    title: string,
    description?: string | null,
    spaceMembershipID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListNotesQueryVariables = {
  filter?: ModelNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotesQuery = {
  listNotes?:  {
    __typename: "ModelNoteConnection",
    items:  Array< {
      __typename: "Note",
      id: string,
      parentID: string,
      title: string,
      description?: string | null,
      spaceMembershipID?: string | null,
      groups?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NoteByParentQueryVariables = {
  parentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NoteByParentQuery = {
  noteByParent?:  {
    __typename: "ModelNoteConnection",
    items:  Array< {
      __typename: "Note",
      id: string,
      parentID: string,
      title: string,
      description?: string | null,
      spaceMembershipID?: string | null,
      groups?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NoteBySpaceMembershipQueryVariables = {
  spaceMembershipID: string,
  parentID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NoteBySpaceMembershipQuery = {
  noteBySpaceMembership?:  {
    __typename: "ModelNoteConnection",
    items:  Array< {
      __typename: "Note",
      id: string,
      parentID: string,
      title: string,
      description?: string | null,
      spaceMembershipID?: string | null,
      groups?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContentEntryQueryVariables = {
  id: string,
};

export type GetContentEntryQuery = {
  getContentEntry?:  {
    __typename: "ContentEntry",
    id: string,
    parentID: string,
    title: string,
    body?: string | null,
    coverImageUrl?: string | null,
    tags: Array< string >,
    appLink?:  {
      __typename: "AppLink",
      appLinkType: AppLinkType,
      appLinkScreen?: AppLinkScreen | null,
      url?: string | null,
      objectID?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListContentEntriesQueryVariables = {
  filter?: ModelContentEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContentEntriesQuery = {
  listContentEntries?:  {
    __typename: "ModelContentEntryConnection",
    items:  Array< {
      __typename: "ContentEntry",
      id: string,
      parentID: string,
      title: string,
      body?: string | null,
      coverImageUrl?: string | null,
      tags: Array< string >,
      appLink?:  {
        __typename: "AppLink",
        appLinkType: AppLinkType,
        appLinkScreen?: AppLinkScreen | null,
        url?: string | null,
        objectID?: string | null,
      } | null,
      groups?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContentEntryByParentQueryVariables = {
  parentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContentEntryByParentQuery = {
  contentEntryByParent?:  {
    __typename: "ModelContentEntryConnection",
    items:  Array< {
      __typename: "ContentEntry",
      id: string,
      parentID: string,
      title: string,
      body?: string | null,
      coverImageUrl?: string | null,
      tags: Array< string >,
      appLink?:  {
        __typename: "AppLink",
        appLinkType: AppLinkType,
        appLinkScreen?: AppLinkScreen | null,
        url?: string | null,
        objectID?: string | null,
      } | null,
      groups?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSharedContentEntryQueryVariables = {
  id: string,
};

export type GetSharedContentEntryQuery = {
  getSharedContentEntry?:  {
    __typename: "SharedContentEntry",
    id: string,
    parentID: string,
    title: string,
    body?: string | null,
    coverImageUrl?: string | null,
    tags: Array< string >,
    origin:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    },
    appLink?:  {
      __typename: "AppLink",
      appLinkType: AppLinkType,
      appLinkScreen?: AppLinkScreen | null,
      url?: string | null,
      objectID?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSharedContentEntriesQueryVariables = {
  filter?: ModelSharedContentEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSharedContentEntriesQuery = {
  listSharedContentEntries?:  {
    __typename: "ModelSharedContentEntryConnection",
    items:  Array< {
      __typename: "SharedContentEntry",
      id: string,
      parentID: string,
      title: string,
      body?: string | null,
      coverImageUrl?: string | null,
      tags: Array< string >,
      origin:  {
        __typename: "Origin",
        originObjectType: OriginObjectType,
        originObjectID: string,
      },
      appLink?:  {
        __typename: "AppLink",
        appLinkType: AppLinkType,
        appLinkScreen?: AppLinkScreen | null,
        url?: string | null,
        objectID?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SharedContentEntryByParentQueryVariables = {
  parentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSharedContentEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SharedContentEntryByParentQuery = {
  sharedContentEntryByParent?:  {
    __typename: "ModelSharedContentEntryConnection",
    items:  Array< {
      __typename: "SharedContentEntry",
      id: string,
      parentID: string,
      title: string,
      body?: string | null,
      coverImageUrl?: string | null,
      tags: Array< string >,
      origin:  {
        __typename: "Origin",
        originObjectType: OriginObjectType,
        originObjectID: string,
      },
      appLink?:  {
        __typename: "AppLink",
        appLinkType: AppLinkType,
        appLinkScreen?: AppLinkScreen | null,
        url?: string | null,
        objectID?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPlannerWeekQueryVariables = {
  id: string,
};

export type GetPlannerWeekQuery = {
  getPlannerWeek?:  {
    __typename: "PlannerWeek",
    id: string,
    schemaVersion: number,
    spaceMembershipID?: string | null,
    startDate: string,
    days:  Array< {
      __typename: "PlannerDay",
      entries:  Array< {
        __typename: "PlannerEntry",
        plannerEntryType?: PlannerEntryType | null,
        mealID?: string | null,
        objectID?: string | null,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListPlannerWeeksQueryVariables = {
  filter?: ModelPlannerWeekFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPlannerWeeksQuery = {
  listPlannerWeeks?:  {
    __typename: "ModelPlannerWeekConnection",
    items:  Array< {
      __typename: "PlannerWeek",
      id: string,
      schemaVersion: number,
      spaceMembershipID?: string | null,
      startDate: string,
      days:  Array< {
        __typename: "PlannerDay",
        entries:  Array< {
          __typename: "PlannerEntry",
          plannerEntryType?: PlannerEntryType | null,
          mealID?: string | null,
          objectID?: string | null,
        } >,
      } >,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PlannerWeekBySpaceMembershipQueryVariables = {
  spaceMembershipID: string,
  startDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlannerWeekFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlannerWeekBySpaceMembershipQuery = {
  plannerWeekBySpaceMembership?:  {
    __typename: "ModelPlannerWeekConnection",
    items:  Array< {
      __typename: "PlannerWeek",
      id: string,
      schemaVersion: number,
      spaceMembershipID?: string | null,
      startDate: string,
      days:  Array< {
        __typename: "PlannerDay",
        entries:  Array< {
          __typename: "PlannerEntry",
          plannerEntryType?: PlannerEntryType | null,
          mealID?: string | null,
          objectID?: string | null,
        } >,
      } >,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PlannerWeekByStartDateQueryVariables = {
  startDate: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlannerWeekFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlannerWeekByStartDateQuery = {
  plannerWeekByStartDate?:  {
    __typename: "ModelPlannerWeekConnection",
    items:  Array< {
      __typename: "PlannerWeek",
      id: string,
      schemaVersion: number,
      spaceMembershipID?: string | null,
      startDate: string,
      days:  Array< {
        __typename: "PlannerDay",
        entries:  Array< {
          __typename: "PlannerEntry",
          plannerEntryType?: PlannerEntryType | null,
          mealID?: string | null,
          objectID?: string | null,
        } >,
      } >,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetShoppingListQueryVariables = {
  id: string,
};

export type GetShoppingListQuery = {
  getShoppingList?:  {
    __typename: "ShoppingList",
    id: string,
    schemaVersion: number,
    spaceMembershipID?: string | null,
    productsBoardID: string,
    title: string,
    shortDescription?: string | null,
    items:  Array< {
      __typename: "ShoppingListItem",
      id: string,
      name: string,
      total?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      quantities?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      linkedIngredient?:  {
        __typename: "LinkedIngredientDetails",
        status: boolean,
        linkedIngredientName: string,
      } | null,
      aisleLocation?: string | null,
      forMealIds?: Array< string > | null,
      checked?: boolean | null,
    } >,
    mealIDs?: Array< string > | null,
    mealImageUrls?: Array< string > | null,
    meals?:  Array< {
      __typename: "ShoppingListMeal",
      mealID?: string | null,
      recipes:  Array< {
        __typename: "Recipe",
        title: string,
        shortDescription?: string | null,
        imageUrl?: string | null,
        recipeUrl?: string | null,
        publisher?: string | null,
        chef?: string | null,
        mealiqId?: string | null,
        nutrition?:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        } | null,
        mealTypes?: Array< string > | null,
        tags?: Array< string > | null,
        supportedDiets?: Array< string > | null,
        ingredients:  Array< {
          __typename: "RecipeIngredient",
          id?: string | null,
          fullText: string,
          structuredIngredient?:  {
            __typename: "StructuredIngredient",
            name: string,
            quantity?: number | null,
            unitOfMeasure?: string | null,
            normalisedQuantity?: number | null,
            linkedIngredient?:  {
              __typename: "LinkedIngredientDetails",
              status: boolean,
              linkedIngredientName: string,
            } | null,
            resolvedNutrition?: boolean | null,
            resolvedNutritionError?: string | null,
          } | null,
          tokFullText?: string | null,
          tokens?:  Array< {
            __typename: "IngredientToken",
            fromChar: number,
            toChar: number,
            type?: string | null,
            label?: string | null,
          } | null > | null,
          scalingRules?: string | null,
          checkedInIngredientsList?: boolean | null,
        } >,
        preparations:  Array< {
          __typename: "RecipePreparation",
          id?: string | null,
          fullText: string,
        } >,
        utensils?:  Array< {
          __typename: "RecipeUtensil",
          id?: string | null,
          fullText: string,
        } > | null,
        servings?: number | null,
        cookTime?: number | null,
        prepTime?: number | null,
        totalTime?: number | null,
        recipeNotes?: string | null,
      } >,
    } > | null,
    activityRecords?:  Array< {
      __typename: "ShoppingListActivity",
      activityType: ShoppingActivityType,
      occurredOn: string,
      vendorName?: string | null,
      transactionID?: string | null,
      shoppingListItemIDs?: Array< string > | null,
    } > | null,
    userItems?:  Array< {
      __typename: "ShoppingListItem",
      id: string,
      name: string,
      total?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      quantities?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      linkedIngredient?:  {
        __typename: "LinkedIngredientDetails",
        status: boolean,
        linkedIngredientName: string,
      } | null,
      aisleLocation?: string | null,
      forMealIds?: Array< string > | null,
      checked?: boolean | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListShoppingListsQueryVariables = {
  filter?: ModelShoppingListFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShoppingListsQuery = {
  listShoppingLists?:  {
    __typename: "ModelShoppingListConnection",
    items:  Array< {
      __typename: "ShoppingList",
      id: string,
      schemaVersion: number,
      spaceMembershipID?: string | null,
      productsBoardID: string,
      title: string,
      shortDescription?: string | null,
      items:  Array< {
        __typename: "ShoppingListItem",
        id: string,
        name: string,
        total?:  Array< {
          __typename: "UnitQuantity",
          quantity?: number | null,
          prettyQuantity?: string | null,
          unitOfMeasure?: string | null,
        } > | null,
        quantities?:  Array< {
          __typename: "UnitQuantity",
          quantity?: number | null,
          prettyQuantity?: string | null,
          unitOfMeasure?: string | null,
        } > | null,
        linkedIngredient?:  {
          __typename: "LinkedIngredientDetails",
          status: boolean,
          linkedIngredientName: string,
        } | null,
        aisleLocation?: string | null,
        forMealIds?: Array< string > | null,
        checked?: boolean | null,
      } >,
      mealIDs?: Array< string > | null,
      mealImageUrls?: Array< string > | null,
      meals?:  Array< {
        __typename: "ShoppingListMeal",
        mealID?: string | null,
        recipes:  Array< {
          __typename: "Recipe",
          title: string,
          shortDescription?: string | null,
          imageUrl?: string | null,
          recipeUrl?: string | null,
          publisher?: string | null,
          chef?: string | null,
          mealiqId?: string | null,
          nutrition?:  {
            __typename: "Nutrition",
            calories?: number | null,
            protein?: number | null,
            carbohydrate?: number | null,
            fat?: number | null,
            fibre?: number | null,
            sugar?: number | null,
            saturates?: number | null,
            salt?: number | null,
            veg_weight?: number | null,
            cholesterol?: number | null,
            calcium?: number | null,
            iron?: number | null,
            magnesium?: number | null,
            potassium?: number | null,
            vitamin_a?: number | null,
            vitamin_b1?: number | null,
            vitamin_b2?: number | null,
            vitamin_b3?: number | null,
            vitamin_b5?: number | null,
            vitamin_b6?: number | null,
            vitamin_b12?: number | null,
            vitamin_c?: number | null,
            vitamin_d?: number | null,
            vitamin_e?: number | null,
            vitamin_k?: number | null,
            phosphorous?: number | null,
            zinc?: number | null,
            copper?: number | null,
            manganese?: number | null,
            selenium?: number | null,
            folate?: number | null,
            omega3_dha?: number | null,
            omega3_dpa?: number | null,
            omega3_epa?: number | null,
            sucrose?: number | null,
            glucose?: number | null,
            fructose?: number | null,
            lactose?: number | null,
            maltose?: number | null,
          } | null,
          mealTypes?: Array< string > | null,
          tags?: Array< string > | null,
          supportedDiets?: Array< string > | null,
          ingredients:  Array< {
            __typename: "RecipeIngredient",
            id?: string | null,
            fullText: string,
            structuredIngredient?:  {
              __typename: "StructuredIngredient",
              name: string,
              quantity?: number | null,
              unitOfMeasure?: string | null,
              normalisedQuantity?: number | null,
              linkedIngredient?:  {
                __typename: "LinkedIngredientDetails",
                status: boolean,
                linkedIngredientName: string,
              } | null,
              resolvedNutrition?: boolean | null,
              resolvedNutritionError?: string | null,
            } | null,
            tokFullText?: string | null,
            tokens?:  Array< {
              __typename: "IngredientToken",
              fromChar: number,
              toChar: number,
              type?: string | null,
              label?: string | null,
            } | null > | null,
            scalingRules?: string | null,
            checkedInIngredientsList?: boolean | null,
          } >,
          preparations:  Array< {
            __typename: "RecipePreparation",
            id?: string | null,
            fullText: string,
          } >,
          utensils?:  Array< {
            __typename: "RecipeUtensil",
            id?: string | null,
            fullText: string,
          } > | null,
          servings?: number | null,
          cookTime?: number | null,
          prepTime?: number | null,
          totalTime?: number | null,
          recipeNotes?: string | null,
        } >,
      } > | null,
      activityRecords?:  Array< {
        __typename: "ShoppingListActivity",
        activityType: ShoppingActivityType,
        occurredOn: string,
        vendorName?: string | null,
        transactionID?: string | null,
        shoppingListItemIDs?: Array< string > | null,
      } > | null,
      userItems?:  Array< {
        __typename: "ShoppingListItem",
        id: string,
        name: string,
        total?:  Array< {
          __typename: "UnitQuantity",
          quantity?: number | null,
          prettyQuantity?: string | null,
          unitOfMeasure?: string | null,
        } > | null,
        quantities?:  Array< {
          __typename: "UnitQuantity",
          quantity?: number | null,
          prettyQuantity?: string | null,
          unitOfMeasure?: string | null,
        } > | null,
        linkedIngredient?:  {
          __typename: "LinkedIngredientDetails",
          status: boolean,
          linkedIngredientName: string,
        } | null,
        aisleLocation?: string | null,
        forMealIds?: Array< string > | null,
        checked?: boolean | null,
      } > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ShoppingListBySpaceMembershipQueryVariables = {
  spaceMembershipID: string,
  productsBoardID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShoppingListFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShoppingListBySpaceMembershipQuery = {
  shoppingListBySpaceMembership?:  {
    __typename: "ModelShoppingListConnection",
    items:  Array< {
      __typename: "ShoppingList",
      id: string,
      schemaVersion: number,
      spaceMembershipID?: string | null,
      productsBoardID: string,
      title: string,
      shortDescription?: string | null,
      items:  Array< {
        __typename: "ShoppingListItem",
        id: string,
        name: string,
        total?:  Array< {
          __typename: "UnitQuantity",
          quantity?: number | null,
          prettyQuantity?: string | null,
          unitOfMeasure?: string | null,
        } > | null,
        quantities?:  Array< {
          __typename: "UnitQuantity",
          quantity?: number | null,
          prettyQuantity?: string | null,
          unitOfMeasure?: string | null,
        } > | null,
        linkedIngredient?:  {
          __typename: "LinkedIngredientDetails",
          status: boolean,
          linkedIngredientName: string,
        } | null,
        aisleLocation?: string | null,
        forMealIds?: Array< string > | null,
        checked?: boolean | null,
      } >,
      mealIDs?: Array< string > | null,
      mealImageUrls?: Array< string > | null,
      meals?:  Array< {
        __typename: "ShoppingListMeal",
        mealID?: string | null,
        recipes:  Array< {
          __typename: "Recipe",
          title: string,
          shortDescription?: string | null,
          imageUrl?: string | null,
          recipeUrl?: string | null,
          publisher?: string | null,
          chef?: string | null,
          mealiqId?: string | null,
          nutrition?:  {
            __typename: "Nutrition",
            calories?: number | null,
            protein?: number | null,
            carbohydrate?: number | null,
            fat?: number | null,
            fibre?: number | null,
            sugar?: number | null,
            saturates?: number | null,
            salt?: number | null,
            veg_weight?: number | null,
            cholesterol?: number | null,
            calcium?: number | null,
            iron?: number | null,
            magnesium?: number | null,
            potassium?: number | null,
            vitamin_a?: number | null,
            vitamin_b1?: number | null,
            vitamin_b2?: number | null,
            vitamin_b3?: number | null,
            vitamin_b5?: number | null,
            vitamin_b6?: number | null,
            vitamin_b12?: number | null,
            vitamin_c?: number | null,
            vitamin_d?: number | null,
            vitamin_e?: number | null,
            vitamin_k?: number | null,
            phosphorous?: number | null,
            zinc?: number | null,
            copper?: number | null,
            manganese?: number | null,
            selenium?: number | null,
            folate?: number | null,
            omega3_dha?: number | null,
            omega3_dpa?: number | null,
            omega3_epa?: number | null,
            sucrose?: number | null,
            glucose?: number | null,
            fructose?: number | null,
            lactose?: number | null,
            maltose?: number | null,
          } | null,
          mealTypes?: Array< string > | null,
          tags?: Array< string > | null,
          supportedDiets?: Array< string > | null,
          ingredients:  Array< {
            __typename: "RecipeIngredient",
            id?: string | null,
            fullText: string,
            structuredIngredient?:  {
              __typename: "StructuredIngredient",
              name: string,
              quantity?: number | null,
              unitOfMeasure?: string | null,
              normalisedQuantity?: number | null,
              linkedIngredient?:  {
                __typename: "LinkedIngredientDetails",
                status: boolean,
                linkedIngredientName: string,
              } | null,
              resolvedNutrition?: boolean | null,
              resolvedNutritionError?: string | null,
            } | null,
            tokFullText?: string | null,
            tokens?:  Array< {
              __typename: "IngredientToken",
              fromChar: number,
              toChar: number,
              type?: string | null,
              label?: string | null,
            } | null > | null,
            scalingRules?: string | null,
            checkedInIngredientsList?: boolean | null,
          } >,
          preparations:  Array< {
            __typename: "RecipePreparation",
            id?: string | null,
            fullText: string,
          } >,
          utensils?:  Array< {
            __typename: "RecipeUtensil",
            id?: string | null,
            fullText: string,
          } > | null,
          servings?: number | null,
          cookTime?: number | null,
          prepTime?: number | null,
          totalTime?: number | null,
          recipeNotes?: string | null,
        } >,
      } > | null,
      activityRecords?:  Array< {
        __typename: "ShoppingListActivity",
        activityType: ShoppingActivityType,
        occurredOn: string,
        vendorName?: string | null,
        transactionID?: string | null,
        shoppingListItemIDs?: Array< string > | null,
      } > | null,
      userItems?:  Array< {
        __typename: "ShoppingListItem",
        id: string,
        name: string,
        total?:  Array< {
          __typename: "UnitQuantity",
          quantity?: number | null,
          prettyQuantity?: string | null,
          unitOfMeasure?: string | null,
        } > | null,
        quantities?:  Array< {
          __typename: "UnitQuantity",
          quantity?: number | null,
          prettyQuantity?: string | null,
          unitOfMeasure?: string | null,
        } > | null,
        linkedIngredient?:  {
          __typename: "LinkedIngredientDetails",
          status: boolean,
          linkedIngredientName: string,
        } | null,
        aisleLocation?: string | null,
        forMealIds?: Array< string > | null,
        checked?: boolean | null,
      } > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ShoppingListByProductsBoardQueryVariables = {
  productsBoardID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShoppingListFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShoppingListByProductsBoardQuery = {
  shoppingListByProductsBoard?:  {
    __typename: "ModelShoppingListConnection",
    items:  Array< {
      __typename: "ShoppingList",
      id: string,
      schemaVersion: number,
      spaceMembershipID?: string | null,
      productsBoardID: string,
      title: string,
      shortDescription?: string | null,
      items:  Array< {
        __typename: "ShoppingListItem",
        id: string,
        name: string,
        total?:  Array< {
          __typename: "UnitQuantity",
          quantity?: number | null,
          prettyQuantity?: string | null,
          unitOfMeasure?: string | null,
        } > | null,
        quantities?:  Array< {
          __typename: "UnitQuantity",
          quantity?: number | null,
          prettyQuantity?: string | null,
          unitOfMeasure?: string | null,
        } > | null,
        linkedIngredient?:  {
          __typename: "LinkedIngredientDetails",
          status: boolean,
          linkedIngredientName: string,
        } | null,
        aisleLocation?: string | null,
        forMealIds?: Array< string > | null,
        checked?: boolean | null,
      } >,
      mealIDs?: Array< string > | null,
      mealImageUrls?: Array< string > | null,
      meals?:  Array< {
        __typename: "ShoppingListMeal",
        mealID?: string | null,
        recipes:  Array< {
          __typename: "Recipe",
          title: string,
          shortDescription?: string | null,
          imageUrl?: string | null,
          recipeUrl?: string | null,
          publisher?: string | null,
          chef?: string | null,
          mealiqId?: string | null,
          nutrition?:  {
            __typename: "Nutrition",
            calories?: number | null,
            protein?: number | null,
            carbohydrate?: number | null,
            fat?: number | null,
            fibre?: number | null,
            sugar?: number | null,
            saturates?: number | null,
            salt?: number | null,
            veg_weight?: number | null,
            cholesterol?: number | null,
            calcium?: number | null,
            iron?: number | null,
            magnesium?: number | null,
            potassium?: number | null,
            vitamin_a?: number | null,
            vitamin_b1?: number | null,
            vitamin_b2?: number | null,
            vitamin_b3?: number | null,
            vitamin_b5?: number | null,
            vitamin_b6?: number | null,
            vitamin_b12?: number | null,
            vitamin_c?: number | null,
            vitamin_d?: number | null,
            vitamin_e?: number | null,
            vitamin_k?: number | null,
            phosphorous?: number | null,
            zinc?: number | null,
            copper?: number | null,
            manganese?: number | null,
            selenium?: number | null,
            folate?: number | null,
            omega3_dha?: number | null,
            omega3_dpa?: number | null,
            omega3_epa?: number | null,
            sucrose?: number | null,
            glucose?: number | null,
            fructose?: number | null,
            lactose?: number | null,
            maltose?: number | null,
          } | null,
          mealTypes?: Array< string > | null,
          tags?: Array< string > | null,
          supportedDiets?: Array< string > | null,
          ingredients:  Array< {
            __typename: "RecipeIngredient",
            id?: string | null,
            fullText: string,
            structuredIngredient?:  {
              __typename: "StructuredIngredient",
              name: string,
              quantity?: number | null,
              unitOfMeasure?: string | null,
              normalisedQuantity?: number | null,
              linkedIngredient?:  {
                __typename: "LinkedIngredientDetails",
                status: boolean,
                linkedIngredientName: string,
              } | null,
              resolvedNutrition?: boolean | null,
              resolvedNutritionError?: string | null,
            } | null,
            tokFullText?: string | null,
            tokens?:  Array< {
              __typename: "IngredientToken",
              fromChar: number,
              toChar: number,
              type?: string | null,
              label?: string | null,
            } | null > | null,
            scalingRules?: string | null,
            checkedInIngredientsList?: boolean | null,
          } >,
          preparations:  Array< {
            __typename: "RecipePreparation",
            id?: string | null,
            fullText: string,
          } >,
          utensils?:  Array< {
            __typename: "RecipeUtensil",
            id?: string | null,
            fullText: string,
          } > | null,
          servings?: number | null,
          cookTime?: number | null,
          prepTime?: number | null,
          totalTime?: number | null,
          recipeNotes?: string | null,
        } >,
      } > | null,
      activityRecords?:  Array< {
        __typename: "ShoppingListActivity",
        activityType: ShoppingActivityType,
        occurredOn: string,
        vendorName?: string | null,
        transactionID?: string | null,
        shoppingListItemIDs?: Array< string > | null,
      } > | null,
      userItems?:  Array< {
        __typename: "ShoppingListItem",
        id: string,
        name: string,
        total?:  Array< {
          __typename: "UnitQuantity",
          quantity?: number | null,
          prettyQuantity?: string | null,
          unitOfMeasure?: string | null,
        } > | null,
        quantities?:  Array< {
          __typename: "UnitQuantity",
          quantity?: number | null,
          prettyQuantity?: string | null,
          unitOfMeasure?: string | null,
        } > | null,
        linkedIngredient?:  {
          __typename: "LinkedIngredientDetails",
          status: boolean,
          linkedIngredientName: string,
        } | null,
        aisleLocation?: string | null,
        forMealIds?: Array< string > | null,
        checked?: boolean | null,
      } > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductsBoardQueryVariables = {
  id: string,
};

export type GetProductsBoardQuery = {
  getProductsBoard?:  {
    __typename: "ProductsBoard",
    id: string,
    schemaVersion: number,
    title: string,
    spaceMembershipID?: string | null,
    shoppingListGroups:  Array< {
      __typename: "ShoppingListGroup",
      id: string,
      title: string,
      shoppingListIDs?: Array< string > | null,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListProductsBoardsQueryVariables = {
  filter?: ModelProductsBoardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsBoardsQuery = {
  listProductsBoards?:  {
    __typename: "ModelProductsBoardConnection",
    items:  Array< {
      __typename: "ProductsBoard",
      id: string,
      schemaVersion: number,
      title: string,
      spaceMembershipID?: string | null,
      shoppingListGroups:  Array< {
        __typename: "ShoppingListGroup",
        id: string,
        title: string,
        shoppingListIDs?: Array< string > | null,
      } >,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductsBoardBySpaceMembershipQueryVariables = {
  spaceMembershipID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductsBoardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductsBoardBySpaceMembershipQuery = {
  productsBoardBySpaceMembership?:  {
    __typename: "ModelProductsBoardConnection",
    items:  Array< {
      __typename: "ProductsBoard",
      id: string,
      schemaVersion: number,
      title: string,
      spaceMembershipID?: string | null,
      shoppingListGroups:  Array< {
        __typename: "ShoppingListGroup",
        id: string,
        title: string,
        shoppingListIDs?: Array< string > | null,
      } >,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGlobalTagQueryVariables = {
  id: string,
  tag: string,
};

export type GetGlobalTagQuery = {
  getGlobalTag?:  {
    __typename: "GlobalTag",
    id: string,
    tag: string,
    count: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGlobalTagsQueryVariables = {
  id?: string | null,
  tag?: ModelStringKeyConditionInput | null,
  filter?: ModelGlobalTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListGlobalTagsQuery = {
  listGlobalTags?:  {
    __typename: "ModelGlobalTagConnection",
    items:  Array< {
      __typename: "GlobalTag",
      id: string,
      tag: string,
      count: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIngredientEditEventQueryVariables = {
  id: string,
};

export type GetIngredientEditEventQuery = {
  getIngredientEditEvent?:  {
    __typename: "IngredientEditEvent",
    mealID: string,
    recipeID: string,
    editMethod?: EditMethod | null,
    ingredientID: string,
    fullTextBefore: string,
    fullTextAfter: string,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListIngredientEditEventsQueryVariables = {
  filter?: ModelIngredientEditEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIngredientEditEventsQuery = {
  listIngredientEditEvents?:  {
    __typename: "ModelIngredientEditEventConnection",
    items:  Array< {
      __typename: "IngredientEditEvent",
      mealID: string,
      recipeID: string,
      editMethod?: EditMethod | null,
      ingredientID: string,
      fullTextBefore: string,
      fullTextAfter: string,
      groups?: Array< string | null > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIngredientEditSuggestionQueryVariables = {
  id: string,
  inputFullText: string,
};

export type GetIngredientEditSuggestionQuery = {
  getIngredientEditSuggestion?:  {
    __typename: "IngredientEditSuggestion",
    id: string,
    inputFullText: string,
    suggestion: string,
    weight: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListIngredientEditSuggestionsQueryVariables = {
  id?: string | null,
  inputFullText?: ModelStringKeyConditionInput | null,
  filter?: ModelIngredientEditSuggestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListIngredientEditSuggestionsQuery = {
  listIngredientEditSuggestions?:  {
    __typename: "ModelIngredientEditSuggestionConnection",
    items:  Array< {
      __typename: "IngredientEditSuggestion",
      id: string,
      inputFullText: string,
      suggestion: string,
      weight: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type IngredientEditSuggestionByInputFullTextQueryVariables = {
  inputFullText: string,
  weight?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIngredientEditSuggestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type IngredientEditSuggestionByInputFullTextQuery = {
  ingredientEditSuggestionByInputFullText?:  {
    __typename: "ModelIngredientEditSuggestionConnection",
    items:  Array< {
      __typename: "IngredientEditSuggestion",
      id: string,
      inputFullText: string,
      suggestion: string,
      weight: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIngredientEditSuggestionByIngredientIDQueryVariables = {
  id: string,
  ingredientID: string,
};

export type GetIngredientEditSuggestionByIngredientIDQuery = {
  getIngredientEditSuggestionByIngredientID?:  {
    __typename: "IngredientEditSuggestionByIngredientID",
    id: string,
    ingredientID: string,
    suggestion: string,
    weight: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListIngredientEditSuggestionByIngredientIDSQueryVariables = {
  id?: string | null,
  ingredientID?: ModelStringKeyConditionInput | null,
  filter?: ModelIngredientEditSuggestionByIngredientIDFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListIngredientEditSuggestionByIngredientIDSQuery = {
  listIngredientEditSuggestionByIngredientIDS?:  {
    __typename: "ModelIngredientEditSuggestionByIngredientIDConnection",
    items:  Array< {
      __typename: "IngredientEditSuggestionByIngredientID",
      id: string,
      ingredientID: string,
      suggestion: string,
      weight: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type IngredientEditSuggestionByIngredientIDQueryVariables = {
  ingredientID: string,
  weight?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIngredientEditSuggestionByIngredientIDFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type IngredientEditSuggestionByIngredientIDQuery = {
  ingredientEditSuggestionByIngredientID?:  {
    __typename: "ModelIngredientEditSuggestionByIngredientIDConnection",
    items:  Array< {
      __typename: "IngredientEditSuggestionByIngredientID",
      id: string,
      ingredientID: string,
      suggestion: string,
      weight: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProgrammeQueryVariables = {
  id: string,
};

export type GetProgrammeQuery = {
  getProgramme?:  {
    __typename: "Programme",
    id: string,
    title: string,
    shortDescription: string,
    description?: string | null,
    coverImageUrl?: string | null,
    plans:  Array< {
      __typename: "ProgrammePlan",
      id: string,
      title: string,
      shortDescription: string,
      description?: string | null,
      coverImageUrl?: string | null,
      days:  Array< {
        __typename: "ProgrammePlanDay",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "ProgrammeEntry",
          id: string,
          programmeEntryType: EntryType,
          objectID: string,
        } >,
      } >,
      nutritionConstraints?:  {
        __typename: "NutritionConstraints",
        nutritionMetricConstraints?:  Array< {
          __typename: "NutritionMetricConstraint",
          id: string,
          nutritionMetric: string,
          operator: ConstraintOperator,
          value: string,
          units: string,
        } > | null,
        excludeIngredients?: Array< string > | null,
        foodPreferences?: Array< FoodPreference > | null,
      } | null,
      showNutritionToUsers?: boolean | null,
    } >,
    locales?: Array< string > | null,
    mealTypes?: Array< string > | null,
    calorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    nutritionConstraints?:  {
      __typename: "NutritionConstraints",
      nutritionMetricConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      excludeIngredients?: Array< string > | null,
      foodPreferences?: Array< FoodPreference > | null,
    } | null,
    showNutritionToUsers?: boolean | null,
    copyMealsExactly?: boolean | null,
    personalisedMealScaling?: boolean | null,
    recipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    databaseRecipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    recipesBoardIDs?: Array< string > | null,
    onboardingConfiguration?:  {
      __typename: "ProgrammeOnboardingConfiguration",
      questionIDs: Array< string >,
    } | null,
    categoryTags?: Array< string > | null,
    shareRecords?:  Array< {
      __typename: "ProgrammeShareRecord",
      sharedProgrammeID: string,
      version: string,
      updatedOn: string,
    } | null > | null,
    availableInMembershipTierIDs?: Array< string > | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListProgrammesQueryVariables = {
  filter?: ModelProgrammeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProgrammesQuery = {
  listProgrammes?:  {
    __typename: "ModelProgrammeConnection",
    items:  Array< {
      __typename: "Programme",
      id: string,
      title: string,
      shortDescription: string,
      description?: string | null,
      coverImageUrl?: string | null,
      plans:  Array< {
        __typename: "ProgrammePlan",
        id: string,
        title: string,
        shortDescription: string,
        description?: string | null,
        coverImageUrl?: string | null,
        days:  Array< {
          __typename: "ProgrammePlanDay",
          id: string,
          title: string,
          entries:  Array< {
            __typename: "ProgrammeEntry",
            id: string,
            programmeEntryType: EntryType,
            objectID: string,
          } >,
        } >,
        nutritionConstraints?:  {
          __typename: "NutritionConstraints",
          nutritionMetricConstraints?:  Array< {
            __typename: "NutritionMetricConstraint",
            id: string,
            nutritionMetric: string,
            operator: ConstraintOperator,
            value: string,
            units: string,
          } > | null,
          excludeIngredients?: Array< string > | null,
          foodPreferences?: Array< FoodPreference > | null,
        } | null,
        showNutritionToUsers?: boolean | null,
      } >,
      locales?: Array< string > | null,
      mealTypes?: Array< string > | null,
      calorieSplits?:  Array< {
        __typename: "CalorieSplit",
        mealType: string,
        fraction: number,
      } > | null,
      nutritionConstraints?:  {
        __typename: "NutritionConstraints",
        nutritionMetricConstraints?:  Array< {
          __typename: "NutritionMetricConstraint",
          id: string,
          nutritionMetric: string,
          operator: ConstraintOperator,
          value: string,
          units: string,
        } > | null,
        excludeIngredients?: Array< string > | null,
        foodPreferences?: Array< FoodPreference > | null,
      } | null,
      showNutritionToUsers?: boolean | null,
      copyMealsExactly?: boolean | null,
      personalisedMealScaling?: boolean | null,
      recipesBoard?:  {
        __typename: "EmbeddedProgrammeRecipesBoard",
        id: string,
        title: string,
        menus:  Array< {
          __typename: "Menu",
          id: string,
          title: string,
          mealIDs: Array< string >,
        } >,
        embeddedInContainerType?: ContainerType | null,
        embeddedInContainerID?: string | null,
      } | null,
      databaseRecipesBoard?:  {
        __typename: "EmbeddedProgrammeRecipesBoard",
        id: string,
        title: string,
        menus:  Array< {
          __typename: "Menu",
          id: string,
          title: string,
          mealIDs: Array< string >,
        } >,
        embeddedInContainerType?: ContainerType | null,
        embeddedInContainerID?: string | null,
      } | null,
      recipesBoardIDs?: Array< string > | null,
      onboardingConfiguration?:  {
        __typename: "ProgrammeOnboardingConfiguration",
        questionIDs: Array< string >,
      } | null,
      categoryTags?: Array< string > | null,
      shareRecords?:  Array< {
        __typename: "ProgrammeShareRecord",
        sharedProgrammeID: string,
        version: string,
        updatedOn: string,
      } | null > | null,
      availableInMembershipTierIDs?: Array< string > | null,
      groups?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSharedProgrammeQueryVariables = {
  id: string,
};

export type GetSharedProgrammeQuery = {
  getSharedProgramme?:  {
    __typename: "SharedProgramme",
    spaceID: string,
    programmeID: string,
    title: string,
    shortDescription: string,
    description?: string | null,
    coverImageUrl?: string | null,
    plans:  Array< {
      __typename: "ProgrammePlan",
      id: string,
      title: string,
      shortDescription: string,
      description?: string | null,
      coverImageUrl?: string | null,
      days:  Array< {
        __typename: "ProgrammePlanDay",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "ProgrammeEntry",
          id: string,
          programmeEntryType: EntryType,
          objectID: string,
        } >,
      } >,
      nutritionConstraints?:  {
        __typename: "NutritionConstraints",
        nutritionMetricConstraints?:  Array< {
          __typename: "NutritionMetricConstraint",
          id: string,
          nutritionMetric: string,
          operator: ConstraintOperator,
          value: string,
          units: string,
        } > | null,
        excludeIngredients?: Array< string > | null,
        foodPreferences?: Array< FoodPreference > | null,
      } | null,
      showNutritionToUsers?: boolean | null,
    } >,
    locales?: Array< string > | null,
    mealTypes?: Array< string > | null,
    calorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    nutritionConstraints?:  {
      __typename: "NutritionConstraints",
      nutritionMetricConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      excludeIngredients?: Array< string > | null,
      foodPreferences?: Array< FoodPreference > | null,
    } | null,
    showNutritionToUsers?: boolean | null,
    copyMealsExactly?: boolean | null,
    personalisedMealScaling?: boolean | null,
    recipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    databaseRecipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    sharedRecipesBoardIDs?: Array< string > | null,
    onboardingConfiguration?:  {
      __typename: "ProgrammeOnboardingConfiguration",
      questionIDs: Array< string >,
    } | null,
    categoryTags?: Array< string > | null,
    version: string,
    availableInMembershipTierIDs?: Array< string > | null,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListSharedProgrammesQueryVariables = {
  filter?: ModelSharedProgrammeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSharedProgrammesQuery = {
  listSharedProgrammes?:  {
    __typename: "ModelSharedProgrammeConnection",
    items:  Array< {
      __typename: "SharedProgramme",
      spaceID: string,
      programmeID: string,
      title: string,
      shortDescription: string,
      description?: string | null,
      coverImageUrl?: string | null,
      plans:  Array< {
        __typename: "ProgrammePlan",
        id: string,
        title: string,
        shortDescription: string,
        description?: string | null,
        coverImageUrl?: string | null,
        days:  Array< {
          __typename: "ProgrammePlanDay",
          id: string,
          title: string,
          entries:  Array< {
            __typename: "ProgrammeEntry",
            id: string,
            programmeEntryType: EntryType,
            objectID: string,
          } >,
        } >,
        nutritionConstraints?:  {
          __typename: "NutritionConstraints",
          nutritionMetricConstraints?:  Array< {
            __typename: "NutritionMetricConstraint",
            id: string,
            nutritionMetric: string,
            operator: ConstraintOperator,
            value: string,
            units: string,
          } > | null,
          excludeIngredients?: Array< string > | null,
          foodPreferences?: Array< FoodPreference > | null,
        } | null,
        showNutritionToUsers?: boolean | null,
      } >,
      locales?: Array< string > | null,
      mealTypes?: Array< string > | null,
      calorieSplits?:  Array< {
        __typename: "CalorieSplit",
        mealType: string,
        fraction: number,
      } > | null,
      nutritionConstraints?:  {
        __typename: "NutritionConstraints",
        nutritionMetricConstraints?:  Array< {
          __typename: "NutritionMetricConstraint",
          id: string,
          nutritionMetric: string,
          operator: ConstraintOperator,
          value: string,
          units: string,
        } > | null,
        excludeIngredients?: Array< string > | null,
        foodPreferences?: Array< FoodPreference > | null,
      } | null,
      showNutritionToUsers?: boolean | null,
      copyMealsExactly?: boolean | null,
      personalisedMealScaling?: boolean | null,
      recipesBoard?:  {
        __typename: "EmbeddedProgrammeRecipesBoard",
        id: string,
        title: string,
        menus:  Array< {
          __typename: "Menu",
          id: string,
          title: string,
          mealIDs: Array< string >,
        } >,
        embeddedInContainerType?: ContainerType | null,
        embeddedInContainerID?: string | null,
      } | null,
      databaseRecipesBoard?:  {
        __typename: "EmbeddedProgrammeRecipesBoard",
        id: string,
        title: string,
        menus:  Array< {
          __typename: "Menu",
          id: string,
          title: string,
          mealIDs: Array< string >,
        } >,
        embeddedInContainerType?: ContainerType | null,
        embeddedInContainerID?: string | null,
      } | null,
      sharedRecipesBoardIDs?: Array< string > | null,
      onboardingConfiguration?:  {
        __typename: "ProgrammeOnboardingConfiguration",
        questionIDs: Array< string >,
      } | null,
      categoryTags?: Array< string > | null,
      version: string,
      availableInMembershipTierIDs?: Array< string > | null,
      groups?: Array< string | null > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SharedProgrammeBySpaceQueryVariables = {
  spaceID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSharedProgrammeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SharedProgrammeBySpaceQuery = {
  sharedProgrammeBySpace?:  {
    __typename: "ModelSharedProgrammeConnection",
    items:  Array< {
      __typename: "SharedProgramme",
      spaceID: string,
      programmeID: string,
      title: string,
      shortDescription: string,
      description?: string | null,
      coverImageUrl?: string | null,
      plans:  Array< {
        __typename: "ProgrammePlan",
        id: string,
        title: string,
        shortDescription: string,
        description?: string | null,
        coverImageUrl?: string | null,
        days:  Array< {
          __typename: "ProgrammePlanDay",
          id: string,
          title: string,
          entries:  Array< {
            __typename: "ProgrammeEntry",
            id: string,
            programmeEntryType: EntryType,
            objectID: string,
          } >,
        } >,
        nutritionConstraints?:  {
          __typename: "NutritionConstraints",
          nutritionMetricConstraints?:  Array< {
            __typename: "NutritionMetricConstraint",
            id: string,
            nutritionMetric: string,
            operator: ConstraintOperator,
            value: string,
            units: string,
          } > | null,
          excludeIngredients?: Array< string > | null,
          foodPreferences?: Array< FoodPreference > | null,
        } | null,
        showNutritionToUsers?: boolean | null,
      } >,
      locales?: Array< string > | null,
      mealTypes?: Array< string > | null,
      calorieSplits?:  Array< {
        __typename: "CalorieSplit",
        mealType: string,
        fraction: number,
      } > | null,
      nutritionConstraints?:  {
        __typename: "NutritionConstraints",
        nutritionMetricConstraints?:  Array< {
          __typename: "NutritionMetricConstraint",
          id: string,
          nutritionMetric: string,
          operator: ConstraintOperator,
          value: string,
          units: string,
        } > | null,
        excludeIngredients?: Array< string > | null,
        foodPreferences?: Array< FoodPreference > | null,
      } | null,
      showNutritionToUsers?: boolean | null,
      copyMealsExactly?: boolean | null,
      personalisedMealScaling?: boolean | null,
      recipesBoard?:  {
        __typename: "EmbeddedProgrammeRecipesBoard",
        id: string,
        title: string,
        menus:  Array< {
          __typename: "Menu",
          id: string,
          title: string,
          mealIDs: Array< string >,
        } >,
        embeddedInContainerType?: ContainerType | null,
        embeddedInContainerID?: string | null,
      } | null,
      databaseRecipesBoard?:  {
        __typename: "EmbeddedProgrammeRecipesBoard",
        id: string,
        title: string,
        menus:  Array< {
          __typename: "Menu",
          id: string,
          title: string,
          mealIDs: Array< string >,
        } >,
        embeddedInContainerType?: ContainerType | null,
        embeddedInContainerID?: string | null,
      } | null,
      sharedRecipesBoardIDs?: Array< string > | null,
      onboardingConfiguration?:  {
        __typename: "ProgrammeOnboardingConfiguration",
        questionIDs: Array< string >,
      } | null,
      categoryTags?: Array< string > | null,
      version: string,
      availableInMembershipTierIDs?: Array< string > | null,
      groups?: Array< string | null > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserProgrammeEnrollmentQueryVariables = {
  id: string,
};

export type GetUserProgrammeEnrollmentQuery = {
  getUserProgrammeEnrollment?:  {
    __typename: "UserProgrammeEnrollment",
    spaceMembershipID: string,
    sharedProgrammeID: string,
    enrolledAt: string,
    onboardingAnswers:  Array< {
      __typename: "OnboardingAnswer",
      questionID: string,
      answer: string,
    } >,
    endedAt?: string | null,
    eventRecords?:  Array< {
      __typename: "UserProgrammeEnrollmentEventRecord",
      calendarDay: string,
      eventType: UserProgrammeEnrollmentEventType,
      updatedOn: string,
    } > | null,
    customTargetCalories?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListUserProgrammeEnrollmentsQueryVariables = {
  filter?: ModelUserProgrammeEnrollmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserProgrammeEnrollmentsQuery = {
  listUserProgrammeEnrollments?:  {
    __typename: "ModelUserProgrammeEnrollmentConnection",
    items:  Array< {
      __typename: "UserProgrammeEnrollment",
      spaceMembershipID: string,
      sharedProgrammeID: string,
      enrolledAt: string,
      onboardingAnswers:  Array< {
        __typename: "OnboardingAnswer",
        questionID: string,
        answer: string,
      } >,
      endedAt?: string | null,
      eventRecords?:  Array< {
        __typename: "UserProgrammeEnrollmentEventRecord",
        calendarDay: string,
        eventType: UserProgrammeEnrollmentEventType,
        updatedOn: string,
      } > | null,
      customTargetCalories?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGRCRecipeQueryVariables = {
  grcRecipeID: string,
};

export type GetGRCRecipeQuery = {
  getGRCRecipe?:  {
    __typename: "GRCRecipe",
    grcRecipeID: string,
    recipe:  {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    },
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGRCRecipesQueryVariables = {
  grcRecipeID?: string | null,
  filter?: ModelGRCRecipeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListGRCRecipesQuery = {
  listGRCRecipes?:  {
    __typename: "ModelGRCRecipeConnection",
    items:  Array< {
      __typename: "GRCRecipe",
      grcRecipeID: string,
      recipe:  {
        __typename: "Recipe",
        title: string,
        shortDescription?: string | null,
        imageUrl?: string | null,
        recipeUrl?: string | null,
        publisher?: string | null,
        chef?: string | null,
        mealiqId?: string | null,
        nutrition?:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        } | null,
        mealTypes?: Array< string > | null,
        tags?: Array< string > | null,
        supportedDiets?: Array< string > | null,
        ingredients:  Array< {
          __typename: "RecipeIngredient",
          id?: string | null,
          fullText: string,
          structuredIngredient?:  {
            __typename: "StructuredIngredient",
            name: string,
            quantity?: number | null,
            unitOfMeasure?: string | null,
            normalisedQuantity?: number | null,
            linkedIngredient?:  {
              __typename: "LinkedIngredientDetails",
              status: boolean,
              linkedIngredientName: string,
            } | null,
            resolvedNutrition?: boolean | null,
            resolvedNutritionError?: string | null,
          } | null,
          tokFullText?: string | null,
          tokens?:  Array< {
            __typename: "IngredientToken",
            fromChar: number,
            toChar: number,
            type?: string | null,
            label?: string | null,
          } | null > | null,
          scalingRules?: string | null,
          checkedInIngredientsList?: boolean | null,
        } >,
        preparations:  Array< {
          __typename: "RecipePreparation",
          id?: string | null,
          fullText: string,
        } >,
        utensils?:  Array< {
          __typename: "RecipeUtensil",
          id?: string | null,
          fullText: string,
        } > | null,
        servings?: number | null,
        cookTime?: number | null,
        prepTime?: number | null,
        totalTime?: number | null,
        recipeNotes?: string | null,
      },
      derivedNutrition?:  {
        __typename: "DerivedNutrition",
        totalNutritionPerServing:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        ingredientNutrition:  Array< {
          __typename: "DerivedIngredientNutrition",
          ingredientID?: string | null,
          nutrition:  {
            __typename: "Nutrition",
            calories?: number | null,
            protein?: number | null,
            carbohydrate?: number | null,
            fat?: number | null,
            fibre?: number | null,
            sugar?: number | null,
            saturates?: number | null,
            salt?: number | null,
            veg_weight?: number | null,
            cholesterol?: number | null,
            calcium?: number | null,
            iron?: number | null,
            magnesium?: number | null,
            potassium?: number | null,
            vitamin_a?: number | null,
            vitamin_b1?: number | null,
            vitamin_b2?: number | null,
            vitamin_b3?: number | null,
            vitamin_b5?: number | null,
            vitamin_b6?: number | null,
            vitamin_b12?: number | null,
            vitamin_c?: number | null,
            vitamin_d?: number | null,
            vitamin_e?: number | null,
            vitamin_k?: number | null,
            phosphorous?: number | null,
            zinc?: number | null,
            copper?: number | null,
            manganese?: number | null,
            selenium?: number | null,
            folate?: number | null,
            omega3_dha?: number | null,
            omega3_dpa?: number | null,
            omega3_epa?: number | null,
            sucrose?: number | null,
            glucose?: number | null,
            fructose?: number | null,
            lactose?: number | null,
            maltose?: number | null,
          },
          resolvedNutrition?: boolean | null,
          error?: string | null,
          resolvedIngredientWeight?: number | null,
        } >,
        foodAllergens?: Array< string > | null,
        foodPreferences?: Array< string > | null,
        status?: boolean | null,
        macroRatio?:  {
          __typename: "MacroRatio",
          carbohydrate?: number | null,
          protein?: number | null,
          fat?: number | null,
        } | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSharedContentBoardQueryVariables = {
  id: string,
};

export type GetSharedContentBoardQuery = {
  getSharedContentBoard?:  {
    __typename: "SharedContentBoard",
    id: string,
    title: string,
    lanes:  Array< {
      __typename: "SharedContentBoardLane",
      id: string,
      title: string,
      entries:  Array< {
        __typename: "SharedContentBoardEntry",
        id: string,
        entryType: EntryType,
        objectID: string,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListSharedContentBoardsQueryVariables = {
  filter?: ModelSharedContentBoardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSharedContentBoardsQuery = {
  listSharedContentBoards?:  {
    __typename: "ModelSharedContentBoardConnection",
    items:  Array< {
      __typename: "SharedContentBoard",
      id: string,
      title: string,
      lanes:  Array< {
        __typename: "SharedContentBoardLane",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "SharedContentBoardEntry",
          id: string,
          entryType: EntryType,
          objectID: string,
        } >,
      } >,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSpaceIAPSubscriptionQueryVariables = {
  id: string,
};

export type GetSpaceIAPSubscriptionQuery = {
  getSpaceIAPSubscription?:  {
    __typename: "SpaceIAPSubscription",
    spaceID: string,
    productID: string,
    title: string,
    shortDescription?: string | null,
    currencyCode: string,
    priceStr: string,
    bundleID: string,
    duration?: SubscriptionDuration | null,
    signUpButtonLabelOverride?: string | null,
    appStoreID?: AppStoreID | null,
    rank?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListSpaceIAPSubscriptionsQueryVariables = {
  filter?: ModelSpaceIAPSubscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpaceIAPSubscriptionsQuery = {
  listSpaceIAPSubscriptions?:  {
    __typename: "ModelSpaceIAPSubscriptionConnection",
    items:  Array< {
      __typename: "SpaceIAPSubscription",
      spaceID: string,
      productID: string,
      title: string,
      shortDescription?: string | null,
      currencyCode: string,
      priceStr: string,
      bundleID: string,
      duration?: SubscriptionDuration | null,
      signUpButtonLabelOverride?: string | null,
      appStoreID?: AppStoreID | null,
      rank?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSpaceQueryVariables = {
  id: string,
};

export type GetSpaceQuery = {
  getSpace?:  {
    __typename: "Space",
    title: string,
    shortDescription?: string | null,
    whiteLabelElements?:  {
      __typename: "SpaceWhiteLabelElements",
      primaryMainColor?: string | null,
      backgroundStyle?: BackgroundStyle | null,
      backgroundColor?: string | null,
      backgroundGradientColor?: string | null,
      cardBackgroundColor?: string | null,
      iconUrl?: string | null,
      splashImageUrl?: string | null,
      myDayBackgroundImageUrl?: string | null,
      desktopBannerImageUrl?: string | null,
      termsUrl?: string | null,
      privacyPolicyUrl?: string | null,
    } | null,
    adminUserIDs?: Array< string > | null,
    landingPageContent?: string | null,
    landingPageStyle?:  {
      __typename: "LandingPageStyle",
      backgroundColor?: string | null,
    } | null,
    landingPageCoverImageUrl?: string | null,
    isDefaultSpace?: boolean | null,
    sharedProgrammeConfiguration?:  {
      __typename: "SpaceSharedProgrammesConfiguration",
      sharedProgrammeCategories?:  Array< {
        __typename: "SpaceSharedProgrammeCategoryConfiguration",
        categoryTag: string,
        orderedProgrammeIDs: Array< string >,
      } | null > | null,
    } | null,
    productPreviewSections?:  Array< {
      __typename: "SpaceProductPreviewSection",
      title: string,
      productPreviews:  Array< {
        __typename: "SpaceProductPreview",
        productType: SpaceProductType,
        productObjectID: string,
        title: string,
        shortDescription: string,
        coverImageUrl?: string | null,
        availableInMembershipTierIDs?: Array< string > | null,
      } >,
    } > | null,
    videoPlaylists?:  Array< {
      __typename: "VideoPlaylist",
      title: string,
      playlistID: string,
      items:  Array< {
        __typename: "VideoPlaylistItem",
        videoID: string,
        title: string,
        description?: string | null,
        thumbnailImageUrl?: string | null,
      } >,
    } > | null,
    spaceContentBoard?:  {
      __typename: "SpaceContentBoard",
      id: string,
      title: string,
      lanes:  Array< {
        __typename: "SpaceContentLane",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "SpaceContentEntry",
          id: string,
          entryType: EntryType,
          objectID: string,
        } >,
      } >,
    } | null,
    spaceSharedContentBoardIDs?: Array< string > | null,
    welcomeVideo?:  {
      __typename: "VideoPlaylistItem",
      videoID: string,
      title: string,
      description?: string | null,
      thumbnailImageUrl?: string | null,
    } | null,
    myDayScreenSections?:  Array< {
      __typename: "SpacePublishedSectionConfig",
      publishedSectionType?: SpacePublishedSectionType | null,
      spaceContentLaneID?: string | null,
    } > | null,
    subscriptionBenefitsContent?: string | null,
    subscriptionPageStyle?:  {
      __typename: "SubscriptionPageStyle",
      backgroundColor?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    sharedRecipesBoardIDs?: Array< string > | null,
    defaultSharedProgrammeIDForNewUsers?: string | null,
    appStoreLinks?:  Array< {
      __typename: "SpaceAppStoreLink",
      appStoreID: AppStoreID,
      appUrl: string,
    } > | null,
    chatConfig?:  {
      __typename: "SpaceChatConfig",
      appID?: string | null,
      appRegion?: string | null,
    } | null,
    publishedSearchTags?:  Array< {
      __typename: "SpacePublishedSearchTag",
      id: string,
      tag: string,
    } > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListSpacesQueryVariables = {
  filter?: ModelSpaceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpacesQuery = {
  listSpaces?:  {
    __typename: "ModelSpaceConnection",
    items:  Array< {
      __typename: "Space",
      title: string,
      shortDescription?: string | null,
      whiteLabelElements?:  {
        __typename: "SpaceWhiteLabelElements",
        primaryMainColor?: string | null,
        backgroundStyle?: BackgroundStyle | null,
        backgroundColor?: string | null,
        backgroundGradientColor?: string | null,
        cardBackgroundColor?: string | null,
        iconUrl?: string | null,
        splashImageUrl?: string | null,
        myDayBackgroundImageUrl?: string | null,
        desktopBannerImageUrl?: string | null,
        termsUrl?: string | null,
        privacyPolicyUrl?: string | null,
      } | null,
      adminUserIDs?: Array< string > | null,
      landingPageContent?: string | null,
      landingPageStyle?:  {
        __typename: "LandingPageStyle",
        backgroundColor?: string | null,
      } | null,
      landingPageCoverImageUrl?: string | null,
      isDefaultSpace?: boolean | null,
      sharedProgrammeConfiguration?:  {
        __typename: "SpaceSharedProgrammesConfiguration",
        sharedProgrammeCategories?:  Array< {
          __typename: "SpaceSharedProgrammeCategoryConfiguration",
          categoryTag: string,
          orderedProgrammeIDs: Array< string >,
        } | null > | null,
      } | null,
      productPreviewSections?:  Array< {
        __typename: "SpaceProductPreviewSection",
        title: string,
        productPreviews:  Array< {
          __typename: "SpaceProductPreview",
          productType: SpaceProductType,
          productObjectID: string,
          title: string,
          shortDescription: string,
          coverImageUrl?: string | null,
          availableInMembershipTierIDs?: Array< string > | null,
        } >,
      } > | null,
      videoPlaylists?:  Array< {
        __typename: "VideoPlaylist",
        title: string,
        playlistID: string,
        items:  Array< {
          __typename: "VideoPlaylistItem",
          videoID: string,
          title: string,
          description?: string | null,
          thumbnailImageUrl?: string | null,
        } >,
      } > | null,
      spaceContentBoard?:  {
        __typename: "SpaceContentBoard",
        id: string,
        title: string,
        lanes:  Array< {
          __typename: "SpaceContentLane",
          id: string,
          title: string,
          entries:  Array< {
            __typename: "SpaceContentEntry",
            id: string,
            entryType: EntryType,
            objectID: string,
          } >,
        } >,
      } | null,
      spaceSharedContentBoardIDs?: Array< string > | null,
      welcomeVideo?:  {
        __typename: "VideoPlaylistItem",
        videoID: string,
        title: string,
        description?: string | null,
        thumbnailImageUrl?: string | null,
      } | null,
      myDayScreenSections?:  Array< {
        __typename: "SpacePublishedSectionConfig",
        publishedSectionType?: SpacePublishedSectionType | null,
        spaceContentLaneID?: string | null,
      } > | null,
      subscriptionBenefitsContent?: string | null,
      subscriptionPageStyle?:  {
        __typename: "SubscriptionPageStyle",
        backgroundColor?: string | null,
      } | null,
      groups?: Array< string | null > | null,
      sharedRecipesBoardIDs?: Array< string > | null,
      defaultSharedProgrammeIDForNewUsers?: string | null,
      appStoreLinks?:  Array< {
        __typename: "SpaceAppStoreLink",
        appStoreID: AppStoreID,
        appUrl: string,
      } > | null,
      chatConfig?:  {
        __typename: "SpaceChatConfig",
        appID?: string | null,
        appRegion?: string | null,
      } | null,
      publishedSearchTags?:  Array< {
        __typename: "SpacePublishedSearchTag",
        id: string,
        tag: string,
      } > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSpaceMembershipQueryVariables = {
  id: string,
};

export type GetSpaceMembershipQuery = {
  getSpaceMembership?:  {
    __typename: "SpaceMembership",
    spaceID: string,
    memberID: string,
    state: SpaceMembershipState,
    correlatedUserID?: string | null,
    expiresAt?: string | null,
    visibleProgrammeIDsOverride?: Array< string > | null,
    membershipTierID?: string | null,
    stripeCustomerID?: string | null,
    stripeSubscriptionID?: string | null,
    onboardingAnswers?:  Array< {
      __typename: "OnboardingAnswer",
      questionID: string,
      answer: string,
    } > | null,
    onboardingAnswersLastUpdatedAt?: string | null,
    customTargetCalories?: number | null,
    welcomeVideoWatched?: boolean | null,
    initialProgrammeSelectionLastOfferedAt?: string | null,
    chatNickname?: string | null,
    referrer?: SpaceMembershipReferrer | null,
    appStoreSubscriptionID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type ListSpaceMembershipsQueryVariables = {
  filter?: ModelSpaceMembershipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpaceMembershipsQuery = {
  listSpaceMemberships?:  {
    __typename: "ModelSpaceMembershipConnection",
    items:  Array< {
      __typename: "SpaceMembership",
      spaceID: string,
      memberID: string,
      state: SpaceMembershipState,
      correlatedUserID?: string | null,
      expiresAt?: string | null,
      visibleProgrammeIDsOverride?: Array< string > | null,
      membershipTierID?: string | null,
      stripeCustomerID?: string | null,
      stripeSubscriptionID?: string | null,
      onboardingAnswers?:  Array< {
        __typename: "OnboardingAnswer",
        questionID: string,
        answer: string,
      } > | null,
      onboardingAnswersLastUpdatedAt?: string | null,
      customTargetCalories?: number | null,
      welcomeVideoWatched?: boolean | null,
      initialProgrammeSelectionLastOfferedAt?: string | null,
      chatNickname?: string | null,
      referrer?: SpaceMembershipReferrer | null,
      appStoreSubscriptionID?: string | null,
      groups?: Array< string | null > | null,
      createdAt: string,
      id: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SpaceMembershipBySpaceIDQueryVariables = {
  spaceID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpaceMembershipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SpaceMembershipBySpaceIDQuery = {
  spaceMembershipBySpaceID?:  {
    __typename: "ModelSpaceMembershipConnection",
    items:  Array< {
      __typename: "SpaceMembership",
      spaceID: string,
      memberID: string,
      state: SpaceMembershipState,
      correlatedUserID?: string | null,
      expiresAt?: string | null,
      visibleProgrammeIDsOverride?: Array< string > | null,
      membershipTierID?: string | null,
      stripeCustomerID?: string | null,
      stripeSubscriptionID?: string | null,
      onboardingAnswers?:  Array< {
        __typename: "OnboardingAnswer",
        questionID: string,
        answer: string,
      } > | null,
      onboardingAnswersLastUpdatedAt?: string | null,
      customTargetCalories?: number | null,
      welcomeVideoWatched?: boolean | null,
      initialProgrammeSelectionLastOfferedAt?: string | null,
      chatNickname?: string | null,
      referrer?: SpaceMembershipReferrer | null,
      appStoreSubscriptionID?: string | null,
      groups?: Array< string | null > | null,
      createdAt: string,
      id: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SpaceMembershipByMemberIDQueryVariables = {
  memberID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpaceMembershipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SpaceMembershipByMemberIDQuery = {
  spaceMembershipByMemberID?:  {
    __typename: "ModelSpaceMembershipConnection",
    items:  Array< {
      __typename: "SpaceMembership",
      spaceID: string,
      memberID: string,
      state: SpaceMembershipState,
      correlatedUserID?: string | null,
      expiresAt?: string | null,
      visibleProgrammeIDsOverride?: Array< string > | null,
      membershipTierID?: string | null,
      stripeCustomerID?: string | null,
      stripeSubscriptionID?: string | null,
      onboardingAnswers?:  Array< {
        __typename: "OnboardingAnswer",
        questionID: string,
        answer: string,
      } > | null,
      onboardingAnswersLastUpdatedAt?: string | null,
      customTargetCalories?: number | null,
      welcomeVideoWatched?: boolean | null,
      initialProgrammeSelectionLastOfferedAt?: string | null,
      chatNickname?: string | null,
      referrer?: SpaceMembershipReferrer | null,
      appStoreSubscriptionID?: string | null,
      groups?: Array< string | null > | null,
      createdAt: string,
      id: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SpaceMembershipByStripeCustomerIDQueryVariables = {
  stripeCustomerID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpaceMembershipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SpaceMembershipByStripeCustomerIDQuery = {
  spaceMembershipByStripeCustomerID?:  {
    __typename: "ModelSpaceMembershipConnection",
    items:  Array< {
      __typename: "SpaceMembership",
      spaceID: string,
      memberID: string,
      state: SpaceMembershipState,
      correlatedUserID?: string | null,
      expiresAt?: string | null,
      visibleProgrammeIDsOverride?: Array< string > | null,
      membershipTierID?: string | null,
      stripeCustomerID?: string | null,
      stripeSubscriptionID?: string | null,
      onboardingAnswers?:  Array< {
        __typename: "OnboardingAnswer",
        questionID: string,
        answer: string,
      } > | null,
      onboardingAnswersLastUpdatedAt?: string | null,
      customTargetCalories?: number | null,
      welcomeVideoWatched?: boolean | null,
      initialProgrammeSelectionLastOfferedAt?: string | null,
      chatNickname?: string | null,
      referrer?: SpaceMembershipReferrer | null,
      appStoreSubscriptionID?: string | null,
      groups?: Array< string | null > | null,
      createdAt: string,
      id: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SpaceMembershipByStripeSubscriptionIDQueryVariables = {
  stripeSubscriptionID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpaceMembershipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SpaceMembershipByStripeSubscriptionIDQuery = {
  spaceMembershipByStripeSubscriptionID?:  {
    __typename: "ModelSpaceMembershipConnection",
    items:  Array< {
      __typename: "SpaceMembership",
      spaceID: string,
      memberID: string,
      state: SpaceMembershipState,
      correlatedUserID?: string | null,
      expiresAt?: string | null,
      visibleProgrammeIDsOverride?: Array< string > | null,
      membershipTierID?: string | null,
      stripeCustomerID?: string | null,
      stripeSubscriptionID?: string | null,
      onboardingAnswers?:  Array< {
        __typename: "OnboardingAnswer",
        questionID: string,
        answer: string,
      } > | null,
      onboardingAnswersLastUpdatedAt?: string | null,
      customTargetCalories?: number | null,
      welcomeVideoWatched?: boolean | null,
      initialProgrammeSelectionLastOfferedAt?: string | null,
      chatNickname?: string | null,
      referrer?: SpaceMembershipReferrer | null,
      appStoreSubscriptionID?: string | null,
      groups?: Array< string | null > | null,
      createdAt: string,
      id: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSpaceMembershipEventQueryVariables = {
  id: string,
};

export type GetSpaceMembershipEventQuery = {
  getSpaceMembershipEvent?:  {
    __typename: "SpaceMembershipEvent",
    spaceMembershipID: string,
    memberID: string,
    eventType?: SpaceMembershipEventType | null,
    transactionSource?: SubscriptionTransactionSource | null,
    transactionID?: string | null,
    details?: string | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type ListSpaceMembershipEventsQueryVariables = {
  filter?: ModelSpaceMembershipEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpaceMembershipEventsQuery = {
  listSpaceMembershipEvents?:  {
    __typename: "ModelSpaceMembershipEventConnection",
    items:  Array< {
      __typename: "SpaceMembershipEvent",
      spaceMembershipID: string,
      memberID: string,
      eventType?: SpaceMembershipEventType | null,
      transactionSource?: SubscriptionTransactionSource | null,
      transactionID?: string | null,
      details?: string | null,
      createdAt: string,
      id: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SpaceMembershipEventBySpaceMembershipIDQueryVariables = {
  spaceMembershipID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpaceMembershipEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SpaceMembershipEventBySpaceMembershipIDQuery = {
  spaceMembershipEventBySpaceMembershipID?:  {
    __typename: "ModelSpaceMembershipEventConnection",
    items:  Array< {
      __typename: "SpaceMembershipEvent",
      spaceMembershipID: string,
      memberID: string,
      eventType?: SpaceMembershipEventType | null,
      transactionSource?: SubscriptionTransactionSource | null,
      transactionID?: string | null,
      details?: string | null,
      createdAt: string,
      id: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserMyDayActionRecordQueryVariables = {
  id: string,
};

export type GetUserMyDayActionRecordQuery = {
  getUserMyDayActionRecord?:  {
    __typename: "UserMyDayActionRecord",
    userProgrammeEnrollmentID: string,
    programmeEntryType: EntryType,
    objectID: string,
    calendarDay: string,
    actionType: MyDayActionType,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListUserMyDayActionRecordsQueryVariables = {
  filter?: ModelUserMyDayActionRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserMyDayActionRecordsQuery = {
  listUserMyDayActionRecords?:  {
    __typename: "ModelUserMyDayActionRecordConnection",
    items:  Array< {
      __typename: "UserMyDayActionRecord",
      userProgrammeEnrollmentID: string,
      programmeEntryType: EntryType,
      objectID: string,
      calendarDay: string,
      actionType: MyDayActionType,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserMyDayActionRecordByUserProgrammeEnrollmentQueryVariables = {
  userProgrammeEnrollmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserMyDayActionRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserMyDayActionRecordByUserProgrammeEnrollmentQuery = {
  userMyDayActionRecordByUserProgrammeEnrollment?:  {
    __typename: "ModelUserMyDayActionRecordConnection",
    items:  Array< {
      __typename: "UserMyDayActionRecord",
      userProgrammeEnrollmentID: string,
      programmeEntryType: EntryType,
      objectID: string,
      calendarDay: string,
      actionType: MyDayActionType,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMembershipTierQueryVariables = {
  id: string,
};

export type GetMembershipTierQuery = {
  getMembershipTier?:  {
    __typename: "MembershipTier",
    spaceID: string,
    title: string,
    shortDescription?: string | null,
    monthlyPrice: string,
    quarterlyPrice?: string | null,
    annualPrice?: string | null,
    freeTrialDurationDays?: number | null,
    rank?: number | null,
    currencyCode?: string | null,
    state: MembershipTierState,
    stripeProductID?: string | null,
    stripePriceID?: string | null,
    stripePriceIDQuarterly?: string | null,
    stripePriceIDAnnual?: string | null,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListMembershipTiersQueryVariables = {
  filter?: ModelMembershipTierFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMembershipTiersQuery = {
  listMembershipTiers?:  {
    __typename: "ModelMembershipTierConnection",
    items:  Array< {
      __typename: "MembershipTier",
      spaceID: string,
      title: string,
      shortDescription?: string | null,
      monthlyPrice: string,
      quarterlyPrice?: string | null,
      annualPrice?: string | null,
      freeTrialDurationDays?: number | null,
      rank?: number | null,
      currencyCode?: string | null,
      state: MembershipTierState,
      stripeProductID?: string | null,
      stripePriceID?: string | null,
      stripePriceIDQuarterly?: string | null,
      stripePriceIDAnnual?: string | null,
      groups?: Array< string | null > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MembershipTierBySpaceQueryVariables = {
  spaceID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMembershipTierFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MembershipTierBySpaceQuery = {
  membershipTierBySpace?:  {
    __typename: "ModelMembershipTierConnection",
    items:  Array< {
      __typename: "MembershipTier",
      spaceID: string,
      title: string,
      shortDescription?: string | null,
      monthlyPrice: string,
      quarterlyPrice?: string | null,
      annualPrice?: string | null,
      freeTrialDurationDays?: number | null,
      rank?: number | null,
      currencyCode?: string | null,
      state: MembershipTierState,
      stripeProductID?: string | null,
      stripePriceID?: string | null,
      stripePriceIDQuarterly?: string | null,
      stripePriceIDAnnual?: string | null,
      groups?: Array< string | null > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSpacePrivateConfigQueryVariables = {
  id: string,
};

export type GetSpacePrivateConfigQuery = {
  getSpacePrivateConfig?:  {
    __typename: "SpacePrivateConfig",
    id: string,
    spaceID: string,
    stripeConfig?:  {
      __typename: "SpaceStripeConfig",
      environment: StripeEnvironment,
      connectedAccountID: string,
      chargesEnabled: boolean,
      stripeProductID?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListSpacePrivateConfigsQueryVariables = {
  filter?: ModelSpacePrivateConfigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpacePrivateConfigsQuery = {
  listSpacePrivateConfigs?:  {
    __typename: "ModelSpacePrivateConfigConnection",
    items:  Array< {
      __typename: "SpacePrivateConfig",
      id: string,
      spaceID: string,
      stripeConfig?:  {
        __typename: "SpaceStripeConfig",
        environment: StripeEnvironment,
        connectedAccountID: string,
        chargesEnabled: boolean,
        stripeProductID?: string | null,
      } | null,
      groups?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SpacePrivateConfigBySpaceIDQueryVariables = {
  spaceID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpacePrivateConfigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SpacePrivateConfigBySpaceIDQuery = {
  spacePrivateConfigBySpaceID?:  {
    __typename: "ModelSpacePrivateConfigConnection",
    items:  Array< {
      __typename: "SpacePrivateConfig",
      id: string,
      spaceID: string,
      stripeConfig?:  {
        __typename: "SpaceStripeConfig",
        environment: StripeEnvironment,
        connectedAccountID: string,
        chargesEnabled: boolean,
        stripeProductID?: string | null,
      } | null,
      groups?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAccountActivitySignalQueryVariables = {
  id: string,
};

export type GetAccountActivitySignalQuery = {
  getAccountActivitySignal?:  {
    __typename: "AccountActivitySignal",
    id: string,
    spaceID: string,
    groups?: Array< string | null > | null,
    lastActivityAt: string,
    friendlyUsername?: string | null,
    lastActivityObjectType?: ActivityObjectType | null,
    lastActivityObjectID?: string | null,
    sourceIp?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListAccountActivitySignalsQueryVariables = {
  filter?: ModelAccountActivitySignalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountActivitySignalsQuery = {
  listAccountActivitySignals?:  {
    __typename: "ModelAccountActivitySignalConnection",
    items:  Array< {
      __typename: "AccountActivitySignal",
      id: string,
      spaceID: string,
      groups?: Array< string | null > | null,
      lastActivityAt: string,
      friendlyUsername?: string | null,
      lastActivityObjectType?: ActivityObjectType | null,
      lastActivityObjectID?: string | null,
      sourceIp?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AccountActivitySignalBySpaceIDQueryVariables = {
  spaceID: string,
  lastActivityAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAccountActivitySignalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AccountActivitySignalBySpaceIDQuery = {
  accountActivitySignalBySpaceID?:  {
    __typename: "ModelAccountActivitySignalConnection",
    items:  Array< {
      __typename: "AccountActivitySignal",
      id: string,
      spaceID: string,
      groups?: Array< string | null > | null,
      lastActivityAt: string,
      friendlyUsername?: string | null,
      lastActivityObjectType?: ActivityObjectType | null,
      lastActivityObjectID?: string | null,
      sourceIp?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateZapierRestHookSubscriptionVariables = {
  filter?: ModelSubscriptionZapierRestHookFilterInput | null,
};

export type OnCreateZapierRestHookSubscription = {
  onCreateZapierRestHook?:  {
    __typename: "ZapierRestHook",
    spaceID: string,
    hookUrl: string,
    hookKey: string,
    enabled: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateZapierRestHookSubscriptionVariables = {
  filter?: ModelSubscriptionZapierRestHookFilterInput | null,
};

export type OnUpdateZapierRestHookSubscription = {
  onUpdateZapierRestHook?:  {
    __typename: "ZapierRestHook",
    spaceID: string,
    hookUrl: string,
    hookKey: string,
    enabled: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteZapierRestHookSubscriptionVariables = {
  filter?: ModelSubscriptionZapierRestHookFilterInput | null,
};

export type OnDeleteZapierRestHookSubscription = {
  onDeleteZapierRestHook?:  {
    __typename: "ZapierRestHook",
    spaceID: string,
    hookUrl: string,
    hookKey: string,
    enabled: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserProfileSubscriptionVariables = {
  filter?: ModelSubscriptionUserProfileFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserProfileSubscription = {
  onCreateUserProfile?:  {
    __typename: "UserProfile",
    id: string,
    showOnboarding?: boolean | null,
    consentToProductComms?: boolean | null,
    plannerViewWeekStartDay?: number | null,
    geoRegion?: string | null,
    countryCode?: string | null,
    mealPlanReminderSchedule?: string | null,
    showMealDetailGuide?: boolean | null,
    recentlyUsedTags?: Array< string | null > | null,
    activitySummary?:  {
      __typename: "UserActivitySummary",
      numMealsViewed?: number | null,
      numMealsViewedMobile?: number | null,
      numShoppingListsViewed?: number | null,
      numShoppingListsViewedMobile?: number | null,
      numMealsEdited?: number | null,
      numNutritionClicks?: number | null,
      numMealsPlanned?: number | null,
      numMealsAdded?: number | null,
      numMealsImported?: number | null,
      numMealsAddedManually?: number | null,
      numPlansViewed?: number | null,
      numShoppingListsCopiedToClipboard?: number | null,
      numShoppingListsEmailed?: number | null,
      numShoppingListsCreated?: number | null,
      numShops?: number | null,
      numNotesAdded?: number | null,
      numNotesViewed?: number | null,
      numNotesViewedMobile?: number | null,
      numRecipesBoardsCreated?: number | null,
      numRecipesBoardsShared?: number | null,
    } | null,
    enabledFeatures?: Array< string | null > | null,
    requestedFeatures?: Array< string | null > | null,
    seenMessages?: Array< string | null > | null,
    recentlyUsedRecommenderMealTypes?: Array< string > | null,
    recentlyUsedRecommenderCalorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    recentlyUsedSearchCriteria?:  {
      __typename: "PersistedSearchCriteria",
      searchString?: string | null,
      mealTypes?: Array< string > | null,
      cookTimeMin?: number | null,
      cookTimeMax?: number | null,
      excludeIngredients?: Array< string > | null,
      includeIngredients?: Array< string > | null,
      dietaryPreferences?: Array< string > | null,
      perMealNutritionConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      tags?: Array< string > | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserProfileSubscriptionVariables = {
  filter?: ModelSubscriptionUserProfileFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserProfileSubscription = {
  onUpdateUserProfile?:  {
    __typename: "UserProfile",
    id: string,
    showOnboarding?: boolean | null,
    consentToProductComms?: boolean | null,
    plannerViewWeekStartDay?: number | null,
    geoRegion?: string | null,
    countryCode?: string | null,
    mealPlanReminderSchedule?: string | null,
    showMealDetailGuide?: boolean | null,
    recentlyUsedTags?: Array< string | null > | null,
    activitySummary?:  {
      __typename: "UserActivitySummary",
      numMealsViewed?: number | null,
      numMealsViewedMobile?: number | null,
      numShoppingListsViewed?: number | null,
      numShoppingListsViewedMobile?: number | null,
      numMealsEdited?: number | null,
      numNutritionClicks?: number | null,
      numMealsPlanned?: number | null,
      numMealsAdded?: number | null,
      numMealsImported?: number | null,
      numMealsAddedManually?: number | null,
      numPlansViewed?: number | null,
      numShoppingListsCopiedToClipboard?: number | null,
      numShoppingListsEmailed?: number | null,
      numShoppingListsCreated?: number | null,
      numShops?: number | null,
      numNotesAdded?: number | null,
      numNotesViewed?: number | null,
      numNotesViewedMobile?: number | null,
      numRecipesBoardsCreated?: number | null,
      numRecipesBoardsShared?: number | null,
    } | null,
    enabledFeatures?: Array< string | null > | null,
    requestedFeatures?: Array< string | null > | null,
    seenMessages?: Array< string | null > | null,
    recentlyUsedRecommenderMealTypes?: Array< string > | null,
    recentlyUsedRecommenderCalorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    recentlyUsedSearchCriteria?:  {
      __typename: "PersistedSearchCriteria",
      searchString?: string | null,
      mealTypes?: Array< string > | null,
      cookTimeMin?: number | null,
      cookTimeMax?: number | null,
      excludeIngredients?: Array< string > | null,
      includeIngredients?: Array< string > | null,
      dietaryPreferences?: Array< string > | null,
      perMealNutritionConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      tags?: Array< string > | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserProfileSubscriptionVariables = {
  filter?: ModelSubscriptionUserProfileFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserProfileSubscription = {
  onDeleteUserProfile?:  {
    __typename: "UserProfile",
    id: string,
    showOnboarding?: boolean | null,
    consentToProductComms?: boolean | null,
    plannerViewWeekStartDay?: number | null,
    geoRegion?: string | null,
    countryCode?: string | null,
    mealPlanReminderSchedule?: string | null,
    showMealDetailGuide?: boolean | null,
    recentlyUsedTags?: Array< string | null > | null,
    activitySummary?:  {
      __typename: "UserActivitySummary",
      numMealsViewed?: number | null,
      numMealsViewedMobile?: number | null,
      numShoppingListsViewed?: number | null,
      numShoppingListsViewedMobile?: number | null,
      numMealsEdited?: number | null,
      numNutritionClicks?: number | null,
      numMealsPlanned?: number | null,
      numMealsAdded?: number | null,
      numMealsImported?: number | null,
      numMealsAddedManually?: number | null,
      numPlansViewed?: number | null,
      numShoppingListsCopiedToClipboard?: number | null,
      numShoppingListsEmailed?: number | null,
      numShoppingListsCreated?: number | null,
      numShops?: number | null,
      numNotesAdded?: number | null,
      numNotesViewed?: number | null,
      numNotesViewedMobile?: number | null,
      numRecipesBoardsCreated?: number | null,
      numRecipesBoardsShared?: number | null,
    } | null,
    enabledFeatures?: Array< string | null > | null,
    requestedFeatures?: Array< string | null > | null,
    seenMessages?: Array< string | null > | null,
    recentlyUsedRecommenderMealTypes?: Array< string > | null,
    recentlyUsedRecommenderCalorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    recentlyUsedSearchCriteria?:  {
      __typename: "PersistedSearchCriteria",
      searchString?: string | null,
      mealTypes?: Array< string > | null,
      cookTimeMin?: number | null,
      cookTimeMax?: number | null,
      excludeIngredients?: Array< string > | null,
      includeIngredients?: Array< string > | null,
      dietaryPreferences?: Array< string > | null,
      perMealNutritionConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      tags?: Array< string > | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateObjectEditEventSubscriptionVariables = {
  filter?: ModelSubscriptionObjectEditEventFilterInput | null,
  owner?: string | null,
};

export type OnCreateObjectEditEventSubscription = {
  onCreateObjectEditEvent?:  {
    __typename: "ObjectEditEvent",
    id: string,
    objectID: string,
    objectType: EntryType,
    section: string,
    itemID?: string | null,
    oldValue?: string | null,
    createdAt: string,
    groups?: Array< string | null > | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateObjectEditEventSubscriptionVariables = {
  filter?: ModelSubscriptionObjectEditEventFilterInput | null,
  owner?: string | null,
};

export type OnUpdateObjectEditEventSubscription = {
  onUpdateObjectEditEvent?:  {
    __typename: "ObjectEditEvent",
    id: string,
    objectID: string,
    objectType: EntryType,
    section: string,
    itemID?: string | null,
    oldValue?: string | null,
    createdAt: string,
    groups?: Array< string | null > | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteObjectEditEventSubscriptionVariables = {
  filter?: ModelSubscriptionObjectEditEventFilterInput | null,
  owner?: string | null,
};

export type OnDeleteObjectEditEventSubscription = {
  onDeleteObjectEditEvent?:  {
    __typename: "ObjectEditEvent",
    id: string,
    objectID: string,
    objectType: EntryType,
    section: string,
    itemID?: string | null,
    oldValue?: string | null,
    createdAt: string,
    groups?: Array< string | null > | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateMealSubscriptionVariables = {
  filter?: ModelSubscriptionMealFilterInput | null,
  owner?: string | null,
};

export type OnCreateMealSubscription = {
  onCreateMeal?:  {
    __typename: "Meal",
    id: string,
    schemaVersion: number,
    smorgBoardID: string,
    recipes:  Array< {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    } >,
    addons?:  Array< {
      __typename: "MealAddOn",
      name: string,
    } > | null,
    additionallyReferredToBy?:  Array< {
      __typename: "ReferenceType",
      sourceType: ReferenceSourceType,
      sourceID: string,
    } > | null,
    origin?:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    } | null,
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    spaceMembershipID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateMealSubscriptionVariables = {
  filter?: ModelSubscriptionMealFilterInput | null,
  owner?: string | null,
};

export type OnUpdateMealSubscription = {
  onUpdateMeal?:  {
    __typename: "Meal",
    id: string,
    schemaVersion: number,
    smorgBoardID: string,
    recipes:  Array< {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    } >,
    addons?:  Array< {
      __typename: "MealAddOn",
      name: string,
    } > | null,
    additionallyReferredToBy?:  Array< {
      __typename: "ReferenceType",
      sourceType: ReferenceSourceType,
      sourceID: string,
    } > | null,
    origin?:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    } | null,
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    spaceMembershipID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteMealSubscriptionVariables = {
  filter?: ModelSubscriptionMealFilterInput | null,
  owner?: string | null,
};

export type OnDeleteMealSubscription = {
  onDeleteMeal?:  {
    __typename: "Meal",
    id: string,
    schemaVersion: number,
    smorgBoardID: string,
    recipes:  Array< {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    } >,
    addons?:  Array< {
      __typename: "MealAddOn",
      name: string,
    } > | null,
    additionallyReferredToBy?:  Array< {
      __typename: "ReferenceType",
      sourceType: ReferenceSourceType,
      sourceID: string,
    } > | null,
    origin?:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    } | null,
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    spaceMembershipID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateSmorgBoardSubscriptionVariables = {
  filter?: ModelSubscriptionSmorgBoardFilterInput | null,
  owner?: string | null,
};

export type OnCreateSmorgBoardSubscription = {
  onCreateSmorgBoard?:  {
    __typename: "SmorgBoard",
    id: string,
    schemaVersion: number,
    title: string,
    shortDescription?: string | null,
    coverImageUrl?: string | null,
    menus:  Array< {
      __typename: "Menu",
      id: string,
      title: string,
      mealIDs: Array< string >,
    } >,
    shareRecords?:  Array< {
      __typename: "RecipesBoardShareRecord",
      sharedBoardID: string,
      version: string,
      updatedOn: string,
    } | null > | null,
    spaceMembershipID?: string | null,
    embeddedInContainerType?: ContainerType | null,
    embeddedInContainerID?: string | null,
    groups?: Array< string | null > | null,
    availableInMembershipTierIDs?: Array< string > | null,
    isMyFavouritesBoard?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateSmorgBoardSubscriptionVariables = {
  filter?: ModelSubscriptionSmorgBoardFilterInput | null,
  owner?: string | null,
};

export type OnUpdateSmorgBoardSubscription = {
  onUpdateSmorgBoard?:  {
    __typename: "SmorgBoard",
    id: string,
    schemaVersion: number,
    title: string,
    shortDescription?: string | null,
    coverImageUrl?: string | null,
    menus:  Array< {
      __typename: "Menu",
      id: string,
      title: string,
      mealIDs: Array< string >,
    } >,
    shareRecords?:  Array< {
      __typename: "RecipesBoardShareRecord",
      sharedBoardID: string,
      version: string,
      updatedOn: string,
    } | null > | null,
    spaceMembershipID?: string | null,
    embeddedInContainerType?: ContainerType | null,
    embeddedInContainerID?: string | null,
    groups?: Array< string | null > | null,
    availableInMembershipTierIDs?: Array< string > | null,
    isMyFavouritesBoard?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteSmorgBoardSubscriptionVariables = {
  filter?: ModelSubscriptionSmorgBoardFilterInput | null,
  owner?: string | null,
};

export type OnDeleteSmorgBoardSubscription = {
  onDeleteSmorgBoard?:  {
    __typename: "SmorgBoard",
    id: string,
    schemaVersion: number,
    title: string,
    shortDescription?: string | null,
    coverImageUrl?: string | null,
    menus:  Array< {
      __typename: "Menu",
      id: string,
      title: string,
      mealIDs: Array< string >,
    } >,
    shareRecords?:  Array< {
      __typename: "RecipesBoardShareRecord",
      sharedBoardID: string,
      version: string,
      updatedOn: string,
    } | null > | null,
    spaceMembershipID?: string | null,
    embeddedInContainerType?: ContainerType | null,
    embeddedInContainerID?: string | null,
    groups?: Array< string | null > | null,
    availableInMembershipTierIDs?: Array< string > | null,
    isMyFavouritesBoard?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateSharedMealSubscriptionVariables = {
  filter?: ModelSubscriptionSharedMealFilterInput | null,
};

export type OnCreateSharedMealSubscription = {
  onCreateSharedMeal?:  {
    __typename: "SharedMeal",
    id: string,
    schemaVersion: number,
    sharedBoardID: string,
    recipes:  Array< {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    } >,
    origin?:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    } | null,
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSharedMealSubscriptionVariables = {
  filter?: ModelSubscriptionSharedMealFilterInput | null,
};

export type OnUpdateSharedMealSubscription = {
  onUpdateSharedMeal?:  {
    __typename: "SharedMeal",
    id: string,
    schemaVersion: number,
    sharedBoardID: string,
    recipes:  Array< {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    } >,
    origin?:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    } | null,
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSharedMealSubscriptionVariables = {
  filter?: ModelSubscriptionSharedMealFilterInput | null,
};

export type OnDeleteSharedMealSubscription = {
  onDeleteSharedMeal?:  {
    __typename: "SharedMeal",
    id: string,
    schemaVersion: number,
    sharedBoardID: string,
    recipes:  Array< {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    } >,
    origin?:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    } | null,
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSharedBoardSubscriptionVariables = {
  filter?: ModelSubscriptionSharedBoardFilterInput | null,
};

export type OnCreateSharedBoardSubscription = {
  onCreateSharedBoard?:  {
    __typename: "SharedBoard",
    id: string,
    schemaVersion: number,
    version?: string | null,
    accessLevel?: SharedBoardAccessLevel | null,
    title?: string | null,
    shortDescription?: string | null,
    coverImageUrl?: string | null,
    menus:  Array< {
      __typename: "SharedMenu",
      id?: string | null,
      title: string,
      sharedMealIDs: Array< string >,
    } >,
    availableInMembershipTierIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSharedBoardSubscriptionVariables = {
  filter?: ModelSubscriptionSharedBoardFilterInput | null,
};

export type OnUpdateSharedBoardSubscription = {
  onUpdateSharedBoard?:  {
    __typename: "SharedBoard",
    id: string,
    schemaVersion: number,
    version?: string | null,
    accessLevel?: SharedBoardAccessLevel | null,
    title?: string | null,
    shortDescription?: string | null,
    coverImageUrl?: string | null,
    menus:  Array< {
      __typename: "SharedMenu",
      id?: string | null,
      title: string,
      sharedMealIDs: Array< string >,
    } >,
    availableInMembershipTierIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSharedBoardSubscriptionVariables = {
  filter?: ModelSubscriptionSharedBoardFilterInput | null,
};

export type OnDeleteSharedBoardSubscription = {
  onDeleteSharedBoard?:  {
    __typename: "SharedBoard",
    id: string,
    schemaVersion: number,
    version?: string | null,
    accessLevel?: SharedBoardAccessLevel | null,
    title?: string | null,
    shortDescription?: string | null,
    coverImageUrl?: string | null,
    menus:  Array< {
      __typename: "SharedMenu",
      id?: string | null,
      title: string,
      sharedMealIDs: Array< string >,
    } >,
    availableInMembershipTierIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateNoteSubscriptionVariables = {
  filter?: ModelSubscriptionNoteFilterInput | null,
  owner?: string | null,
};

export type OnCreateNoteSubscription = {
  onCreateNote?:  {
    __typename: "Note",
    id: string,
    parentID: string,
    title: string,
    description?: string | null,
    spaceMembershipID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateNoteSubscriptionVariables = {
  filter?: ModelSubscriptionNoteFilterInput | null,
  owner?: string | null,
};

export type OnUpdateNoteSubscription = {
  onUpdateNote?:  {
    __typename: "Note",
    id: string,
    parentID: string,
    title: string,
    description?: string | null,
    spaceMembershipID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteNoteSubscriptionVariables = {
  filter?: ModelSubscriptionNoteFilterInput | null,
  owner?: string | null,
};

export type OnDeleteNoteSubscription = {
  onDeleteNote?:  {
    __typename: "Note",
    id: string,
    parentID: string,
    title: string,
    description?: string | null,
    spaceMembershipID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateContentEntrySubscriptionVariables = {
  filter?: ModelSubscriptionContentEntryFilterInput | null,
  owner?: string | null,
};

export type OnCreateContentEntrySubscription = {
  onCreateContentEntry?:  {
    __typename: "ContentEntry",
    id: string,
    parentID: string,
    title: string,
    body?: string | null,
    coverImageUrl?: string | null,
    tags: Array< string >,
    appLink?:  {
      __typename: "AppLink",
      appLinkType: AppLinkType,
      appLinkScreen?: AppLinkScreen | null,
      url?: string | null,
      objectID?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateContentEntrySubscriptionVariables = {
  filter?: ModelSubscriptionContentEntryFilterInput | null,
  owner?: string | null,
};

export type OnUpdateContentEntrySubscription = {
  onUpdateContentEntry?:  {
    __typename: "ContentEntry",
    id: string,
    parentID: string,
    title: string,
    body?: string | null,
    coverImageUrl?: string | null,
    tags: Array< string >,
    appLink?:  {
      __typename: "AppLink",
      appLinkType: AppLinkType,
      appLinkScreen?: AppLinkScreen | null,
      url?: string | null,
      objectID?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteContentEntrySubscriptionVariables = {
  filter?: ModelSubscriptionContentEntryFilterInput | null,
  owner?: string | null,
};

export type OnDeleteContentEntrySubscription = {
  onDeleteContentEntry?:  {
    __typename: "ContentEntry",
    id: string,
    parentID: string,
    title: string,
    body?: string | null,
    coverImageUrl?: string | null,
    tags: Array< string >,
    appLink?:  {
      __typename: "AppLink",
      appLinkType: AppLinkType,
      appLinkScreen?: AppLinkScreen | null,
      url?: string | null,
      objectID?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateSharedContentEntrySubscriptionVariables = {
  filter?: ModelSubscriptionSharedContentEntryFilterInput | null,
};

export type OnCreateSharedContentEntrySubscription = {
  onCreateSharedContentEntry?:  {
    __typename: "SharedContentEntry",
    id: string,
    parentID: string,
    title: string,
    body?: string | null,
    coverImageUrl?: string | null,
    tags: Array< string >,
    origin:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    },
    appLink?:  {
      __typename: "AppLink",
      appLinkType: AppLinkType,
      appLinkScreen?: AppLinkScreen | null,
      url?: string | null,
      objectID?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSharedContentEntrySubscriptionVariables = {
  filter?: ModelSubscriptionSharedContentEntryFilterInput | null,
};

export type OnUpdateSharedContentEntrySubscription = {
  onUpdateSharedContentEntry?:  {
    __typename: "SharedContentEntry",
    id: string,
    parentID: string,
    title: string,
    body?: string | null,
    coverImageUrl?: string | null,
    tags: Array< string >,
    origin:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    },
    appLink?:  {
      __typename: "AppLink",
      appLinkType: AppLinkType,
      appLinkScreen?: AppLinkScreen | null,
      url?: string | null,
      objectID?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSharedContentEntrySubscriptionVariables = {
  filter?: ModelSubscriptionSharedContentEntryFilterInput | null,
};

export type OnDeleteSharedContentEntrySubscription = {
  onDeleteSharedContentEntry?:  {
    __typename: "SharedContentEntry",
    id: string,
    parentID: string,
    title: string,
    body?: string | null,
    coverImageUrl?: string | null,
    tags: Array< string >,
    origin:  {
      __typename: "Origin",
      originObjectType: OriginObjectType,
      originObjectID: string,
    },
    appLink?:  {
      __typename: "AppLink",
      appLinkType: AppLinkType,
      appLinkScreen?: AppLinkScreen | null,
      url?: string | null,
      objectID?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePlannerWeekSubscriptionVariables = {
  filter?: ModelSubscriptionPlannerWeekFilterInput | null,
  owner?: string | null,
};

export type OnCreatePlannerWeekSubscription = {
  onCreatePlannerWeek?:  {
    __typename: "PlannerWeek",
    id: string,
    schemaVersion: number,
    spaceMembershipID?: string | null,
    startDate: string,
    days:  Array< {
      __typename: "PlannerDay",
      entries:  Array< {
        __typename: "PlannerEntry",
        plannerEntryType?: PlannerEntryType | null,
        mealID?: string | null,
        objectID?: string | null,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdatePlannerWeekSubscriptionVariables = {
  filter?: ModelSubscriptionPlannerWeekFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePlannerWeekSubscription = {
  onUpdatePlannerWeek?:  {
    __typename: "PlannerWeek",
    id: string,
    schemaVersion: number,
    spaceMembershipID?: string | null,
    startDate: string,
    days:  Array< {
      __typename: "PlannerDay",
      entries:  Array< {
        __typename: "PlannerEntry",
        plannerEntryType?: PlannerEntryType | null,
        mealID?: string | null,
        objectID?: string | null,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeletePlannerWeekSubscriptionVariables = {
  filter?: ModelSubscriptionPlannerWeekFilterInput | null,
  owner?: string | null,
};

export type OnDeletePlannerWeekSubscription = {
  onDeletePlannerWeek?:  {
    __typename: "PlannerWeek",
    id: string,
    schemaVersion: number,
    spaceMembershipID?: string | null,
    startDate: string,
    days:  Array< {
      __typename: "PlannerDay",
      entries:  Array< {
        __typename: "PlannerEntry",
        plannerEntryType?: PlannerEntryType | null,
        mealID?: string | null,
        objectID?: string | null,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateShoppingListSubscriptionVariables = {
  filter?: ModelSubscriptionShoppingListFilterInput | null,
  owner?: string | null,
};

export type OnCreateShoppingListSubscription = {
  onCreateShoppingList?:  {
    __typename: "ShoppingList",
    id: string,
    schemaVersion: number,
    spaceMembershipID?: string | null,
    productsBoardID: string,
    title: string,
    shortDescription?: string | null,
    items:  Array< {
      __typename: "ShoppingListItem",
      id: string,
      name: string,
      total?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      quantities?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      linkedIngredient?:  {
        __typename: "LinkedIngredientDetails",
        status: boolean,
        linkedIngredientName: string,
      } | null,
      aisleLocation?: string | null,
      forMealIds?: Array< string > | null,
      checked?: boolean | null,
    } >,
    mealIDs?: Array< string > | null,
    mealImageUrls?: Array< string > | null,
    meals?:  Array< {
      __typename: "ShoppingListMeal",
      mealID?: string | null,
      recipes:  Array< {
        __typename: "Recipe",
        title: string,
        shortDescription?: string | null,
        imageUrl?: string | null,
        recipeUrl?: string | null,
        publisher?: string | null,
        chef?: string | null,
        mealiqId?: string | null,
        nutrition?:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        } | null,
        mealTypes?: Array< string > | null,
        tags?: Array< string > | null,
        supportedDiets?: Array< string > | null,
        ingredients:  Array< {
          __typename: "RecipeIngredient",
          id?: string | null,
          fullText: string,
          structuredIngredient?:  {
            __typename: "StructuredIngredient",
            name: string,
            quantity?: number | null,
            unitOfMeasure?: string | null,
            normalisedQuantity?: number | null,
            linkedIngredient?:  {
              __typename: "LinkedIngredientDetails",
              status: boolean,
              linkedIngredientName: string,
            } | null,
            resolvedNutrition?: boolean | null,
            resolvedNutritionError?: string | null,
          } | null,
          tokFullText?: string | null,
          tokens?:  Array< {
            __typename: "IngredientToken",
            fromChar: number,
            toChar: number,
            type?: string | null,
            label?: string | null,
          } | null > | null,
          scalingRules?: string | null,
          checkedInIngredientsList?: boolean | null,
        } >,
        preparations:  Array< {
          __typename: "RecipePreparation",
          id?: string | null,
          fullText: string,
        } >,
        utensils?:  Array< {
          __typename: "RecipeUtensil",
          id?: string | null,
          fullText: string,
        } > | null,
        servings?: number | null,
        cookTime?: number | null,
        prepTime?: number | null,
        totalTime?: number | null,
        recipeNotes?: string | null,
      } >,
    } > | null,
    activityRecords?:  Array< {
      __typename: "ShoppingListActivity",
      activityType: ShoppingActivityType,
      occurredOn: string,
      vendorName?: string | null,
      transactionID?: string | null,
      shoppingListItemIDs?: Array< string > | null,
    } > | null,
    userItems?:  Array< {
      __typename: "ShoppingListItem",
      id: string,
      name: string,
      total?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      quantities?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      linkedIngredient?:  {
        __typename: "LinkedIngredientDetails",
        status: boolean,
        linkedIngredientName: string,
      } | null,
      aisleLocation?: string | null,
      forMealIds?: Array< string > | null,
      checked?: boolean | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateShoppingListSubscriptionVariables = {
  filter?: ModelSubscriptionShoppingListFilterInput | null,
  owner?: string | null,
};

export type OnUpdateShoppingListSubscription = {
  onUpdateShoppingList?:  {
    __typename: "ShoppingList",
    id: string,
    schemaVersion: number,
    spaceMembershipID?: string | null,
    productsBoardID: string,
    title: string,
    shortDescription?: string | null,
    items:  Array< {
      __typename: "ShoppingListItem",
      id: string,
      name: string,
      total?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      quantities?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      linkedIngredient?:  {
        __typename: "LinkedIngredientDetails",
        status: boolean,
        linkedIngredientName: string,
      } | null,
      aisleLocation?: string | null,
      forMealIds?: Array< string > | null,
      checked?: boolean | null,
    } >,
    mealIDs?: Array< string > | null,
    mealImageUrls?: Array< string > | null,
    meals?:  Array< {
      __typename: "ShoppingListMeal",
      mealID?: string | null,
      recipes:  Array< {
        __typename: "Recipe",
        title: string,
        shortDescription?: string | null,
        imageUrl?: string | null,
        recipeUrl?: string | null,
        publisher?: string | null,
        chef?: string | null,
        mealiqId?: string | null,
        nutrition?:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        } | null,
        mealTypes?: Array< string > | null,
        tags?: Array< string > | null,
        supportedDiets?: Array< string > | null,
        ingredients:  Array< {
          __typename: "RecipeIngredient",
          id?: string | null,
          fullText: string,
          structuredIngredient?:  {
            __typename: "StructuredIngredient",
            name: string,
            quantity?: number | null,
            unitOfMeasure?: string | null,
            normalisedQuantity?: number | null,
            linkedIngredient?:  {
              __typename: "LinkedIngredientDetails",
              status: boolean,
              linkedIngredientName: string,
            } | null,
            resolvedNutrition?: boolean | null,
            resolvedNutritionError?: string | null,
          } | null,
          tokFullText?: string | null,
          tokens?:  Array< {
            __typename: "IngredientToken",
            fromChar: number,
            toChar: number,
            type?: string | null,
            label?: string | null,
          } | null > | null,
          scalingRules?: string | null,
          checkedInIngredientsList?: boolean | null,
        } >,
        preparations:  Array< {
          __typename: "RecipePreparation",
          id?: string | null,
          fullText: string,
        } >,
        utensils?:  Array< {
          __typename: "RecipeUtensil",
          id?: string | null,
          fullText: string,
        } > | null,
        servings?: number | null,
        cookTime?: number | null,
        prepTime?: number | null,
        totalTime?: number | null,
        recipeNotes?: string | null,
      } >,
    } > | null,
    activityRecords?:  Array< {
      __typename: "ShoppingListActivity",
      activityType: ShoppingActivityType,
      occurredOn: string,
      vendorName?: string | null,
      transactionID?: string | null,
      shoppingListItemIDs?: Array< string > | null,
    } > | null,
    userItems?:  Array< {
      __typename: "ShoppingListItem",
      id: string,
      name: string,
      total?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      quantities?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      linkedIngredient?:  {
        __typename: "LinkedIngredientDetails",
        status: boolean,
        linkedIngredientName: string,
      } | null,
      aisleLocation?: string | null,
      forMealIds?: Array< string > | null,
      checked?: boolean | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteShoppingListSubscriptionVariables = {
  filter?: ModelSubscriptionShoppingListFilterInput | null,
  owner?: string | null,
};

export type OnDeleteShoppingListSubscription = {
  onDeleteShoppingList?:  {
    __typename: "ShoppingList",
    id: string,
    schemaVersion: number,
    spaceMembershipID?: string | null,
    productsBoardID: string,
    title: string,
    shortDescription?: string | null,
    items:  Array< {
      __typename: "ShoppingListItem",
      id: string,
      name: string,
      total?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      quantities?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      linkedIngredient?:  {
        __typename: "LinkedIngredientDetails",
        status: boolean,
        linkedIngredientName: string,
      } | null,
      aisleLocation?: string | null,
      forMealIds?: Array< string > | null,
      checked?: boolean | null,
    } >,
    mealIDs?: Array< string > | null,
    mealImageUrls?: Array< string > | null,
    meals?:  Array< {
      __typename: "ShoppingListMeal",
      mealID?: string | null,
      recipes:  Array< {
        __typename: "Recipe",
        title: string,
        shortDescription?: string | null,
        imageUrl?: string | null,
        recipeUrl?: string | null,
        publisher?: string | null,
        chef?: string | null,
        mealiqId?: string | null,
        nutrition?:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        } | null,
        mealTypes?: Array< string > | null,
        tags?: Array< string > | null,
        supportedDiets?: Array< string > | null,
        ingredients:  Array< {
          __typename: "RecipeIngredient",
          id?: string | null,
          fullText: string,
          structuredIngredient?:  {
            __typename: "StructuredIngredient",
            name: string,
            quantity?: number | null,
            unitOfMeasure?: string | null,
            normalisedQuantity?: number | null,
            linkedIngredient?:  {
              __typename: "LinkedIngredientDetails",
              status: boolean,
              linkedIngredientName: string,
            } | null,
            resolvedNutrition?: boolean | null,
            resolvedNutritionError?: string | null,
          } | null,
          tokFullText?: string | null,
          tokens?:  Array< {
            __typename: "IngredientToken",
            fromChar: number,
            toChar: number,
            type?: string | null,
            label?: string | null,
          } | null > | null,
          scalingRules?: string | null,
          checkedInIngredientsList?: boolean | null,
        } >,
        preparations:  Array< {
          __typename: "RecipePreparation",
          id?: string | null,
          fullText: string,
        } >,
        utensils?:  Array< {
          __typename: "RecipeUtensil",
          id?: string | null,
          fullText: string,
        } > | null,
        servings?: number | null,
        cookTime?: number | null,
        prepTime?: number | null,
        totalTime?: number | null,
        recipeNotes?: string | null,
      } >,
    } > | null,
    activityRecords?:  Array< {
      __typename: "ShoppingListActivity",
      activityType: ShoppingActivityType,
      occurredOn: string,
      vendorName?: string | null,
      transactionID?: string | null,
      shoppingListItemIDs?: Array< string > | null,
    } > | null,
    userItems?:  Array< {
      __typename: "ShoppingListItem",
      id: string,
      name: string,
      total?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      quantities?:  Array< {
        __typename: "UnitQuantity",
        quantity?: number | null,
        prettyQuantity?: string | null,
        unitOfMeasure?: string | null,
      } > | null,
      linkedIngredient?:  {
        __typename: "LinkedIngredientDetails",
        status: boolean,
        linkedIngredientName: string,
      } | null,
      aisleLocation?: string | null,
      forMealIds?: Array< string > | null,
      checked?: boolean | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateProductsBoardSubscriptionVariables = {
  filter?: ModelSubscriptionProductsBoardFilterInput | null,
  owner?: string | null,
};

export type OnCreateProductsBoardSubscription = {
  onCreateProductsBoard?:  {
    __typename: "ProductsBoard",
    id: string,
    schemaVersion: number,
    title: string,
    spaceMembershipID?: string | null,
    shoppingListGroups:  Array< {
      __typename: "ShoppingListGroup",
      id: string,
      title: string,
      shoppingListIDs?: Array< string > | null,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateProductsBoardSubscriptionVariables = {
  filter?: ModelSubscriptionProductsBoardFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProductsBoardSubscription = {
  onUpdateProductsBoard?:  {
    __typename: "ProductsBoard",
    id: string,
    schemaVersion: number,
    title: string,
    spaceMembershipID?: string | null,
    shoppingListGroups:  Array< {
      __typename: "ShoppingListGroup",
      id: string,
      title: string,
      shoppingListIDs?: Array< string > | null,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteProductsBoardSubscriptionVariables = {
  filter?: ModelSubscriptionProductsBoardFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProductsBoardSubscription = {
  onDeleteProductsBoard?:  {
    __typename: "ProductsBoard",
    id: string,
    schemaVersion: number,
    title: string,
    spaceMembershipID?: string | null,
    shoppingListGroups:  Array< {
      __typename: "ShoppingListGroup",
      id: string,
      title: string,
      shoppingListIDs?: Array< string > | null,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateGlobalTagSubscriptionVariables = {
  filter?: ModelSubscriptionGlobalTagFilterInput | null,
};

export type OnCreateGlobalTagSubscription = {
  onCreateGlobalTag?:  {
    __typename: "GlobalTag",
    id: string,
    tag: string,
    count: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGlobalTagSubscriptionVariables = {
  filter?: ModelSubscriptionGlobalTagFilterInput | null,
};

export type OnUpdateGlobalTagSubscription = {
  onUpdateGlobalTag?:  {
    __typename: "GlobalTag",
    id: string,
    tag: string,
    count: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGlobalTagSubscriptionVariables = {
  filter?: ModelSubscriptionGlobalTagFilterInput | null,
};

export type OnDeleteGlobalTagSubscription = {
  onDeleteGlobalTag?:  {
    __typename: "GlobalTag",
    id: string,
    tag: string,
    count: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateIngredientEditEventSubscriptionVariables = {
  filter?: ModelSubscriptionIngredientEditEventFilterInput | null,
  owner?: string | null,
};

export type OnCreateIngredientEditEventSubscription = {
  onCreateIngredientEditEvent?:  {
    __typename: "IngredientEditEvent",
    mealID: string,
    recipeID: string,
    editMethod?: EditMethod | null,
    ingredientID: string,
    fullTextBefore: string,
    fullTextAfter: string,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateIngredientEditEventSubscriptionVariables = {
  filter?: ModelSubscriptionIngredientEditEventFilterInput | null,
  owner?: string | null,
};

export type OnUpdateIngredientEditEventSubscription = {
  onUpdateIngredientEditEvent?:  {
    __typename: "IngredientEditEvent",
    mealID: string,
    recipeID: string,
    editMethod?: EditMethod | null,
    ingredientID: string,
    fullTextBefore: string,
    fullTextAfter: string,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteIngredientEditEventSubscriptionVariables = {
  filter?: ModelSubscriptionIngredientEditEventFilterInput | null,
  owner?: string | null,
};

export type OnDeleteIngredientEditEventSubscription = {
  onDeleteIngredientEditEvent?:  {
    __typename: "IngredientEditEvent",
    mealID: string,
    recipeID: string,
    editMethod?: EditMethod | null,
    ingredientID: string,
    fullTextBefore: string,
    fullTextAfter: string,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateIngredientEditSuggestionSubscriptionVariables = {
  filter?: ModelSubscriptionIngredientEditSuggestionFilterInput | null,
};

export type OnCreateIngredientEditSuggestionSubscription = {
  onCreateIngredientEditSuggestion?:  {
    __typename: "IngredientEditSuggestion",
    id: string,
    inputFullText: string,
    suggestion: string,
    weight: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateIngredientEditSuggestionSubscriptionVariables = {
  filter?: ModelSubscriptionIngredientEditSuggestionFilterInput | null,
};

export type OnUpdateIngredientEditSuggestionSubscription = {
  onUpdateIngredientEditSuggestion?:  {
    __typename: "IngredientEditSuggestion",
    id: string,
    inputFullText: string,
    suggestion: string,
    weight: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteIngredientEditSuggestionSubscriptionVariables = {
  filter?: ModelSubscriptionIngredientEditSuggestionFilterInput | null,
};

export type OnDeleteIngredientEditSuggestionSubscription = {
  onDeleteIngredientEditSuggestion?:  {
    __typename: "IngredientEditSuggestion",
    id: string,
    inputFullText: string,
    suggestion: string,
    weight: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateIngredientEditSuggestionByIngredientIDSubscriptionVariables = {
  filter?: ModelSubscriptionIngredientEditSuggestionByIngredientIDFilterInput | null,
};

export type OnCreateIngredientEditSuggestionByIngredientIDSubscription = {
  onCreateIngredientEditSuggestionByIngredientID?:  {
    __typename: "IngredientEditSuggestionByIngredientID",
    id: string,
    ingredientID: string,
    suggestion: string,
    weight: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateIngredientEditSuggestionByIngredientIDSubscriptionVariables = {
  filter?: ModelSubscriptionIngredientEditSuggestionByIngredientIDFilterInput | null,
};

export type OnUpdateIngredientEditSuggestionByIngredientIDSubscription = {
  onUpdateIngredientEditSuggestionByIngredientID?:  {
    __typename: "IngredientEditSuggestionByIngredientID",
    id: string,
    ingredientID: string,
    suggestion: string,
    weight: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteIngredientEditSuggestionByIngredientIDSubscriptionVariables = {
  filter?: ModelSubscriptionIngredientEditSuggestionByIngredientIDFilterInput | null,
};

export type OnDeleteIngredientEditSuggestionByIngredientIDSubscription = {
  onDeleteIngredientEditSuggestionByIngredientID?:  {
    __typename: "IngredientEditSuggestionByIngredientID",
    id: string,
    ingredientID: string,
    suggestion: string,
    weight: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateProgrammeSubscriptionVariables = {
  filter?: ModelSubscriptionProgrammeFilterInput | null,
  owner?: string | null,
};

export type OnCreateProgrammeSubscription = {
  onCreateProgramme?:  {
    __typename: "Programme",
    id: string,
    title: string,
    shortDescription: string,
    description?: string | null,
    coverImageUrl?: string | null,
    plans:  Array< {
      __typename: "ProgrammePlan",
      id: string,
      title: string,
      shortDescription: string,
      description?: string | null,
      coverImageUrl?: string | null,
      days:  Array< {
        __typename: "ProgrammePlanDay",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "ProgrammeEntry",
          id: string,
          programmeEntryType: EntryType,
          objectID: string,
        } >,
      } >,
      nutritionConstraints?:  {
        __typename: "NutritionConstraints",
        nutritionMetricConstraints?:  Array< {
          __typename: "NutritionMetricConstraint",
          id: string,
          nutritionMetric: string,
          operator: ConstraintOperator,
          value: string,
          units: string,
        } > | null,
        excludeIngredients?: Array< string > | null,
        foodPreferences?: Array< FoodPreference > | null,
      } | null,
      showNutritionToUsers?: boolean | null,
    } >,
    locales?: Array< string > | null,
    mealTypes?: Array< string > | null,
    calorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    nutritionConstraints?:  {
      __typename: "NutritionConstraints",
      nutritionMetricConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      excludeIngredients?: Array< string > | null,
      foodPreferences?: Array< FoodPreference > | null,
    } | null,
    showNutritionToUsers?: boolean | null,
    copyMealsExactly?: boolean | null,
    personalisedMealScaling?: boolean | null,
    recipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    databaseRecipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    recipesBoardIDs?: Array< string > | null,
    onboardingConfiguration?:  {
      __typename: "ProgrammeOnboardingConfiguration",
      questionIDs: Array< string >,
    } | null,
    categoryTags?: Array< string > | null,
    shareRecords?:  Array< {
      __typename: "ProgrammeShareRecord",
      sharedProgrammeID: string,
      version: string,
      updatedOn: string,
    } | null > | null,
    availableInMembershipTierIDs?: Array< string > | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateProgrammeSubscriptionVariables = {
  filter?: ModelSubscriptionProgrammeFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProgrammeSubscription = {
  onUpdateProgramme?:  {
    __typename: "Programme",
    id: string,
    title: string,
    shortDescription: string,
    description?: string | null,
    coverImageUrl?: string | null,
    plans:  Array< {
      __typename: "ProgrammePlan",
      id: string,
      title: string,
      shortDescription: string,
      description?: string | null,
      coverImageUrl?: string | null,
      days:  Array< {
        __typename: "ProgrammePlanDay",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "ProgrammeEntry",
          id: string,
          programmeEntryType: EntryType,
          objectID: string,
        } >,
      } >,
      nutritionConstraints?:  {
        __typename: "NutritionConstraints",
        nutritionMetricConstraints?:  Array< {
          __typename: "NutritionMetricConstraint",
          id: string,
          nutritionMetric: string,
          operator: ConstraintOperator,
          value: string,
          units: string,
        } > | null,
        excludeIngredients?: Array< string > | null,
        foodPreferences?: Array< FoodPreference > | null,
      } | null,
      showNutritionToUsers?: boolean | null,
    } >,
    locales?: Array< string > | null,
    mealTypes?: Array< string > | null,
    calorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    nutritionConstraints?:  {
      __typename: "NutritionConstraints",
      nutritionMetricConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      excludeIngredients?: Array< string > | null,
      foodPreferences?: Array< FoodPreference > | null,
    } | null,
    showNutritionToUsers?: boolean | null,
    copyMealsExactly?: boolean | null,
    personalisedMealScaling?: boolean | null,
    recipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    databaseRecipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    recipesBoardIDs?: Array< string > | null,
    onboardingConfiguration?:  {
      __typename: "ProgrammeOnboardingConfiguration",
      questionIDs: Array< string >,
    } | null,
    categoryTags?: Array< string > | null,
    shareRecords?:  Array< {
      __typename: "ProgrammeShareRecord",
      sharedProgrammeID: string,
      version: string,
      updatedOn: string,
    } | null > | null,
    availableInMembershipTierIDs?: Array< string > | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteProgrammeSubscriptionVariables = {
  filter?: ModelSubscriptionProgrammeFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProgrammeSubscription = {
  onDeleteProgramme?:  {
    __typename: "Programme",
    id: string,
    title: string,
    shortDescription: string,
    description?: string | null,
    coverImageUrl?: string | null,
    plans:  Array< {
      __typename: "ProgrammePlan",
      id: string,
      title: string,
      shortDescription: string,
      description?: string | null,
      coverImageUrl?: string | null,
      days:  Array< {
        __typename: "ProgrammePlanDay",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "ProgrammeEntry",
          id: string,
          programmeEntryType: EntryType,
          objectID: string,
        } >,
      } >,
      nutritionConstraints?:  {
        __typename: "NutritionConstraints",
        nutritionMetricConstraints?:  Array< {
          __typename: "NutritionMetricConstraint",
          id: string,
          nutritionMetric: string,
          operator: ConstraintOperator,
          value: string,
          units: string,
        } > | null,
        excludeIngredients?: Array< string > | null,
        foodPreferences?: Array< FoodPreference > | null,
      } | null,
      showNutritionToUsers?: boolean | null,
    } >,
    locales?: Array< string > | null,
    mealTypes?: Array< string > | null,
    calorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    nutritionConstraints?:  {
      __typename: "NutritionConstraints",
      nutritionMetricConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      excludeIngredients?: Array< string > | null,
      foodPreferences?: Array< FoodPreference > | null,
    } | null,
    showNutritionToUsers?: boolean | null,
    copyMealsExactly?: boolean | null,
    personalisedMealScaling?: boolean | null,
    recipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    databaseRecipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    recipesBoardIDs?: Array< string > | null,
    onboardingConfiguration?:  {
      __typename: "ProgrammeOnboardingConfiguration",
      questionIDs: Array< string >,
    } | null,
    categoryTags?: Array< string > | null,
    shareRecords?:  Array< {
      __typename: "ProgrammeShareRecord",
      sharedProgrammeID: string,
      version: string,
      updatedOn: string,
    } | null > | null,
    availableInMembershipTierIDs?: Array< string > | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateSharedProgrammeSubscriptionVariables = {
  filter?: ModelSubscriptionSharedProgrammeFilterInput | null,
  owner?: string | null,
};

export type OnCreateSharedProgrammeSubscription = {
  onCreateSharedProgramme?:  {
    __typename: "SharedProgramme",
    spaceID: string,
    programmeID: string,
    title: string,
    shortDescription: string,
    description?: string | null,
    coverImageUrl?: string | null,
    plans:  Array< {
      __typename: "ProgrammePlan",
      id: string,
      title: string,
      shortDescription: string,
      description?: string | null,
      coverImageUrl?: string | null,
      days:  Array< {
        __typename: "ProgrammePlanDay",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "ProgrammeEntry",
          id: string,
          programmeEntryType: EntryType,
          objectID: string,
        } >,
      } >,
      nutritionConstraints?:  {
        __typename: "NutritionConstraints",
        nutritionMetricConstraints?:  Array< {
          __typename: "NutritionMetricConstraint",
          id: string,
          nutritionMetric: string,
          operator: ConstraintOperator,
          value: string,
          units: string,
        } > | null,
        excludeIngredients?: Array< string > | null,
        foodPreferences?: Array< FoodPreference > | null,
      } | null,
      showNutritionToUsers?: boolean | null,
    } >,
    locales?: Array< string > | null,
    mealTypes?: Array< string > | null,
    calorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    nutritionConstraints?:  {
      __typename: "NutritionConstraints",
      nutritionMetricConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      excludeIngredients?: Array< string > | null,
      foodPreferences?: Array< FoodPreference > | null,
    } | null,
    showNutritionToUsers?: boolean | null,
    copyMealsExactly?: boolean | null,
    personalisedMealScaling?: boolean | null,
    recipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    databaseRecipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    sharedRecipesBoardIDs?: Array< string > | null,
    onboardingConfiguration?:  {
      __typename: "ProgrammeOnboardingConfiguration",
      questionIDs: Array< string >,
    } | null,
    categoryTags?: Array< string > | null,
    version: string,
    availableInMembershipTierIDs?: Array< string > | null,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateSharedProgrammeSubscriptionVariables = {
  filter?: ModelSubscriptionSharedProgrammeFilterInput | null,
  owner?: string | null,
};

export type OnUpdateSharedProgrammeSubscription = {
  onUpdateSharedProgramme?:  {
    __typename: "SharedProgramme",
    spaceID: string,
    programmeID: string,
    title: string,
    shortDescription: string,
    description?: string | null,
    coverImageUrl?: string | null,
    plans:  Array< {
      __typename: "ProgrammePlan",
      id: string,
      title: string,
      shortDescription: string,
      description?: string | null,
      coverImageUrl?: string | null,
      days:  Array< {
        __typename: "ProgrammePlanDay",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "ProgrammeEntry",
          id: string,
          programmeEntryType: EntryType,
          objectID: string,
        } >,
      } >,
      nutritionConstraints?:  {
        __typename: "NutritionConstraints",
        nutritionMetricConstraints?:  Array< {
          __typename: "NutritionMetricConstraint",
          id: string,
          nutritionMetric: string,
          operator: ConstraintOperator,
          value: string,
          units: string,
        } > | null,
        excludeIngredients?: Array< string > | null,
        foodPreferences?: Array< FoodPreference > | null,
      } | null,
      showNutritionToUsers?: boolean | null,
    } >,
    locales?: Array< string > | null,
    mealTypes?: Array< string > | null,
    calorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    nutritionConstraints?:  {
      __typename: "NutritionConstraints",
      nutritionMetricConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      excludeIngredients?: Array< string > | null,
      foodPreferences?: Array< FoodPreference > | null,
    } | null,
    showNutritionToUsers?: boolean | null,
    copyMealsExactly?: boolean | null,
    personalisedMealScaling?: boolean | null,
    recipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    databaseRecipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    sharedRecipesBoardIDs?: Array< string > | null,
    onboardingConfiguration?:  {
      __typename: "ProgrammeOnboardingConfiguration",
      questionIDs: Array< string >,
    } | null,
    categoryTags?: Array< string > | null,
    version: string,
    availableInMembershipTierIDs?: Array< string > | null,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteSharedProgrammeSubscriptionVariables = {
  filter?: ModelSubscriptionSharedProgrammeFilterInput | null,
  owner?: string | null,
};

export type OnDeleteSharedProgrammeSubscription = {
  onDeleteSharedProgramme?:  {
    __typename: "SharedProgramme",
    spaceID: string,
    programmeID: string,
    title: string,
    shortDescription: string,
    description?: string | null,
    coverImageUrl?: string | null,
    plans:  Array< {
      __typename: "ProgrammePlan",
      id: string,
      title: string,
      shortDescription: string,
      description?: string | null,
      coverImageUrl?: string | null,
      days:  Array< {
        __typename: "ProgrammePlanDay",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "ProgrammeEntry",
          id: string,
          programmeEntryType: EntryType,
          objectID: string,
        } >,
      } >,
      nutritionConstraints?:  {
        __typename: "NutritionConstraints",
        nutritionMetricConstraints?:  Array< {
          __typename: "NutritionMetricConstraint",
          id: string,
          nutritionMetric: string,
          operator: ConstraintOperator,
          value: string,
          units: string,
        } > | null,
        excludeIngredients?: Array< string > | null,
        foodPreferences?: Array< FoodPreference > | null,
      } | null,
      showNutritionToUsers?: boolean | null,
    } >,
    locales?: Array< string > | null,
    mealTypes?: Array< string > | null,
    calorieSplits?:  Array< {
      __typename: "CalorieSplit",
      mealType: string,
      fraction: number,
    } > | null,
    nutritionConstraints?:  {
      __typename: "NutritionConstraints",
      nutritionMetricConstraints?:  Array< {
        __typename: "NutritionMetricConstraint",
        id: string,
        nutritionMetric: string,
        operator: ConstraintOperator,
        value: string,
        units: string,
      } > | null,
      excludeIngredients?: Array< string > | null,
      foodPreferences?: Array< FoodPreference > | null,
    } | null,
    showNutritionToUsers?: boolean | null,
    copyMealsExactly?: boolean | null,
    personalisedMealScaling?: boolean | null,
    recipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    databaseRecipesBoard?:  {
      __typename: "EmbeddedProgrammeRecipesBoard",
      id: string,
      title: string,
      menus:  Array< {
        __typename: "Menu",
        id: string,
        title: string,
        mealIDs: Array< string >,
      } >,
      embeddedInContainerType?: ContainerType | null,
      embeddedInContainerID?: string | null,
    } | null,
    sharedRecipesBoardIDs?: Array< string > | null,
    onboardingConfiguration?:  {
      __typename: "ProgrammeOnboardingConfiguration",
      questionIDs: Array< string >,
    } | null,
    categoryTags?: Array< string > | null,
    version: string,
    availableInMembershipTierIDs?: Array< string > | null,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateUserProgrammeEnrollmentSubscriptionVariables = {
  filter?: ModelSubscriptionUserProgrammeEnrollmentFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserProgrammeEnrollmentSubscription = {
  onCreateUserProgrammeEnrollment?:  {
    __typename: "UserProgrammeEnrollment",
    spaceMembershipID: string,
    sharedProgrammeID: string,
    enrolledAt: string,
    onboardingAnswers:  Array< {
      __typename: "OnboardingAnswer",
      questionID: string,
      answer: string,
    } >,
    endedAt?: string | null,
    eventRecords?:  Array< {
      __typename: "UserProgrammeEnrollmentEventRecord",
      calendarDay: string,
      eventType: UserProgrammeEnrollmentEventType,
      updatedOn: string,
    } > | null,
    customTargetCalories?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserProgrammeEnrollmentSubscriptionVariables = {
  filter?: ModelSubscriptionUserProgrammeEnrollmentFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserProgrammeEnrollmentSubscription = {
  onUpdateUserProgrammeEnrollment?:  {
    __typename: "UserProgrammeEnrollment",
    spaceMembershipID: string,
    sharedProgrammeID: string,
    enrolledAt: string,
    onboardingAnswers:  Array< {
      __typename: "OnboardingAnswer",
      questionID: string,
      answer: string,
    } >,
    endedAt?: string | null,
    eventRecords?:  Array< {
      __typename: "UserProgrammeEnrollmentEventRecord",
      calendarDay: string,
      eventType: UserProgrammeEnrollmentEventType,
      updatedOn: string,
    } > | null,
    customTargetCalories?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserProgrammeEnrollmentSubscriptionVariables = {
  filter?: ModelSubscriptionUserProgrammeEnrollmentFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserProgrammeEnrollmentSubscription = {
  onDeleteUserProgrammeEnrollment?:  {
    __typename: "UserProgrammeEnrollment",
    spaceMembershipID: string,
    sharedProgrammeID: string,
    enrolledAt: string,
    onboardingAnswers:  Array< {
      __typename: "OnboardingAnswer",
      questionID: string,
      answer: string,
    } >,
    endedAt?: string | null,
    eventRecords?:  Array< {
      __typename: "UserProgrammeEnrollmentEventRecord",
      calendarDay: string,
      eventType: UserProgrammeEnrollmentEventType,
      updatedOn: string,
    } > | null,
    customTargetCalories?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateGRCRecipeSubscriptionVariables = {
  filter?: ModelSubscriptionGRCRecipeFilterInput | null,
};

export type OnCreateGRCRecipeSubscription = {
  onCreateGRCRecipe?:  {
    __typename: "GRCRecipe",
    grcRecipeID: string,
    recipe:  {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    },
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGRCRecipeSubscriptionVariables = {
  filter?: ModelSubscriptionGRCRecipeFilterInput | null,
};

export type OnUpdateGRCRecipeSubscription = {
  onUpdateGRCRecipe?:  {
    __typename: "GRCRecipe",
    grcRecipeID: string,
    recipe:  {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    },
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGRCRecipeSubscriptionVariables = {
  filter?: ModelSubscriptionGRCRecipeFilterInput | null,
};

export type OnDeleteGRCRecipeSubscription = {
  onDeleteGRCRecipe?:  {
    __typename: "GRCRecipe",
    grcRecipeID: string,
    recipe:  {
      __typename: "Recipe",
      title: string,
      shortDescription?: string | null,
      imageUrl?: string | null,
      recipeUrl?: string | null,
      publisher?: string | null,
      chef?: string | null,
      mealiqId?: string | null,
      nutrition?:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      } | null,
      mealTypes?: Array< string > | null,
      tags?: Array< string > | null,
      supportedDiets?: Array< string > | null,
      ingredients:  Array< {
        __typename: "RecipeIngredient",
        id?: string | null,
        fullText: string,
        structuredIngredient?:  {
          __typename: "StructuredIngredient",
          name: string,
          quantity?: number | null,
          unitOfMeasure?: string | null,
          normalisedQuantity?: number | null,
          linkedIngredient?:  {
            __typename: "LinkedIngredientDetails",
            status: boolean,
            linkedIngredientName: string,
          } | null,
          resolvedNutrition?: boolean | null,
          resolvedNutritionError?: string | null,
        } | null,
        tokFullText?: string | null,
        tokens?:  Array< {
          __typename: "IngredientToken",
          fromChar: number,
          toChar: number,
          type?: string | null,
          label?: string | null,
        } | null > | null,
        scalingRules?: string | null,
        checkedInIngredientsList?: boolean | null,
      } >,
      preparations:  Array< {
        __typename: "RecipePreparation",
        id?: string | null,
        fullText: string,
      } >,
      utensils?:  Array< {
        __typename: "RecipeUtensil",
        id?: string | null,
        fullText: string,
      } > | null,
      servings?: number | null,
      cookTime?: number | null,
      prepTime?: number | null,
      totalTime?: number | null,
      recipeNotes?: string | null,
    },
    derivedNutrition?:  {
      __typename: "DerivedNutrition",
      totalNutritionPerServing:  {
        __typename: "Nutrition",
        calories?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        fibre?: number | null,
        sugar?: number | null,
        saturates?: number | null,
        salt?: number | null,
        veg_weight?: number | null,
        cholesterol?: number | null,
        calcium?: number | null,
        iron?: number | null,
        magnesium?: number | null,
        potassium?: number | null,
        vitamin_a?: number | null,
        vitamin_b1?: number | null,
        vitamin_b2?: number | null,
        vitamin_b3?: number | null,
        vitamin_b5?: number | null,
        vitamin_b6?: number | null,
        vitamin_b12?: number | null,
        vitamin_c?: number | null,
        vitamin_d?: number | null,
        vitamin_e?: number | null,
        vitamin_k?: number | null,
        phosphorous?: number | null,
        zinc?: number | null,
        copper?: number | null,
        manganese?: number | null,
        selenium?: number | null,
        folate?: number | null,
        omega3_dha?: number | null,
        omega3_dpa?: number | null,
        omega3_epa?: number | null,
        sucrose?: number | null,
        glucose?: number | null,
        fructose?: number | null,
        lactose?: number | null,
        maltose?: number | null,
      },
      ingredientNutrition:  Array< {
        __typename: "DerivedIngredientNutrition",
        ingredientID?: string | null,
        nutrition:  {
          __typename: "Nutrition",
          calories?: number | null,
          protein?: number | null,
          carbohydrate?: number | null,
          fat?: number | null,
          fibre?: number | null,
          sugar?: number | null,
          saturates?: number | null,
          salt?: number | null,
          veg_weight?: number | null,
          cholesterol?: number | null,
          calcium?: number | null,
          iron?: number | null,
          magnesium?: number | null,
          potassium?: number | null,
          vitamin_a?: number | null,
          vitamin_b1?: number | null,
          vitamin_b2?: number | null,
          vitamin_b3?: number | null,
          vitamin_b5?: number | null,
          vitamin_b6?: number | null,
          vitamin_b12?: number | null,
          vitamin_c?: number | null,
          vitamin_d?: number | null,
          vitamin_e?: number | null,
          vitamin_k?: number | null,
          phosphorous?: number | null,
          zinc?: number | null,
          copper?: number | null,
          manganese?: number | null,
          selenium?: number | null,
          folate?: number | null,
          omega3_dha?: number | null,
          omega3_dpa?: number | null,
          omega3_epa?: number | null,
          sucrose?: number | null,
          glucose?: number | null,
          fructose?: number | null,
          lactose?: number | null,
          maltose?: number | null,
        },
        resolvedNutrition?: boolean | null,
        error?: string | null,
        resolvedIngredientWeight?: number | null,
      } >,
      foodAllergens?: Array< string > | null,
      foodPreferences?: Array< string > | null,
      status?: boolean | null,
      macroRatio?:  {
        __typename: "MacroRatio",
        carbohydrate?: number | null,
        protein?: number | null,
        fat?: number | null,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSharedContentBoardSubscriptionVariables = {
  filter?: ModelSubscriptionSharedContentBoardFilterInput | null,
  owner?: string | null,
};

export type OnCreateSharedContentBoardSubscription = {
  onCreateSharedContentBoard?:  {
    __typename: "SharedContentBoard",
    id: string,
    title: string,
    lanes:  Array< {
      __typename: "SharedContentBoardLane",
      id: string,
      title: string,
      entries:  Array< {
        __typename: "SharedContentBoardEntry",
        id: string,
        entryType: EntryType,
        objectID: string,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateSharedContentBoardSubscriptionVariables = {
  filter?: ModelSubscriptionSharedContentBoardFilterInput | null,
  owner?: string | null,
};

export type OnUpdateSharedContentBoardSubscription = {
  onUpdateSharedContentBoard?:  {
    __typename: "SharedContentBoard",
    id: string,
    title: string,
    lanes:  Array< {
      __typename: "SharedContentBoardLane",
      id: string,
      title: string,
      entries:  Array< {
        __typename: "SharedContentBoardEntry",
        id: string,
        entryType: EntryType,
        objectID: string,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteSharedContentBoardSubscriptionVariables = {
  filter?: ModelSubscriptionSharedContentBoardFilterInput | null,
  owner?: string | null,
};

export type OnDeleteSharedContentBoardSubscription = {
  onDeleteSharedContentBoard?:  {
    __typename: "SharedContentBoard",
    id: string,
    title: string,
    lanes:  Array< {
      __typename: "SharedContentBoardLane",
      id: string,
      title: string,
      entries:  Array< {
        __typename: "SharedContentBoardEntry",
        id: string,
        entryType: EntryType,
        objectID: string,
      } >,
    } >,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateSpaceIAPSubscriptionSubscriptionVariables = {
  filter?: ModelSubscriptionSpaceIAPSubscriptionFilterInput | null,
  owner?: string | null,
};

export type OnCreateSpaceIAPSubscriptionSubscription = {
  onCreateSpaceIAPSubscription?:  {
    __typename: "SpaceIAPSubscription",
    spaceID: string,
    productID: string,
    title: string,
    shortDescription?: string | null,
    currencyCode: string,
    priceStr: string,
    bundleID: string,
    duration?: SubscriptionDuration | null,
    signUpButtonLabelOverride?: string | null,
    appStoreID?: AppStoreID | null,
    rank?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateSpaceIAPSubscriptionSubscriptionVariables = {
  filter?: ModelSubscriptionSpaceIAPSubscriptionFilterInput | null,
  owner?: string | null,
};

export type OnUpdateSpaceIAPSubscriptionSubscription = {
  onUpdateSpaceIAPSubscription?:  {
    __typename: "SpaceIAPSubscription",
    spaceID: string,
    productID: string,
    title: string,
    shortDescription?: string | null,
    currencyCode: string,
    priceStr: string,
    bundleID: string,
    duration?: SubscriptionDuration | null,
    signUpButtonLabelOverride?: string | null,
    appStoreID?: AppStoreID | null,
    rank?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteSpaceIAPSubscriptionSubscriptionVariables = {
  filter?: ModelSubscriptionSpaceIAPSubscriptionFilterInput | null,
  owner?: string | null,
};

export type OnDeleteSpaceIAPSubscriptionSubscription = {
  onDeleteSpaceIAPSubscription?:  {
    __typename: "SpaceIAPSubscription",
    spaceID: string,
    productID: string,
    title: string,
    shortDescription?: string | null,
    currencyCode: string,
    priceStr: string,
    bundleID: string,
    duration?: SubscriptionDuration | null,
    signUpButtonLabelOverride?: string | null,
    appStoreID?: AppStoreID | null,
    rank?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateSpaceSubscriptionVariables = {
  filter?: ModelSubscriptionSpaceFilterInput | null,
  owner?: string | null,
};

export type OnCreateSpaceSubscription = {
  onCreateSpace?:  {
    __typename: "Space",
    title: string,
    shortDescription?: string | null,
    whiteLabelElements?:  {
      __typename: "SpaceWhiteLabelElements",
      primaryMainColor?: string | null,
      backgroundStyle?: BackgroundStyle | null,
      backgroundColor?: string | null,
      backgroundGradientColor?: string | null,
      cardBackgroundColor?: string | null,
      iconUrl?: string | null,
      splashImageUrl?: string | null,
      myDayBackgroundImageUrl?: string | null,
      desktopBannerImageUrl?: string | null,
      termsUrl?: string | null,
      privacyPolicyUrl?: string | null,
    } | null,
    adminUserIDs?: Array< string > | null,
    landingPageContent?: string | null,
    landingPageStyle?:  {
      __typename: "LandingPageStyle",
      backgroundColor?: string | null,
    } | null,
    landingPageCoverImageUrl?: string | null,
    isDefaultSpace?: boolean | null,
    sharedProgrammeConfiguration?:  {
      __typename: "SpaceSharedProgrammesConfiguration",
      sharedProgrammeCategories?:  Array< {
        __typename: "SpaceSharedProgrammeCategoryConfiguration",
        categoryTag: string,
        orderedProgrammeIDs: Array< string >,
      } | null > | null,
    } | null,
    productPreviewSections?:  Array< {
      __typename: "SpaceProductPreviewSection",
      title: string,
      productPreviews:  Array< {
        __typename: "SpaceProductPreview",
        productType: SpaceProductType,
        productObjectID: string,
        title: string,
        shortDescription: string,
        coverImageUrl?: string | null,
        availableInMembershipTierIDs?: Array< string > | null,
      } >,
    } > | null,
    videoPlaylists?:  Array< {
      __typename: "VideoPlaylist",
      title: string,
      playlistID: string,
      items:  Array< {
        __typename: "VideoPlaylistItem",
        videoID: string,
        title: string,
        description?: string | null,
        thumbnailImageUrl?: string | null,
      } >,
    } > | null,
    spaceContentBoard?:  {
      __typename: "SpaceContentBoard",
      id: string,
      title: string,
      lanes:  Array< {
        __typename: "SpaceContentLane",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "SpaceContentEntry",
          id: string,
          entryType: EntryType,
          objectID: string,
        } >,
      } >,
    } | null,
    spaceSharedContentBoardIDs?: Array< string > | null,
    welcomeVideo?:  {
      __typename: "VideoPlaylistItem",
      videoID: string,
      title: string,
      description?: string | null,
      thumbnailImageUrl?: string | null,
    } | null,
    myDayScreenSections?:  Array< {
      __typename: "SpacePublishedSectionConfig",
      publishedSectionType?: SpacePublishedSectionType | null,
      spaceContentLaneID?: string | null,
    } > | null,
    subscriptionBenefitsContent?: string | null,
    subscriptionPageStyle?:  {
      __typename: "SubscriptionPageStyle",
      backgroundColor?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    sharedRecipesBoardIDs?: Array< string > | null,
    defaultSharedProgrammeIDForNewUsers?: string | null,
    appStoreLinks?:  Array< {
      __typename: "SpaceAppStoreLink",
      appStoreID: AppStoreID,
      appUrl: string,
    } > | null,
    chatConfig?:  {
      __typename: "SpaceChatConfig",
      appID?: string | null,
      appRegion?: string | null,
    } | null,
    publishedSearchTags?:  Array< {
      __typename: "SpacePublishedSearchTag",
      id: string,
      tag: string,
    } > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateSpaceSubscriptionVariables = {
  filter?: ModelSubscriptionSpaceFilterInput | null,
  owner?: string | null,
};

export type OnUpdateSpaceSubscription = {
  onUpdateSpace?:  {
    __typename: "Space",
    title: string,
    shortDescription?: string | null,
    whiteLabelElements?:  {
      __typename: "SpaceWhiteLabelElements",
      primaryMainColor?: string | null,
      backgroundStyle?: BackgroundStyle | null,
      backgroundColor?: string | null,
      backgroundGradientColor?: string | null,
      cardBackgroundColor?: string | null,
      iconUrl?: string | null,
      splashImageUrl?: string | null,
      myDayBackgroundImageUrl?: string | null,
      desktopBannerImageUrl?: string | null,
      termsUrl?: string | null,
      privacyPolicyUrl?: string | null,
    } | null,
    adminUserIDs?: Array< string > | null,
    landingPageContent?: string | null,
    landingPageStyle?:  {
      __typename: "LandingPageStyle",
      backgroundColor?: string | null,
    } | null,
    landingPageCoverImageUrl?: string | null,
    isDefaultSpace?: boolean | null,
    sharedProgrammeConfiguration?:  {
      __typename: "SpaceSharedProgrammesConfiguration",
      sharedProgrammeCategories?:  Array< {
        __typename: "SpaceSharedProgrammeCategoryConfiguration",
        categoryTag: string,
        orderedProgrammeIDs: Array< string >,
      } | null > | null,
    } | null,
    productPreviewSections?:  Array< {
      __typename: "SpaceProductPreviewSection",
      title: string,
      productPreviews:  Array< {
        __typename: "SpaceProductPreview",
        productType: SpaceProductType,
        productObjectID: string,
        title: string,
        shortDescription: string,
        coverImageUrl?: string | null,
        availableInMembershipTierIDs?: Array< string > | null,
      } >,
    } > | null,
    videoPlaylists?:  Array< {
      __typename: "VideoPlaylist",
      title: string,
      playlistID: string,
      items:  Array< {
        __typename: "VideoPlaylistItem",
        videoID: string,
        title: string,
        description?: string | null,
        thumbnailImageUrl?: string | null,
      } >,
    } > | null,
    spaceContentBoard?:  {
      __typename: "SpaceContentBoard",
      id: string,
      title: string,
      lanes:  Array< {
        __typename: "SpaceContentLane",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "SpaceContentEntry",
          id: string,
          entryType: EntryType,
          objectID: string,
        } >,
      } >,
    } | null,
    spaceSharedContentBoardIDs?: Array< string > | null,
    welcomeVideo?:  {
      __typename: "VideoPlaylistItem",
      videoID: string,
      title: string,
      description?: string | null,
      thumbnailImageUrl?: string | null,
    } | null,
    myDayScreenSections?:  Array< {
      __typename: "SpacePublishedSectionConfig",
      publishedSectionType?: SpacePublishedSectionType | null,
      spaceContentLaneID?: string | null,
    } > | null,
    subscriptionBenefitsContent?: string | null,
    subscriptionPageStyle?:  {
      __typename: "SubscriptionPageStyle",
      backgroundColor?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    sharedRecipesBoardIDs?: Array< string > | null,
    defaultSharedProgrammeIDForNewUsers?: string | null,
    appStoreLinks?:  Array< {
      __typename: "SpaceAppStoreLink",
      appStoreID: AppStoreID,
      appUrl: string,
    } > | null,
    chatConfig?:  {
      __typename: "SpaceChatConfig",
      appID?: string | null,
      appRegion?: string | null,
    } | null,
    publishedSearchTags?:  Array< {
      __typename: "SpacePublishedSearchTag",
      id: string,
      tag: string,
    } > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteSpaceSubscriptionVariables = {
  filter?: ModelSubscriptionSpaceFilterInput | null,
  owner?: string | null,
};

export type OnDeleteSpaceSubscription = {
  onDeleteSpace?:  {
    __typename: "Space",
    title: string,
    shortDescription?: string | null,
    whiteLabelElements?:  {
      __typename: "SpaceWhiteLabelElements",
      primaryMainColor?: string | null,
      backgroundStyle?: BackgroundStyle | null,
      backgroundColor?: string | null,
      backgroundGradientColor?: string | null,
      cardBackgroundColor?: string | null,
      iconUrl?: string | null,
      splashImageUrl?: string | null,
      myDayBackgroundImageUrl?: string | null,
      desktopBannerImageUrl?: string | null,
      termsUrl?: string | null,
      privacyPolicyUrl?: string | null,
    } | null,
    adminUserIDs?: Array< string > | null,
    landingPageContent?: string | null,
    landingPageStyle?:  {
      __typename: "LandingPageStyle",
      backgroundColor?: string | null,
    } | null,
    landingPageCoverImageUrl?: string | null,
    isDefaultSpace?: boolean | null,
    sharedProgrammeConfiguration?:  {
      __typename: "SpaceSharedProgrammesConfiguration",
      sharedProgrammeCategories?:  Array< {
        __typename: "SpaceSharedProgrammeCategoryConfiguration",
        categoryTag: string,
        orderedProgrammeIDs: Array< string >,
      } | null > | null,
    } | null,
    productPreviewSections?:  Array< {
      __typename: "SpaceProductPreviewSection",
      title: string,
      productPreviews:  Array< {
        __typename: "SpaceProductPreview",
        productType: SpaceProductType,
        productObjectID: string,
        title: string,
        shortDescription: string,
        coverImageUrl?: string | null,
        availableInMembershipTierIDs?: Array< string > | null,
      } >,
    } > | null,
    videoPlaylists?:  Array< {
      __typename: "VideoPlaylist",
      title: string,
      playlistID: string,
      items:  Array< {
        __typename: "VideoPlaylistItem",
        videoID: string,
        title: string,
        description?: string | null,
        thumbnailImageUrl?: string | null,
      } >,
    } > | null,
    spaceContentBoard?:  {
      __typename: "SpaceContentBoard",
      id: string,
      title: string,
      lanes:  Array< {
        __typename: "SpaceContentLane",
        id: string,
        title: string,
        entries:  Array< {
          __typename: "SpaceContentEntry",
          id: string,
          entryType: EntryType,
          objectID: string,
        } >,
      } >,
    } | null,
    spaceSharedContentBoardIDs?: Array< string > | null,
    welcomeVideo?:  {
      __typename: "VideoPlaylistItem",
      videoID: string,
      title: string,
      description?: string | null,
      thumbnailImageUrl?: string | null,
    } | null,
    myDayScreenSections?:  Array< {
      __typename: "SpacePublishedSectionConfig",
      publishedSectionType?: SpacePublishedSectionType | null,
      spaceContentLaneID?: string | null,
    } > | null,
    subscriptionBenefitsContent?: string | null,
    subscriptionPageStyle?:  {
      __typename: "SubscriptionPageStyle",
      backgroundColor?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    sharedRecipesBoardIDs?: Array< string > | null,
    defaultSharedProgrammeIDForNewUsers?: string | null,
    appStoreLinks?:  Array< {
      __typename: "SpaceAppStoreLink",
      appStoreID: AppStoreID,
      appUrl: string,
    } > | null,
    chatConfig?:  {
      __typename: "SpaceChatConfig",
      appID?: string | null,
      appRegion?: string | null,
    } | null,
    publishedSearchTags?:  Array< {
      __typename: "SpacePublishedSearchTag",
      id: string,
      tag: string,
    } > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateSpaceMembershipSubscriptionVariables = {
  filter?: ModelSubscriptionSpaceMembershipFilterInput | null,
  memberID?: string | null,
};

export type OnCreateSpaceMembershipSubscription = {
  onCreateSpaceMembership?:  {
    __typename: "SpaceMembership",
    spaceID: string,
    memberID: string,
    state: SpaceMembershipState,
    correlatedUserID?: string | null,
    expiresAt?: string | null,
    visibleProgrammeIDsOverride?: Array< string > | null,
    membershipTierID?: string | null,
    stripeCustomerID?: string | null,
    stripeSubscriptionID?: string | null,
    onboardingAnswers?:  Array< {
      __typename: "OnboardingAnswer",
      questionID: string,
      answer: string,
    } > | null,
    onboardingAnswersLastUpdatedAt?: string | null,
    customTargetCalories?: number | null,
    welcomeVideoWatched?: boolean | null,
    initialProgrammeSelectionLastOfferedAt?: string | null,
    chatNickname?: string | null,
    referrer?: SpaceMembershipReferrer | null,
    appStoreSubscriptionID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSpaceMembershipSubscriptionVariables = {
  filter?: ModelSubscriptionSpaceMembershipFilterInput | null,
  memberID?: string | null,
};

export type OnUpdateSpaceMembershipSubscription = {
  onUpdateSpaceMembership?:  {
    __typename: "SpaceMembership",
    spaceID: string,
    memberID: string,
    state: SpaceMembershipState,
    correlatedUserID?: string | null,
    expiresAt?: string | null,
    visibleProgrammeIDsOverride?: Array< string > | null,
    membershipTierID?: string | null,
    stripeCustomerID?: string | null,
    stripeSubscriptionID?: string | null,
    onboardingAnswers?:  Array< {
      __typename: "OnboardingAnswer",
      questionID: string,
      answer: string,
    } > | null,
    onboardingAnswersLastUpdatedAt?: string | null,
    customTargetCalories?: number | null,
    welcomeVideoWatched?: boolean | null,
    initialProgrammeSelectionLastOfferedAt?: string | null,
    chatNickname?: string | null,
    referrer?: SpaceMembershipReferrer | null,
    appStoreSubscriptionID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSpaceMembershipSubscriptionVariables = {
  filter?: ModelSubscriptionSpaceMembershipFilterInput | null,
  memberID?: string | null,
};

export type OnDeleteSpaceMembershipSubscription = {
  onDeleteSpaceMembership?:  {
    __typename: "SpaceMembership",
    spaceID: string,
    memberID: string,
    state: SpaceMembershipState,
    correlatedUserID?: string | null,
    expiresAt?: string | null,
    visibleProgrammeIDsOverride?: Array< string > | null,
    membershipTierID?: string | null,
    stripeCustomerID?: string | null,
    stripeSubscriptionID?: string | null,
    onboardingAnswers?:  Array< {
      __typename: "OnboardingAnswer",
      questionID: string,
      answer: string,
    } > | null,
    onboardingAnswersLastUpdatedAt?: string | null,
    customTargetCalories?: number | null,
    welcomeVideoWatched?: boolean | null,
    initialProgrammeSelectionLastOfferedAt?: string | null,
    chatNickname?: string | null,
    referrer?: SpaceMembershipReferrer | null,
    appStoreSubscriptionID?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSpaceMembershipEventSubscriptionVariables = {
  filter?: ModelSubscriptionSpaceMembershipEventFilterInput | null,
  memberID?: string | null,
};

export type OnCreateSpaceMembershipEventSubscription = {
  onCreateSpaceMembershipEvent?:  {
    __typename: "SpaceMembershipEvent",
    spaceMembershipID: string,
    memberID: string,
    eventType?: SpaceMembershipEventType | null,
    transactionSource?: SubscriptionTransactionSource | null,
    transactionID?: string | null,
    details?: string | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSpaceMembershipEventSubscriptionVariables = {
  filter?: ModelSubscriptionSpaceMembershipEventFilterInput | null,
  memberID?: string | null,
};

export type OnUpdateSpaceMembershipEventSubscription = {
  onUpdateSpaceMembershipEvent?:  {
    __typename: "SpaceMembershipEvent",
    spaceMembershipID: string,
    memberID: string,
    eventType?: SpaceMembershipEventType | null,
    transactionSource?: SubscriptionTransactionSource | null,
    transactionID?: string | null,
    details?: string | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSpaceMembershipEventSubscriptionVariables = {
  filter?: ModelSubscriptionSpaceMembershipEventFilterInput | null,
  memberID?: string | null,
};

export type OnDeleteSpaceMembershipEventSubscription = {
  onDeleteSpaceMembershipEvent?:  {
    __typename: "SpaceMembershipEvent",
    spaceMembershipID: string,
    memberID: string,
    eventType?: SpaceMembershipEventType | null,
    transactionSource?: SubscriptionTransactionSource | null,
    transactionID?: string | null,
    details?: string | null,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserMyDayActionRecordSubscriptionVariables = {
  filter?: ModelSubscriptionUserMyDayActionRecordFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserMyDayActionRecordSubscription = {
  onCreateUserMyDayActionRecord?:  {
    __typename: "UserMyDayActionRecord",
    userProgrammeEnrollmentID: string,
    programmeEntryType: EntryType,
    objectID: string,
    calendarDay: string,
    actionType: MyDayActionType,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserMyDayActionRecordSubscriptionVariables = {
  filter?: ModelSubscriptionUserMyDayActionRecordFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserMyDayActionRecordSubscription = {
  onUpdateUserMyDayActionRecord?:  {
    __typename: "UserMyDayActionRecord",
    userProgrammeEnrollmentID: string,
    programmeEntryType: EntryType,
    objectID: string,
    calendarDay: string,
    actionType: MyDayActionType,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserMyDayActionRecordSubscriptionVariables = {
  filter?: ModelSubscriptionUserMyDayActionRecordFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserMyDayActionRecordSubscription = {
  onDeleteUserMyDayActionRecord?:  {
    __typename: "UserMyDayActionRecord",
    userProgrammeEnrollmentID: string,
    programmeEntryType: EntryType,
    objectID: string,
    calendarDay: string,
    actionType: MyDayActionType,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateMembershipTierSubscriptionVariables = {
  filter?: ModelSubscriptionMembershipTierFilterInput | null,
  owner?: string | null,
};

export type OnCreateMembershipTierSubscription = {
  onCreateMembershipTier?:  {
    __typename: "MembershipTier",
    spaceID: string,
    title: string,
    shortDescription?: string | null,
    monthlyPrice: string,
    quarterlyPrice?: string | null,
    annualPrice?: string | null,
    freeTrialDurationDays?: number | null,
    rank?: number | null,
    currencyCode?: string | null,
    state: MembershipTierState,
    stripeProductID?: string | null,
    stripePriceID?: string | null,
    stripePriceIDQuarterly?: string | null,
    stripePriceIDAnnual?: string | null,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateMembershipTierSubscriptionVariables = {
  filter?: ModelSubscriptionMembershipTierFilterInput | null,
  owner?: string | null,
};

export type OnUpdateMembershipTierSubscription = {
  onUpdateMembershipTier?:  {
    __typename: "MembershipTier",
    spaceID: string,
    title: string,
    shortDescription?: string | null,
    monthlyPrice: string,
    quarterlyPrice?: string | null,
    annualPrice?: string | null,
    freeTrialDurationDays?: number | null,
    rank?: number | null,
    currencyCode?: string | null,
    state: MembershipTierState,
    stripeProductID?: string | null,
    stripePriceID?: string | null,
    stripePriceIDQuarterly?: string | null,
    stripePriceIDAnnual?: string | null,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteMembershipTierSubscriptionVariables = {
  filter?: ModelSubscriptionMembershipTierFilterInput | null,
  owner?: string | null,
};

export type OnDeleteMembershipTierSubscription = {
  onDeleteMembershipTier?:  {
    __typename: "MembershipTier",
    spaceID: string,
    title: string,
    shortDescription?: string | null,
    monthlyPrice: string,
    quarterlyPrice?: string | null,
    annualPrice?: string | null,
    freeTrialDurationDays?: number | null,
    rank?: number | null,
    currencyCode?: string | null,
    state: MembershipTierState,
    stripeProductID?: string | null,
    stripePriceID?: string | null,
    stripePriceIDQuarterly?: string | null,
    stripePriceIDAnnual?: string | null,
    groups?: Array< string | null > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateSpacePrivateConfigSubscriptionVariables = {
  filter?: ModelSubscriptionSpacePrivateConfigFilterInput | null,
  owner?: string | null,
};

export type OnCreateSpacePrivateConfigSubscription = {
  onCreateSpacePrivateConfig?:  {
    __typename: "SpacePrivateConfig",
    id: string,
    spaceID: string,
    stripeConfig?:  {
      __typename: "SpaceStripeConfig",
      environment: StripeEnvironment,
      connectedAccountID: string,
      chargesEnabled: boolean,
      stripeProductID?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateSpacePrivateConfigSubscriptionVariables = {
  filter?: ModelSubscriptionSpacePrivateConfigFilterInput | null,
  owner?: string | null,
};

export type OnUpdateSpacePrivateConfigSubscription = {
  onUpdateSpacePrivateConfig?:  {
    __typename: "SpacePrivateConfig",
    id: string,
    spaceID: string,
    stripeConfig?:  {
      __typename: "SpaceStripeConfig",
      environment: StripeEnvironment,
      connectedAccountID: string,
      chargesEnabled: boolean,
      stripeProductID?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteSpacePrivateConfigSubscriptionVariables = {
  filter?: ModelSubscriptionSpacePrivateConfigFilterInput | null,
  owner?: string | null,
};

export type OnDeleteSpacePrivateConfigSubscription = {
  onDeleteSpacePrivateConfig?:  {
    __typename: "SpacePrivateConfig",
    id: string,
    spaceID: string,
    stripeConfig?:  {
      __typename: "SpaceStripeConfig",
      environment: StripeEnvironment,
      connectedAccountID: string,
      chargesEnabled: boolean,
      stripeProductID?: string | null,
    } | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateAccountActivitySignalSubscriptionVariables = {
  filter?: ModelSubscriptionAccountActivitySignalFilterInput | null,
  owner?: string | null,
};

export type OnCreateAccountActivitySignalSubscription = {
  onCreateAccountActivitySignal?:  {
    __typename: "AccountActivitySignal",
    id: string,
    spaceID: string,
    groups?: Array< string | null > | null,
    lastActivityAt: string,
    friendlyUsername?: string | null,
    lastActivityObjectType?: ActivityObjectType | null,
    lastActivityObjectID?: string | null,
    sourceIp?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateAccountActivitySignalSubscriptionVariables = {
  filter?: ModelSubscriptionAccountActivitySignalFilterInput | null,
  owner?: string | null,
};

export type OnUpdateAccountActivitySignalSubscription = {
  onUpdateAccountActivitySignal?:  {
    __typename: "AccountActivitySignal",
    id: string,
    spaceID: string,
    groups?: Array< string | null > | null,
    lastActivityAt: string,
    friendlyUsername?: string | null,
    lastActivityObjectType?: ActivityObjectType | null,
    lastActivityObjectID?: string | null,
    sourceIp?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteAccountActivitySignalSubscriptionVariables = {
  filter?: ModelSubscriptionAccountActivitySignalFilterInput | null,
  owner?: string | null,
};

export type OnDeleteAccountActivitySignalSubscription = {
  onDeleteAccountActivitySignal?:  {
    __typename: "AccountActivitySignal",
    id: string,
    spaceID: string,
    groups?: Array< string | null > | null,
    lastActivityAt: string,
    friendlyUsername?: string | null,
    lastActivityObjectType?: ActivityObjectType | null,
    lastActivityObjectID?: string | null,
    sourceIp?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};
