import React from 'react';
import { useSelector } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { END_USER_CONTENT_BOARD_LANE_ROUTE } from '../../services/routes';
import { recipeCollectionsCardDOMID } from '../../services/meals';
import EndUserContentEntryItem from './end_user_content_entry_item';

const MAX_CAROUSEL_ITEMS = 10;

const EndUserContentBoardView = () => {
  const navigate = useNavigate();

  const { boardID } = useParams();

  const sharedContentBoard = useSelector((state) =>
    (state.sharedContentBoards || []).find((b) => b.id === boardID),
  );

  if (!sharedContentBoard) {
    return null;
  }

  return (
    <div className="collections-view">
      <div className="topNavBarMobile" style={{ flexDirection: 'column' }}>
        <Box style={{ borderBottom: '1px solid #e1e1e1' }}>
          <div className="popup-title-back-button">
            <IconButton aria-label="back" onClick={() => navigate(-1)}>
              <ArrowBackIosOutlinedIcon />
            </IconButton>
          </div>
          <div
            style={{
              padding: '8px',
              textAlign: 'center',
            }}>
            <Typography variant="popupTitle">
              {sharedContentBoard.title}
            </Typography>
          </div>
        </Box>
      </div>
      <div
        style={{
          marginTop: '20px',
        }}>
        <h1 style={{ marginLeft: '23px' }}>{sharedContentBoard.title}</h1>
        {sharedContentBoard.lanes.map((lane) => (
          <>
            <Grid container>
              <Grid item xs={8}>
                <h2 style={{ margin: '23px 0 5px 23px' }}>{lane.title}</h2>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ margin: 'auto 10px 7px', color: 'white' }}>
                  <Link
                    to={formatRoute(END_USER_CONTENT_BOARD_LANE_ROUTE, {
                      boardID,
                      laneID: lane.id,
                    })}>
                    View all
                  </Link>
                </Box>
              </Grid>
            </Grid>
            <div style={{ overflowX: 'auto', display: 'flex' }}>
              {lane.entries.slice(0, MAX_CAROUSEL_ITEMS).map((entry) => (
                <div
                  key={entry.id}
                  id={recipeCollectionsCardDOMID(entry.id)}
                  style={{ flexShrink: 0 }}>
                  <EndUserContentEntryItem contentEntryID={entry.objectID} />
                </div>
              ))}
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default EndUserContentBoardView;
