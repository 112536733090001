import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GRCRecipe,
  Meal,
  Programme,
  RecommendationSource,
  RecommendedMenu,
  UserProfile,
} from '../../API';
import ProgrammePreviewNavigationBar from './programme_preview_navigation_bar';
import ProgrammePlanBoardPreview from './programme_plan_board_preview';
import { recommendMealsOperation } from '../../operations/recommender_operations';
import { getGRCRecipesByGRCRecipeIDsOperation } from '../../operations/grc_recipes_operations';
import {
  DEFAULT_PROGRAMME_CALORIE_SPLITS,
  DEFAULT_PROGRAMME_MEAL_TYPES,
  calorieSplitsAreValid,
} from '../../services/meal_types';
import { programmeLocalesSelector } from '../../reducers/programmes_reducer';
import { buildExistingMealsFromProgrammePrescribedMeals } from '../../services/recommender';

interface RootState {
  programmes: Array<Programme>;
  meals: Record<string, Meal>;
  userProfile: UserProfile;
}

interface ProgrammeViewProps {
  programmeId: string;
  planId: string;
}

const SIMULATED_USER_DAILY_CALORIE_TARGET = 2000;

const ProgrammePlanPreview = ({ programmeId, planId }: ProgrammeViewProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [recommendedMenu, setRecommendedMenu] =
    useState<RecommendedMenu | null>(null);
  const [recommenderErrorMessage, setRecommenderErrorMessage] = useState<
    string | null
  >(null);

  const programme = useSelector((state: RootState) =>
    state.programmes.find((p) => p.id === programmeId),
  );

  const meals = useSelector((state: RootState) => state.meals);

  const locales = useSelector((state: RootState) =>
    programmeLocalesSelector(state, programmeId),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!programme) {
      return;
    }

    const currentPlan = programme.plans.find((p) => p.id === planId);

    if (!currentPlan) {
      return;
    }

    const programmeHasValidMealTypesAndCalorieSplits =
      (programme.mealTypes || []).length > 0 &&
      calorieSplitsAreValid(programme.mealTypes, programme.calorieSplits);

    const mealTypes = programmeHasValidMealTypesAndCalorieSplits
      ? programme.mealTypes
      : DEFAULT_PROGRAMME_MEAL_TYPES;

    const calorieSplits = programmeHasValidMealTypesAndCalorieSplits
      ? programme.calorieSplits
      : DEFAULT_PROGRAMME_CALORIE_SPLITS;

    const existingDays = currentPlan.days.map((day, indexZeroBased) => ({
      dayIndex: indexZeroBased + 1,
      entries: buildExistingMealsFromProgrammePrescribedMeals(
        day.entries,
        meals,
      ),
    }));
    if (!programme.copyMealsExactly) {
      setIsLoading(true);
      const parentIDs = [...(programme.recipesBoardIDs || [])];
      if (programme.recipesBoard?.id) {
        parentIDs.push(programme.recipesBoard?.id);
      }
      recommendMealsOperation(
        mealTypes,
        7,
        existingDays,
        1,
        programme.nutritionConstraints,
        {},
        parentIDs,
        calorieSplits,
        1,
        programme.personalisedMealScaling,
        [RecommendationSource.SMORG],
        SIMULATED_USER_DAILY_CALORIE_TARGET,
        locales,
      )
        .then((menu: RecommendedMenu) => {
          if (menu.errorMessage) {
            setRecommenderErrorMessage(menu.errorMessage);
            return;
          }
          const grcEntries = menu.days.flatMap((day) =>
            day.entries
              .filter((e) => e.entryType === 'meal')
              .flatMap((e) => e.meals.filter((m) => m.mealSource === 'grc')),
          );
          const grcRecipeIDs = grcEntries.map((e) => e.id);

          (
            getGRCRecipesByGRCRecipeIDsOperation(grcRecipeIDs) as Promise<
              Record<string, GRCRecipe>
            >
          ).then((grcRecipes) => {
            dispatch({
              type: 'GRC_RECIPES_AVAILABLE',
              grcRecipes,
            });
            setRecommendedMenu(menu);
          });
        })
        .finally(() => setIsLoading(false));
    } else {
      setRecommendedMenu({ days: existingDays } as any as RecommendedMenu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programmeId, planId, dispatch]);

  if (!programme) {
    return null;
  }

  const currentPlan = programme.plans.find((p) => p.id === planId);

  if (!currentPlan) {
    return null;
  }

  return (
    <>
      <ProgrammePreviewNavigationBar
        selectedProgrammeId={programmeId}
        selectedPlanId={currentPlan.id}
        calorieTarget={SIMULATED_USER_DAILY_CALORIE_TARGET}
      />
      <ProgrammePlanBoardPreview
        recommendedMenu={recommendedMenu}
        recommenderErrorMessage={recommenderErrorMessage}
        isLoading={isLoading}
        nutritionConstraints={programme.nutritionConstraints}
        showNutritionToUsers={programme.showNutritionToUsers}
      />
    </>
  );
};

export default ProgrammePlanPreview;
