import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

const RelaxSearchCriteriaChips = ({
  searchCriteria,
  onChangeSearchCriteria,
}) => {
  const handleRemoveSearchString = () => {
    onChangeSearchCriteria({
      ...searchCriteria,
      searchString: null,
    });
  };

  const handleRemoveMealType = (mealType) => {
    onChangeSearchCriteria({
      ...searchCriteria,
      mealTypes: searchCriteria.mealTypes.filter((mt) => mt !== mealType),
    });
  };

  const handleRemoveExcludeIngredient = (excludeIng) => {
    onChangeSearchCriteria({
      ...searchCriteria,
      excludeIngredients: searchCriteria.excludeIngredients.filter(
        (ing) => ing !== excludeIng,
      ),
    });
  };

  const handleRemoveDietaryPreference = (pref) => {
    onChangeSearchCriteria({
      ...searchCriteria,
      dietaryPreferences: searchCriteria.dietaryPreferences.filter(
        (pr) => pr !== pref,
      ),
    });
  };

  const handleRemoveTag = (tag) => {
    onChangeSearchCriteria({
      ...searchCriteria,
      tags: searchCriteria.tags.filter((t) => t !== tag),
    });
  };

  return (
    <>
      {searchCriteria.searchString && (
        <Chip
          label={`"${searchCriteria.searchString}"`}
          variant="filled"
          style={{ color: 'white' }}
          onDelete={handleRemoveSearchString}
        />
      )}
      {(searchCriteria.mealTypes || []).map((mealType) => (
        <Chip
          key={mealType}
          label={mealType}
          variant="filled"
          style={{ color: 'white' }}
          onDelete={() => handleRemoveMealType(mealType)}
        />
      ))}
      {(searchCriteria.excludeIngredients || []).map((excludeIng) => (
        <Chip
          key={excludeIng}
          label={`Excluding ${excludeIng}`}
          variant="filled"
          style={{ color: 'white' }}
          onDelete={() => handleRemoveExcludeIngredient(excludeIng)}
        />
      ))}
      {(searchCriteria.dietaryPreferences || []).map((pref) => (
        <Chip
          key={pref}
          label={pref}
          variant="filled"
          style={{ color: 'white' }}
          onDelete={() => handleRemoveDietaryPreference(pref)}
        />
      ))}
      {(searchCriteria.tags || []).map((tag) => (
        <Chip
          key={tag}
          label={tag}
          variant="filled"
          style={{ color: 'white' }}
          onDelete={() => handleRemoveTag(tag)}
        />
      ))}
    </>
  );
};

RelaxSearchCriteriaChips.propTypes = {
  searchCriteria: PropTypes.shape({
    searchString: PropTypes.string,
    mealTypes: PropTypes.arrayOf(PropTypes.string),
    excludeIngredients: PropTypes.arrayOf(PropTypes.string),
    includeIngredients: PropTypes.arrayOf(PropTypes.string),
    dietaryPreferences: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onChangeSearchCriteria: PropTypes.func.isRequired,
};

export default RelaxSearchCriteriaChips;
