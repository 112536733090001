import React, { useRef } from 'react';
import Icon from '@mdi/react';
import { mdiCamera } from '@mdi/js';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';

const ImageUploadButton = ({ style, onChange }) => {
  const fileRef = useRef();
  return (
    <div>
      <IconButton
        sx={{
          backgroundColor: 'rgba(225, 225, 225, 0.6)',
          '&:hover': { backgroundColor: 'rgba(225, 225, 225, 1)' },
        }}
        style={style}
        onClick={() => fileRef.current.click()}
        title="Upload new image">
        <Icon path={mdiCamera} style={{ width: '24px', height: '24px' }} />
      </IconButton>
      <input
        ref={fileRef}
        onChange={() => {
          onChange(fileRef.current.files);
          fileRef.current.value = '';
        }}
        multiple={false}
        type="file"
        accept="image/png, image/jpeg, image/webp"
        hidden
      />
    </div>
  );
};

ImageUploadButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ImageUploadButton;
