import { Auth } from 'aws-amplify';
import {
  createOrGetDefaultBoardsWithMeals,
  getGRCRecipeCategoriesOperation,
  getGRCResultsOperation,
  loadSharedBoard,
} from '../operations/recipes_operations';
import {
  createOrGetDefaultProductsBoard,
  migrateShoppingLists,
} from '../operations/shopping_lists_operations';
import {
  createOrGetExistingUserProfileOperation,
  createSimpleSpaceMembershipOperation,
  getSpaceMembershipsOperation,
  loadGlobalTagsOperation,
  getSharedProgrammesOperation,
  createPreactivatedSpaceMembershipOperation,
  getPublicSpaceOperation,
} from '../operations/user_profile_operations';
import {
  createNewUserProfileAction,
  submitDeferredIapPurchasesAction,
  syncUserProfileAction,
  userSpaceMembershipInitialProgrammeOfferedAction,
  userSpaceMembershipOnboardingAnswersAvailableAction,
} from './user_action_creators';
import {
  createContactOperation,
  emailHealthProWelcomeMessageOperation,
  updateContactAttributeOperation,
} from '../operations/email_list_operations';
import { importSharedBoardIntoStandaloneRecipesBoardAction } from './recipes_action_creators';
import {
  getUserProgrammeEnrollmentsOperation,
  getUserMyDayActionRecordsOperation,
  loadProgrammesOperation,
} from '../operations/programmes_operations';
import { getNotesByNoteIDsOperation } from '../operations/note_operations';
import {
  contentEntriesInProgrammes,
  grcRecipesMissingInProgrammes,
  mealsMissingInProgrammes,
  notesInProgrammes,
} from '../services/programmes';
import {
  getMealsByMealIDsOperation,
  getMealsBySmorgBoardID,
  getSharedContentEntriesByObjectIDsOperation,
  getSharedContentEntriesOperation,
  getSharedMeals,
} from '../operations/meal_operations';
import { getGRCRecipesByGRCRecipeIDsOperation } from '../operations/grc_recipes_operations';
import {
  getContentEntriesByContentEntryIDsOperation,
  getSharedContentBoardIAMOperation,
  getSharedContentBoardOperation,
} from '../operations/content_entry_operations';
import {
  createOrGetExistingSpacePrivateConfigOperation,
  createSpaceOperation,
  listAndMigrateOwnedSpacesOperation,
  listSpaceIAPSubscriptionsOperation,
  updateSpaceOperation,
} from '../operations/spaces_operations';
import {
  currentCreatorSpaceSelector,
  currentEndUserSpaceIDSelector,
  currentHealthProGroupSelector,
  currentSpaceMembershipIDSelector,
  currentSpaceMembershipSelector,
  isInitialProgrammeSelectionCompletedSelector,
  userIsCreatorSelector,
} from '../reducers/user_reducer';
import { flattenObjects } from '../services/arrays';
import {
  isEmbeddedInSmorgCompanion,
  spaceMembershipDefaultReferrer,
} from '../services/smorg_companion_auth';
import { getMembershipTiersOperation } from '../operations/membership_tier_operations';
import { trackingSetSpaceID } from '../operations/tracking_operations';
import {
  DEFAULT_SPACE_ID,
  applySmorgStudioSpaceWhiteLabelRules,
  applySpaceWhiteLabelRules,
} from '../services/spaces';
import {
  PRODUCTIZED_SHARED_BOARD_IDS_BY_SPACE,
  doesSpaceProductizeRecipesBoardsDirectly,
} from '../services/productized_shared_boards';
import { endUserEnsureSharedRecipesBoardsAvailableAction } from './shared_entities_action_creators';
import { addAppStoreSmartBannerTags } from '../services/app_stores';
import { isAuthenticated } from '../operations/auth_operations';
import { listSpaceMembershipsOperation } from '../operations/smorg_studio_memberships_operations';
import { adminListChatGroupsOperation } from '../operations/chat_operations';
import { DEFAULT_SPACE_COMETCHAT_CHAT_CONFIG } from '../services/chat';

export const signupAction = (
  email,
  givenName,
  familyName,
  consentToProductComms,
  cleartextPassword,
  isCreator,
  sharedBoardIDToImport,
  intoSpaceID,
) => {
  return async (dispatch) => {
    await Auth.signUp({
      username: email,
      password: cleartextPassword,
      attributes: {
        email,
        given_name: givenName,
        family_name: familyName,
      },
    });
    await createContactOperation(email, givenName, familyName);
    // Launch the operation in background
    updateContactAttributeOperation(
      email,
      'consentToProductComms',
      consentToProductComms.toString(),
    );
    if (isCreator) {
      await emailHealthProWelcomeMessageOperation(email, givenName, familyName);
    }
    return dispatch(
      loginAfterSignupAction(
        email,
        cleartextPassword,
        isCreator,
        consentToProductComms,
        sharedBoardIDToImport,
        intoSpaceID,
      ),
    );
  };
};

export const loginAction = (email, cleartextPassword, intoSpaceID) => {
  return async (dispatch) => {
    await Auth.signIn(email, cleartextPassword);
    dispatch(initialLoadAction(intoSpaceID));
  };
};

export const loginAfterSignupAction = (
  email,
  cleartextPassword,
  isCreator,
  consentToProductComms,
  sharedBoardIDToImport,
  intoSpaceID,
) => {
  return async (dispatch, getState) => {
    await Auth.signIn(email, cleartextPassword);
    if (intoSpaceID) {
      const { endUserSpaceOnboardingAnswers } = getState();
      const referrer = spaceMembershipDefaultReferrer();
      console.log(
        `Creating membership into space ${intoSpaceID}, referrer: ${referrer}`,
      );
      if (isEmbeddedInSmorgCompanion()) {
        await createSimpleSpaceMembershipOperation(
          intoSpaceID,
          endUserSpaceOnboardingAnswers,
          referrer,
        );
      } else if (!intoSpaceID || intoSpaceID === DEFAULT_SPACE_ID) {
        await createPreactivatedSpaceMembershipOperation(intoSpaceID, referrer);
      } else {
        await createSimpleSpaceMembershipOperation(
          intoSpaceID,
          endUserSpaceOnboardingAnswers,
          referrer,
        );
      }
      dispatch({ type: 'SPACE_LANDING_PAGE_ONBOARDING_WIZARD_RESET_ANSWERS' });
    }
    await dispatch(
      createNewUserProfileAction(isCreator, consentToProductComms),
    );
    await dispatch(initialLoadAction(intoSpaceID));
    if (sharedBoardIDToImport) {
      const { sharedBoard, sharedMeals } = await loadSharedBoard(
        sharedBoardIDToImport,
      );
      const firstRecipesBoard = getState().recipesBoards[0];
      console.log({ firstRecipesBoard, sharedBoard, sharedMeals });
      await dispatch(
        importSharedBoardIntoStandaloneRecipesBoardAction(
          firstRecipesBoard,
          sharedBoard,
          sharedMeals,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => {},
        ),
      );
    }
  };
};

/**
 * May be called when user signs in,
 * but also before signin, for example when displaying the landing page
 * or the signin page in the colours of a particular space.
 */
export const ensureSpaceLoadedAction = (spaceID) => {
  console.log(`ensureSpaceLoadedAction ${spaceID}`);
  return async (dispatch, getState) => {
    const loadMembershipTiers = async () => {
      try {
        const membershipTiers = await getMembershipTiersOperation(spaceID);

        return dispatch({
          type: 'MEMBERSHIP_TIERS_AVAILABLE',
          membershipTiers,
        });
      } catch (e) {
        console.log(e);
        return null;
      }
    };

    const loadSpaceContentEntries = async () => {
      try {
        const objectIDs = (getState().sharedContentBoards || [])
          .flatMap((sharedContentBoard) => sharedContentBoard?.lanes || [])
          .flatMap((l) => l.entries)
          .map((e) => e.objectID);
        const sharedContentEntries =
          await getSharedContentEntriesByObjectIDsOperation(objectIDs);
        return dispatch({
          type: 'SPACES_SHARED_CONTENT_ENTRIES_AVAILABLE',
          sharedContentEntries,
        });
      } catch (e) {
        console.log(e);
        return null;
      }
    };

    if (!getState().spaces.find((sp) => sp.id === spaceID)) {
      const space = await getPublicSpaceOperation(spaceID);
      if (space) {
        const spacesSharedContentBoardIDs =
          space.spaceSharedContentBoardIDs || [];
        const sharedContentBoards = await Promise.all(
          spacesSharedContentBoardIDs.map(getSharedContentBoardIAMOperation),
        );
        dispatch({
          type: 'SPACES_AVAILABLE',
          spaces: [space],
          sharedContentBoards,
        });
      }
    }

    const promises = [loadMembershipTiers()];

    const isSignedIn = await isAuthenticated();

    if (isSignedIn) {
      promises.push(loadSpaceContentEntries());
    }

    await Promise.all(promises);
  };
};

/** Action that takes a long time and should only run in the bg after sign-in */
export const ensureSpaceRecipesLoadedAction = (spaceID) => {
  console.log(`ensureSpaceRecipesLoadedAction ${spaceID}`);
  return async (dispatch, getState) => {
    const space = getState().spaces.find((s) => s.id === spaceID);
    if (!space) {
      return;
    }
    const { sharedRecipesBoardIDs } = space;
    console.log({ sharedRecipesBoardIDs });
    if (sharedRecipesBoardIDs && sharedRecipesBoardIDs.length > 0) {
      await dispatch(
        endUserEnsureSharedRecipesBoardsAvailableAction(sharedRecipesBoardIDs),
      );
    }
  };
};

export const endUserSwitchToSpaceAction = (spaceID) => {
  console.log(`endUserSwitchToSpaceAction ${spaceID}`);
  return async (dispatch, getState) => {
    await dispatch(ensureSpaceLoadedAction(spaceID));
    trackingSetSpaceID(spaceID);
    console.log(`Finished loading space ${spaceID}`);
    if (spaceID) {
      const currentSpace = getState().spaces.find((sp) => sp.id === spaceID);
      applySpaceWhiteLabelRules(currentSpace?.whiteLabelElements);
      addAppStoreSmartBannerTags(currentSpace?.appStoreLinks);
    } else {
      applySpaceWhiteLabelRules(null);
    }
  };
};

export const initialLoadAction = (intoSpaceID) => {
  return async (dispatch, getState) => {
    const loadUserProfile = async () => {
      try {
        const userProfile = await createOrGetExistingUserProfileOperation();
        return dispatch({ type: 'USER_PROFILE_AVAILABLE', userProfile });
      } catch (e) {
        console.log(e);
        return null;
      }
    };

    const loadSpaceMemberships = async () => {
      try {
        const currentUserInfo = await Auth.currentUserInfo();
        if (!currentUserInfo) {
          // User not signed in
          return true;
        }
        const userId = currentUserInfo.username;
        console.log(`Loading spaces for user ${userId}`);
        const spaceMemberships = await getSpaceMembershipsOperation(userId);
        if (
          spaceMemberships.length === 0 &&
          (!intoSpaceID || intoSpaceID === DEFAULT_SPACE_ID)
        ) {
          // const defaultSpaceMembership = spaceMemberships.find(
          //   (membership) => membership.spaceID === DEFAULT_SPACE_ID,
          // );
          // if (!defaultSpaceMembership) {
          const referrer = spaceMembershipDefaultReferrer();
          console.info(
            `Creating default space membership, referrer ${referrer}`,
          );
          const newSpaceMembership =
            await createPreactivatedSpaceMembershipOperation(
              DEFAULT_SPACE_ID,
              referrer,
            );
          spaceMemberships.push(newSpaceMembership);
          // }
        }

        return dispatch({
          type: 'SPACE_MEMBERSHIPS_AVAILABLE',
          spaceMemberships,
        });
      } catch (e) {
        console.log(e);
        return null;
      }
    };

    const loadRecipesBoards = async (
      currentSpaceMembershipID,
      currentHealthProGroup,
      userIsCreator,
    ) => {
      try {
        const { recipesBoards, meals } =
          await createOrGetDefaultBoardsWithMeals(
            currentSpaceMembershipID,
            currentHealthProGroup,
            userIsCreator,
          );
        return dispatch({
          type: 'RECIPES_BOARDS_AVAILABLE',
          recipesBoards,
          meals,
        });
      } catch (e) {
        console.log(e);
        return null;
      }
    };

    const loadGRCRecipeCategories = async (spaceID) => {
      const categories = await getGRCRecipeCategoriesOperation(spaceID);
      const promises = categories.map((c) =>
        getGRCResultsOperation(spaceID, c.id, 0),
      );
      const results = await Promise.all(promises);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < categories.length; i++) {
        dispatch({
          type: 'GRC_RECIPES_CATEGORY_AVAILABLE',
          category: {
            ...categories[i],
            result: results[i],
          },
        });
      }
    };

    const loadProductsBoard = async (currentSpaceMembershipID) => {
      try {
        const { productsBoard, shoppingLists } =
          await createOrGetDefaultProductsBoard(currentSpaceMembershipID);
        const migratedShoppingLists = await migrateShoppingLists(
          shoppingLists,
          currentSpaceMembershipID,
        );
        return dispatch({
          type: 'PRODUCTS_BOARD_AVAILABLE',
          productsBoard,
          shoppingLists: migratedShoppingLists,
        });
      } catch (e) {
        console.log(e);
        return null;
      }
    };

    const loadSharedProgrammes = async (
      spaceID,
      visibleProgrammeIDsOverride,
    ) => {
      if (doesSpaceProductizeRecipesBoardsDirectly(spaceID)) {
        // Shared programmes a
        return null;
      }
      try {
        const sharedProgrammes = await getSharedProgrammesOperation(spaceID);
        const visibleProgrammes =
          visibleProgrammeIDsOverride === null ||
          visibleProgrammeIDsOverride === undefined
            ? sharedProgrammes
            : sharedProgrammes.filter((sp) =>
                visibleProgrammeIDsOverride.includes(sp.programmeID),
              );
        dispatch({
          type: 'SHARED_PROGRAMMES_AVAILABLE',
          sharedProgrammes: visibleProgrammes,
        });

        const ensureSharedBoardsAvailablePromises = sharedProgrammes
          .filter(
            (sp) =>
              sp.sharedRecipesBoardIDs && sp.sharedRecipesBoardIDs.length > 0,
          )
          .map((sp) => {
            return dispatch(
              endUserEnsureSharedRecipesBoardsAvailableAction(
                sp.sharedRecipesBoardIDs,
              ),
            );
          });
        await Promise.all(ensureSharedBoardsAvailablePromises);

        const linkedResourcesPromises = [
          ...visibleProgrammes.map(async (sharedProgramme) => {
            const sharedContentEntriesObj =
              await getSharedContentEntriesOperation(sharedProgramme.id);
            dispatch({
              type: 'SHARED_CONTENT_ENTRIES_AVAILABLE',
              sharedContentEntries: sharedContentEntriesObj,
            });
          }),
          ...visibleProgrammes.map(async (sharedProgramme) => {
            const boardID = sharedProgramme?.recipesBoard?.id;
            if (boardID) {
              const sharedMealsObj = await getSharedMeals(boardID);
              dispatch({
                type: 'SHARED_MEALS_AVAILABLE',
                sharedMeals: sharedMealsObj,
              });
            }
          }),
          ...visibleProgrammes.map(async (sharedProgramme) => {
            const boardID = sharedProgramme?.databaseRecipesBoard?.id;
            if (boardID) {
              const sharedMealsObj = await getSharedMeals(boardID);
              dispatch({
                type: 'SHARED_MEALS_AVAILABLE',
                sharedMeals: sharedMealsObj,
              });
            }
          }),
        ];

        await Promise.all(linkedResourcesPromises);

        return null;
      } catch (e) {
        console.log(e);
        return null;
      }
    };

    const loadProductizedSharedBoards = async (spaceID) => {
      const sharedBoardIDs = doesSpaceProductizeRecipesBoardsDirectly(spaceID)
        ? PRODUCTIZED_SHARED_BOARD_IDS_BY_SPACE[spaceID]
        : [];
      const loadResults = await Promise.all(
        sharedBoardIDs.map(loadSharedBoard),
      );
      const sharedBoards = loadResults.map((lr) => lr.sharedBoard);
      const allSharedMeals = loadResults.reduce(
        (acc, result) => ({
          ...acc,
          ...result.sharedMeals,
        }),
        {},
      );
      dispatch({
        type: 'PRODUCTIZED_SHARED_BOARDS_WITH_MEALS_AVAILABLE',
        sharedBoards,
        sharedMeals: allSharedMeals,
      });
    };

    const loadGlobalTags = async () => {
      try {
        // Avoid 'one-off' tags
        const MINIMUM_TAG_COUNT = 3;
        const globalTags = await loadGlobalTagsOperation();
        const globalTagsSorted = globalTags
          .filter((t) => t.count >= MINIMUM_TAG_COUNT)
          .sort((a, b) => b.count - a.count)
          .map((a) => a.tag);
        console.log({ globalTagsSorted });
        return dispatch({
          type: 'GLOBAL_TAGS_AVAILABLE',
          globalTagsSorted,
        });
      } catch (e) {
        console.log(e);
        return null;
      }
    };

    const loadOwnedSpaces = async () => {
      try {
        const spaces = await listAndMigrateOwnedSpacesOperation();
        const spacesSharedContentBoardIDs = spaces.flatMap(
          (space) => space.spaceSharedContentBoardIDs || [],
        );
        const sharedContentBoards = await Promise.all(
          spacesSharedContentBoardIDs.map(getSharedContentBoardOperation),
        );
        return dispatch({
          type: 'OWNED_SPACES_AVAILABLE',
          spaces,
          sharedContentBoards,
        });
      } catch (e) {
        console.log(e);
        return null;
      }
    };

    const loadSpacePrivateConfig = async (spaceID, currentHealthProGroup) => {
      try {
        const spacePrivateConfig =
          await createOrGetExistingSpacePrivateConfigOperation(
            spaceID,
            currentHealthProGroup,
          );
        return dispatch({
          type: 'SPACE_PRIVATE_CONFIG_AVAILABLE',
          spacePrivateConfig,
        });
      } catch (e) {
        console.log(e);
        return null;
      }
    };

    const loadSmorgStudioMemberships = async () => {
      try {
        const memberships = await listSpaceMembershipsOperation();
        return dispatch({
          type: 'SMORG_STUDIO_MEMBERSHIPS_AVAILABLE',
          memberships,
        });
      } catch (e) {
        console.log(e);
        return null;
      }
    };

    const loadAdminChatGroups = async () => {
      try {
        const chatGroups = await adminListChatGroupsOperation();
        return dispatch({
          type: 'ADMIN_CHAT_GROUPS_AVAILABLE',
          chatGroups,
        });
      } catch (e) {
        console.log(e);
        return null;
      }
    };

    const loadProgrammes = async () => {
      try {
        const programmes = await loadProgrammesOperation();

        return dispatch({
          type: 'PROGRAMMES_AVAILABLE',
          programmes,
        });
      } catch (e) {
        console.log(e);
        return null;
      }
    };

    const loadActiveUserProgrammeEnrollments = async (
      currentSpaceMembershipID,
    ) => {
      try {
        const allProgrammeEnrollments =
          await getUserProgrammeEnrollmentsOperation(currentSpaceMembershipID);
        const activeProgrammeEnrollments = allProgrammeEnrollments.filter(
          (enrollment) => !enrollment.endedAt,
        );
        const unsortedMyDayActionRecords = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const enrollment of activeProgrammeEnrollments) {
          // eslint-disable-next-line no-await-in-loop
          const records = await getUserMyDayActionRecordsOperation(
            enrollment.id,
          );
          unsortedMyDayActionRecords.push(...records);
        }
        const myDayActionRecords = [...unsortedMyDayActionRecords];
        myDayActionRecords.sort((a, b) =>
          b.createdAt.localeCompare(a.createdAt),
        );

        const isInitialProgrammeSelectionCompleted =
          isInitialProgrammeSelectionCompletedSelector(getState());

        if (!isInitialProgrammeSelectionCompleted) {
          const hasEnrolledInAnyProgrammeBefore =
            allProgrammeEnrollments.length > 0;
          if (hasEnrolledInAnyProgrammeBefore) {
            console.log(
              'User has enrolled in programmes before, marking that they were offered the initial default programme',
            );
            dispatch(
              userSpaceMembershipInitialProgrammeOfferedAction(
                currentSpaceMembershipID,
                new Date().toISOString(),
              ),
            );
          }
        }

        dispatch({
          type: 'USER_PROGRAMME_ENROLLMENTS_AVAILABLE',
          programmeEnrollments: activeProgrammeEnrollments,
          myDayActionRecords,
        });

        return null;
      } catch (e) {
        console.log(e);
        return null;
      }
    };

    const migrateOnboardingAnswers = async () => {
      const currentSpaceMembership = currentSpaceMembershipSelector(getState());
      if (!currentSpaceMembership) {
        return;
      }
      console.log(`currentSpaceMembership id=${currentSpaceMembership.id}`);
      if (currentSpaceMembership.onboardingAnswersLastUpdatedAt) {
        // No migration needed
        return;
      }
      const { programmeEnrollments: activeProgrammeEnrollments } = getState();
      if (
        !activeProgrammeEnrollments ||
        activeProgrammeEnrollments.length === 0
      ) {
        console.warn(
          'Migration needed but no active programme enrollments found',
        );
        return;
      }
      const mostRecentProgrammeEnrollment = activeProgrammeEnrollments[0];
      if (!mostRecentProgrammeEnrollment.onboardingAnswers) {
        console.warn('Migration needed but no onboarding answers found');
        return;
      }
      console.log('Migrating onboarding answers');
      dispatch(
        userSpaceMembershipOnboardingAnswersAvailableAction(
          currentSpaceMembership.id,
          mostRecentProgrammeEnrollment.onboardingAnswers,
          new Date().toISOString(),
        ),
      );
    };

    const loadProgrammesRelatedObjects = async () => {
      // This must run after loadEssentialData has completed.
      console.log('loadProgrammesRelatedObjects starting');
      try {
        const { programmes } = getState();

        const loadReferencedMeals = async () => {
          const missingMealIDs = mealsMissingInProgrammes(
            programmes,
            getState().meals,
          );
          console.log(`${missingMealIDs.length} missing meals`);
          return getMealsByMealIDsOperation(missingMealIDs);
        };

        const loadOwnedMeals = async () => {
          const programmesMealsPromises = programmes
            .filter((pr) => pr.recipesBoard)
            .map((pr) => getMealsBySmorgBoardID(pr.recipesBoard.id));
          const mealsResults = await Promise.all(programmesMealsPromises);
          return flattenObjects(mealsResults);
        };

        const loadReferencedGRCRecipes = async () => {
          const missingGRCRecipeIDs = grcRecipesMissingInProgrammes(
            programmes,
            getState().grcRecipes,
          );
          console.log(`${missingGRCRecipeIDs.length} missing GRC recipes`);
          return getGRCRecipesByGRCRecipeIDsOperation(missingGRCRecipeIDs);
        };

        const loadRelatedNotes = async () => {
          const relatedNoteIDs = notesInProgrammes(programmes);
          console.log(`${relatedNoteIDs.length} related notes`);
          return getNotesByNoteIDsOperation(relatedNoteIDs);
        };

        const loadRelatedContentEntries = async () => {
          const relatedContentEntryIDs = contentEntriesInProgrammes(programmes);
          console.log(
            `${relatedContentEntryIDs.length} related content entries`,
          );
          return getContentEntriesByContentEntryIDsOperation(
            relatedContentEntryIDs,
          );
        };

        const [
          referencedMeals,
          referencedGrcRecipes,
          ownedMeals,
          notes,
          contentEntries,
        ] = await Promise.all([
          loadReferencedMeals(),
          loadReferencedGRCRecipes(),
          loadOwnedMeals(),
          loadRelatedNotes(),
          loadRelatedContentEntries(),
        ]);

        await dispatch({
          type: 'PROGRAMMES_RELATED_OBJECTS_AVAILABLE',
          referencedMeals,
          referencedGrcRecipes,
          ownedMeals,
          notes,
          contentEntries,
        });

        // return loadGRCRecipesDerivedNutrition(
        //   Object.values(grcRecipes).map((r) => r.grcRecipeID),
        // );
        return true;
      } catch (e) {
        console.log(e);
        return null;
      }
    };

    const submitDeferredIapPurchases = async () => {
      return dispatch(submitDeferredIapPurchasesAction());
    };

    const loadIAP = async () => {
      const iapSubscriptions = await listSpaceIAPSubscriptionsOperation();
      return dispatch({
        type: 'IAP_SUBSCRIPTIONS_AVAILABLE',
        iapSubscriptions,
      });
    };

    const loadEssentialData = async () => {
      dispatch({
        type: 'APP_LOAD_NETWORK_STATE',
        networkState: { loading: true, loaded: false },
      });
      try {
        await loadUserProfile();
        console.log(`User profile loaded`);

        const isCreator = userIsCreatorSelector(getState());
        console.log({ isCreator });

        const isAnyUserLoggedIn = !!getState().userProfile?.id;

        if (isCreator) {
          await loadOwnedSpaces();
          const space = currentCreatorSpaceSelector(getState());
          applySpaceWhiteLabelRules(null);
          const creatorLoadPromises = [loadGlobalTags(), loadProgrammes()];
          if (space) {
            applySmorgStudioSpaceWhiteLabelRules(space.whiteLabelElements);
            const spaceID = space.id;
            const currentHealthProGroup = currentHealthProGroupSelector(
              getState(),
            );
            dispatch(ensureSpaceLoadedAction(spaceID));
            trackingSetSpaceID(spaceID);
            creatorLoadPromises.push(
              loadRecipesBoards(null, currentHealthProGroup, true),
              loadSharedProgrammes(spaceID),
              loadSpacePrivateConfig(spaceID, currentHealthProGroup),
              loadSmorgStudioMemberships(),
              loadAdminChatGroups(),
            );
          }
          await Promise.all(creatorLoadPromises);
        } else if (isAnyUserLoggedIn) {
          // End user

          await loadSpaceMemberships();

          const spaceID = currentEndUserSpaceIDSelector(getState());

          await dispatch(endUserSwitchToSpaceAction(spaceID));

          const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
            getState(),
          );

          const currentSpaceMembership = getState().spaceMemberships.find(
            (sm) => sm.id === currentSpaceMembershipID,
          );

          await Promise.all([
            submitDeferredIapPurchases(),
            loadGRCRecipeCategories(spaceID),
            loadIAP(),
            loadGlobalTags(),
            loadRecipesBoards(currentSpaceMembershipID, null, false),
            loadProductsBoard(currentSpaceMembershipID),
            loadSharedProgrammes(
              spaceID,
              currentSpaceMembership?.visibleProgrammeIDsOverride,
            ),
            loadProductizedSharedBoards(spaceID),
            loadActiveUserProgrammeEnrollments(currentSpaceMembershipID).then(
              migrateOnboardingAnswers,
            ),
          ]);
          /* Loads in the background */
          dispatch(ensureSpaceRecipesLoadedAction(spaceID));
        }
        dispatch({
          type: 'APP_LOAD_NETWORK_STATE',
          networkState: { loading: false, loaded: true },
        });
      } catch (e) {
        console.log(e);
        dispatch({
          type: 'APP_LOAD_NETWORK_STATE',
          networkState: { loading: false, loaded: false },
        });
      }
    };

    await loadEssentialData();
    await loadProgrammesRelatedObjects();
  };
};

export const creatorOnboardingFinishedAction = (space) => {
  return async (dispatch, getState) => {
    const currentHealthProGroup = currentHealthProGroupSelector(getState());
    try {
      const spaceWithGroups = {
        ...space,
        chatConfig: DEFAULT_SPACE_COMETCHAT_CHAT_CONFIG,
        groups: currentHealthProGroup && [currentHealthProGroup],
      };
      const createdSpace = await (space.id
        ? updateSpaceOperation(space)
        : createSpaceOperation(spaceWithGroups));
      dispatch({ type: 'CREATOR_ONBOARDING_SHOWN', space: createdSpace });
      dispatch(initialLoadAction(createdSpace.id));
      applySmorgStudioSpaceWhiteLabelRules(createdSpace.whiteLabelElements);
    } finally {
      await dispatch(syncUserProfileAction());
    }
  };
};
