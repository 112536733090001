import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import PopupTitle from '../common/popup_title';
import { entryTypeForGroup } from '../common/meal_search_hook';
import { ensureObjectsLoadedAction } from '../../action_creators/recipes_action_creators';
import MealDetailModal from '../meal/meal_detail_modal';
import RecipeCollectionsSearchResultItem from './recipe_collections_search_result_item';
import { isUserSpaceMembershipDefaultSpaceSelector } from '../../reducers/user_reducer';
import RecipeCollectionsSearchGroupResultsView from './recipe_collections_search_group_results_view';

const RecipeCollectionsSearchTopResultsView = ({
  results,
  onGoToGroup,
  onLoadMore,
  onBack,
  onDismiss,
  destinationBoardID,
  destinationMenuID,
  onClick,
  searchCriteria,
  onChangeSearchCriteria,
}) => {
  const dispatch = useDispatch();

  const isCommunityUser = useSelector(
    isUserSpaceMembershipDefaultSpaceSelector,
  );

  const isBlank = results.every((group) => group.data.length === 0);

  const objectIDsByGroup = results.map((group) => ({
    groupLabel: group.groupLabel,
    entryType: entryTypeForGroup(group.groupLabel),
    objectIDs: group.data.map((result) => result.mealID || result.grcRecipeID),
  }));

  const [showDetailForMealID, setShowDetailForMealID] = useState(null);

  const meals = useSelector((state) => state.meals);

  const sharedMeals = useSelector((state) => state.sharedMeals);

  const visibleMeal =
    showDetailForMealID &&
    (meals[showDetailForMealID] || sharedMeals[showDetailForMealID]);

  const onDismissDetail = () => setShowDetailForMealID(null);

  useEffect(() => {
    objectIDsByGroup.forEach((group) =>
      dispatch(ensureObjectsLoadedAction(group.entryType, group.objectIDs)),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(objectIDsByGroup)]);

  const showNutritionMetrics = [
    'calories',
    'fat',
    'carbohydrate',
    'protein',
    'fibre',
  ];

  const theOnlyGroupLabel = results.length === 1 && results[0].groupLabel;

  const groupsWithNonEmptyResults = results.filter(
    (group) => group.isLoading || group.moreAvailable || group.data.length > 0,
  );

  const theOneGroupLabelWithNonEmptyResults =
    groupsWithNonEmptyResults.length === 1 &&
    groupsWithNonEmptyResults[0].groupLabel;

  const labelOfSingleGroupToDisplay =
    theOneGroupLabelWithNonEmptyResults || theOnlyGroupLabel;

  const alwaysShowNutrition = !isCommunityUser;

  if (visibleMeal) {
    return (
      <MealDetailModal
        meal={visibleMeal}
        menu={null}
        isReadOnly
        alwaysShowNutrition={alwaysShowNutrition}
        savingInProgress={false}
        showNutritionMetrics={showNutritionMetrics}
        derivedNutrition={visibleMeal.derivedNutrition}
        canScaleIngredients={false}
        scaleToServings={1}
        currentlyEditingInline={{ section: '', itemId: null }}
        currentTextRef={{ current: null }}
        ingredientSuggestions={[]}
        ingredientSuggestionsNetworkState={{ loading: false }}
        onInlineEditFocus={() => {}}
        onInlineEditChange={() => {}}
        onInlineEditBlur={() => {}}
        onAddArraySectionItem={() => {}}
        onRemoveArraySectionItem={() => {}}
        onIngredientCheckChange={() => {}}
        onEditScaleToServings={() => {}}
        onChangeScaleToServings={() => {}}
        resetScaleToServings={() => {}}
        onNewImageChosen={() => {}}
        onRuleChanged={() => {}}
        onAddonChange={() => {}}
        onTagsChange={() => {}}
        onNewImageUrlSet={() => {}}
        onDialogClose={onDismissDetail}
      />
    );
  }

  if (labelOfSingleGroupToDisplay) {
    const groupResults = results.find(
      (group) => group.groupLabel === labelOfSingleGroupToDisplay,
    );
    return (
      <RecipeCollectionsSearchGroupResultsView
        groupResults={groupResults}
        onLoadMore={onLoadMore}
        onBack={onBack}
        onDismiss={onDismiss}
        destinationBoardID={destinationBoardID}
        destinationMenuID={destinationMenuID}
        onClick={onClick}
        searchCriteria={searchCriteria}
        onChangeSearchCriteria={onChangeSearchCriteria}
      />
    );
  }

  return (
    <>
      <PopupTitle
        hideBorder
        titleText="Search results"
        backButtonEnabled
        closeButtonEnabled
        onClickBack={onBack}
        onClickClose={onDismiss}
      />
      <div className="alternatives-page">
        {isBlank && <p style={{ margin: '23px' }}>No results found</p>}
        {results.map((group) => {
          if (group.data.length === 0) {
            return null;
          }
          return (
            <Grid container key={group.groupLabel} style={{ padding: 10 }}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={8}>
                    <h2 style={{ margin: '23px 0 5px' }}>{group.groupLabel}</h2>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '27px',
                        marginBottom: 0,
                      }}>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        href="#"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onGoToGroup(group.groupLabel);
                        }}>
                        View all
                      </a>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <div style={{ display: 'flex', overflowX: 'auto' }}>
                      {group.data.map((result) => {
                        const id = result.mealID || result.grcRecipeID;
                        return (
                          <RecipeCollectionsSearchResultItem
                            key={id}
                            entryType={group.entryType}
                            objectID={id}
                            title={result.title}
                            destinationBoardID={destinationBoardID}
                            destinationMenuID={destinationMenuID}
                            onClick={onClick}
                          />
                        );
                      })}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </div>
    </>
  );
};

RecipeCollectionsSearchTopResultsView.propTypes = {
  results: PropTypes.arrayOf().isRequired,
  onGoToGroup: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  destinationBoardID: PropTypes.string.isRequired,
  destinationMenuID: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  searchCriteria: PropTypes.shape({
    searchString: PropTypes.string,
    mealTypes: PropTypes.arrayOf(PropTypes.string),
    excludeIngredients: PropTypes.arrayOf(PropTypes.string),
    includeIngredients: PropTypes.arrayOf(PropTypes.string),
    dietaryPreferences: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onChangeSearchCriteria: PropTypes.func.isRequired,
};

export default RecipeCollectionsSearchTopResultsView;
