import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiDotsHorizontal } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import PopupTitle from '../common/popup_title';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import RecipeBoardsPickerNewBoardPage from './recipe_boards_picker_new_board_page';
import { createRecipesBoardAction } from '../../action_creators/recipes_action_creators';
import { trackAction } from '../../action_creators/user_action_creators';
import {
  allRecipesBoardsSelector,
  isBoardPublishedToSpaceSelector,
  recipesBoardByIdSelector,
} from '../../reducers/recipes_reducer';
import { useRecipesBoardId } from './recipes_hooks';
import {
  currentSpaceMembershipIDSelector,
  userIsCreatorSelector,
} from '../../reducers/user_reducer';
import { isProductizedSharedBoardID } from '../../services/productized_shared_boards';
import { useIsMobile } from '../common/layout_hooks';

const RecipeBoardActionsPopupMenu = ({
  onEditRecipesBoard,
  onDuplicateRecipesBoard,
  onShareRecipesBoard,
  onDeleteRecipesBoard,
  editBoardDisabled,
  duplicateBoardDisabled,
  shareBoardDisabled,
  deleteBoardDisabled,
  onPublishRecipesBoardToSpace,
  onUnpublishRecipesBoardFromSpace,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;
  const [deletePopupAnchorEl, setDeletePopupAnchorEl] = useState(null);

  const recipesBoards = useSelector(allRecipesBoardsSelector);

  const selectedRecipesBoardId = useRecipesBoardId();

  const selectedRecipesBoardTitle = recipesBoards.find(
    (b) => b.id === selectedRecipesBoardId,
  )?.title;

  const currentSpaceMembershipID = useSelector(
    currentSpaceMembershipIDSelector,
  );

  const [showNewBoardPage, setShowNewBoardPage] = useState(false);
  const [creationInProgress, setCreationInProgress] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setShowNewBoardPage(false);
    setAnchorEl(null);
  };

  const handleCreateNewRecipesBoard = (title) => {
    setShowNewBoardPage(false);
    handleClose();
    setCreationInProgress(true);
    dispatch(
      createRecipesBoardAction(title, currentSpaceMembershipID, (result) => {
        setCreationInProgress(false);
        const newRecipesBoardId = result?.id;
        if (newRecipesBoardId) {
          navigate(`/recipes/${newRecipesBoardId}`);
        }
      }),
    );
  };

  const handleEditRecipesBoard = () => {
    handleClose();
    onEditRecipesBoard();
  };

  const handleDuplicateRecipesBoard = () => {
    handleClose();
    onDuplicateRecipesBoard();
  };

  const handleShareRecipesBoard = () => {
    handleClose();
    onShareRecipesBoard();
  };

  const handleDeleteRecipesBoard = () => {
    setDeletePopupAnchorEl(null);
    handleClose();
    onDeleteRecipesBoard();
  };

  const onSelectBoard = (recipesBoardId) => {
    handleClose();
    dispatch(trackAction(['Switch to recipes board']));
    navigate(`/recipes/${recipesBoardId}`);
  };

  const userIsCreator = useSelector(userIsCreatorSelector);

  const isBoardPublishedToSpace = useSelector((state) =>
    isBoardPublishedToSpaceSelector(state, selectedRecipesBoardId),
  );

  const isProductizedSharedBoard = isProductizedSharedBoardID(
    selectedRecipesBoardId,
  );

  const boardEmbeddedInProgrammeID = useSelector(
    (state) =>
      recipesBoardByIdSelector(state, selectedRecipesBoardId)
        ?.embeddedInContainerID,
  );
  const isBoardEmbeddedInProgramme = !!boardEmbeddedInProgrammeID;

  const handlePublishRecipesBoardToSpace = () => {
    handleClose();
    onPublishRecipesBoardToSpace();
  };

  const handleUnpublishRecipesBoardFromSpace = () => {
    handleClose();
    onUnpublishRecipesBoardFromSpace();
  };

  const isMobile = useIsMobile();

  return (
    <>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        PaperProps={{
          style: { minWidth: '250px' },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {showNewBoardPage ? (
          <RecipeBoardsPickerNewBoardPage
            handleClose={handleClose}
            handleClickBack={() => setShowNewBoardPage(false)}
            onCreateNewBoard={handleCreateNewRecipesBoard}
            creationInProgress={creationInProgress}
            defaultTitle="My recipes"
          />
        ) : (
          <>
            <PopupTitle
              titleText={selectedRecipesBoardTitle || 'Choose option'}
              closeButtonEnabled
              onClickClose={handleClose}
            />
            <MenuItem onClick={() => setShowNewBoardPage(true)}>
              Create new recipe board
            </MenuItem>
            <MenuItem
              onClick={handleEditRecipesBoard}
              disabled={editBoardDisabled}>
              Edit recipe board
            </MenuItem>
            <MenuItem
              onClick={handleDuplicateRecipesBoard}
              disabled={duplicateBoardDisabled}>
              Duplicate board
            </MenuItem>
            <MenuItem
              onClick={handleShareRecipesBoard}
              disabled={shareBoardDisabled}>
              Share board
            </MenuItem>
            {userIsCreator &&
              !isMobile &&
              !isBoardEmbeddedInProgramme &&
              !isProductizedSharedBoard && (
                <MenuItem onClick={handlePublishRecipesBoardToSpace}>
                  {isBoardPublishedToSpace
                    ? 'Publish changes to space'
                    : 'Publish board to space'}
                </MenuItem>
              )}
            {userIsCreator &&
              !isMobile &&
              !isBoardEmbeddedInProgramme &&
              !isProductizedSharedBoard &&
              isBoardPublishedToSpace && (
                <MenuItem onClick={handleUnpublishRecipesBoardFromSpace}>
                  Unpublish board from space
                </MenuItem>
              )}
            <MenuItem
              onClick={(ev) => setDeletePopupAnchorEl(ev.target)}
              disabled={deleteBoardDisabled}>
              Delete board
            </MenuItem>
            <MenuItem
              sx={{ fontWeight: 'bold', opacity: '1 !important' }}
              disabled>
              Switch to
            </MenuItem>
            {recipesBoards.map((recipesBoard) => (
              <MenuItem
                disabled={recipesBoard.id === selectedRecipesBoardId}
                key={recipesBoard.id}
                onClick={() => {
                  onSelectBoard(recipesBoard.id);
                }}>
                {recipesBoard.title}
              </MenuItem>
            ))}
          </>
        )}
      </Menu>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open && 'true'}
        aria-haspopup="true"
        size="small"
        className="actionsPopupMenu"
        onClick={handleClick}>
        <Icon path={mdiDotsHorizontal} />
      </IconButton>
      <DeleteCardConfirmPopover
        anchorEl={deletePopupAnchorEl}
        visible={!!deletePopupAnchorEl}
        onDismiss={() => setDeletePopupAnchorEl(null)}
        message="The board and all meals will be deleted permanently. Are you sure?"
        onConfirmDelete={handleDeleteRecipesBoard}
        height={160}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        title="Delete all meals"
      />
    </>
  );
};

RecipeBoardActionsPopupMenu.propTypes = {
  onEditRecipesBoard: PropTypes.func.isRequired,
  onDuplicateRecipesBoard: PropTypes.func.isRequired,
  onShareRecipesBoard: PropTypes.func.isRequired,
  onDeleteRecipesBoard: PropTypes.func.isRequired,
  editBoardDisabled: PropTypes.bool.isRequired,
  duplicateBoardDisabled: PropTypes.bool.isRequired,
  shareBoardDisabled: PropTypes.bool.isRequired,
  deleteBoardDisabled: PropTypes.bool.isRequired,
  onPublishRecipesBoardToSpace: PropTypes.func.isRequired,
  onUnpublishRecipesBoardFromSpace: PropTypes.func.isRequired,
};

export default RecipeBoardActionsPopupMenu;
