import React, { useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useNavigate,
  unstable_useBlocker as useBlocker,
} from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiCamera, mdiDelete } from '@mdi/js';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Grid, IconButton } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { currentCreatorSpaceSelector } from '../../reducers/user_reducer';
import SpaceMyDayNewSectionModal from './space_my_day_new_section_modal';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import {
  spaceMyDaySectionsUpdatedAction,
  spaceMyDayWelcomeVideoUpdatedAction,
} from '../../action_creators/spaces_action_creators';
import videoPlaceholder from '../../assets/images/video_placeholder.png';
import VideoUrlModal from './video_url_modal';
import { SpacePublishedSectionType } from '../../API';
import { SECTION_LABELS } from '../../services/spaces';

const isContentEntriesSectionType = (publishedSectionType) =>
  !publishedSectionType ||
  publishedSectionType === SpacePublishedSectionType.CONTENT_ENTRIES;

const sectionsReducer = (sections, action) => {
  switch (action.type) {
    case 'NEW_SECTION_ADDED': {
      const { publishedSectionType, spaceContentLaneID } = action;
      return [...sections, { publishedSectionType, spaceContentLaneID }];
    }

    case 'SECTION_MOVED': {
      const { removedIndex, addedIndex } = action;
      const updatedSections = [...sections];
      const [item] = updatedSections.splice(removedIndex, 1);
      updatedSections.splice(addedIndex, 0, item);
      return updatedSections;
    }

    case 'SECTION_REMOVED': {
      const { publishedSectionType, spaceContentLaneID } = action;
      if (!isContentEntriesSectionType(publishedSectionType)) {
        return sections.filter(
          (s) => s.publishedSectionType !== publishedSectionType,
        );
      }
      return sections.filter(
        (s) => s.spaceContentLaneID !== spaceContentLaneID,
      );
    }

    default: {
      return sections;
    }
  }
};

const ALL_NON_CONTENT_SECTIONS = [
  {
    publishedSectionType: SpacePublishedSectionType.LATEST_RECIPE_COLLECTIONS,
  },
  {
    publishedSectionType: SpacePublishedSectionType.TODAYS_MEALS,
  },
  {
    publishedSectionType: SpacePublishedSectionType.TODAYS_LESSONS,
  },
  {
    publishedSectionType: SpacePublishedSectionType.SHOPPING_LISTS,
  },
];

const SpaceMyDayContentConfigView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isDirty, setIsDirty] = useState(false);

  const [newSectionModalAnchorEl, setNewSectionModalAnchorEl] = useState(null);

  const [videoUrlModalVisible, setVideoUrlModalVisible] = useState(null);

  const [spaceContentHashToRemove, setSpaceContentHashToRemove] =
    useState(null);

  const [removeSectionPopupAnchorEl, setRemoveSectionPopupAnchorEl] =
    useState(null);

  const space = useSelector(currentCreatorSpaceSelector);

  const lanes = useSelector((state) =>
    (state.sharedContentBoards || []).flatMap((b) => b.lanes),
  );

  // Remove sections that reference lanes that existed but have since been deleted
  const cleanSections = (space.myDayScreenSections || []).filter(
    (s) =>
      !isContentEntriesSectionType(s.publishedSectionType) ||
      lanes.some((l) => l.id === s.spaceContentLaneID),
  );

  const [sections, sectionsDispatch] = useReducer(
    sectionsReducer,
    cleanSections,
  );

  const publishedContentLanes = sections
    .filter((s) => isContentEntriesSectionType(s.publishedSectionType))
    .map((s) => s.spaceContentLaneID)
    .filter((contentLaneID) => lanes.some((l) => l.id === contentLaneID));

  const availableLanes = (lanes || []).filter(
    (l) => !publishedContentLanes.includes(l.id),
  );

  const publishedSections = sections.map((s) => {
    if (isContentEntriesSectionType(s.publishedSectionType)) {
      return {
        ...s,
        contentLane: lanes.find((l) => l.id === s.spaceContentLaneID),
      };
    }
    return s;
  });

  const availableSections = [
    ...availableLanes.map((l) => ({
      publishedSectionType: SpacePublishedSectionType.CONTENT_ENTRIES,
      contentLane: l,
    })),
    ...ALL_NON_CONTENT_SECTIONS.filter(
      (s) =>
        !publishedSections.some(
          (ps) => ps.publishedSectionType === s.publishedSectionType,
        ),
    ),
  ];

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    sectionsDispatch({
      type: 'SECTION_MOVED',
      removedIndex: result.source.index,
      addedIndex: result.destination.index,
    });
    setIsDirty(true);
  };

  const handleSave = () => {
    dispatch(spaceMyDaySectionsUpdatedAction(sections)).then(() => {
      setIsDirty(false);
      window.setTimeout(() => navigate(-1), 300);
    });
  };

  const onAddNewSection = (newSection) => {
    setNewSectionModalAnchorEl(null);
    sectionsDispatch({
      type: 'NEW_SECTION_ADDED',
      publishedSectionType: newSection.publishedSectionType,
      spaceContentLaneID: newSection.spaceContentLaneID,
    });
    setIsDirty(true);
  };

  const onRemoveSection = (publishedSectionType, spaceContentLaneID) => {
    setRemoveSectionPopupAnchorEl(null);
    setSpaceContentHashToRemove(null);
    sectionsDispatch({
      type: 'SECTION_REMOVED',
      publishedSectionType,
      spaceContentLaneID,
    });
    setIsDirty(true);
  };

  const setWelcomeVideoPlaylistItem = (welcomeVideoPlaylistItem) => {
    dispatch(spaceMyDayWelcomeVideoUpdatedAction(welcomeVideoPlaylistItem));
  };

  useBlocker(() => {
    if (isDirty) {
      // eslint-disable-next-line no-alert
      return !window.confirm(
        'You have unsaved changes. Are you sure you want to leave this page?',
      );
    }
    return false; // Allow navigation
  });

  return (
    <div
      style={{
        width: '520px',
        height: 'calc(var(--app-height) - 82px)',
        margin: 'auto',
        padding: '16px',
        overflowY: 'auto',
        backgroundColor: 'white',
        textAlign: 'left',
      }}>
      <h2>Configure My Day content</h2>

      <h3>Welcome video</h3>

      <Grid container>
        <Grid
          item
          xs={12}
          className="mealDetailImage"
          style={{
            position: 'relative',
            backgroundImage: `url("${
              space.welcomeVideo?.thumbnailImageUrl || videoPlaceholder
            }")`,
          }}>
          <IconButton
            sx={{
              backgroundColor: 'rgba(225, 225, 225, 0.6)',
              '&:hover': { backgroundColor: 'rgba(225, 225, 225, 1)' },
            }}
            style={{
              position: 'absolute',
              bottom: 10,
              right: 10,
            }}
            onClick={() => {
              setVideoUrlModalVisible(true);
            }}
            title="Upload new image">
            <Icon path={mdiCamera} style={{ width: '24px', height: '24px' }} />
          </IconButton>
        </Grid>
      </Grid>

      <h3>My Day screen sections</h3>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="myDaySectionsDroppable">
          {(droppableProvided) => (
            <div
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}>
              {publishedSections.map((section, index) => {
                const hash = JSON.stringify({
                  publishedSectionType: section.publishedSectionType,
                  spaceContentLaneID: section.contentLane?.id,
                });
                return (
                  <Draggable key={hash} draggableId={hash} index={index}>
                    {(provided) => (
                      <Grid
                        container
                        ref={provided.innerRef}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...provided.draggableProps}
                        alignItems="center">
                        <Grid item xs={9}>
                          <Grid container>
                            <Grid
                              item
                              sx={{ display: { xs: 'none', sm: 'block' } }}
                              xs={1}>
                              &nbsp;
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              flexShrink={0}
                              flexGrow={0}
                              style={{ display: 'flex', minHeight: 38 }}>
                              <div
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...provided.dragHandleProps}
                                style={{
                                  margin: '-4 0 0 0',
                                  // paddingTop: -4,
                                  paddingRight: 8,
                                  paddingLeft: 0,
                                }}>
                                <DragHandleIcon />
                              </div>
                            </Grid>
                            <Grid item style={{ display: 'flex' }} xs={9}>
                              {isContentEntriesSectionType(
                                section.publishedSectionType,
                              )
                                ? section.contentLane.title
                                : SECTION_LABELS[section.publishedSectionType]}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={2}>
                          <Icon
                            path={mdiDelete}
                            style={{
                              width: '16px',
                              height: '16px',
                              cursor: 'pointer',
                              color: 'red',
                              margin: 'auto 4px',
                            }}
                            onClick={(ev) => {
                              ev.preventDefault();
                              setSpaceContentHashToRemove(hash);
                              setRemoveSectionPopupAnchorEl(ev.target);
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="#"
        style={{
          fontWeight: 'bold',
          textDecoration: 'none',
          margin: 'auto 0',
        }}
        onClick={(ev) => {
          ev.preventDefault();
          setNewSectionModalAnchorEl(ev.target);
        }}>
        + Add new section
      </a>

      <Grid container>
        <Grid item xs={12} style={{ margin: '15px 0', padding: '6px' }}>
          <Button variant="contained" onClick={handleSave} disabled={!isDirty}>
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate(-1)}
            style={{ marginLeft: '6px' }}>
            Cancel
          </Button>
        </Grid>
      </Grid>
      {newSectionModalAnchorEl && (
        <SpaceMyDayNewSectionModal
          anchorEl={newSectionModalAnchorEl}
          availableSections={availableSections}
          onSave={onAddNewSection}
          onDismiss={() => setNewSectionModalAnchorEl(null)}
        />
      )}
      <DeleteCardConfirmPopover
        visible={!!removeSectionPopupAnchorEl}
        anchorEl={removeSectionPopupAnchorEl}
        title="Delete section"
        message="The section will no longer be shown on the My Day screen. Are you sure?"
        height={170}
        onConfirmDelete={() => {
          const { publishedSectionType, spaceContentLaneID } = JSON.parse(
            spaceContentHashToRemove,
          );
          onRemoveSection(publishedSectionType, spaceContentLaneID);
        }}
        onDismiss={() => setRemoveSectionPopupAnchorEl(null)}
      />
      {videoUrlModalVisible && (
        <VideoUrlModal
          videoPlaylistItem={space?.welcomeVideo}
          onChange={(videoPlaylistItem) => {
            setWelcomeVideoPlaylistItem(videoPlaylistItem);
            setVideoUrlModalVisible(false);
          }}
          onDismiss={() => {
            setVideoUrlModalVisible(false);
          }}
        />
      )}
    </div>
  );
};

export default SpaceMyDayContentConfigView;
