import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { mealboardReducer } from '../reducers/mealboard_reducer';
import { currentViewWeekStartDateStr } from './planner';

const initialState = {
  userProfile: {},
  recipesBoards: [],
  meals: {},
  productsBoard: { shoppingListGroups: [] },
  plannerWeeks: {},
  notes: {},
  shoppingLists: {},
  programmes: [],
  membershipTiers: [],
  grcRecipes: {},
  contentEntries: {},
  spaces: [],
  sharedContentBoards: [],
  spacePrivateConfig: null,
  spaceMemberships: [],
  sharedProgrammes: [],
  sharedRecipesBoards: [],
  programmeEnrollments: [],
  myDayActionRecords: [],
  productizedSharedBoards: [],
  sharedMeals: {},
  // sharedMealsTags: [],
  sharedContentEntries: {},
  appLoadNetworkState: { loading: false, loaded: false },
  plannerNetworkState: { loading: false },
  modalSpinner: { visible: false },
  globalSnackbar: { notificationText: null, linkText: null, linkTarget: null },
  // grcRecipesDerivedNutrition: {},
  derivedNutritionNetworkState: {
    loading: false,
    loaded: false,
    error: null,
  },
  mealBasket: { mealIDs: [], selectedShoppingListID: null },
  shoppingListChangesPending: {},
  currentRecipesBoardId: null,
  currentPlannerViewWeekStartDate: currentViewWeekStartDateStr(1),
  currentProgrammeId: null,
  currentProgrammePlanId: null,
  lastUsedCopyRecipesBoardId: null,
  currentAddToPlannerDestination: 'Planner',
  currentAddToPlannerDestinationPlanId: null,
  iapSubscriptions: [],
  activitySignals: [],
  activitySignalsOwnPublicIp: null,
  endUserSpaceOnboardingAnswers: [],
  endUserSpaceOnboardingChosenSharedProgrammeID: null,
  recipesAIGenerationJobs: [],
  chatAuthToken: null,
  deferredIapPurchases: [],
  smorgStudioMemberships: [],
  adminChatGroups: [],
};

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

export const store = createStore(mealboardReducer, initialState, enhancer);

export const deepEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);
