import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import {
  useNavigate,
  unstable_useBlocker as useBlocker,
} from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { currentCreatorSpaceSelector } from '../../reducers/user_reducer';
import { allSharedMealsTagsSelector } from '../../reducers/programmes_reducer';
import { spacePublishedSearchTagsUpdatedAction } from '../../action_creators/spaces_action_creators';

const SpacePublishedSearchTagsView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isDirty, setIsDirty] = useState(false);

  const space = useSelector(currentCreatorSpaceSelector);

  const [publishedSearchTags, setPublishedSearchTags] = useState(
    space.publishedSearchTags || [],
  );

  const allSharedMealsTags = useSelector(allSharedMealsTagsSelector);

  const availableSearchTags = allSharedMealsTags.filter(
    (tag) => !publishedSearchTags.some((pst) => pst.tag === tag),
  );

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const removedIndex = result.source.index;
    const addedIndex = result.destination.index;
    setPublishedSearchTags((oldTags) => {
      const updatedTags = [...oldTags];
      const [item] = updatedTags.splice(removedIndex, 1);
      updatedTags.splice(addedIndex, 0, item);
      return updatedTags;
    });
    setIsDirty(true);
  };

  const handleSave = () => {
    dispatch(spacePublishedSearchTagsUpdatedAction(publishedSearchTags)).then(
      () => {
        setIsDirty(false);
        window.setTimeout(() => navigate(-1), 300);
      },
    );
  };

  const onAddNewTag = (tag) => {
    setPublishedSearchTags((oldTags) => [...oldTags, { id: uuidv4(), tag }]);
    setCurrentlyAddingTag(null);
    setIsDirty(true);
  };

  const onRemoveTag = (tagID) => {
    setPublishedSearchTags((oldTags) => oldTags.filter((t) => t.id !== tagID));
    setIsDirty(true);
  };

  useBlocker(() => {
    if (isDirty) {
      // eslint-disable-next-line no-alert
      return !window.confirm(
        'You have unsaved changes. Are you sure you want to leave this page?',
      );
    }
    return false; // Allow navigation
  });

  const [currentlyAddingTag, setCurrentlyAddingTag] = useState(null);

  return (
    <div
      style={{
        width: '520px',
        height: 'calc(var(--app-height) - 82px)',
        margin: 'auto',
        padding: '16px',
        overflowY: 'auto',
        backgroundColor: 'white',
        textAlign: 'left',
      }}>
      <h2>Configure search tags</h2>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="myDaySectionsDroppable">
          {(droppableProvided) => (
            <div
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}>
              {publishedSearchTags.length === 0 && (
                <em>No search tags published yet</em>
              )}
              {publishedSearchTags.map((searchTag, index) => {
                return (
                  <Draggable
                    key={searchTag.id}
                    draggableId={searchTag.id}
                    index={index}>
                    {(provided) => (
                      <Grid
                        container
                        ref={provided.innerRef}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...provided.draggableProps}
                        alignItems="center">
                        <Grid item xs={9}>
                          <Grid container>
                            <Grid
                              item
                              sx={{ display: { xs: 'none', sm: 'block' } }}
                              xs={1}>
                              &nbsp;
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              flexShrink={0}
                              flexGrow={0}
                              style={{ display: 'flex', minHeight: 38 }}>
                              <div
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...provided.dragHandleProps}
                                style={{
                                  margin: '-4 0 0 0',
                                  // paddingTop: -4,
                                  paddingRight: 8,
                                  paddingLeft: 0,
                                }}>
                                <DragHandleIcon />
                              </div>
                            </Grid>
                            <Grid item style={{ display: 'flex' }} xs={9}>
                              {searchTag.tag}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={2}>
                          <Icon
                            path={mdiDelete}
                            style={{
                              width: '16px',
                              height: '16px',
                              cursor: 'pointer',
                              color: 'red',
                              margin: 'auto 4px',
                            }}
                            onClick={(ev) => {
                              ev.preventDefault();
                              onRemoveTag(searchTag.id);
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <h4>Add new tag</h4>

      <Grid container>
        <Grid item xs={9} style={{ padding: '5px' }}>
          <Autocomplete
            variant="standard"
            size="small"
            freeSolo
            fullWidth
            disablePortal
            options={availableSearchTags}
            onChange={(ev, data, reason) => {
              setCurrentlyAddingTag(data);
            }}
            onInputChange={(ev, value, reason) => setCurrentlyAddingTag(value)}
            renderInput={(params) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <TextField size="small" {...params} label="New tag" />
            )}
          />
        </Grid>
        <Grid item xs={3} style={{ padding: '5px' }}>
          <Button
            onClick={(ev) => {
              onAddNewTag(currentlyAddingTag);
              ev.stopPropagation();
            }}
            variant="contained"
            size="medium"
            disabled={!currentlyAddingTag}
            disableElevation>
            Add
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} style={{ margin: '15px 0', padding: '6px' }}>
          <Button variant="contained" onClick={handleSave} disabled={!isDirty}>
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate(-1)}
            style={{ marginLeft: '6px' }}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SpacePublishedSearchTagsView;
