import { escapeToHtmlEntities } from "./strings";

// eslint-disable-next-line import/prefer-default-export
export const tinymceEmbedPluginEntryPoint = (editor) => {
  console.log('Initializing smorgembed plugin');
  const openDialog = () =>
    editor.windowManager.open({
      title: 'Insert Code',
      body: {
        type: 'panel',
        items: [
          {
            type: 'textarea',
            name: 'embedCode',
            label: 'Embed code',
          },
        ],
      },
      buttons: [
        {
          type: 'cancel',
          text: 'Close',
        },
        {
          type: 'submit',
          text: 'Insert',
          buttonType: 'primary',
        },
      ],
      onSubmit: (api) => {
        const { embedCode } = api.getData();
        if (embedCode) {
          const iframedEmbedCode = `<iframe width="100%" height="620" frameborder="0" srcdoc="${escapeToHtmlEntities(
            embedCode,
          )}"></iframe>`;
          editor.insertContent(iframedEmbedCode);
        }
        api.close();
      },
    });
  /* Add a button that opens a window */
  editor.ui.registry.addButton('smorgembed', {
    tooltip: 'Insert code',
    icon: 'embed-page',
    onAction: () => {
      /* Open window */
      openDialog();
    },
  });
  /* Adds a menu item, which can then be included in any menu via the menu/menubar configuration */
  editor.ui.registry.addMenuItem('smorgembed', {
    text: 'Code...',
    tooltip: 'Insert code',
    icon: 'embed-page',
    onAction: () => {
      /* Open window */
      openDialog();
    },
  });
  /* Return the metadata for the help plugin */
  return {
    getMetadata: () => ({
      name: 'Smorg Embed plugin',
      url: 'https://www.smorg.io',
    }),
  };
};
