import React from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { AppLinkScreen, AppLinkType } from '../../API';
import {
  END_USER_CHAT_GROUP_ROUTE,
  END_USER_CHAT_ROUTE,
  END_USER_CONTENT_BOARD_ROUTE,
  END_USER_RECIPES_ROUTE,
  END_USER_SHARED_PROGRAMMES_ROUTE,
  MY_DAY_CONTENT_ENTRY_ROUTE,
} from '../../services/routes';
import NarrowEndUserContentEntryCard from './narrow_end_user_content_entry_card';

const EndUserContentEntryItem = ({ contentEntryID }) => {
  const navigate = useNavigate();

  const contentEntryExists = useSelector(
    (state) =>
      !!state.sharedContentEntries[contentEntryID] ||
      !!state.contentEntries[contentEntryID],
  );

  const title = useSelector(
    (state) =>
      (
        state.sharedContentEntries[contentEntryID] ||
        state.contentEntries[contentEntryID]
      )?.title,
  );

  const coverImageUrl = useSelector(
    (state) =>
      (
        state.sharedContentEntries[contentEntryID] ||
        state.contentEntries[contentEntryID]
      )?.coverImageUrl,
  );

  const appLink = useSelector(
    (state) =>
      (
        state.sharedContentEntries[contentEntryID] ||
        state.contentEntries[contentEntryID]
      )?.appLink,
  );

  if (!contentEntryExists) {
    return null;
  }

  const onClick = () => {
    if (appLink) {
      if (appLink.appLinkType === AppLinkType.APP_SCREEN) {
        switch (appLink.appLinkScreen) {
          case AppLinkScreen.RECIPES:
            navigate(END_USER_RECIPES_ROUTE);
            break;
          case AppLinkScreen.PLANNER:
            navigate('/planner');
            break;
          case AppLinkScreen.SHOPPING_LISTS:
            navigate('/products');
            break;
          case AppLinkScreen.SHARED_PROGRAMMES:
            navigate(END_USER_SHARED_PROGRAMMES_ROUTE);
            break;
          case AppLinkScreen.CHAT:
            if (appLink.objectID) {
              navigate(
                formatRoute(END_USER_CHAT_GROUP_ROUTE, {
                  groupID: appLink.objectID,
                }),
              );
            } else {
              navigate(END_USER_CHAT_ROUTE);
            }
            break;
          case AppLinkScreen.SHARED_CONTENT_BOARDS:
            if (appLink.objectID) {
              navigate(
                formatRoute(END_USER_CONTENT_BOARD_ROUTE, {
                  boardID: appLink.objectID,
                }),
              );
            } else {
              console.warn(
                `Missing content board ID for app link screen of type SHARED_CONTENT_BOARDS`,
              );
            }
            break;
          default:
            console.warn(`Unknown app link screen ${appLink.appLinkScreen}`);
        }
      } else if (appLink.appLinkType === AppLinkType.URL) {
        if (appLink.url) {
          window.open(appLink.url, '_blank');
        } else {
          console.warn(`Bad URL ${appLink.url}`);
        }
      } else {
        console.warn(`Unknown app link type ${appLink.appLinkType}`);
      }
      return;
    }
    navigate(
      formatRoute(MY_DAY_CONTENT_ENTRY_ROUTE, {
        sharedContentEntryID: contentEntryID,
      }),
    );
  };

  return (
    <NarrowEndUserContentEntryCard
      title={title}
      coverImageUrl={coverImageUrl}
      onClick={onClick}
    />
  );
};

EndUserContentEntryItem.propTypes = {
  contentEntryID: PropTypes.string.isRequired,
};

export default EndUserContentEntryItem;
