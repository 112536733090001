import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import Icon from '@mdi/react';
import { useNavigate } from 'react-router-dom';
import { mdiPlusCircle } from '@mdi/js';
import { Menu, MenuItem } from '@mui/material';
import { currentProgrammeEnrollmentSelector } from '../../reducers/programmes_reducer';
import { usePlannerViewWeekStartDate } from './planner_hooks';
import {
  calendarAddDays,
  daysArrayIndex,
  dbWeekStartDateForViewWeekAndIndex,
  plannerDayCalendarDate,
} from '../../services/planner';
import { programmeStartCalendarDay } from '../../reducers/my_day_reducer';
import { daysSince } from '../../services/programmes';
import PopupTitle from '../common/popup_title';
import {
  END_USER_FIND_ALTERNATIVES_ROUTE,
  END_USER_PLANNER_RECOMMENDER_ROUTE,
} from '../../services/routes';
import { linkWithQueryParams } from '../../services/urls';

const AddPlannerCardLink = ({ onClick, laneId }) => {
  const navigate = useNavigate();

  const plannerViewWeekStartDate = usePlannerViewWeekStartDate();
  const currentProgrammeEnrollment = useSelector(
    currentProgrammeEnrollmentSelector,
  );
  const isUserEnrolledIntoProgramme = !!currentProgrammeEnrollment;

  const calendarDay = calendarAddDays(
    plannerViewWeekStartDate,
    daysArrayIndex(laneId),
  );

  const dayIndexStr = laneId;
  const dayIndex = parseInt(dayIndexStr, 10);
  const plannerDbWeekStartDate = dbWeekStartDateForViewWeekAndIndex(
    plannerViewWeekStartDate,
    dayIndex,
  );
  const todaysDate = plannerDayCalendarDate(plannerDbWeekStartDate, dayIndex);
  const programmeStartDay = programmeStartCalendarDay(
    currentProgrammeEnrollment,
  );
  const daysSinceStart = daysSince(todaysDate, programmeStartDay);
  console.log({ daysSinceStart });
  const isOutsideEnrollmentTimePeriod = daysSinceStart < 0; // TODO also should not allow auto planning beyond the limit of the programme?

  const [popupMenuAnchorEl, setPopupMenuAnchorEl] = useState(null);

  const handleClosePopupMenu = () => {
    setPopupMenuAnchorEl(null);
  };

  const handleAddNewMeal = () => {
    handleClosePopupMenu();
    navigate(
      linkWithQueryParams(
        formatRoute(END_USER_FIND_ALTERNATIVES_ROUTE, {
          calendarDay,
        }),
        { scrollCookie: new Date().getTime().toString() },
      ),
    );
  };

  const handleAutoPlan = () => {
    navigate(
      formatRoute(END_USER_PLANNER_RECOMMENDER_ROUTE, {
        startDate: plannerViewWeekStartDate,
        dayIndex: dayIndexStr,
      }),
    );
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a
        onClick={(ev) => {
          setPopupMenuAnchorEl(ev.target);
        }}
        style={{
          display: 'flex',
          color: 'black',
          padding: '5px 2px',
          textDecoration: 'none',
          fontSize: '16px',
          fontWeight: 'bold',
          cursor: 'pointer',
        }}>
        <Icon
          path={mdiPlusCircle}
          style={{
            width: '32px',
            height: '32px',
            cursor: 'pointer',
            // color: theme.palette.primary.dark,
            // marginTop: 6,
            justifyContent: 'flex-end',
          }}
          onClick={(ev) => {
            setPopupMenuAnchorEl(ev.target);
          }}
        />
        <div style={{ margin: 'auto 0 auto 6px' }}>Add new</div>
      </a>
      {popupMenuAnchorEl && (
        <Menu
          anchorEl={popupMenuAnchorEl}
          open
          onClose={handleClosePopupMenu}
          PaperProps={{
            style: { minWidth: '200px' },
          }}>
          <PopupTitle
            titleText="Add menu"
            closeButtonEnabled
            onClickClose={handleClosePopupMenu}
          />
          <MenuItem onClick={handleAddNewMeal}>Add meal</MenuItem>
          {isUserEnrolledIntoProgramme && !isOutsideEnrollmentTimePeriod && (
            <MenuItem onClick={handleAutoPlan}>Plan meals for me</MenuItem>
          )}
        </Menu>
      )}
    </>
  );
};

AddPlannerCardLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  laneId: PropTypes.string.isRequired,
};

export default AddPlannerCardLink;
