import React from 'react';
import PropTypes from 'prop-types';
import { formatRoute } from 'react-router-named-routes';
import { Box, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WarningIcon from '@mui/icons-material/Warning';
import { useDispatch, useSelector } from 'react-redux';
import { ActivityObjectType } from '../../API';
import { otherUserActiveOnResourceSelector } from '../../reducers/mealboard_reducer';
import SharedContentBoardActionsPopupMenu from './shared_content_board_actions_popup_menu';
import { SMORG_STUDIO_SPACE_SHARED_CONTENT_BOARD_ROUTE } from '../../services/routes';
import { removeSharedContentBoardAction } from '../../action_creators/spaces_action_creators';

const SharedContentBoardNavigationBar = ({ sharedContentBoardID }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const sharedContentBoards = useSelector((state) => state.sharedContentBoards);
  const board = sharedContentBoards.find((b) => b.id === sharedContentBoardID);
  const selectedRecipesBoardTitle = board?.title;

  // const onEditRecipesBoard = () => {
  //   setRecipesBoardEditMode(true);
  // };

  // const updateRecipesBoard = (updatedRecipesBoard) => {
  //   setRecipesBoardEditMode(false);
  //   dispatch(
  //     updateStandaloneRecipesBoardAction(updatedRecipesBoard, () => {
  //       // Remove the ?rename from URL
  //       navigate(`/recipes/${selectedRecipesBoardId}`);
  //     }),
  //   );
  // };

  const onDeleteBoard = () => {
    const firstRemainingBoard = sharedContentBoards.find(
      (b) => b.id !== sharedContentBoardID,
    );
    dispatch(removeSharedContentBoardAction(sharedContentBoardID));
    navigate(
      formatRoute(SMORG_STUDIO_SPACE_SHARED_CONTENT_BOARD_ROUTE, {
        sharedContentBoardID: firstRemainingBoard.id,
      }),
    );
  };

  const otherActiveUser = useSelector((state) =>
    otherUserActiveOnResourceSelector(
      state,
      ActivityObjectType.SHARED_CONTENT_BOARDS,
      sharedContentBoardID,
    ),
  );

  return (
    <Box
      display="flex"
      className="plannerNavigationBar"
      justifyContent="center"
      style={{ color: 'white' }}>
      <div style={{ margin: 'auto 0', padding: '0 10px' }}>
        {selectedRecipesBoardTitle}
      </div>
      {otherActiveUser && (
        <div style={{ marginLeft: '20px', display: 'flex' }}>
          <Tooltip title="Modifying this board whilst another user is active may cause data loss">
            <WarningIcon
              style={{
                margin: 'auto 10px auto 0',
                color: 'orange',
              }}
            />
          </Tooltip>
          <div style={{ margin: 'auto 0', fontSize: '14px' }}>
            {otherActiveUser} is currently editing this board!
          </div>
        </div>
      )}
      <div style={{ flexGrow: 1, margin: 'auto 0' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            margin: 'auto 0',
          }}
        />
      </div>
      <div style={{ padding: '0 10px' }}>
        <SharedContentBoardActionsPopupMenu
          sharedContentBoardID={sharedContentBoardID}
          deleteBoardDisabled={
            !sharedContentBoards || sharedContentBoards.length <= 1
          }
          onEditBoard={() => {}}
          onDeleteBoard={onDeleteBoard}
        />
      </div>
      {/* {recipesBoardEditMode && (
        <RecipesBoardEditModal
          initialBoard={selectedRecipesBoard}
          onSave={updateRecipesBoard}
          onDismiss={() => setRecipesBoardEditMode(false)}
        />
      )} */}
    </Box>
  );
};

SharedContentBoardNavigationBar.propTypes = {
  sharedContentBoardID: PropTypes.string.isRequired,
};

export default SharedContentBoardNavigationBar;
