import React from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { Card, CardContent, CardMedia, Grid } from '@mui/material';
import {
  END_USER_CHAT_GROUP_ROUTE,
  END_USER_CHAT_ROUTE,
  END_USER_CONTENT_BOARD_ROUTE,
  END_USER_RECIPES_ROUTE,
  END_USER_SHARED_PROGRAMMES_ROUTE,
  MY_DAY_CONTENT_ENTRY_ROUTE,
} from '../../services/routes';
import { AppLinkScreen, AppLinkType } from '../../API';

const MyDayCompactContentCard = ({ contentEntryID }) => {
  const navigate = useNavigate();

  const theme = useTheme();

  const contentEntryExists = useSelector(
    (state) =>
      !!state.sharedContentEntries[contentEntryID] ||
      !!state.contentEntries[contentEntryID],
  );

  const title = useSelector(
    (state) =>
      (
        state.sharedContentEntries[contentEntryID] ||
        state.contentEntries[contentEntryID]
      )?.title,
  );

  const hasTitle = !!title;

  const coverImageUrl = useSelector(
    (state) =>
      (
        state.sharedContentEntries[contentEntryID] ||
        state.contentEntries[contentEntryID]
      )?.coverImageUrl,
  );

  const appLink = useSelector(
    (state) =>
      (
        state.sharedContentEntries[contentEntryID] ||
        state.contentEntries[contentEntryID]
      )?.appLink,
  );

  if (!contentEntryExists) {
    return null;
  }

  const onClickCard = () => {
    if (appLink) {
      if (appLink.appLinkType === AppLinkType.APP_SCREEN) {
        switch (appLink.appLinkScreen) {
          case AppLinkScreen.RECIPES:
            navigate(END_USER_RECIPES_ROUTE);
            break;
          case AppLinkScreen.PLANNER:
            navigate('/planner');
            break;
          case AppLinkScreen.SHOPPING_LISTS:
            navigate('/products');
            break;
          case AppLinkScreen.SHARED_PROGRAMMES:
            navigate(END_USER_SHARED_PROGRAMMES_ROUTE);
            break;
          case AppLinkScreen.CHAT:
            if (appLink.objectID) {
              navigate(
                formatRoute(END_USER_CHAT_GROUP_ROUTE, {
                  groupID: appLink.objectID,
                }),
              );
            } else {
              navigate(END_USER_CHAT_ROUTE);
            }
            break;
          case AppLinkScreen.SHARED_CONTENT_BOARDS:
            if (appLink.objectID) {
              navigate(
                formatRoute(END_USER_CONTENT_BOARD_ROUTE, {
                  boardID: appLink.objectID,
                }),
              );
            } else {
              console.warn(
                `Missing content board ID for app link screen of type SHARED_CONTENT_BOARDS`,
              );
            }
            break;
          default:
            console.warn(`Unknown app link screen ${appLink.appLinkScreen}`);
        }
      } else if (appLink.appLinkType === AppLinkType.URL) {
        if (appLink.url) {
          window.open(appLink.url, '_blank');
        } else {
          console.warn(`Bad URL ${appLink.url}`);
        }
      } else {
        console.warn(`Unknown app link type ${appLink.appLinkType}`);
      }
      return;
    }
    navigate(
      formatRoute(MY_DAY_CONTENT_ENTRY_ROUTE, {
        sharedContentEntryID: contentEntryID,
      }),
    );
  };

  const imgClassNames = classNames('myDayCompactContentEntryCardImage', {
    cardWithNoTitle: !hasTitle,
  });

  return (
    <Card
      className="my-day-compact-content-card"
      onClick={onClickCard}
      sx={{ display: 'flex', flexDirection: 'column' }}
      style={{ backgroundColor: theme.palette.primary.cardBackground }}>
      <Grid item xs={12}>
        {coverImageUrl ? (
          <CardMedia
            component="img"
            className={imgClassNames}
            image={coverImageUrl}
            alt=""
          />
        ) : (
          <div className={imgClassNames} />
        )}
      </Grid>
      {hasTitle && (
        <Grid item xs={12}>
          <CardContent
            sx={{
              flex: '1 0 auto',
              margin: '8px',
              padding: '0 !important',
              fontSize: '14px',
              whiteSpace: 'break-spaces',
            }}
            style={{
              color: 'white',
              lineClamp: 3,
              WebkitLineClamp: 3,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}>
            {title}
          </CardContent>
        </Grid>
      )}
    </Card>
  );
};

MyDayCompactContentCard.propTypes = {
  contentEntryID: PropTypes.string.isRequired,
};

export default MyDayCompactContentCard;
