import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useDispatch, useSelector } from 'react-redux';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import { MovableCardWrapper } from '../common/board/styles/Base';
import contentEntryPlaceholder from '../../assets/images/content_entry_placeholder.png';
import { spaceContentBoardEntryQuickDuplicateAction } from '../../action_creators/spaces_action_creators';

const SpaceContentBoardContentEntryCard = ({
  id,
  appBoardId,
  title,
  style,
  className,
  onClick,
  onDelete,
}) => {
  const dispatch = useDispatch();
  const sharedContentEntryID = style._objectID;
  const sharedContentEntry = useSelector(
    (state) => state.sharedContentEntries[sharedContentEntryID],
  );

  const [deleteContentEntryAnchorEl, setDeleteContentEntryAnchorEl] =
    useState(null);

  const onQuickDuplicate = () =>
    dispatch(spaceContentBoardEntryQuickDuplicateAction(appBoardId, id));

  const coverImageUrl = useSelector(
    (state) => state.sharedContentEntries[sharedContentEntryID]?.coverImageUrl,
  );

  if (!sharedContentEntry) {
    return null;
  }

  return (
    <MovableCardWrapper
      data-id={id}
      onClick={() => onClick(id)}
      style={style}
      className={className}>
      <Card sx={{ display: 'flex', flexDirection: 'column' }}>
        <Grid item xs={12}>
          <CardMedia
            component="img"
            className="contentEntryCardImage"
            image={coverImageUrl || contentEntryPlaceholder}
            alt=""
          />
        </Grid>
        <Grid item xs={12}>
          <CardContent
            sx={{
              flex: '1 0 auto',
              margin: '8px',
              padding: '0 !important',
              fontSize: '14px',
              whiteSpace: 'break-spaces',
            }}
            style={{
              maxWidth: 156,
              lineClamp: 3,
              WebkitLineClamp: 3,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}>
            {title}
          </CardContent>
        </Grid>
        <Grid item xs={12} sx={{ margin: '2px 0 4px' }}>
          <Grid container>
            <Grid item xs={8}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '4px',
                }}>
                <Tooltip
                  title="Duplicate this content entry"
                  placement="top-start">
                  <IconButton
                    aria-label="quick duplicate"
                    size="small"
                    onClick={(ev) => {
                      onQuickDuplicate();
                      ev.stopPropagation();
                    }}>
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={(ev) => {
                    setDeleteContentEntryAnchorEl(ev.target);
                    ev.stopPropagation();
                  }}>
                  <DeleteIcon />
                </IconButton>
                <DeleteCardConfirmPopover
                  visible={!!deleteContentEntryAnchorEl}
                  anchorEl={deleteContentEntryAnchorEl}
                  title="Delete content entry"
                  message="The content entry will be deleted permanently. Are you sure?"
                  height={155}
                  onConfirmDelete={() => onDelete(id)}
                  onDismiss={() => setDeleteContentEntryAnchorEl(null)}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </MovableCardWrapper>
  );
};

SpaceContentBoardContentEntryCard.propTypes = {
  id: PropTypes.string.isRequired,
  appBoardId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default SpaceContentBoardContentEntryCard;
