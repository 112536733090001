import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import { Link } from 'react-router-dom';
import MyDayCompactContentCard from './my_day_compact_content_card';
import { spaceMyDayPublishedLaneSelector } from '../../reducers/user_reducer';
import { END_USER_CONTENT_BOARD_LANE_ROUTE } from '../../services/routes';

const MyDayPublishedContentLaneSection = ({ laneID }) => {
  const { boardID, lane } = useSelector((state) =>
    spaceMyDayPublishedLaneSelector(state, laneID),
  );

  if (!lane || !boardID) {
    return null;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <h2 style={{ margin: '23px 23px 5px', color: 'white' }}>
            {lane.title}
          </h2>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <Box sx={{ margin: '28px 10px 5px' }}>
            <Link
              to={formatRoute(END_USER_CONTENT_BOARD_LANE_ROUTE, {
                boardID,
                laneID: lane.id,
              })}>
              View all
            </Link>
          </Box>
        </Grid>
      </Grid>

      <div style={{ overflowX: 'auto', display: 'flex' }}>
        {lane.entries.map((entry) => (
          <div key={entry.id} style={{ flexShrink: 0 }}>
            <MyDayCompactContentCard contentEntryID={entry.objectID} />
          </div>
        ))}
      </div>
    </>
  );
};

MyDayPublishedContentLaneSection.propTypes = {
  laneID: PropTypes.string.isRequired,
};

export default MyDayPublishedContentLaneSection;
