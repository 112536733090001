import React, { useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import PopupTitle from '../common/popup_title';

const RecipeBoardsPickerNewBoardPage = ({
  handleClose,
  handleClickBack,
  onCreateNewBoard,
  creationInProgress,
  defaultTitle,
}) => {
  const [title, setTitle] = useState('');

  const onClickCreate = () => {
    onCreateNewBoard(title || defaultTitle);
  };

  return (
    <>
      <PopupTitle
        titleText="Create a board"
        backButtonEnabled
        closeButtonEnabled
        onClickBack={handleClickBack}
        onClickClose={handleClose}
      />
      <Grid container>
        <Grid item xs={12} style={{ padding: '25px 10px 20px' }}>
          <TextField
            variant="standard"
            fullWidth
            autoFocus
            value={title}
            placeholder={defaultTitle}
            onChange={(ev) => setTitle(ev.target.value)}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px 10px' }}>
          <Button
            onClick={onClickCreate}
            variant="contained"
            size="medium"
            color="primary"
            disableElevation
            disabled={creationInProgress}
            style={{
              justifyContent: 'flex-start',
            }}>
            Create
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

RecipeBoardsPickerNewBoardPage.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleClickBack: PropTypes.func.isRequired,
  onCreateNewBoard: PropTypes.func.isRequired,
  creationInProgress: PropTypes.bool.isRequired,
  defaultTitle: PropTypes.string.isRequired,
};

export default RecipeBoardsPickerNewBoardPage;
