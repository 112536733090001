import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { Box, Button, CircularProgress, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StepInput from '../common/step_input';
import { isStringOrNumberInt } from '../../services/strings';
// import { MY_DAY_ROUTE } from '../../services/routes';

const MAX_DAYS_TO_PLAN = 14;
const MAX_ADULTS = 9;
const MAX_CHILDREN = 9;

const isValidInput = (
  daysToPlanStrOrNumber,
  adultsInputStrOrNumber,
  childrenInputStrOrNumber,
) =>
  isStringOrNumberInt(daysToPlanStrOrNumber, {
    gt: 0,
    lt: MAX_DAYS_TO_PLAN + 1,
  }) &&
  isStringOrNumberInt(adultsInputStrOrNumber, {
    gt: 0,
    lt: MAX_ADULTS + 1,
  }) &&
  (!childrenInputStrOrNumber ||
    isStringOrNumberInt(childrenInputStrOrNumber, {
      gt: -1,
      lt: MAX_CHILDREN + 1,
    }));

const EndUserPlannerRecommenderStepDays = ({
  canChangeNumDays,
  numDaysToPlan,
  servingsAdults,
  servingsChildren,
  onChangeNumDaysToPlan,
  onChangeServingsAdults,
  onChangeServingsChildren,
  onBack,
  onNext,
  isLoading,
  stepNumber,
  numTotalSteps,
  onDismiss,
  recommendMealsError,
}) => {
  // const navigate = useNavigate();

  const [numAdultsStr, setNumAdultsStr] = useState(servingsAdults.toString());
  const [numChildrenStr, setNumChildrenStr] = useState(
    servingsChildren.toString(),
  );

  const onChangeDays = (ev) => {
    // ev.preventDefault();
    // console.log(ev.target.value);
    onChangeNumDaysToPlan(parseInt(ev.target.value, 10));
  };

  const onChangeAdults = (ev) => {
    const newAdultsStr = ev.target.value;
    setNumAdultsStr(newAdultsStr);
    if (isValidInput(numDaysToPlan, newAdultsStr, numChildrenStr)) {
      onChangeServingsAdults(parseInt(newAdultsStr, 10));
    }
  };

  const onChangeChildren = (ev) => {
    const newChildrenStr = ev.target.value;
    setNumChildrenStr(newChildrenStr);
    if (isValidInput(numDaysToPlan, numAdultsStr, newChildrenStr)) {
      onChangeServingsChildren(parseInt(newChildrenStr, 10));
    }
  };
  return (
    <div className="app-wizard">
      <div className="app-wizard-title">Plan my meals for me</div>
      <div className="app-wizard-step">
        <div className="step-heading">
          <div className="completion-percentage">
            <div className="close-button">
              <IconButton
                aria-label="close"
                onClick={(ev) => {
                  onDismiss();
                  ev.stopPropagation();
                }}>
                <CloseIcon />
              </IconButton>
            </div>
            Step {stepNumber} of {numTotalSteps}
          </div>
        </div>
        <div className="step-content light-background">
          {canChangeNumDays && (
            <>
              <div className="step-title" style={{ marginTop: '50px' }}>
                How many days do you need?
              </div>
              <Grid container spacing={2} style={{ marginTop: '16px' }}>
                <Grid item xs={4} style={{ display: 'flex' }}>
                  <div style={{ margin: 'auto 0' }}>
                    <StepInput
                      value={numDaysToPlan}
                      onChange={onChangeDays}
                      min={1}
                      max={MAX_DAYS_TO_PLAN}
                    />
                  </div>
                </Grid>
                <Grid item xs={8} style={{ display: 'flex' }}>
                  <div style={{ margin: 'auto 6px' }}>days</div>
                </Grid>
              </Grid>
            </>
          )}

          <div className="step-title" style={{ marginTop: '50px' }}>
            For how many people are you planning?
          </div>
          <Grid container spacing={2} style={{ marginTop: '16px' }}>
            <Grid item xs={4} style={{ display: 'flex' }}>
              <div style={{ margin: 'auto 0' }}>
                <StepInput
                  value={numAdultsStr}
                  onChange={onChangeAdults}
                  min={1}
                  max={MAX_ADULTS}
                />
              </div>
            </Grid>
            <Grid item xs={8} style={{ display: 'flex' }}>
              <div style={{ margin: 'auto 6px' }}>
                adults
                <br />
                (and children over 12)
              </div>
            </Grid>
            <Grid item xs={4} style={{ display: 'flex' }}>
              <div style={{ margin: 'auto 0' }}>
                <StepInput
                  value={numChildrenStr}
                  onChange={onChangeChildren}
                  min={0}
                  max={MAX_CHILDREN}
                />
              </div>
            </Grid>
            <Grid item xs={8} style={{ display: 'flex' }}>
              <div style={{ margin: 'auto 6px' }}>children (under 12)</div>
            </Grid>
          </Grid>
        </div>
        {recommendMealsError && (
          <div
            style={{
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '22px',
              color: '#DF1F2A',
              marginBottom: '40px',
            }}>
            We encountered an error - please try again
          </div>
        )}
        {isLoading && !recommendMealsError && (
          <div
            style={{
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '22px',
              color: '#005C91',
              marginBottom: '40px',
            }}>
            Compiling your personalised plan
          </div>
        )}
        <Grid container>
          <Grid item xs={6}>
            <Button
              color="secondary"
              onClick={onBack}
              variant="contained"
              size="medium"
              disableElevation
              style={{ borderRadius: '30px', width: '150px' }}>
              I&apos;ll do it myself
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-end">
              {isLoading ? (
                <CircularProgress
                  size={36.5}
                  color="primary"
                  style={{
                    marginRight: '45px',
                  }}
                />
              ) : (
                <Button
                  color="primary"
                  onClick={onNext}
                  variant="contained"
                  size="medium"
                  disableElevation
                  disabled={
                    isLoading ||
                    !isValidInput(numDaysToPlan, numAdultsStr, numChildrenStr)
                  }
                  style={{ borderRadius: '30px', width: '150px' }}>
                  Plan for me
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

EndUserPlannerRecommenderStepDays.propTypes = {
  canChangeNumDays: PropTypes.bool.isRequired,
  numDaysToPlan: PropTypes.number.isRequired,
  servingsAdults: PropTypes.number.isRequired,
  servingsChildren: PropTypes.number.isRequired,
  onChangeNumDaysToPlan: PropTypes.func.isRequired,
  onChangeServingsAdults: PropTypes.func.isRequired,
  onChangeServingsChildren: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  stepNumber: PropTypes.number.isRequired,
  numTotalSteps: PropTypes.number.isRequired,
  onDismiss: PropTypes.func.isRequired,
  recommendMealsError: PropTypes.bool.isRequired,
};

export default EndUserPlannerRecommenderStepDays;
