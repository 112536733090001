import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import ImageUploadButton from '../meal/image_upload_button';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';
import {
  IProgrammesState,
  updateProgrammeAction,
} from '../../action_creators/programmes_action_creators';
import {
  CalorieSplit,
  ContentEntry,
  GRCRecipe,
  Meal,
  MembershipTier,
  MembershipTierState,
  Note,
  NutritionConstraints,
  Programme,
  SharedProgramme,
  SmorgBoard,
  Space,
  UserMyDayActionRecord,
  UserProgrammeEnrollment,
} from '../../API';
import EditNutritionConstraintsModal from './edit_nutrition_constraints.modal';
import { uploadUserImage } from '../../services/user_generated_content';
import TagsInput from '../meal/tags_input';
import EditProgrammeDescriptionModal from './edit_programme_description_modal';
import ProgrammeCardPreview from './programme_card_preview';
import EditProgrammeMealTypesModal from './edit_programme_meal_types_modal';
import {
  DEFAULT_PROGRAMME_CALORIE_SPLITS,
  DEFAULT_PROGRAMME_MEAL_TYPES,
} from '../../services/meal_types';
import { standaloneRecipesBoardsSelector } from '../../reducers/recipes_reducer';
import { sharedProgrammeIDForProgrammeSelector } from '../../reducers/programmes_reducer';
import { currentCreatorSpaceSelector } from '../../reducers/user_reducer';
import { spaceSetDefaultSharedProgrammeIDForNewUsersAction } from '../../action_creators/spaces_action_creators';

interface EditProgrammeFormProps {
  programme: Programme;
  isDirty: boolean;
  setIsDirty: (dirty: boolean) => void;
}

interface RootState {
  programmes: Array<Programme>;
  meals: Record<string, Meal>;
  grcRecipes: Record<string, GRCRecipe>;
  notes: Record<string, Note>;
  contentEntries: Record<string, ContentEntry>;
  programmeEnrollments: Array<UserProgrammeEnrollment>;
  myDayActionRecords: Array<UserMyDayActionRecord>;
  spaces: Array<Space>;
  sharedProgrammes: Array<SharedProgramme>;
  membershipTiers: Array<MembershipTier>;
}

const EditProgrammeForm = ({
  programme,
  isDirty,
  setIsDirty,
}: EditProgrammeFormProps) => {
  const [imageObjectId] = useState(uuidv4());
  const [title, setTitle] = useState(programme.title);
  const [imageUrl, setImageUrl] = useState(programme.coverImageUrl);
  const [shortDescription, setShortDescription] = useState(
    programme.shortDescription,
  );
  const [description, setDescription] = useState(programme.description);
  const [mealTypes, setMealTypes] = useState(
    programme.mealTypes || DEFAULT_PROGRAMME_MEAL_TYPES,
  );
  const [calorieSplits, setCalorieSplits] = useState(
    programme.calorieSplits ||
      (DEFAULT_PROGRAMME_CALORIE_SPLITS as Array<CalorieSplit>),
  );
  const [categoryTags, setCategoryTags] = useState(
    programme.categoryTags || [],
  );
  const [nutritionConstraints, setNutritionConstraints] = useState(
    programme.nutritionConstraints,
  );
  const [showNutritionToUsers, setShowNutritionToUsers] = useState(
    programme.showNutritionToUsers,
  );
  const [copyMealsExactly, setCopyMealsExactly] = useState(
    programme.copyMealsExactly,
  );
  const [personalisedMealScaling, setPersonalisedMealScaling] = useState(
    programme.personalisedMealScaling,
  );
  const [availableInMembershipTierIDs, setAvailableInMembershipTierIDs] =
    useState(programme.availableInMembershipTierIDs);
  const [recipesBoardIDs, setRecipesBoardIDs] = useState(
    programme.recipesBoardIDs || [],
  );
  const sharedProgrammeID = useSelector((state: RootState) =>
    sharedProgrammeIDForProgrammeSelector(state, programme.id),
  );
  const defaultSharedProgrammeIDForNewUsers = useSelector(
    (state) =>
      currentCreatorSpaceSelector(state)?.defaultSharedProgrammeIDForNewUsers,
  );
  const showuldShowDefaultForNewUsers = !!sharedProgrammeID;
  const initialIsDefaultForNewUsers =
    !!sharedProgrammeID &&
    sharedProgrammeID === defaultSharedProgrammeIDForNewUsers;
  const [isDefaultForNewUsers, setIsDefaultForNewUsers] = useState(
    initialIsDefaultForNewUsers,
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const allStandaloneRecipesBoards = useSelector(
    standaloneRecipesBoardsSelector,
  ) as Array<SmorgBoard>;

  const warningMessage =
    (availableInMembershipTierIDs || []).length === 0 &&
    'Your program will not be available to any users until you select at least one membership tier';

  const dispatch: ThunkDispatch<IProgrammesState, void, AnyAction> =
    useDispatch();

  const [descriptionDialogVisible, setDescriptionDialogVisible] =
    useState(false);

  const [mealTypesDialogVisible, setMealTypesDialogVisible] = useState(false);

  const [
    nutritionConstraintsDialogVisible,
    setNutritionConstraintsDialogVisible,
  ] = useState(false);

  const navigate = useNavigate();

  const onNewImageChosen = async (fileList: FileList) => {
    const newImageUrl = await uploadUserImage(imageObjectId, fileList);
    setImageUrl(newImageUrl);
    afterChange();
  };

  const onChangeDescription = (newDescription: string) => {
    setDescription(newDescription);
    afterChange();
  };

  const onChangeNutritionConstraints = (
    newConstraints: NutritionConstraints,
  ) => {
    setNutritionConstraints(newConstraints);
    afterChange();
  };

  const onChangeShowNutritionToUsers = (newValue: boolean) => {
    setShowNutritionToUsers(newValue);
    afterChange();
  };

  const onChangePersonalisedMealScaling = (newValue: boolean) => {
    setPersonalisedMealScaling(newValue);
    afterChange();
  };

  const membershipTiers = useSelector((state: RootState) =>
    state.membershipTiers.filter(
      (mt) => mt.state === MembershipTierState.ACTIVE,
    ),
  );

  const onChangeAvailableInMembershipTierID = (
    membershipTierID: string,
    isAvailable: boolean,
  ) => {
    if (
      isAvailable &&
      !availableInMembershipTierIDs?.includes(membershipTierID)
    ) {
      setAvailableInMembershipTierIDs((mtids) => [
        ...(mtids || []),
        membershipTierID,
      ]);
      setIsDirty(true);
    } else if (!isAvailable) {
      setAvailableInMembershipTierIDs((mtids) =>
        mtids?.filter((mtid) => mtid !== membershipTierID),
      );
      setIsDirty(true);
    }
  };

  const onChangeCopyMealsExactly = (isCopy: boolean) => {
    setCopyMealsExactly(isCopy);
    setIsDirty(true);
  };

  const onChangeAssignedRecipesBoard = (
    recipesBoardID: string,
    isAssigned: boolean,
  ) => {
    if (isAssigned && !recipesBoardIDs?.includes(recipesBoardID)) {
      setRecipesBoardIDs((oldRecipesBoardIDs) => [
        ...(oldRecipesBoardIDs || []),
        recipesBoardID,
      ]);
      setIsDirty(true);
    } else if (!isAssigned) {
      setRecipesBoardIDs((oldRecipesBoardIDs) =>
        oldRecipesBoardIDs?.filter(
          (oldRecipesBoardID) => oldRecipesBoardID !== recipesBoardID,
        ),
      );
      setIsDirty(true);
    }
  };

  const onChangeMealTypesAndCalorieSplits = (
    newMealTypes: Array<string>,
    newCalorieSplits: Array<CalorieSplit>,
  ) => {
    setMealTypes(newMealTypes);
    setCalorieSplits(newCalorieSplits);
    setMealTypesDialogVisible(false);
    afterChange();
  };

  const onChangeIsDefaultForNewUsers = (newValue: boolean) => {
    setIsDefaultForNewUsers(newValue);
    afterChange();
  };

  const handleSave = async () => {
    if (!title) {
      setErrorMessage('Type a name for the program');
      return;
    }
    if (!shortDescription) {
      setErrorMessage('Type a short description for the program');
      return;
    }
    try {
      setErrorMessage('');
      setIsLoading(true);
      dispatch(
        updateProgrammeAction(
          programme.id,
          {
            title,
            coverImageUrl: imageUrl,
            shortDescription,
            description,
            mealTypes,
            calorieSplits,
            categoryTags,
            nutritionConstraints,
            showNutritionToUsers,
            copyMealsExactly,
            personalisedMealScaling,
            availableInMembershipTierIDs,
            recipesBoardIDs,
          },
          () => navigate(`/programmes/${programme.id}`),
        ),
      );
      if (
        !!sharedProgrammeID &&
        initialIsDefaultForNewUsers !== isDefaultForNewUsers
      ) {
        dispatch(
          spaceSetDefaultSharedProgrammeIDForNewUsersAction(sharedProgrammeID),
        );
      }
    } catch (err) {
      console.log({ err });
    } finally {
      setIsLoading(false);
    }
  };

  const afterChange = () => {
    setIsDirty(true);
    setErrorMessage('');
  };

  const previewCategoryTitle =
    (categoryTags && categoryTags[0]) || 'All programs';

  return (
    <>
      <div className="newProgramme">
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Grid container columnSpacing={1} className="newProgrammeForm">
              <Grid item xs={12}>
                <h5>Program name</h5>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={title}
                  onChange={(ev) => {
                    setTitle(ev.target.value);
                    afterChange();
                  }}
                  autoFocus
                  margin="dense"
                  label="e.g. My new program"
                  type="text"
                  fullWidth
                  size="small"
                  variant="outlined"
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <h5>Program cover image</h5>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    className="mealDetailImage"
                    style={{
                      position: 'relative',
                      backgroundImage: `url("${imageUrl || imgPlaceholder}")`,
                    }}>
                    <ImageUploadButton
                      style={{ position: 'absolute', bottom: 10, right: 10 }}
                      onChange={onNewImageChosen}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <h5>Short description</h5>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={shortDescription}
                  onChange={(ev) => {
                    setShortDescription(ev.target.value);
                    afterChange();
                  }}
                  margin="dense"
                  label="Add a short description"
                  type="text"
                  fullWidth
                  size="small"
                  variant="outlined"
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <h5>Long description</h5>
              </Grid>
              <Grid item xs={12}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  to="#"
                  onClick={(ev) => {
                    setDescriptionDialogVisible(true);
                    ev.preventDefault();
                  }}>
                  Edit long description
                </Link>
              </Grid>
              <Grid item xs={12}>
                <h5>Meal types</h5>
              </Grid>
              <Grid item xs={12}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  to="#"
                  onClick={(ev) => {
                    setMealTypesDialogVisible(true);
                    ev.preventDefault();
                  }}>
                  Edit meal types
                </Link>
              </Grid>
              <Grid item xs={12}>
                <h5>Category tags</h5>
              </Grid>
              <Grid item xs={12}>
                <TagsInput
                  tags={categoryTags}
                  tagsSorted={[]}
                  onTagsChange={(newTags) => {
                    setCategoryTags(newTags);
                    setIsDirty(true);
                  }}
                  placeholder="Add a tag and press Enter"
                  skipNormalization
                />
              </Grid>
              <Grid item xs={12}>
                <h5>Nutrition constraints</h5>
              </Grid>
              <Grid item xs={12}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  to="#"
                  onClick={(ev) => {
                    setNutritionConstraintsDialogVisible(true);
                    ev.preventDefault();
                  }}>
                  Edit nutrition constraints
                </Link>
              </Grid>
              <Grid item xs={12}>
                <h5>Program behaviour</h5>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!copyMealsExactly}
                      onChange={(ev) => {
                        onChangeCopyMealsExactly(ev.target.checked);
                      }}
                    />
                  }
                  label="Copy meals exactly as defined"
                />
              </Grid>
              <Grid item xs={12}>
                <h5>Assign membership tier</h5>
              </Grid>
              <Grid item xs={12}>
                {membershipTiers.map((mt) => (
                  <FormControlLabel
                    key={mt.id}
                    control={
                      <Checkbox
                        checked={availableInMembershipTierIDs?.includes(mt.id)}
                        onChange={(ev) => {
                          onChangeAvailableInMembershipTierID(
                            mt.id,
                            ev.target.checked,
                          );
                        }}
                      />
                    }
                    label={mt.title}
                  />
                ))}
              </Grid>
              <Grid item xs={12}>
                <h5>Assign recipes boards</h5>
              </Grid>
              <Grid item xs={12}>
                {allStandaloneRecipesBoards.map((recipesBoard) => (
                  <FormControlLabel
                    key={recipesBoard.id}
                    control={
                      <Checkbox
                        checked={recipesBoardIDs.includes(recipesBoard.id)}
                        onChange={(ev) => {
                          onChangeAssignedRecipesBoard(
                            recipesBoard.id,
                            ev.target.checked,
                          );
                        }}
                      />
                    }
                    label={recipesBoard.title}
                  />
                ))}
              </Grid>
              {showuldShowDefaultForNewUsers && (
                <>
                  <Grid item xs={12}>
                    <h5>Set as default for new users</h5>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isDefaultForNewUsers}
                          onChange={(ev) => {
                            onChangeIsDefaultForNewUsers(ev.target.checked);
                          }}
                        />
                      }
                      label="Make this program the default for new users"
                    />
                  </Grid>
                </>
              )}
              {warningMessage && (
                <Grid item xs={12}>
                  <div style={{ paddingTop: '5px' }}>{warningMessage}</div>
                </Grid>
              )}
              {errorMessage && (
                <Grid item xs={12}>
                  <div style={{ paddingTop: '5px', color: 'red' }}>
                    {errorMessage}
                  </div>
                </Grid>
              )}
              <Grid item xs={12} style={{ margin: '15px 0', padding: '6px' }}>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  disabled={!isDirty || isLoading}>
                  Save
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ProgrammeCardPreview
              title={title}
              categoryTitle={previewCategoryTitle}
              coverImageUrl={imageUrl}
              shortDescription={shortDescription}
            />
          </Grid>
        </Grid>
      </div>
      {descriptionDialogVisible && (
        <EditProgrammeDescriptionModal
          description={description}
          previewProgrammeTitle={title}
          previewCoverImageUrl={imageUrl}
          onChangeDescription={onChangeDescription}
          onDismiss={() => setDescriptionDialogVisible(false)}
        />
      )}
      {mealTypesDialogVisible && (
        <EditProgrammeMealTypesModal
          mealTypes={mealTypes}
          calorieSplits={calorieSplits}
          onChange={onChangeMealTypesAndCalorieSplits}
          onDismiss={() => setMealTypesDialogVisible(false)}
        />
      )}
      {nutritionConstraintsDialogVisible && (
        <EditNutritionConstraintsModal
          nutritionConstraints={nutritionConstraints}
          showNutritionToUsers={showNutritionToUsers === true}
          personalisedMealScaling={personalisedMealScaling === true}
          onChangeNutritionConstraints={onChangeNutritionConstraints}
          onChangeShowNutritionToUsers={onChangeShowNutritionToUsers}
          onChangePersonalisedMealScaling={onChangePersonalisedMealScaling}
          onDismiss={() => setNutritionConstraintsDialogVisible(false)}
        />
      )}
    </>
  );
};

export default EditProgrammeForm;
