import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { currentEndUserSpaceSelector } from '../../reducers/user_reducer';

const NarrowEndUserMoreButton = ({ onClick, isLoading }) => {
  const backgroundColor = useSelector((state) => {
    const whiteLabelElements =
      currentEndUserSpaceSelector(state)?.whiteLabelElements;
    return (
      whiteLabelElements?.cardBackgroundColor ||
      whiteLabelElements?.primaryMainColor
    );
  });

  return (
    <div
      style={{
        display: 'flex',
        width: 166,
        height: 260,
        minWidth: 166,

        margin: '6px',
      }}>
      <Button
        onClick={onClick}
        variant="contained"
        size="medium"
        fullWidth
        disableElevation
        disabled={isLoading}
        style={{
          borderRadius: '8px',
          padding: '12px',
          margin: 'auto',
          backgroundColor,
        }}>
        {isLoading ? 'Loading...' : 'More...'}
      </Button>
    </div>
  );
};

NarrowEndUserMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default NarrowEndUserMoreButton;
