import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import {
  ContentEntry,
  EmbeddedProgrammeRecipesBoard,
  EntryType,
  GRCRecipe,
  Meal,
  NutritionConstraintsInput,
  Programme,
  ProgrammePlan,
  ProgrammePlanDay,
  SharedProgramme,
  SmorgBoard,
} from '../API';
import { addedItems, deduplicate } from './arrays';
import { plannerDayCalendarDate } from './planner';
import { mealIDsReferencedInBoard } from './smorg_board';

export const PROGRAMMES_STUDIO_FEATURE_CODE = 'programmesStudio';

export const PROGRAMME_MAX_WEEKS = 52;

export const PROGRAMME_PLANS_TITLE_PREFIX = 'week';

export const PROGRAMMES_DEFAULT_TARGET_CALORIES = 2000;

export const DAY_TITLES = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const embeddedRecipesBoardTitle = (programmeTitle: string) =>
  `Programme: ${programmeTitle}`;

export const programmesWithProgrammeUpdated = (
  programmes: Array<Programme>,
  programmeId: string,
  editInput: UpdateProgrammeInput,
) => {
  return programmes.map((pr) => {
    if (pr.id === programmeId) {
      const updatedProgramme = { ...pr, ...editInput };
      if (editInput.title && pr.recipesBoard) {
        updatedProgramme.recipesBoard = {
          ...pr.recipesBoard,
        } as EmbeddedProgrammeRecipesBoard;
        updatedProgramme.recipesBoard.title = embeddedRecipesBoardTitle(
          editInput.title,
        );
      }
      return updatedProgramme;
    }
    return pr;
  });
};

export const programmesWithPlanDaysUpdated = (
  programmes: Array<Programme>,
  programmeId: string,
  programmePlanId: string,
  planDays: Array<ProgrammePlanDay>,
) => {
  return programmes.map((pr) => {
    if (pr.id === programmeId) {
      return {
        ...pr,
        plans: pr.plans.map((pl) => {
          if (pl.id === programmePlanId) {
            return { ...pl, days: planDays };
          }
          return pl;
        }),
      };
    }
    return pr;
  });
};

export const programmesWithEmbeddedRecipesBoardUpdated = (
  programmes: Array<Programme>,
  programmeId: string,
  recipesBoard: SmorgBoard,
) => {
  return programmes.map((pr) => {
    if (pr.id === programmeId) {
      return {
        ...pr,
        recipesBoard,
      };
    }
    return pr;
  });
};

export interface UpdateProgrammeInput {
  title: string;
  shortDescription: string;
  description?: string | null;
  coverImageUrl?: string | null;
  nutritionConstraints: NutritionConstraintsInput | null;
  showNutritionToUsers: boolean | null;
  copyMealsExactly: boolean | null;
  personalisedMealScaling: boolean | null;
}

export interface UpdatePlanInput {
  title: string;
  shortDescription: string;
  description?: string | null;
  coverImageUrl?: string | null;
  nutritionConstraints: NutritionConstraintsInput | null;
  showNutritionToUsers: boolean | null;
}

export const programmesWithPlanMetadataUpdated = (
  programmes: Array<Programme>,
  programmeId: string,
  programmePlanId: string,
  editInput: UpdatePlanInput,
) => {
  return programmes.map((pr) => {
    if (pr.id === programmeId) {
      return {
        ...pr,
        plans: pr.plans.map((pl) => {
          if (pl.id === programmePlanId) {
            return { ...pl, ...editInput };
          }
          return pl;
        }),
      };
    }
    return pr;
  });
};

export const programmesWithPlanAdded = (
  programmes: Array<Programme>,
  programmeId: string,
  programmePlan: ProgrammePlan,
  addAfterProgrammePlanId: string,
) => {
  return programmes.map((pr) => {
    if (pr.id === programmeId) {
      const plans = [...pr.plans];

      const insertIndex = plans.findIndex(
        (plan) => plan.id === addAfterProgrammePlanId,
      );

      plans.splice(insertIndex + 1, 0, programmePlan);

      // eslint-disable-next-line no-plusplus
      for (let i = insertIndex + 2; i < plans.length; i++) {
        plans[i].title = `${PROGRAMME_PLANS_TITLE_PREFIX} ${i + 1}`;
      }

      return {
        ...pr,
        plans,
      };
    }
    return pr;
  });
};

export const programmesWithPlanRemoved = (
  programmes: Array<Programme>,
  programmeId: string,
  planId: string,
) => {
  return programmes.map((pr) => {
    if (pr.id === programmeId) {
      return {
        ...pr,
        plans: pr.plans.filter((pl) => pl.id !== planId),
      };
    }
    return pr;
  });
};

const planEntries = (plan: ProgrammePlan) =>
  plan.days.flatMap((d) => d.entries);

const programmeEntries = (programme: Programme | SharedProgramme) =>
  programme.plans.flatMap(planEntries);

export const programmeObjectIDsOfType = (
  programme: Programme | SharedProgramme,
  entryType: EntryType,
) => {
  const objectIDs = deduplicate(
    programmeEntries(programme)
      .filter((en) => en.programmeEntryType === entryType)
      .map((en) => en.objectID)
      .filter((objectID) => !!objectID),
  );
  console.log({ programmeID: programme.id, entryType, objectIDs });
  return objectIDs;
};

export const programmeObjectIDsOfTypeInPlan = (
  programme: Programme | SharedProgramme,
  planIndex: number,
  entryType: EntryType,
) => {
  const plan = programme.plans[planIndex];

  if (!plan || !plan.days) {
    return [];
  }

  return deduplicate(
    plan.days.flatMap((day) =>
      day.entries
        .filter((en) => en.programmeEntryType === entryType)
        .map((en) => en.objectID)
        .filter((objectID) => !!objectID),
    ),
  );
};

export const programmeEntryByEntryID = (
  programme: Programme,
  entryID: string,
) => {
  const entries = programmeEntries(programme).filter((e) => e.id === entryID);
  if (entries.length > 0) {
    return entries[0];
  }
  return null;
};

export const programmeEntryByObjectID = (
  programme: Programme,
  objectID: string,
) => {
  const entries = programmeEntries(programme).filter(
    (e) => e.objectID === objectID,
  );
  if (entries.length > 0) {
    return entries[0];
  }
  return null;
};

export const planEntryByEntryID = (plan: ProgrammePlan, entryID: string) => {
  const entries = planEntries(plan).filter((e) => e.id === entryID);
  if (entries.length > 0) {
    return entries[0];
  }
  return null;
};

/** Sorts Programmes and SharedProgrammes with the most recently updated item first */
export const programmesComparer = (
  a: Programme | SharedProgramme,
  b: Programme | SharedProgramme,
) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();

export const mealsMissingInProgrammes = (
  programmes: Array<Programme>,
  presentMeals: Record<string, Meal>,
) => {
  const existingMealIDs = Object.keys(presentMeals);
  const mealIDsReferencedInProgrammes = deduplicate(
    programmes.flatMap((pr) => programmeObjectIDsOfType(pr, EntryType.MEAL)),
  );
  console.log(`${mealIDsReferencedInProgrammes.length} referenced meals`);
  const missingMealIDs = addedItems(
    mealIDsReferencedInProgrammes,
    existingMealIDs,
  );
  console.log(`${missingMealIDs.length} missing meals`);
  return missingMealIDs;
};

export const grcRecipesMissingInProgrammes = (
  programmes: Array<Programme>,
  presentGRCRecipes: Record<string, GRCRecipe>,
) => {
  const existingGRCRecipeIDs = Object.keys(presentGRCRecipes);
  const referencedGRCRecipeIDs = deduplicate(
    programmes.flatMap((pr) =>
      programmeObjectIDsOfType(pr, EntryType.GRC_RECIPE),
    ),
  );
  console.log(`${referencedGRCRecipeIDs.length} referenced GRC recipes`);
  const missingGRCRecipeIDs = addedItems(
    referencedGRCRecipeIDs,
    existingGRCRecipeIDs,
  );
  console.log(`${missingGRCRecipeIDs.length} missing GRC recipes`);
  return missingGRCRecipeIDs;
};

export const mealsInProgrammes = (
  programmes: Array<Programme | SharedProgramme>,
) =>
  deduplicate(
    programmes.flatMap((pr) => programmeObjectIDsOfType(pr, EntryType.MEAL)),
  );

export const notesInProgrammes = (
  programmes: Array<Programme | SharedProgramme>,
) =>
  deduplicate(
    programmes.flatMap((pr) => programmeObjectIDsOfType(pr, EntryType.NOTE)),
  );

export const contentEntriesInProgrammes = (
  programmes: Array<Programme | SharedProgramme>,
) =>
  deduplicate(
    programmes.flatMap((pr) =>
      programmeObjectIDsOfType(pr, EntryType.CONTENT_ENTRY),
    ),
  );

export const programmePlanDayIndex = (
  dayId: string,
  days: Array<ProgrammePlanDay>,
) => {
  const idxZeroBased = days.findIndex((d) => d.id === dayId);
  if (idxZeroBased === -1) {
    console.warn(`Day ${dayId} not found, should never happen!`);
    return null;
  }
  return idxZeroBased === 6 ? 0 : idxZeroBased + 1;
};

export const programmePlanDayIDFromDayIndex = (
  dayIndex: number,
  days: Array<ProgrammePlanDay>,
) => {
  const idxZeroBased = dayIndex === 0 ? 6 : dayIndex - 1;
  return days[idxZeroBased].id;
};

export const programmePlanDayIDOfEntry = (
  entryID: string,
  days: Array<ProgrammePlanDay>,
) => {
  const day = days.find((d) => d.entries.some((e) => e.id === entryID));
  if (day) {
    return day.id;
  }
  return null;
};

export const emptyProgrammePlan = () => {
  const days = [];

  // eslint-disable-next-line no-plusplus
  for (let d = 0; d < 7; d++) {
    days.push({
      id: uuidv4(),
      title: DAY_TITLES[d],
      entries: [],
    });
  }

  return days;
};

export const daysSince = (currentDate: string, referenceDate: string) =>
  dayjs(currentDate).diff(referenceDate, 'day');

export const programmeDaysForCalendar = (
  programmePlanWithDays: Array<ProgrammePlan>,
  programmeStartDay: string,
  dbPlannerWeekStartDate: string,
  dayIndex: number,
  numDays: number,
) => {
  const programmePlanDays = programmePlanWithDays.flatMap((ppd) => ppd.days);
  const recommenderInvokedForDate = plannerDayCalendarDate(
    dbPlannerWeekStartDate,
    dayIndex,
  );
  const daysSinceStartOfProgramme = daysSince(
    recommenderInvokedForDate,
    programmeStartDay,
  );
  console.log(
    `Recommender invoked for ${recommenderInvokedForDate}, it is ${daysSinceStartOfProgramme} days since user started the programme`,
  );
  return programmePlanDays.slice(
    daysSinceStartOfProgramme,
    daysSinceStartOfProgramme + numDays,
  );
};

const programmeExportJson = (programme: Programme, meals: Array<Meal>) => {
  const exportedProgrammeObj: any = { ...programme };
  exportedProgrammeObj.shareRecords = [];
  exportedProgrammeObj.availableInMembershipTierIDs = [];
  delete exportedProgrammeObj.owner;
  const alreadyLinkedMealIDs = deduplicate([
    ...mealIDsReferencedInBoard(exportedProgrammeObj.recipesBoard),
    ...mealIDsReferencedInBoard(exportedProgrammeObj.databaseRecipesBoard),
  ]);
  const unlinkedMeals = meals.filter(
    (meal) => !alreadyLinkedMealIDs.includes(meal.id),
  );
  if (unlinkedMeals.length > 0) {
    console.warn(
      `${unlinkedMeals.length} meals are not linked to the programme, will add them to the recipes board`,
    );
    if (!exportedProgrammeObj.recipesBoard) {
      exportedProgrammeObj.recipesBoard = {
        id: uuidv4(),
        title: `Programme: ${programme.title}`,
        menus: [],
      };
    }
    if (!exportedProgrammeObj.recipesBoard.menus[0]) {
      exportedProgrammeObj.recipesBoard.menus = [
        { id: uuidv4(), title: 'Meals', mealIDs: [] },
      ];
    }
    const unlinkedMealIDs = unlinkedMeals.map((m) => m.id);
    exportedProgrammeObj.recipesBoard.menus[0].mealIDs.push(...unlinkedMealIDs);
  }
  return JSON.stringify(exportedProgrammeObj, null, 2);
};

const programmeMealExportJson = (meal: Meal) => {
  const exportedMealObj: any = { ...meal };
  delete exportedMealObj.smorgBoardID;
  delete exportedMealObj.owner;
  delete exportedMealObj.spaceMembershipID;
  delete exportedMealObj.additionallyReferredToBy;
  return JSON.stringify(exportedMealObj, null, 2);
};

const programmeContentEntryExportJson = (contentEntry: ContentEntry) => {
  const exportedContentEntryObj: any = { ...contentEntry };
  delete exportedContentEntryObj.parentID;
  delete exportedContentEntryObj.owner;
  return JSON.stringify(exportedContentEntryObj, null, 2);
};

/* eslint-disable no-restricted-syntax */
export const downloadProgrammeAndObjects = async (
  programme: Programme,
  meals: Array<Meal>,
  contentEntries: Array<ContentEntry>,
) => {
  const zip = new JSZip();
  zip.file('programme.json', programmeExportJson(programme, meals), {});
  for (const meal of meals) {
    zip.file(`meals/${meal.id}.json`, programmeMealExportJson(meal), {});
  }
  for (const contentEntry of contentEntries) {
    zip.file(
      `contentEntries/${contentEntry.id}.json`,
      programmeContentEntryExportJson(contentEntry),
      {},
    );
  }
  const blob = await zip.generateAsync(
    { type: 'blob', compression: 'DEFLATE' },
    (metadata: any) => {
      let msg = `progress: ${metadata.percent.toFixed(2)} %`;
      if (metadata.currentFile) {
        msg += `, current file = ${metadata.currentFile}`;
      }
      console.log(msg);
    },
  );
  saveAs(blob, `${programme.id} ${programme.title}.zip`);
};

export const readProgrammeAndObjects = async (blob: Blob) => {
  const zip = new JSZip();
  const zipContent = await zip.loadAsync(blob);
  let programme = null as Programme | null;
  const meals = [] as Array<Meal>;
  const contentEntries = [] as Array<ContentEntry>;
  const promises: Array<Promise<void> | undefined> = [];
  zipContent.forEach((relativePath) => {
    if (relativePath === 'programme.json') {
      promises.push(
        zip
          .file(relativePath)
          ?.async('string')
          .then((content) => {
            if (content) {
              programme = JSON.parse(content);
            }
          }),
      );
    } else if (
      relativePath.startsWith('meals/') &&
      relativePath.endsWith('.json')
    ) {
      promises.push(
        zip
          .file(relativePath)
          ?.async('string')
          .then((content) => {
            if (content) {
              // try {
              meals.push(JSON.parse(content));
              // } catch (e) {
              //   console.warn(`Error parsing meal ${relativePath}: ${e}`);
              // }
            }
          }),
      );
    } else if (
      relativePath.startsWith('contentEntries/') &&
      relativePath.endsWith('.json')
    ) {
      promises.push(
        zip
          .file(relativePath)
          ?.async('string')
          .then((content) => {
            if (content) {
              // try {
              contentEntries.push(JSON.parse(content));
              // } catch (e) {
              //   console.warn(`Error parsing meal ${relativePath}: ${e}`);
              // }
            }
          }),
      );
    }
  });
  await Promise.all(promises);
  return { programme, meals, contentEntries };
};

/* eslint-disable no-restricted-syntax */
export const prepareNewProgrammeFromImport = (
  programme: Programme,
  meals: Array<Meal>,
  contentEntries: Array<ContentEntry>,
) => {
  const newProgramme = { ...programme };
  newProgramme.id = uuidv4();
  const oldMealIDs = deduplicate([
    ...mealIDsReferencedInBoard(programme.recipesBoard),
    ...mealIDsReferencedInBoard(programme.databaseRecipesBoard),
    ...programmeObjectIDsOfType(newProgramme, EntryType.MEAL),
  ]);
  const newMealIDMap = {} as Record<string, string>;
  for (const oldMealID of oldMealIDs) {
    newMealIDMap[oldMealID] = uuidv4();
  }
  const newMeals = meals.map((meal) => ({
    ...meal,
    id: newMealIDMap[meal.id],
  }));
  const oldContentEntryIDs = programmeObjectIDsOfType(
    newProgramme,
    EntryType.CONTENT_ENTRY,
  );
  const newContentEntryIDMap = {} as Record<string, string>;
  for (const oldContentEntryID of oldContentEntryIDs) {
    newContentEntryIDMap[oldContentEntryID] = uuidv4();
  }
  const newContentEntries = contentEntries.map((contentEntry) => ({
    ...contentEntry,
    id: newContentEntryIDMap[contentEntry.id],
  }));
  for (const menu of programme.recipesBoard?.menus || []) {
    menu.mealIDs = menu.mealIDs.map((mealID) => newMealIDMap[mealID]);
  }
  for (const menu of programme.databaseRecipesBoard?.menus || []) {
    menu.mealIDs = menu.mealIDs.map((mealID) => newMealIDMap[mealID]);
  }
  for (const plan of programme.plans) {
    for (const day of plan.days) {
      day.entries = day.entries.map((entry) => {
        if (entry.programmeEntryType === EntryType.MEAL) {
          return {
            ...entry,
            objectID: newMealIDMap[entry.objectID],
          };
        }
        if (entry.programmeEntryType === EntryType.CONTENT_ENTRY) {
          return {
            ...entry,
            objectID: newContentEntryIDMap[entry.objectID],
          };
        }
        return entry;
      });
    }
  }

  return {
    programme: newProgramme,
    meals: newMeals,
    contentEntries: newContentEntries,
  };
};

export const numDaysInProgramme = (programme: Programme) =>
  programme.plans
    .flatMap((pl) => pl.days.length)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
