import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { EntryType } from '../../API';
import Board from '../common/board';
import { useIsMobile } from '../common/layout_hooks';
import ContentEntryDetailContainer from '../programmes/content_entry_detail_container';
import usePrevious from '../../services/use_previous';
import { scrollNewObjectCardIntoView } from '../../services/smorg_board';
import SpaceContentBoardEntryCard from './space_content_board_entry_card';
import { spaceContentBoardEntryByEntryID } from '../../services/spaces';
import {
  ensureSpaceContentBoardExistsAction,
  spaceContentBoardEntryDeletedAction,
  spaceContentBoardEntryMovedAction,
  spaceContentBoardLaneAddedAction,
  spaceContentBoardLaneDeletedAction,
  spaceContentBoardLaneMovedAction,
  spaceContentBoardLaneRenamedAction,
} from '../../action_creators/spaces_action_creators';
import SpaceContentBoardAddCardLink from './space_content_board_add_card_link';
import SpaceContentBoardNewLaneButton from './space_content_board_new_lane_button';
import SpaceContentBoardNewLaneForm from './space_content_board_new_lane_form';
import SpaceContentBoardLaneHeader from './space_content_board_lane_header';
import { SMORG_STUDIO_SPACE_SHARED_CONTENT_BOARD_ENTRY_ROUTE } from '../../services/routes';
import SharedContentBoardNavigationBar from './shared_content_board_navigation_bar';

const components = {
  Card: SpaceContentBoardEntryCard,
  AddCardLink: SpaceContentBoardAddCardLink,
  NewLaneForm: SpaceContentBoardNewLaneForm,
  NewLaneSection: SpaceContentBoardNewLaneButton,
  LaneHeader: SpaceContentBoardLaneHeader,
};

const labels = {
  'Add another lane': '+ Add another lane',
  'Delete lane': 'Delete lane',
  'Lane actions': 'Lane actions',
  button: {
    'Add lane': 'Add lane',
    'Add card': 'Add content',
    Cancel: 'Cancel',
  },
  placeholder: {
    title: 'title',
    description: 'description',
    label: 'label',
  },
};

const customTranslation = (key) => labels[key];

const boardCss = { textAlign: 'left' };
// This screen is not meant to be used on mobile screens
const mobileHeightRule = { height: 'calc(var(--app-height) - 119px)' };
const desktopHeightRule = { height: 'calc(var(--app-height) - 50px)' };
const mobileBoardCss = { ...boardCss, ...mobileHeightRule };
const desktopBoardCss = { ...boardCss, ...desktopHeightRule };

const SpaceContentBoardView = () => {
  const { sharedContentBoardID: routeSharedContentBoardID } = useParams();

  const spaceContentBoard = useSelector((state) =>
    routeSharedContentBoardID
      ? (state.sharedContentBoards || []).find(
          (b) => b.id === routeSharedContentBoardID,
        )
      : (state.sharedContentBoards || [])[0],
  );

  const sharedContentEntries = useSelector(
    (state) => state.sharedContentEntries,
  );

  const allEntryIDs = (spaceContentBoard?.lanes || []).flatMap((lane) =>
    lane.entries.map((e) => e.id),
  );
  const memoizedEntryIDs = useMemo(() => allEntryIDs, [allEntryIDs]);
  const previousEntryIDs = usePrevious(memoizedEntryIDs);

  // console.log({ memoizedEntryIDs, previousEntryIDs });

  useEffect(() => {
    scrollNewObjectCardIntoView(memoizedEntryIDs, previousEntryIDs);
  }, [memoizedEntryIDs, previousEntryIDs]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { entryId } = useParams();

  const getDetailVisibleFor = () => {
    if (!entryId || !spaceContentBoard?.lanes) {
      return null;
    }
    const entry = spaceContentBoardEntryByEntryID(spaceContentBoard, entryId);
    if (!entry) {
      return null;
    }
    return {
      entryID: entryId,
      entryType: entry.entryType,
      objectID: entry.objectID,
    };
  };

  const detailVisibleFor = getDetailVisibleFor();

  const reactTrelloData = {
    lanes: (spaceContentBoard?.lanes || []).map((lane) => {
      const cards = lane.entries
        .map((entry) => {
          if (entry.entryType === EntryType.CONTENT_ENTRY) {
            const sharedContentEntry = sharedContentEntries[entry.objectID];
            if (!sharedContentEntry) {
              // Note not yet loaded
              return null;
            }
            return {
              id: entry.id,
              title: sharedContentEntry.title,
              description: sharedContentEntry.body || '',
              style: {
                _entryType: entry.entryType,
                _objectID: entry.objectID,
              },
            };
          }
          return null;
        })
        .filter((cardData) => !!cardData);

      return {
        id: lane.id,
        title: lane.title,
        cards,
      };
    }),
  };

  const onCardClick = useCallback(
    (cardId, metadata, laneId) => {
      console.log({ cardId, metadata, laneId });
      navigate(
        formatRoute(SMORG_STUDIO_SPACE_SHARED_CONTENT_BOARD_ENTRY_ROUTE, {
          sharedContentBoardID: spaceContentBoard.id,
          entryId: cardId,
        }),
      );
    },
    [navigate, spaceContentBoard?.id],
  );

  const afterCardDrag = useCallback(
    (cardId, sourceLaneId, targetLaneId, position) => {
      dispatch(
        spaceContentBoardEntryMovedAction(
          spaceContentBoard.id,
          cardId,
          sourceLaneId,
          targetLaneId,
          position,
        ),
      );
      // Cancel the drop, because the board will re-render with the new structure.
      return false;
    },
    [dispatch, spaceContentBoard?.id],
  );

  const afterCardDelete = useCallback(
    (cardId, laneId) => {
      dispatch(
        spaceContentBoardEntryDeletedAction(
          spaceContentBoard.id,
          laneId,
          cardId,
        ),
      );
    },
    [dispatch, spaceContentBoard?.id],
  );

  const afterLaneAdd = useCallback(
    (params) => {
      const { id, title } = params;
      if (title && title.length > 0) {
        dispatch(
          spaceContentBoardLaneAddedAction(spaceContentBoard.id, id, title),
        );
      }
    },
    [dispatch, spaceContentBoard?.id],
  );

  const afterLaneDelete = useCallback(
    (laneId) => {
      dispatch(spaceContentBoardLaneDeletedAction(laneId));
    },
    [dispatch],
  );

  const afterLaneDrag = useCallback(
    (removedIndex, addedIndex, payload) => {
      const laneId = payload.id;
      dispatch(
        spaceContentBoardLaneMovedAction(
          spaceContentBoard.id,
          laneId,
          removedIndex,
          addedIndex,
        ),
      );
    },
    [dispatch, spaceContentBoard?.id],
  );

  const afterLaneUpdate = useCallback(
    (laneId, data) => {
      console.log({ laneId, data });
      // Only the title can be edited
      const updatedTitle = data.title;
      if (updatedTitle && updatedTitle.length > 0) {
        dispatch(
          spaceContentBoardLaneRenamedAction(
            spaceContentBoard.id,
            laneId,
            updatedTitle,
          ),
        );
      }
    },
    [dispatch, spaceContentBoard?.id],
  );

  const isMobile = useIsMobile();

  useEffect(() => {
    if (!spaceContentBoard?.id) {
      dispatch(ensureSpaceContentBoardExistsAction());
    }
  }, [dispatch, spaceContentBoard?.id]);

  return (
    <>
      <SharedContentBoardNavigationBar
        sharedContentBoardID={spaceContentBoard?.id}
      />
      <div className="smorg-board-container" id="space-content-board">
        <Board
          editable
          draggable
          cardDraggable
          editLaneTitle
          canAddLanes
          appBoardId={spaceContentBoard?.id}
          data={reactTrelloData}
          components={components}
          onCardClick={onCardClick}
          onCardDelete={afterCardDelete}
          handleDragEnd={afterCardDrag}
          onLaneAdd={afterLaneAdd}
          onLaneDelete={afterLaneDelete}
          onLaneUpdate={afterLaneUpdate}
          handleLaneDragEnd={afterLaneDrag}
          style={isMobile ? mobileBoardCss : desktopBoardCss}
          backdropStyle={isMobile ? mobileHeightRule : desktopHeightRule}
          t={customTranslation}
        />
      </div>
      {detailVisibleFor?.entryType === EntryType.CONTENT_ENTRY && (
        <ContentEntryDetailContainer
          contentEntryID={detailVisibleFor.objectID}
          visible
          appLinkEnabled
          dismiss={() => navigate(-1)}
        />
      )}
    </>
  );
};

export default SpaceContentBoardView;
