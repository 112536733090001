import React, { useRef } from 'react';
import { Grid, IconButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mdi/react';
import { mdiCamera } from '@mdi/js';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';
import { uploadUserImage } from '../../services/user_generated_content';
import { updateStandaloneRecipesBoardCoverImageUrlAction } from '../../action_creators/recipes_action_creators';
import PopupTitle from '../common/popup_title';
import { isEmbeddedInSmorgCompanion } from '../../services/smorg_companion_auth';

/**
 * Hack to disable 'Take photo' as an input option
 * in the embedded app.
 * This would require the permission from the user
 * to access the camera.
 * Without the permission, the app crashes.
 */
const imageInputAcceptMimeTypes = isEmbeddedInSmorgCompanion()
  ? 'application/octet-stream'
  : 'image/png, image/jpeg, image/webp';

const RecipeCollectionsEditCoverImageView = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { boardID } = useParams();

  const coverImageUrl = useSelector((state) => {
    const board = state.recipesBoards.find((b) => b.id === boardID);
    return board?.coverImageUrl;
  });

  const fileRef = useRef();

  const onNewImageChosen = async (fileList) => {
    const newImageUrl = await uploadUserImage(boardID, fileList);
    dispatch(
      updateStandaloneRecipesBoardCoverImageUrlAction(boardID, newImageUrl),
    );
  };

  const onClickUpload = () => {
    fileRef.current.click();
  };

  return (
    <div className="collections-view">
      <input
        ref={fileRef}
        onChange={() => {
          onNewImageChosen(fileRef.current.files);
          fileRef.current.value = '';
        }}
        multiple={false}
        type="file"
        accept={imageInputAcceptMimeTypes}
        hidden
      />
      <div className="topNavBarMobile" style={{ flexDirection: 'column' }}>
        <PopupTitle
          titleText="Edit cover image"
          backButtonEnabled
          onClickBack={() => navigate(-1)}
        />
      </div>
      <div style={{ marginLeft: '23px' }}>
        <h2>Edit cover image</h2>
        <Grid container>
          <Grid
            item
            xs={12}
            className="mealDetailImage"
            style={{
              marginRight: '23px',
              position: 'relative',
              backgroundImage: `url("${coverImageUrl || imgPlaceholder}")`,
            }}>
            <IconButton
              sx={{
                backgroundColor: 'rgba(225, 225, 225, 0.6)',
                '&:hover': { backgroundColor: 'rgba(225, 225, 225, 1)' },
              }}
              style={{ position: 'absolute', bottom: 10, right: 10 }}
              onClick={onClickUpload}
              title="Upload new image">
              <Icon
                path={mdiCamera}
                style={{ width: '24px', height: '24px' }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default RecipeCollectionsEditCoverImageView;
